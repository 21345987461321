import React, { useState, useEffect } from "react";
import { useParams,useNavigate, useLocation } from "react-router-dom";
import Paths from "../../paths";
import { apiRequest, apiRoutes } from "./../../services";
import "./AddEditUser.css"
import PageSection from "../../components/PageSection/PageSection";
import InputFile from "../../components/InputFile/InputFile";
import { notificationSubject } from '../../services/subjects';


const AddEditUser = () => {
  const routeParams = useParams();
  const history = useNavigate();
  const state = useLocation()
  const [userInfo, setUser] = useState(null)
  const [error, setError] = useState(null);
  const [roles, setRoles] = useState([])
  const [managers, setManagers] = useState([])
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false); 
  const [form, setForm] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [inputErrors, setErrorInputs] = useState(null);
  const [migrationApp, setMigrationApp] = useState(null);
  const [successOnDemandApp, setSuccessOnDemandApp] = useState(null);
  const [adminApp, setAdminApp] = useState(null);
  const [healthCheck, setHealthCheck] = useState(null);
  const [onBoarding, setOnboarding] = useState(null);
  // const { email, user} = state.state

  useEffect(() => {
    getRoles();
    getManagers();
    if (routeParams.id) {
      getUser();
    } else {
    }
  }, []);



  const getUser = async () => {
    const [error, user] = await apiRequest({
      method: "post",
      url: apiRoutes.userDetails,
      data: {"userEmail": state.state.email, "userName": state.state.user}
    });
    if (user) {
      
      const obj = {
        userName: user.userName,
        userEmail: user.userEmail,
        userRole: user.userRole,
        userJobTitle: user.userJobTitle ? user.userJobTitle: '',
        userManager: user.userManager ? user.userManager: '',
        userProfilePhotoUrl: user.userProfilePhotoUrl,
      }
      user.appAuthorizations && user.appAuthorizations.map(role => {
        role.roleApppermissionInfo.permissions.map(app => {
          switch (app.appName) {
            case 'HealthCheckApp':
              setHealthCheck(app);
              break;
            case 'AdministrationApp':
              setAdminApp(app);
              break;
            case 'SuccessOnDemandApp':
              setSuccessOnDemandApp(app);
              break;
            case 'OnBoardingApp':
              setOnboarding(app);
              break;
          }
        })
      })
      setForm(obj);
      setUser(obj);
    }
    if (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const getRoles = async () => {
    const [error, apiRoles] = await apiRequest({
      method: "get",
      url: apiRoutes.getRoles,
    });
    if (apiRoles) {
      setRoles(apiRoles)
    }
    if (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const getManagers = async () => {
    const [error, apiManagers] = await apiRequest({
      method: "get",
      url: apiRoutes.getManagers,
    });
    if (apiManagers) {
      setManagers(apiManagers)
    }
    if (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const validateForm = () => {
    let formErrors = {
      userName: false,
      userEmail: false,
      userRole: false,
      userManager: false
    };
    if (!form?.userName) {
      formErrors.userName = true;
    }
    if (!form?.userEmail) {
      formErrors.userEmail = true;
    }
    if (!form?.userRole) {
      formErrors.userRole = true;
    }
    if (!form?.userManager) {
      formErrors.userManager = true;
    }
 
    setErrorInputs(formErrors);
    return (
      !formErrors.userName &&
      !formErrors.userEmail &&
      !formErrors.userRole &&
      !formErrors.userManager 
    );
  };

  const submitForm = async() => {
    if(validateForm()) {
      let permissionsArray = [
        onBoarding && onBoarding.permissions.length > 0 ? onBoarding : null,
        healthCheck && healthCheck.permissions.length > 0 ? healthCheck : null,
        adminApp && adminApp.permissions.length > 0 ? adminApp : null,,
        successOnDemandApp && successOnDemandApp.permissions.length > 0 ? successOnDemandApp : null,
      ]
      form.appAuthorizations = permissionsArray.filter(Boolean)
      if(routeParams.id) {
        const [error, data] = await apiRequest({
          method: "put",
          url: apiRoutes.users,
          data: {...form}
        });
        if (!error) {
          notificationSubject.next({ message: 'Successfully updated user', type: 'SUCCESS' });
          history(Paths.Users)
        } else {
          notificationSubject.next({ message: 'There was a problem creating the user', type: 'ERROR' });
          setError(error);
        }

      } else {
        const [error, data] = await apiRequest({
          method: "post",
          url: apiRoutes.users,
          data: {...form}
        });
        if (!error) {
          notificationSubject.next({ message: 'Successfully created user', type: 'SUCCESS' });
          history(Paths.Users)
        } else {
          notificationSubject.next({ message: 'There was a problem creating the user', type: 'ERROR' });
          setError(error);
        }
      }
    setIsLoading(false);
    }
  }

  const deleteUser = async() => {
    const [error, data] = await apiRequest({
      method: "put",
      url: apiRoutes.userDetails,
      data: {userEmail: state.state.email}
    });
    if (data) {
      history(Paths.Users)
      notificationSubject.next({ message: 'Successfully deleted user', type: 'SUCCESS' });
    }
    if (error) {
      setError(error);
      notificationSubject.next({ message: 'There was a problem deleting the user', type: 'ERROR' });
    }
  }

  const onSelectedOption = (e) => {
    let updateForm = {...form};
    updateForm[e.target.id] = e.target.value;
    setForm(updateForm)
  }

  const onOnboardingChange = (e) => {
    let change = {...onBoarding};
    if( change.permissions) {
      if(change.permissions.includes(e.target.id)) {
        let index = change.permissions.indexOf(e.target.id);
        if (index !== -1) {
          change.permissions.splice(index, 1);
        }
      } else {
        change.permissions.push(e.target.id)
      }
    } else {
      change = {
        appName: 'OnBoardingApp',
        permissions: [e.target.id]
      }
    }
  setOnboarding(change)
  }

  const onAdminChange = (e) => {
    let change = {...adminApp};
    if( change.permissions) {
      if(change.permissions.includes(e.target.id)) {
        let index = change.permissions.indexOf(e.target.id);
        if (index !== -1) {
          change.permissions.splice(index, 1);
        }
      } else {
        change.permissions.push(e.target.id)
      }
    } else {
      change = {
        appName: 'AdministrationApp',
        permissions: [e.target.id]
      }
    }
  setAdminApp(change)
  }

  const onSuccessChange = (e) => {
    let change = {...successOnDemandApp};
    if( change.permissions) {
      if(change.permissions.includes(e.target.id)) {
        let index = change.permissions.indexOf(e.target.id);
        if (index !== -1) {
          change.permissions.splice(index, 1);
        }
      } else {
        change.permissions.push(e.target.id)
      }
    } else {
      change = {
        appName: 'SuccessOnDemandApp',
        permissions: [e.target.id]
      }
    }
  setSuccessOnDemandApp(change)
  }

  const onHealthChange = (e) => {
    let change = {...healthCheck};
    if( change.permissions) {
      if(change.permissions.includes(e.target.id)) {
        let index = change.permissions.indexOf(e.target.id);
        if (index !== -1) {
          change.permissions.splice(index, 1);
        }
      } else {
        change.permissions.push(e.target.id)
      }
    } else {
      change = {
        appName: 'HealthCheckApp',
        permissions: [e.target.id]
      }
    }
  setHealthCheck(change)
  }

  const handleChangeInput = (e) => {
    let updateForm = {...form};
    updateForm[e.target.id] = e.target.value;
    setForm(updateForm)
  }

  const propsButtonSave = {
    disabled: isLoading,
    text: "Save",
    onAction: () => submitForm(),
  };
  const propsButtonDelete = {
    disabled: true,
    text: "Delete",
    onAction: () => deleteUser(),
  };

  return (
    <div className="page-content user-detail">

      <>
      <PageSection title={routeParams.id ? "User": " Create user" } primaryAction={propsButtonSave} secondaryActions={routeParams.id && [propsButtonDelete]}>
          <div className="user-form">
            <div className="form-section">
             <h5>General Info</h5> 
            </div>
          <div className="form-layout">
          <label> Username
            <input
              id="userName"
              type="text"
              value={form.userName ? form.userName : ""}
              onChange={(event) => handleChangeInput(event)}>
            </input>
            {
              inputErrors && inputErrors.userName && <h4 className="input-error">Field is required</h4>
            }
          </label>
            
          
          <label> Email
            <input
              id="userEmail"
              type="email"
              value={form.userEmail ? form.userEmail : ""}
              onChange={(event) => handleChangeInput(event)} readOnly={routeParams.id}>
            </input>
            {
              inputErrors && inputErrors.userEmail && <h4 className="input-error">Field is required</h4>
            }
          </label>
          
          <label> Job Title
            <input
              id="userJobTitle"
              type="text"
              value={form.userJobTitle ? form.userJobTitle : ""}
              onChange={(event) => handleChangeInput(event)}>
            </input>
            {
              inputErrors && inputErrors.userJobTitle && <h4 className="input-error">Field is required</h4>
            }
          </label>
          <label> Manager
            <select id="userManager" className="select-input" value={form.userManager} onChange={(e) => onSelectedOption(e)}>
              {managers.map((manager, i) => {
                return (
                  <option key={i} value={manager}>{manager} </option>
                )
              })}
            </select>
            {
              inputErrors && inputErrors.userManager && <h4 className="input-error">Field is required</h4>
            }
          </label>
         
          
            <label> User Picture
              <input
                id="userProfilePhotoUrl"
                type="text"
                value={form.userProfilePhotoUrl ? form.userProfilePhotoUrl : ""}
                onChange={(event) => handleChangeInput(event)}>
              </input>
            </label>
          </div>
          <div className="form-section">
             <h5>Application Permission</h5> 
          </div>
          <div className="form-layout">
          <label> Role
            <select id="userRole" className="select-input" value={form.userRole} onChange={(e) => onSelectedOption(e)}>
              {roles.map((option, i) => {
                return (
                  <option key={i} value={option.roleName}>{option.roleName} </option>
                )
              })}
            </select>
            {
              inputErrors && inputErrors.userRole && <h4 className="input-error">Field is required</h4>
            }
          </label>
          <div className="role-container">
          <label>
            Healthcheck
            <div className="switch-label">
            
            <div className="switch-container">
               <label>Edit</label>
                <input type="checkbox" id="WRITE" onChange={(e) => onHealthChange(e)} checked={healthCheck && healthCheck.permissions.includes('WRITE')}/>
                <label>Read</label>
              <input type="checkbox" id="READ" onChange={(e) => onHealthChange(e)}  checked={healthCheck && healthCheck.permissions.includes('READ')}/>
            </div>
            </div>
          </label>
          <label>
            Onboarding
            <div className="switch-label">
            
            <div className="switch-container">
               <label>Edit</label>
                <input type="checkbox" id="WRITE" onChange={(e) => onOnboardingChange(e)} checked={onBoarding && onBoarding.permissions.includes('WRITE')}/>
                <label>Read</label>
              <input type="checkbox" id="READ" onChange={(e) => onOnboardingChange(e)}  checked={onBoarding && onBoarding.permissions.includes('READ')}/>
            </div>
            </div>
          </label>
          <label>
            Administration
            <div className="switch-label">
            
            <div className="switch-container">
               <label>Edit</label>
                <input type="checkbox" id="WRITE" onChange={(e) => onAdminChange(e)}  checked={adminApp && adminApp.permissions.includes('WRITE')}/>
                <label>Read</label>
              <input type="checkbox" onChange={(e) => onAdminChange(e)} id="READ" checked={adminApp && adminApp.permissions.includes('READ')}/>
            </div>
            </div>
          </label>
          <label>
            Success on Demand
            <div className="switch-label">
            
            <div className="switch-container">
               <label>Edit</label>
                <input type="checkbox" id="WRITE" onChange={(e) => onSuccessChange(e)}  checked={successOnDemandApp && successOnDemandApp.permissions.includes('WRITE')}/>
                <label>Read</label>
              <input type="checkbox" onChange={(e) => onSuccessChange(e)} id="READ" checked={successOnDemandApp && successOnDemandApp.permissions.includes('READ')}/>
            </div>
            </div>
          </label>
          </div>
            </div> 
            </div>
          </PageSection>
      </>
      
  </div>
  )
}
export default AddEditUser;