
import {
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/PagerDuty-WhiteRGB_white.svg";
import { Handle } from "react-flow-renderer";

export default function ModalHealthCheckAlert({setShowGWP, newItem, onShowModalHealthCheckAlert, onePage, onShowGWP, getSelectedGWP, showGWP}) {

	const backgroundStatus = (status) => {
    const severityMapping = {
      'Critical': { 'backgroundStatus': '#df1010', 'color': '#fff' },
      'Major': { 'backgroundStatus': '#FA640A', 'color': '#fff' },
      'High': { 'backgroundStatus': '#f7c604', 'color': '#fff' },
      "Medium": { 'backgroundStatus': '#690375', 'color': '#fff' },
      "Low": { 'backgroundStatus': '#329cad', 'color': '#fff' },
      "Minor": { 'backgroundStatus': '#cecece', 'color': '#808080' },
    };
    return severityMapping[status] || { 'backgroundStatus': '#06AC38', 'color': '#fff' };
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

	return (    
		<div className="container__detail">
				 <section className="container__section">
					<div className="modal__containerDetail">
						<div className="header__modalHealthCheckAlert">
							<div>Health Check Alert</div>
							<div onClick={() => onShowModalHealthCheckAlert(false)} ><FontAwesomeIcon icon={faX} /></div>
						</div>
						{onePage && Object.keys(onePage).length > 0 &&
							<div className="container__modalHealthCheckAlert">
									<div>
											<div className="pagerDutyObject">PagerDuty Object: {capitalizeFirstLetter(onePage?.pd_object)}</div>
											<div className="healthCheckRule">Health Check Rule: {onePage?.hc_rule}</div>
											<div style={{backgroundColor: onePage?.long_urgency === 1 ?  "#e8c900"  : backgroundStatus(onePage?.urgency[0]?.split('/')[0].trim()).backgroundStatus, color: onePage?.long_urgency === 1 ?  "#000" :''}} className="status__modalHealthCheckAlert">
												{onePage?.urgency.map(text => (
													<div style={{marginBottom: onePage?.urgency.length === 1 ? '' : '10px'}}>{text}</div>
												))}
											</div>
									</div>
									<div className="container__infoModalHealthCheckAlert">
											<div>
													<div className="title__infoModalHealthCheckAlert">Why</div>
													<div className="description__infoModalHealthCheckAlert">{onePage?.why}</div>
													</div>
											<div>
												<div className="title__infoModalHealthCheckAlert">What</div>
												<div className="description__infoModalHealthCheckAlert">{onePage?.what}</div>
												<div className="description__infoModalHealthCheckAlert">
														{onePage?.what_object_type && <div className="analysis__infoModalHealthCheckAlert"><strong style={{color: 'black'}}>Object Type:</strong> {onePage?.what_object_type}</div>}
														{onePage?.what_evaluation_method && <div className="analysis__infoModalHealthCheckAlert"><strong style={{color: 'black'}}>Evaluation Method:</strong> {onePage?.what_evaluation_method}  </div>}
														{onePage?.what_alerts_values && <div><strong style={{color: 'black'}}>Alert Values:</strong> {onePage?.what_alerts_values}</div>}
												</div>
											</div>
											<div>
												{onePage?.how && <div className="title__infoModalHealthCheckAlert">How</div>}
												<div className="description__infoModalHealthCheckAlert">
													{onePage?.how}
												</div>
												<div className="description__infoModalHealthCheckAlert">
													{onePage?.how_discovery &&<div><strong style={{color: 'black'}}>Discovery:</strong>{onePage?.how_discovery?.map(item => <div style={{marginTop:'2px'}}>{item}</div>)} </div>}
													{onePage?.how_analysis && <div className="analysis__infoModalHealthCheckAlert"><strong style={{color: 'black'}}>Analysis:</strong> {onePage?.how_analysis}</div>}
													{onePage?.how_alerting &&	<div><strong style={{color: 'black'}}>Alerting:</strong> 
                            {onePage?.how_alerting?.map(item => <div style={{marginTop:'2px'}}>{item}</div>)}
                            </div>}
												</div>
											</div>
											<div>
												{showGWP && <div className="title__infoModalHealthCheckAlert">Get Well Plan</div>}
												<div className="description__infoModalHealthCheckAlert">
													{onePage?.gwp}
                          {showGWP &&  <div style={{width:'50%', marginTop:'10px'}} className="textGwpSra__link" onClick={() => 
                              {
                                onShowModalHealthCheckAlert(false)
                                onShowGWP(newItem?.gwp_id)
                                setShowGWP(true)
                                getSelectedGWP(newItem)
                              }}>
                                View Get Well Plan
                          </div>}
												</div>
											</div>
											<div>
												{onePage?.sra && <div className="title__infoModalHealthCheckAlert">Service Request Accelerator (SRA)</div>}
												<div className="description__infoModalHealthCheckAlert">
                          {onePage?.sra?.map(item => <div className="bullet-item" style={{marginTop:'2px'}}>{item}</div>)}
												</div>
											</div>
									</div>
							</div>}
						{!onePage && <div style={{padding:"25px"}} className="not__foundGwp">No data found</div>}
						<div className="footer__detail">
							<img className="logo__footer" src={logo}/>
						</div>
					</div>
				</section>
		</div>
	)
}


