import { useCallback } from "react";
import { apiRoutes, apiRequest } from "../../services";


const DownloadBase64 = () => {
  const downloadCSV = useCallback(
    async (api, reportName, reportType, payload) => {
      const [error, data] = await apiRequest({
        method: "get",
        url: `${api}`,
      });
      if (data) {
        const str = data.pdf_base64 ?  window.atob(data.pdf_base64) : data
        let download = document.createElement("a");
        download.textContent = "download";
        download.download =
          reportName + '-' + new Date().toISOString() + "." + reportType;
        download.href = "data:text/csv;charset=utf-8," + escape(str);
        download.click();
      } else {
        console.log("error", error);
      }
      return [error, data];
    },
    []
  );

  return {
    downloadCSV,
  };
};

export default DownloadBase64;
