import React from "react";
import { Navigate } from "react-router-dom";
import Paths from "./paths";
import Layout from "./components/Layout";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  return  token ? <div style={{ height: '100%' }}><Layout /></div>: <Navigate to={Paths.Login} />
};

export default PrivateRoute;
