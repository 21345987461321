import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonTable,
  ButtonsContainer,
  FlexRow,
  PaginationDiv,
  PaginationIcon,
} from "./PaginationStyled";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

interface Props {
  numberOfElements: number;
  showElementsPerPage?: number;
  onPaginationChange?: (firstElement: number, lastElement: number) => void;
  currentPage?:  (actualPage: number) => void;
  pageClickNavigation?:(actualPage: boolean) => void;
}

const Pagination = ({
  numberOfElements,
  showElementsPerPage = 10,
  onPaginationChange,
  currentPage,
  pageClickNavigation
}: Props) => {
  const [pages, setPages] = useState<number>(showElementsPerPage);
  const [pagination, setPagination] = useState<number>(showElementsPerPage);
  const [actualPage, setActualPage] = useState<number>(1);
  const [firstElement, setFirstElement] = useState<number>(0);
  const [lastElement, setlastElement] = useState<number>(showElementsPerPage);

  useEffect(() => {
    setFirstElement(actualPage * pagination);
    setlastElement(
      numberOfElements < (actualPage + 1) * pagination
        ? numberOfElements
        : (actualPage + 1) * pagination
    );

    actualPage === 0 && currentPage && currentPage(actualPage)
    actualPage === 0 && pageClickNavigation && pageClickNavigation(actualPage)
  }, [actualPage, numberOfElements, pagination]);

  useEffect(() => {
    setPages(Math.ceil(numberOfElements / pagination));
    setActualPage(0);
  }, [pagination, numberOfElements]);

  useEffect(() => {
    onPaginationChange && onPaginationChange(firstElement, lastElement - 1);
  }, [firstElement, lastElement, pagination]);

  return (
    <PaginationDiv className="pagination__solutionGallery">
      <FlexRow>
        Showing{" "}
        <input
          className="PaginationNumber"
          onChange={(e) =>
            (parseInt(e.target.value) < 1000 || !e.target.value) &&
            setPagination(parseInt(e.target.value))
          }
          type="number"
          value={pagination}
          style={{margin: "0 5px"}}
        />{" "}
        Elements
      </FlexRow>
      <ButtonsContainer>
        <ButtonTable 
        onClick={() => actualPage >= 1 && setActualPage(0)}>
          <PaginationIcon active={actualPage >= 1}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </PaginationIcon>
        </ButtonTable>
        <ButtonTable
          onClick={() => {
            actualPage >= 1 && setActualPage(actualPage - 1)
            actualPage >= 1 && currentPage && currentPage(actualPage - 1)
            actualPage >= 1 && pageClickNavigation && pageClickNavigation(true)
          }
        }
        >
          <PaginationIcon active={actualPage >= 1}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </PaginationIcon>
        </ButtonTable>
        <label>
          {firstElement + 1}-{lastElement}/{numberOfElements}
        </label>
        <ButtonTable
          disable={actualPage <= 1}
          onClick={() =>{
            pages > actualPage &&
            pages !== 0 &&
            lastElement < numberOfElements &&
            setActualPage(actualPage + 1)
            pages > actualPage &&
            pages !== 0 &&
            lastElement < numberOfElements && currentPage && currentPage(actualPage + 1)
            lastElement < numberOfElements && pageClickNavigation && pageClickNavigation(true)
          }
            
          }
        >
          <PaginationIcon
            active={
              pages > actualPage &&
              pages !== 0 &&
              lastElement < numberOfElements
            }
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </PaginationIcon>
        </ButtonTable>
        <ButtonTable
          onClick={() => {
            pages > actualPage &&
            pages !== 0 &&
            lastElement < numberOfElements &&
            setActualPage(pages - 1) 
            pages > actualPage &&
            pages !== 0 &&
            lastElement < numberOfElements && currentPage && currentPage(pages - 1)
            lastElement < numberOfElements && pageClickNavigation && pageClickNavigation(true)

          }
          }
        >
          <PaginationIcon
            active={
              pages > actualPage &&
              pages !== 0 &&
              lastElement < numberOfElements
            }
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </PaginationIcon>
        </ButtonTable>
      </ButtonsContainer>
    </PaginationDiv>
  );
};

export default Pagination;