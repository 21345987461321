import './SolutionGallery.css';

const SolutionGallery = () => {
  return ( 
    <div className="container__topLoading">
			<div className="container__cardSkeletonTop skeleton__animation "/>
			<div className="container__cardSkeletonTop skeleton__animation "/>
			<div className="container__cardSkeletonTop skeleton__animation "/>
			<div className="container__cardSkeletonTop skeleton__animation "/>
    </div>

  )
};

export default SolutionGallery;

