import { useState, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import {
  faMagnifyingGlass,
  faCircleQuestion,
  faX,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import healthCheck from "../../assets/wallpaper1.png";
import Copyright from "../../components/Copyright"
import Modal from "../../components/Modal";
import Pagination from "../../components/Pagination";
import AppIcon from "../../components/AppIcon";
import logo from "../../assets/PagerDuty-WhiteRGB_white.svg";
import { cartSolution } from "../../services/subjects.js";
import './SolutionSubCategory.css'


export default function SolutionSubCategory() { 
  const solutions1 = [
    // {
    //   "solution_name": "On-Call Readiness",
    //   "solution_target": "1 Team",
    //   "solution_hour_cost": "1",
    //   "solution_type": "Core",
    //   'id': 1
    // },
    //de la otra categoria
    {
      "solution_name": "Analyze Holiday On-Call Readiness",
      "solution_target": "1 Domain",
      "solution_hour_cost": "1",
      "solution_type": "Core",
      'id': 2
    }
  ]

  const modalDetail= {
    "solution_detail": {
      "catergory_name": "Analytics & Reporting Service Request Accelerator",
      "solution_name": "Analyze Holiday On-Call Readiness",
      "solution_target": "1 Domain",
      "solution_hour_cost": "1 Hour",
      "solution_team_request_name": "Professional Services Service Request",
      "solution_why": [
        "Identify gaps in on-call coverage for business critical services.",
        "Identify configurations that cause incident response mobilization challenges.",
        "Identify opportunities to automate incident mobilization and response activities.",
        "Analyze configurations that can lead to missed incident notifications."
      ],
      "solution_what_does_it_include": {
        "solution_description": "As part of the Analyze Holiday On-Call Readiness Service Request Accelerator, the Professional Services consultant will:",
        "solution_points": [
          "Analyze PagerDuty Domain for Ready for Duty Best Practices.",
          "Analyze Responder Contact Method Configurations.",
          "Analyze Responder Notification Rule Configurations.",
          "Analyze Responder Mobile App Usage.",
          "Analyze Status Pages and Status Communications."
        ]
      }
    }
  }

  const navigate = useNavigate();
  const [solutions, setSolutions] = useState(solutions1);
  const [checkedSortByAlphabetically, setCheckedSortByAlphabetically] = useState(false);
  const [onSearchCategory, setOnSearchCategory] = useState('');
  const [page, setPage] = useState(1);
  const [byPage, setByPage] = useState(8);
  const [modalOpenDetailSolution, setModalOpenDetailSolution] = useState(false);


  useEffect(() => {
    let filteredNews = [];

    filteredNews= solutions.filter((item) => {
      return item.solution_name.toLocaleLowerCase().includes(onSearchCategory.toLocaleLowerCase())
    })

    if (filteredNews.length ) {
      setSolutions(filteredNews)
    } 
    if (onSearchCategory === '')  {
      setSolutions(solutions1)
    }
  }, [onSearchCategory]);


  const handleToggleAlphabetically = () => {
    setCheckedSortByAlphabetically(!checkedSortByAlphabetically);
    sortedCategory()
  };

  const sortedCategory = () => {
    const sortedNews = [...solutions]; 

    if (checkedSortByAlphabetically) {
      sortedNews.sort((a, b) => a.solution_name.localeCompare(b.solution_name));
    } else {
      sortedNews.sort((a, b) => b.solution_name.localeCompare(a.solution_name));
    }
    setSolutions(sortedNews);
  } 

  const maxPage = solutions.length / byPage;
  let prueba= 0

  const addCart = () => {
    prueba += 1
    cartSolution.next(prueba);
  }

  return (
    <div className='container__solutionSubGallery'>
      <div className="solutionGallery__header" >
        <section className="container__headerIcon">
          <AppIcon type='SolutionGallery' />
          <h1 className="headerSub__title">
            Solution Gallery
          </h1>
        </section>
        <div className="container__searchSolutionGallery">
          <div className="input__containerSolutionSubGallery">
            <div className="go__back" onClick={() => navigate('/solution-gallery')}><FontAwesomeIcon icon={faArrowLeft} /></div>
            <input
              name="search-form"
              id="search-form"
              className="searchSolutionGallery__input"
              placeholder="Search by name solutions ..."
              value={onSearchCategory}
              onChange={(e) => setOnSearchCategory(e.target.value)}
            />
            <div className="icon__containerSearch">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
          </div>
        </div>
        <div className="container__toggleAndAddSolutionGallery">
          <div className="solutionGallery__toggle">
            <input
              type="checkbox"
              id="toggleAlphabetically"
              checked={checkedSortByAlphabetically}
              onChange={handleToggleAlphabetically}
            />
            <label htmlFor="toggleAlphabetically" className="toggleSolution__slider" >
                {!checkedSortByAlphabetically && <div className="toggle__active">Az</div>}
                {checkedSortByAlphabetically && <div className="toggle__disabled">Za</div>}
            </label>
          </div>
        </div>
      </div>
      <div className="container__cardSolutionSubCategory">
      {solutions.slice(
          (page - 1) * byPage,
          (page - 1) * byPage + byPage
        )?.map((item, index) => (
        <div key={index} className="cards">
          <div className="cards__item">
            <div className="card__solutionSubCategory" style={{backgroundImage:  `url(${healthCheck}` }}>
              <div className="card__headerSubSolution" onClick={() => setModalOpenDetailSolution(!modalOpenDetailSolution)}>
                <FontAwesomeIcon icon={faCircleQuestion} />
              </div>
              <div className="card__nameSubCategory">
                  {item.solution_name}
              </div>
              <div className="card__footerSubCategory">
                <div onClick={() => addCart()} className="card__addCar">
                  Add to cart
                </div>
                <div className="container__hourAndTarget">
                  <div>{item.solution_target}</div>
                  <div className="hour__text">{item.solution_hour_cost} Hour</div>
                </div>
              </div>
            </div>
          </div>
        </div>     
      ))}
      </div>
      <div className="container__copy">
        <Copyright/>
      </div> 
      {modalOpenDetailSolution && 
      <div className="container__detail">
        <section className="container__section">
          <div className="modal__containerDetail">
            <div className="detail__categoryName">
              <div>{modalDetail?.solution_detail?.catergory_name}</div>
              <div className="icon__fax" onClick={() => setModalOpenDetailSolution(false)}><FontAwesomeIcon icon={faX} /></div>
            </div>
            <div className="detail__header">{modalDetail?.solution_detail.solution_name}</div>
            <div className="container__subHeader">
              <div className="solutionDetail">{modalDetail?.solution_detail?.solution_team_request_name}</div>
              <div className="container__domainAndHour">
                <div className="domain" >{modalDetail?.solution_detail?.solution_target}</div>
                <div className="hour">{modalDetail?.solution_detail?.solution_hour_cost}</div>
              </div>
            </div>
            <div className="container__why">
              <div className="why__info">
                <div className="why__title">Why?</div>
                <di>
                  <ul className="why__ul">
                    {modalDetail?.solution_detail?.solution_why.map((item, index) => (
                      <li key={index} className="why__li">{item}</li>
                    ))}
                    </ul>
                </di>
              </div>
            </div>
            <div className="container__whatDoes">
              <div className="why__title">What does it include?</div>
              <div className="solution__description">{modalDetail?.solution_detail?.solution_what_does_it_include?.solution_description}</div>
              <di>
                <ul className="why__ul">
                  {modalDetail?.solution_detail?.solution_what_does_it_include?.solution_points.map((item, index) => (
                    <li key={index} className="why__li">{item}</li>
                  ))}
                  </ul>
              </di>
            </div>
            <div className="footer__detail">
              <img className="logo__footer" src={logo}/>
            </div>
          </div>
        </section>
      </div>}
    </div>
  )
}




