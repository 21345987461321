import React, { useState, useEffect, useRef } from "react";
import "./PsStatus.css";

import GroupedBarChart from "../../components/GroupedBarChart/GroupedBarChart";
import GeneralReport from "./GeneralReport";


export default function PsStatusUpdatesReport(props) {
  return (
    <>
      {( props.reportTab === 'PSGeneralReport') ? <GeneralReport reportTab={props.reportTab} canSeeReport={props.canSeeReport}/> :  <div className="soc-empty-query">
          <h3>No data available </h3>
        </div>}
    </>
    
  )
}