import { useRive, useStateMachineInput, Event } from "@rive-app/react-canvas";
import loadingpagey from "./loadingpagey.riv";
import { forwardRef, useEffect, useImperativeHandle } from "react";

interface Props {
  state: number | boolean;
}
const LoadingPagey = forwardRef(({ state }: Props, Ref) => {
  useImperativeHandle(Ref, () => ({ state }));
  const { rive, RiveComponent } = useRive({
    src: loadingpagey,
    stateMachines: "State Machine 1",
    autoplay: true,
    onStateChange: (event: Event) => {},
  });

  const stateInput = useStateMachineInput(rive, "State Machine 1", "state");

  useEffect(() => {
    if (stateInput) {
      stateInput.value = state;
    }
  }, [stateInput, state]);

  return <RiveComponent style={{ width: "600px", height: "400px" }} />;
});

export default LoadingPagey;
