import React from "react";
import StyledPageSection, { SectionBody } from "./PageSection.styles";

import PageSectionHeader from "./PageSectionHeader";

const PageSection = ({
  children,
  title,
  icon,
  primaryAction,
  secondaryActions,
  loading,
}) => {
  return (
    <StyledPageSection>
      {(title || primaryAction || secondaryActions) && (
        <PageSectionHeader
          icon={icon}
          title={title}
          primaryAction={primaryAction}
          secondaryActions={secondaryActions}
        />
      )}
      <SectionBody>{children}</SectionBody>
    </StyledPageSection>
  );
};

export default PageSection