import React, { useState, useEffect } from "react";
import { Redirect, useNavigate } from "react-router-dom";
import Paths from "../../../paths";
import Questions from "./../../../components/Questions/Questions";
import './FAQ.css'

export default function FAQ() {
  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");
  const [error, setError] = useState(false);
  const [loginErrorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [userError, setUserError] = useState(false);
  const [envError, setEnvError] = useState(false);

  const questionsArray = [
    {
      question: `How are the CSV reports created?`,
      answer: `Unless otherwise noted, all CSV reports are generated using the authorized user's PagerDuty OAuth token and associated PagerDuty role and permissions. Some CSV reports or associated data may not be available based on your PagerDuty configurations.`,
    },
    {
      question: `How is the CSV report data collected?`,
      answer: `Unless otherwise noted, all CSV report data is collected using the standard PagerDuty REST API. All standard PagerDuty REST API rate limits apply.`,
    },
    {
      question: `What's a Pagey Token?`,
      answer: `A Pagey Token is an expression of the value delivered from the CSV report and is a reflection of the effort and complexity required to provide that specific CSV report.  In the future, we may explore Pagey Tokens as a means for our Premium Services customers to request things of value such as these CSV reports.`,
    },
    {
      question: `I don't see a CSV report that meets my needs`,
      answer: `Feel free to share your needs with us using your Premium Services On-Demand entitlements via psondemand@pagerduty.com`,
    },
    {
      question: `I need a CSV report with an additional field`,
      answer: `Feel free to request updates to an existing report using your Premium Services On-Demand entitlements via psondemand@pagerduty.com`,
    },
    {
      question: `I need a new CSV report`,
      answer: `Feel free to request a new report using your Premium Services On-Demand entitlements via psondemand@pagerduty.com`,
    },
    {
      question: `The CSV report I requested has an error`,
      answer: `Please report all problems accessing a CSV report using your Premium Services On-Demand entitlements via psondemand@pagerduty.com`,
    },
    {
      question: `The CSV report I tried to download had an error`,
      answer: `Please report all problems accessing a CSV report using your Premium Services On-Demand entitlements via psondemand@pagerduty.com`,
    },
    {
      question: `I'd like to share feedback on the Premium Services Report Marketplace app`,
      answer: `Please share your feedback using your Premium Services On-Demand entitlements via psondemand@pagerduty.com`,
    },
  ];

  return (
    <div className="faq-container">
      {!isLoading ? <> 
      </>
      : <>
        <h1>Frequently Asked Questions</h1> 
        <div className="faq-layout">
          <Questions questionsArray={questionsArray} />
        </div>
        </>}
    </div> 
  );
}
