import styled from "styled-components";

export const PaginationDiv = styled.div`
  background-color: #fff;
  border-top: 1px solid #dededf;
  border-bottom: 1px solid #dededf;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
  margin-top: 10px;
  font-family: "Plain-Thin";
  margin-top: auto;
  @media only screen and (max-width: 680px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`;

export const FlexRow = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonTable = styled.div`
  background-color: transparent;
  border: none;
  outline: none;
  color: #707070;
  cursor: pointer;
  &:hover {
    outline: none;
    border: none;
  }
  &:focus {
    outline: none;
    border: none;
  }
`;

export const PaginationIcon = styled.span`
  margin-right: 5px;
  margin-left: 5px;
  color: ${props => (props.active ? '#00000099' : '#e0e1e2')};
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;
