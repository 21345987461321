import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './Tabs.css';

const Tabs = forwardRef(({ Tabs, onTabChange, isLoading = false, children }, Ref) => {
    const [activeTab, setActiveTab] = useState(true);

    useImperativeHandle(Ref, () => ({}));

    useEffect(() => {
        setActiveTab(Tabs[0].label);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onTabChange(activeTab);
    }, [activeTab]);

    return (
        <>
            <div id="tabsContainer">
                {Tabs.map((tab) => <div key={uuidv4(tab + 'tab')} onClick={() => setActiveTab(tab.label)}
                    id={tab.label === activeTab ? 'tabsClassActive' : 'tabsClass'}>
                    <label className={isLoading ? 'skeleton-text-HCDV' : ''} style={{ cursor: tab.enable ? 'default' : 'no-drop', color: tab.label !== activeTab ? tab.enable ? 'black' : 'red' : 'black' }}>{tab.label}</label>
                </div>)}
            </div>
            <div id='tabsConatinerBottom'>
                {children}
            </div>
        </>
    );
}
);

export default Tabs;