import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Paths from "../../../paths";
import loginBackgound1 from "./../../../assets/loginBackgound1.png";
import './404.css'

export default function Error404() {
  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();


  const home = () => {
    history(Paths.Home)
  }

  return (
    <div >
      {!isLoading ? <> 
      </>
      : <> <div className="unauthorized-container">
      <main className="unathorized-message_container">
        <div style={{flex:1, alignItems:'center', display:'flex', margin: '0 auto', flexDirection: 'column'}}>
          <p className="unauthorized-message_error">404</p>
          <h1 className="unathorized-message_header">Not Found</h1>
          <p className="unathorized-message_paragraph">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10">
            <button className="unathorized-login_button" onClick={() => home()}>Go Home</button>
          </div>
        </div>
        <div className="unathorized-image_container">
          <img
            src="https://d274pwa32h7vmw.cloudfront.net/loginBackgound1.png"
            alt=""
            className="unathorized-image"
          />
        </div>
      </main>
    </div>
        </>}
    </div> 
  );
}
