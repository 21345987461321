import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import './DragAndDropList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';

const DragAndDropList = forwardRef(({ arrayOfOptions, onListOrdenChange }, Ref) => {

    useImperativeHandle(Ref, () => ({}));

    const dragItem = useRef();
    const dragOverItem = useRef();
    const [list, setList] = useState([...arrayOfOptions]);
    const [dragOver, setDragOver] = useState(null);
   
    const dragStart = (e, position) => {
      dragItem.current = position;
    };
   
    const dragEnter = (e, position) => {
      dragOverItem.current = position;
      setDragOver(position);
    };
   
    const drop = (e) => {
      const copyListItems = [...list];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      onListOrdenChange(copyListItems)
      setList(copyListItems);
      setDragOver(null);
    };

    return (
        <>
    {
    list &&
    list.map((item, index) => (
      <div className={dragOver === index ? 'dropableRowHover' : 'dropableRow'}
        onDragStart={(e) => dragStart(e, index)}
        onDragEnter={(e) => dragEnter(e, index)}
        onDragEnd={drop}
        key={index}
        draggable>
          {dragOver === index ? 'Drop here' : <>{item.label}  <FontAwesomeIcon icon={faGripVertical} /></>}
      </div>
      ))}
    </>
    );
}
);

export default DragAndDropList;