import styled from "styled-components";
import designVariables from "./../utils";
import pxToRem from "./../pxToTem"

const buttonStyles = {
  primary: {
    backgroundColor: designVariables.COLOR_BASE_PRIMARY,
    color: "white",
    borderColor: "transparent",
  },
  secondary: {
    backgroundColor: "white",
    color: designVariables.COLOR_BASE_PRIMARY,
    borderColor: designVariables.COLOR_NEUTRAL_50,
  },

  light: {
    backgroundColor: "#0047FF0A",
    color: designVariables.COLOR_BASE_PRIMARY,
    borderColor: "transparent",
  },
  dark: {
    backgroundColor: designVariables.COLOR_NEUTRAL,
    color: "white",
    borderColor: "transparent",
  },
};

export default styled.button`
  position: relative;
  font-weight: ${designVariables.SIZE_FONT_WEIGHT_MEDIUM};
  color: ${(props) => buttonStyles[props.buttonStyle].color} !important;
  background-color: ${(props) =>
    buttonStyles[props.buttonStyle].backgroundColor};
  border-color: ${(props) => buttonStyles[props.buttonStyle].borderColor};
  border-radius: ${designVariables.SIZE_RADIUS_REGULAR};
  cursor: pointer;
  &:disabled,
  &[disabled] {
    opacity: 0.3;
  }
  & > svg {
    max-width: 14px;
    height: auto;
    margin-right: ${pxToRem(designVariables.SIZE_SPACING_TIGHT)};
  }
`;
