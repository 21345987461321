import React, { useState, useEffect, useRef } from "react";
import "./OnBoarding.css";
import AppIcon from "../../components/AppIcon";
import FillingCircle from "../../components/FillingCircle";
import Chevron from "../../components/Chevron";
import NoUser from "./../../assets/NoUser.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faEye,
  faWarning,
  faTimes,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import DropdownO from "../../components/DropdownO";
import Questions from "../../components/Questions";
import EmojiPicker from "../../components/EmojiPicker";
import useElementSize from "../../hooks/useElementSize";
import useCSV from "../../hooks/useCSV";
import { notificationSubject } from "../../services/subjects";
import LoadingSpinner from "../../components/LoadingSpinner";
import Jwt from "../../utils/jwt";
import { apiRequest, apiRoutes } from "./../../services";
import Modal from "../../components/Modal";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";

export default function OnBoarding() {
  const [isLoading, setIsLoading] = useState(false);
  const [encrypt, setEncrypt] = useState(true);
  const [advanceModeToggle, setAdvanceModeToggle] = useState(false);
  const [addKeyToggle, setAddKeyToggle] = useState(false);
  const [addKeyToggleTimeOut, setAddKeyToggleTimeOut] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalObject, setModalObject] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [emailOwner, setEmailOwner] = useState("");
  const [domainNamesInput, setDomainNamesInput] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [addEmailToggle, setAddEmailToggle] = useState(false);
  const [addEmailToggleTimeOut, setAddEmailToggleTimeOut] = useState(false);

  const [FAQModalOpen, setFAQModalOpen] = useState(false);

  const [lastLoadedDate, setLastLoadedDate] = useState("");

  const [userKey, setUserKey] = useState("");
  const [userKeySubdomain, setUserKeySubdomain] = useState("");

  const [teamName, setTeamName] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [teamTag, setTeamTag] = useState("");

  // const [managerImage, setManagerImage] = useState('');
  const [managerName, setManagerName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerCurrentEmail, setManagerCurrentEmail] = useState(null);
  const [managerCountryCode, setManagerCountryCode] = useState("");
  const [managerCellPhone, setManagerCellPhone] = useState("");
  const [managerBaseRole, setManagerBaseRole] = useState("user");
  const [managerTeamRole, setManagerTeamRole] = useState("manager");

  // const [teamUserImage, setTeamUserImage] = useState('');
  const [teamUserName, setTeamUserName] = useState("");
  const [teamUserEmail, setTeamUserEmail] = useState("");
  const [teamUserCurrentEmail, setTeamUserCurrentEmail] = useState(null);
  const [teamUserCountryCode, setTeamUserCountryCode] = useState("");
  const [teamUserCellPhone, setTeamUserCellPhone] = useState("");
  const [teamUserBaseRole, setTeamUserBaseRole] = useState("limited_user");
  const [teamUserTeamRole, setTeamUserTeamRole] = useState("responder");
  const [teamMembersArray, setTeamMembersArray] = useState([]);
  const [teamMembersError, setTeamMembersError] = useState(false);

  const [teamMembersErrorGlobal, setTeamMembersErrorGlobal] = useState(false);

  const [currentTeamMemberIndex, setCurrentTeamMemberIndex] = useState(0);
  const [editMemberActive, setEditMemberActive] = useState(false);

  const [step1Percentage, setStep1Percentage] = useState(0);
  const [step2Percentage, setStep2Percentage] = useState(0);
  const [step3Percentage, setStep3Percentage] = useState(0);
  const [step4Percentage, setStep4Percentage] = useState(0);

  const [majorIncidentPrefix, setMajorIncidentPrefix] = useState(
    "[🚨 Major Incident]"
  );
  const [majorIncidentSuffix, setMajorIncidentSuffix] = useState("");
  const [nonMajorIncidentPrefix, setNonMajorIncidentPrefix] = useState(
    "[👋 Non-Major Incident]"
  );
  const [nonMajorIncidentSuffix, setNonMajorIncidentSuffix] = useState("");
  const [majorIncidentRPNPrefix, setMajorIncidentRPNPrefix] = useState(
    "[🚨 Major Incident]"
  );
  const [majorIncidentRPNSuffix, setMajorIncidentRPNSuffix] = useState("");
  const [nonMajorIncidentRPNPrefix, setNonMajorIncidentRPNPrefix] = useState(
    "[👋 Non-Major Incident]"
  );
  const [nonMajorIncidentRPNSuffix, setNonMajorIncidentRPNSuffix] =
    useState("");

  const [primaryScheduleType, setPrimaryScheduleType] = useState("");
  const [secondaryScheduleType, setsecondaryScheduleType] = useState("");
  const [primaryScheduleTypeDescription, setPrimaryScheduleTypeDescription] =
    useState("");
  const [
    secondaryScheduleTypeDescription,
    setsecondaryScheduleTypeDescription,
  ] = useState("");
  const [token] = useState(Jwt.getItem("all"));
  const [domainNamesArray, setDomainNamesArray] = useState(
    token.sub ? [{ value: "@" + token.sub.split("@")[1] }] : []
  );
  const [teamRoleDropdownOptions, setTeamRoleDropdownOptions] = useState([]);
  const [baseRoleDropdownOptions, setBaseRoleDropdownOptions] = useState([]);
  const [scheduleTypes, setScheduleTypes] = useState([]);

  const [waveRef, { width, height }] = useElementSize();
  const { exportCSV } = useCSV();
  const stepOneRef = useRef();
  const stepOneRefDiv = useRef();
  const stepTwoRef = useRef();
  const stepTwoRefDiv = useRef();
  const stepThreeRef = useRef();
  const stepThreeRefDiv = useRef();
  const stepFourRef = useRef();
  const stepFourRefDiv = useRef();

  const questionsArray = [
    {
      question: `Can I use existing users in a Ready for Duty onboarding session?`,
      answer: `Not at this time. The onboarding application assumes that the team and all team members do not currently exist in PagerDuty.`,
    },
    {
      question: `Can I onboard only the team members?`,
      answer: `Not at this time. The Ready for Duty onboarding application onboards a complete team unit and core objects enabling the team’s mobilization during major/non-major incidents.`,
    },
    {
      question: `Can I use Incident Workflows instead of Response Plays?`,
      answer: `Support for Incident Workflows is planned for the next version.`,
    },
    {
      question: `Where can I go for help or feedback?`,
      answer: `Slack channel #csg-onboarding-app.`,
    },
  ];

  useEffect(() => {
    setTeamUserCurrentEmail(domainNamesArray[0].value);
    setManagerCurrentEmail(domainNamesArray[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainNamesArray]);

  useEffect(() => {
    let newPercentage = 0;
    if (teamName) newPercentage = newPercentage + 33.34;
    if (teamDescription) newPercentage = newPercentage + 33.34;
    if (teamTag) newPercentage = newPercentage + 33.34;
    setStep1Percentage(newPercentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamName, teamDescription, teamTag]);

  useEffect(() => {
    let newPercentage = 0;
    if (managerName) newPercentage = newPercentage + 25;
    if (managerEmail) newPercentage = newPercentage + 25;
    if (managerCountryCode) newPercentage = newPercentage + 25;
    if (managerCellPhone) newPercentage = newPercentage + 25;
    setStep2Percentage(newPercentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    managerName,
    managerEmail,
    managerCountryCode,
    managerCellPhone,
    managerBaseRole,
    managerTeamRole,
  ]);

  useEffect(() => {
    setStep3Percentage(
      teamMembersArray.length > 1 ? 100 : teamMembersArray.length > 0 ? 50 : 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembersArray]);

  useEffect(() => {
    !addKeyToggle && setUserKey("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addKeyToggle]);

  useEffect(() => {
    let newPercentage = 0;
    if (majorIncidentPrefix) newPercentage = newPercentage + 20;
    if (majorIncidentRPNPrefix) newPercentage = newPercentage + 20;
    if (nonMajorIncidentPrefix) newPercentage = newPercentage + 20;
    if (nonMajorIncidentRPNPrefix) newPercentage = newPercentage + 20;
    if (primaryScheduleType) newPercentage = newPercentage + 10;
    if (secondaryScheduleType) newPercentage = newPercentage + 10;
    setStep4Percentage(newPercentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    majorIncidentPrefix,
    majorIncidentSuffix,
    majorIncidentRPNPrefix,
    majorIncidentRPNSuffix,
    nonMajorIncidentPrefix,
    nonMajorIncidentSuffix,
    nonMajorIncidentRPNPrefix,
    nonMajorIncidentRPNSuffix,
    primaryScheduleType,
    secondaryScheduleType,
  ]);

  useEffect(() => {
    for (let schedule of scheduleTypes) {
      if (schedule.schedule_type === primaryScheduleType)
        setPrimaryScheduleTypeDescription(schedule.schedule_description);
      if (schedule.schedule_type === secondaryScheduleType)
        setsecondaryScheduleTypeDescription(schedule.schedule_description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryScheduleType, scheduleTypes, secondaryScheduleType]);

  const checkIfKeyIsValid = async () => {
    if (userKey.length === 20) {
      setIsLoading(true);

      // eslint-disable-next-line
      const [error, res] = await apiRequest({
        method: "post",
        url: apiRoutes.onboardingGetSubdomain,
        data: {
          track_information: {
            api_key: userKey,
          },
        },
      });
      if (res?.subdomain) {
        setUserKeySubdomain(res.subdomain);
      } else {
        setUserKeySubdomain("");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkIfKeyIsValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey]);

  useEffect(() => {
    fetch_Team_Roles();
    fetch_Base_Roles();
    fetch_Schedules();
    fetch_Draft();
  }, []);

  const delete_Draft = async () => {
    setIsLoading(true);
    const [error, res] = await apiRequest({
      method: "put",
      url: apiRoutes.onboardingDraft,
    });
    if (res) {
      notificationSubject.next({
        message: `The information in the draft was successfully deleted`,
        type: "SUCCESS",
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    }
    if (error) {
      notificationSubject.next({
        message: `There was an error trying to delete the draft`,
        type: "ERROR",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isComplete && modalObject === null) {
      delete_Draft();
    }
  }, [isComplete, modalObject]);

  useEffect(() => {
    setsecondaryScheduleType(primaryScheduleType);
  }, [primaryScheduleType]);

  useEffect(() => {
    if (addKeyToggle) {
      setAddKeyToggleTimeOut(true);
    } else {
      setTimeout(() => {
        setAddKeyToggleTimeOut(false);
      }, 500);
    }
  }, [addKeyToggle]);

  const fetch_Team_Roles = async () => {
    setIsLoading(true);
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.teamRoles,
    });
    if (res) {
      setTeamRoleDropdownOptions(
        res.map((element) => {
          return { value: element };
        })
      );
      setIsLoading(false);
    }
    if (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const fetch_Base_Roles = async () => {
    setIsLoading(true);
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.baseRoles,
    });
    if (res) {
      setBaseRoleDropdownOptions(
        res.map((element) => {
          return { value: element };
        })
      );
      setIsLoading(false);
    }
    if (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const fetch_Draft = async () => {
    setIsLoading(true);
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.onboardingDraft,
    });
    if (res) {
      res.key && setUserKey(res.key);
      res.domainNamesArray && setDomainNamesArray(res.domainNamesArray);
      res.last_save_draft_date && setLastLoadedDate(res.last_save_draft_date);
      if (res.track_information) {
        res.track_information.email &&
          setEmailOwner(res.track_information.email);
        res.track_information.api_key &&
          setTeamTag(res.track_information.api_key);
      }
      if (res.team_information) {
        res.team_information.team_name &&
          setTeamName(res.team_information.team_name);
        res.team_information.team_tag &&
          setTeamTag(res.team_information.team_tag);
        res.team_information.team_description &&
          setTeamDescription(res.team_information.team_description);
      }
      if (res.team_manager) {
        res.team_manager.name && setManagerName(res.team_manager.name);
        res.team_manager.email &&
          setManagerEmail(res.team_manager.email.split("@")[0]);
        res.team_manager.base_role &&
          setManagerBaseRole(res.team_manager.base_role);
        res.team_manager.team_role &&
          setManagerTeamRole(res.team_manager.team_role);
        res.team_manager.phone.cell_phone &&
          setManagerCellPhone(res.team_manager.phone.cell_phone);
        res.team_manager.phone.country_code &&
          setManagerCountryCode(res.team_manager.phone.country_code);
        // res.team_manager.image && setManagerImage(res.team_manager.image);
      }
      res.team_members &&
        setTeamMembersArray(
          res.team_members.map((member) => {
            return {
              name: member.name,
              email: member.email,
              countryCode: member.phone.country_code || "",
              cellPhone: member.phone.cell_phone || "",
              baseRole: member.base_role,
              teamRole: member.team_role,
              // image: member.image,
            };
          })
        );
      if (res.schedules) {
        res.schedules.primary_schedule_type &&
          setPrimaryScheduleType(res.schedules.primary_schedule_type);
        res.schedules.secondary_schedule_type &&
          setsecondaryScheduleType(res.schedules.secondary_schedule_type);
      }
      if (res.ready_for_duty_configurations) {
        if (
          res.ready_for_duty_configurations.escalation_policies_major_incident
        ) {
          res.ready_for_duty_configurations.escalation_policies_major_incident
            .prefix &&
            setMajorIncidentPrefix(
              res.ready_for_duty_configurations
                .escalation_policies_major_incident.prefix
            );
          res.ready_for_duty_configurations.escalation_policies_major_incident
            .suffix &&
            setMajorIncidentSuffix(
              res.ready_for_duty_configurations
                .escalation_policies_major_incident.suffix
            );
        }
        if (
          res.ready_for_duty_configurations
            .escalation_policies_non_major_incident
        ) {
          res.ready_for_duty_configurations
            .escalation_policies_non_major_incident.prefix &&
            setNonMajorIncidentPrefix(
              res.ready_for_duty_configurations
                .escalation_policies_non_major_incident.prefix
            );
          res.ready_for_duty_configurations
            .escalation_policies_non_major_incident.suffix &&
            setNonMajorIncidentSuffix(
              res.ready_for_duty_configurations
                .escalation_policies_non_major_incident.suffix
            );
        }
        if (res.ready_for_duty_configurations.response_play_major_incident) {
          res.ready_for_duty_configurations.response_play_major_incident
            .prefix &&
            setMajorIncidentRPNPrefix(
              res.ready_for_duty_configurations.response_play_major_incident
                .prefix
            );
          res.ready_for_duty_configurations.response_play_major_incident
            .suffix &&
            setMajorIncidentRPNSuffix(
              res.ready_for_duty_configurations.response_play_major_incident
                .suffix
            );
        }
        if (
          res.ready_for_duty_configurations.response_play_non_major_incident
        ) {
          res.ready_for_duty_configurations.response_play_non_major_incident
            .prefix &&
            setNonMajorIncidentRPNPrefix(
              res.ready_for_duty_configurations.response_play_non_major_incident
                .prefix
            );
          res.ready_for_duty_configurations.response_play_non_major_incident
            .suffix &&
            setNonMajorIncidentRPNSuffix(
              res.ready_for_duty_configurations.response_play_non_major_incident
                .suffix
            );
        }
      }
      setIsLoading(false);
    }
    if (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const fetch_Schedules = async () => {
    setIsLoading(true);
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.schedulesDescription,
    });
    if (res) {
      setScheduleTypes(
        res.map((element) => {
          return { ...element, value: element.schedule_type };
        })
      );
      setIsLoading(false);
    }
    if (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setConfirmationModalOpen(false);
    setIsLoading(true);
    const payload = createPayload();
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.onboardingSubmit,
      data: payload,
    });
    if (res) {
      notificationSubject.next({
        message: `The information was saved successfully`,
        type: "SUCCESS",
      });
      setIsLoading(false);
      setModalObject(res.message);
      setIsComplete(true);
    }
    if (error) {
      notificationSubject.next({
        message: `There was an error trying to save the information`,
        type: "ERROR",
      });
      setIsLoading(false);
      setModalObject(error.error.response.data.message);
    }
  };
  const saveAsAdraft = async () => {
    setIsLoading(true);
    const payload = createPayload();
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.onboardingDraft,
      data: payload,
    });
    if (res) {
      notificationSubject.next({
        message: `The information was saved successfully`,
        type: "SUCCESS",
      });
      setIsLoading(false);
    }
    if (error) {
      console.log("err", error);
      notificationSubject.next({
        message: `There was an error trying to save the information`,
        type: "ERROR",
      });
      setIsLoading(false);
      setModalObject(error.error.response.data.message);
    }
  };

  useEffect(() => {
    setIsEmailValid(
      /^^[a-z](?=[^.]*\.[^.]*$)[a-z0-9.]*[a-z0-9]$/gim.test(domainNamesInput)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainNamesInput]);

  const addDomainName = () => {
    let newDomainNamesArray = [...domainNamesArray];
    newDomainNamesArray.push({ value: "@" + domainNamesInput });
    setDomainNamesInput("");
    setDomainNamesArray([...new Set(newDomainNamesArray)]);
  };

  const removeDomainName = (index) => {
    let newDomainNamesArray = [...domainNamesArray];
    newDomainNamesArray.splice(index, 1);
    setDomainNamesArray(newDomainNamesArray);
  };

  const validateEmail = () => {
    var re = /\S+@\S+\.\S+/;
    return re.test(emailOwner);
  };

  const addTeamMember = () => {
    let newTeamArray = [...teamMembersArray];
    if (editMemberActive) {
      newTeamArray = newTeamArray.map((element, i) =>
        i !== currentTeamMemberIndex
          ? element
          : {
              name: teamUserName,
              email: (
                teamUserEmail.split("@")[0] +
                "@" +
                teamUserCurrentEmail
              ).replace(/@{2,}/g, "@"),
              countryCode: teamUserCountryCode,
              cellPhone: teamUserCellPhone,
              baseRole: teamUserBaseRole,
              teamRole: teamUserTeamRole,
              // image: teamUserImage,
              error: null,
            }
      );
      setEditMemberActive(false);
      setCurrentTeamMemberIndex(0);
    } else {
      if (newTeamArray.length < 30) {
        newTeamArray.push({
          name: teamUserName,
          email: (
            teamUserEmail.split("@")[0] +
            "@" +
            teamUserCurrentEmail
          ).replace(/@{2,}/g, "@"),
          countryCode: teamUserCountryCode,
          cellPhone: teamUserCellPhone,
          baseRole: teamUserBaseRole,
          teamRole: teamUserTeamRole,
          // image: teamUserImage,
        });
      } else
        notificationSubject.next({
          message: `The maximum user limit was reached, please do not exceed the parameters minimum of 2 and a maximum of 30. The users were deleted`,
          type: "ERROR",
        });
    }
    setTeamMembersArray([...newTeamArray]);
    setTeamUserName("");
    setTeamUserEmail("");
    setTeamUserCellPhone(`${teamUserCountryCode}`);
    setTeamUserBaseRole("limited_user");
    setTeamUserTeamRole("responder");
    // setTeamUserImage(null);
    setTeamMembersError(false);
    setTeamMembersErrorGlobal(
      newTeamArray.filter((member) => member.error).length > 0
    );
  };

  const removeTeamMember = (index) => {
    let newTeamArray = [...teamMembersArray];
    newTeamArray = newTeamArray.filter((element, i) => i !== index);
    setTeamMembersArray([...newTeamArray]);
    setTeamMembersErrorGlobal(
      newTeamArray.filter((member) => member.error).length > 0
    );
  };

  const modifyUser = (index) => {
    setCurrentTeamMemberIndex(index);
    setEditMemberActive(true);
    setTeamUserName(teamMembersArray[index].name);
    setTeamUserEmail(teamMembersArray[index].email.split("@")[0]);
    teamMembersArray[index].email.split("@")[1] &&
      setTeamUserCurrentEmail(teamMembersArray[index].email.split("@")[1]);
    setTeamUserCountryCode(teamMembersArray[index].countryCode || "");
    setTeamUserCellPhone(
      isNaN(teamMembersArray[index].cellPhone)
        ? ""
        : teamMembersArray[index].cellPhone || ""
    );
    setTeamUserBaseRole(teamMembersArray[index].baseRole);
    setTeamUserTeamRole(teamMembersArray[index].teamRole);
    // setTeamUserImage(teamMembersArray[index].image || null);
    setTeamMembersError(teamMembersArray[index].error);
  };

  function isValidEmail(email) {
    return domainNamesArray.some((domain) => email.includes(domain.value));
  }

  const importCSV = (e) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result.split("\r\n");
      let newTeamArray = [...teamMembersArray];
      text.map((row, index) => {
        if (row && newTeamArray.length < 30) {
          row = row.split(",");
          if (index !== 0 && index <= text.length) {
            let error = null;
            const CC = parsePhoneNumber(row[2]);
            if (row.length !== 5 || row.includes("")) {
              error = `This row don't have the correct format`;
              notificationSubject.next({
                message: `The row ${index + 1} dont have the correct format`,
                type: "ERROR",
              });
            }
            const validEmail = isValidEmail(row[1]);
            if (!validEmail) {
              error =
                error !== null
                  ? `${error} & The E-mail is not valid`
                  : `The E-mail is not valid`;
              notificationSubject.next({
                message: `The E-mail in row ${
                  index + 1
                } dont have the correct format`,
                type: "ERROR",
              });
            }
            if (!CC || !CC.countryCallingCode) {
              error =
                error !== null
                  ? `${error} & This row has and invalid country code`
                  : `This row has and invalid country code`;
              notificationSubject.next({
                message: `The row ${index + 1} has and invalid country code `,
                type: "ERROR",
              });
            }
            if (!CC || !CC.number) {
              error =
                error !== null
                  ? `${error} & This row has and invalid phone`
                  : `This row has and invalid phone`;
              notificationSubject.next({
                message: `The row ${index + 1} has and invalid phone `,
                type: "ERROR",
              });
            }
            if (
              !baseRoleDropdownOptions
                .map((element) => element.value)
                .includes(row[3])
            ) {
              error =
                error !== null
                  ? `${error} & This row has and invalid BaseRole`
                  : `This row has and invalid BaseRole`;
              notificationSubject.next({
                message: `The row ${index + 1} has and invalid BaseRole `,
                type: "ERROR",
              });
              row[3] = "";
            }
            if (
              !teamRoleDropdownOptions
                .map((element) => element.value)
                .includes(row[4])
            ) {
              error =
                error !== null
                  ? `${error} & This row has and invalid TeamRole`
                  : `This row has and invalid TeamRole`;
              notificationSubject.next({
                message: `The row ${index + 1} has and invalid TeamRole `,
                type: "ERROR",
              });
              row[4] = "";
            }
            newTeamArray.push({
              name: row[0] + "",
              email: validEmail ? row[1] : "",
              countryCode: CC?.countryCallingCode,
              cellPhone: CC?.number,
              baseRole: row[3],
              teamRole: row[4],
              error,
            });
          }
        } else
          notificationSubject.next({
            message: `The maximum user limit was reached, please do not exceed the parameters minimum of 2 and a maximum of 30. The users were deleted`,
            type: "ERROR",
          });
      });
      setTeamMembersArray([...newTeamArray]);
      setTeamMembersErrorGlobal(
        newTeamArray.filter((member) => member.error).length > 0
      );
    };
    reader.readAsText(e.target.files[0]);
  };

  const handleStepsMoveLiteral = {
    Step1: () => {
      stepOneRef?.current.setChevronState(true);
      stepTwoRef?.current.setChevronState(false);
      stepThreeRef?.current.setChevronState(false);
      stepFourRef?.current.setChevronState(false);
      setTimeout(() => {
        stepOneRefDiv?.current.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }, 0);
    },
    Step2: () => {
      stepOneRef?.current.setChevronState(false);
      stepTwoRef?.current.setChevronState(true);
      stepThreeRef?.current.setChevronState(false);
      stepFourRef?.current.setChevronState(false);
      setTimeout(() => {
        stepTwoRefDiv?.current.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }, 0);
    },
    Step3: () => {
      stepOneRef?.current.setChevronState(false);
      stepTwoRef?.current.setChevronState(false);
      stepThreeRef?.current.setChevronState(true);
      stepFourRef?.current.setChevronState(false);
      setTimeout(() => {
        stepThreeRefDiv?.current.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }, 0);
    },
    Step4: () => {
      stepOneRef?.current.setChevronState(false);
      stepTwoRef?.current.setChevronState(false);
      stepThreeRef?.current.setChevronState(false);
      stepFourRef?.current.setChevronState(true);
      setTimeout(() => {
        stepFourRefDiv?.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }, 0);
    },
  };

  const modalPrimaryColorLiteral = {
    "Team Information": () => "rgb(127 59 0)",
    "Team Manager": () => "rgb(127 0 106)",
    "Ready For Duty Configurations": () => "rgb(0 127 33)",
    "Team Members": () => "rgb(49 0 127)",
    "Escalation Policies": () => "rgb(127 0 82)",
    Schedules: () => "rgb(127 0 29)",
    "Response Plays": () => "rgb(251 108 108)",
    Tag: () => "rgb(255 212 0)",
    Teams: () => "rgb(108 198 251)",
    Users: () => "rgb(209 108 251)",
  };

  const modalSecundaryColorLiteral = {
    "Team Information": () => "rgb(127 70 0 / 52%)",
    "Team Manager": () => "rgb(107 0 127 / 52%)",
    "Ready For Duty Configurations": () => "rgb(0 127 39 / 52%)",
    "Team Members": () => "rgb(0 16 127 / 52%)",
    "Escalation Policies": () => "rgb(127 0 82 / 52%)",
    Schedules: () => "rgb(127 0 0 / 75%)",
    "Response Plays": () => "rgb(255 124 124 / 39%)",
    Tag: () => "rgb(249 219 73 / 58%)",
    Teams: () => "rgb(108 198 251 / 55%)",
    Users: () => "rgb(209 108 251 / 54%)",
  };

  const createPayload = () => {
    return {
      track_information: {
        email: emailOwner,
        api_key: userKey,
        subdomain:
          userKey && userKeySubdomain ? userKeySubdomain : token.subdomain,
      },
      domainNamesArray: domainNamesArray,
      key: userKey,
      team_information: {
        team_name: teamName,
        team_tag: teamTag,
        team_description: teamDescription,
      },
      team_manager: {
        name: managerName,
        email: managerEmail + managerCurrentEmail,
        base_role: managerBaseRole,
        team_role: managerTeamRole,
        phone: {
          country_code: managerCountryCode,
          cell_phone: managerCellPhone,
        },
        // "image": managerImage || null
      },
      team_members: teamMembersArray.map((member) => {
        return {
          name: member.name,
          email: member.email,
          base_role: member.baseRole,
          team_role: member.teamRole,
          phone: {
            country_code: member.countryCode,
            cell_phone: member.cellPhone,
          },
          // "image": member.image || null
        };
      }),
      ready_for_duty_configurations: {
        escalation_policies_major_incident: {
          prefix: majorIncidentPrefix,
          suffix: majorIncidentSuffix,
          final_name: `${
            majorIncidentPrefix && majorIncidentPrefix + "-"
          }${teamName}${majorIncidentSuffix && "-" + majorIncidentSuffix}`,
        },
        response_play_major_incident: {
          prefix: majorIncidentRPNPrefix,
          suffix: majorIncidentRPNSuffix,
          final_name: `${
            majorIncidentRPNPrefix && majorIncidentRPNPrefix + "-"
          }${teamName}${
            majorIncidentRPNSuffix && "-" + majorIncidentRPNSuffix
          }`,
        },
        escalation_policies_non_major_incident: {
          prefix: nonMajorIncidentPrefix,
          suffix: nonMajorIncidentSuffix,
          final_name: `${
            nonMajorIncidentPrefix && nonMajorIncidentPrefix + "-"
          }${teamName}${
            nonMajorIncidentSuffix && "-" + nonMajorIncidentSuffix
          }`,
        },
        response_play_non_major_incident: {
          prefix: nonMajorIncidentRPNPrefix,
          suffix: nonMajorIncidentRPNSuffix,
          final_name: `${
            nonMajorIncidentRPNPrefix && nonMajorIncidentRPNPrefix + "-"
          }${teamName}${
            nonMajorIncidentRPNSuffix && "-" + nonMajorIncidentRPNSuffix
          }`,
        },
      },
      schedules: {
        primary_schedule_type: primaryScheduleType,
        secondary_schedule_type: secondaryScheduleType,
      },
    };
  };

  const setManagerCellPhoneFunction = (phone) => {
    setManagerCellPhone(phone);
    setManagerCountryCode(
      parsePhoneNumber(phone || "")
        ? `+${parsePhoneNumber(phone).countryCallingCode}`
        : ""
    );
  };

  const setTeamUserCellPhoneFunction = (phone) => {
    setTeamUserCellPhone(phone);
    setTeamUserCountryCode(
      parsePhoneNumber(phone || "")
        ? `+${parsePhoneNumber(phone).countryCallingCode}`
        : ""
    );
  };

  useEffect(() => {
    !addEmailToggle && setEmailOwner("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEmailToggle]);

  useEffect(() => {
    if (addEmailToggle) {
      setAddEmailToggleTimeOut(true);
    } else {
      setTimeout(() => {
        setAddEmailToggleTimeOut(false);
      }, 500);
    }
  }, [addEmailToggle]);

  return (
    <>
      {isLoading && (
        <div id="Onboarding-loader">
          <LoadingSpinner />
        </div>
      )}
      <div id="OnBoarding-container">
        <div id="OnBoarding-title-container">
          <div style={{ display: "flex" }}>
            <AppIcon type="Onboarding" />
            <h2 id="OnBoarding-title">Onboarding</h2>
          </div>
          {lastLoadedDate ? 
          <div>
            <div id="Onboarding-FAQ" onClick={() => setFAQModalOpen(true)}>
              Frequently Asked Questions
              <FontAwesomeIcon
                id="Onboarding-FAQIcon"
                icon={faQuestionCircle}
              />
            </div>
            <div id="OnBoarding-title-container-time">
              <label>
                <b>Last Loaded:</b>
              </label>
              <span>{lastLoadedDate}</span>
            </div>
          </div>
          : <div>
              <div id="Onboarding-FAQ" onClick={() => setFAQModalOpen(true)}>
                Frequently Asked Questions
                <FontAwesomeIcon
                  id="Onboarding-FAQIcon"
                  icon={faQuestionCircle}
                />
              </div>
              <div id="OnBoarding-title-container-time"></div>
            </div>}
        </div>
        <div id="OnBoarding-container-left">
          <div id="OnBoarding-container-left-waves" ref={waveRef}>
            <div className="OnBoarding-waves-title">
              <FillingCircle
                diameter={(height - 272) / 4}
                percentage={step1Percentage}
              />
              <label>Team Information</label>
            </div>
            <div className="OnBoarding-waves-title">
              <FillingCircle
                percentage={step2Percentage}
                diameter={(height - 272) / 4}
              />
              <label>Team Manager</label>
            </div>
            <div className="OnBoarding-waves-title">
              <FillingCircle
                percentage={step3Percentage}
                diameter={(height - 272) / 4}
              />
              <label>Team Members</label>
            </div>
            <div className="OnBoarding-waves-title">
              <FillingCircle
                percentage={step4Percentage}
                diameter={(height - 272) / 4}
              />
              <label>Ready for Duty Configurations</label>
            </div>
          </div>
          <div id="OnBoarding-container-right">
            <button
              style={{ marginRight: "15px", width: "160px" }}
              className="OnBoarding-container-right-button button-regular"
              onClick={() => setAdvanceModeToggle(!advanceModeToggle)}
            >
              Advance Mode
            </button>
            {advanceModeToggle && (
              <div id="OnBoarding-container-key">
                <div style={{ width: "fit-content" }}>
                  <button
                    style={{ marginRight: "15px", width: "160px" }}
                    className="OnBoarding-container-right-button button-regular"
                    onClick={() => setAddKeyToggle(!addKeyToggle)}
                  >
                    {addKeyToggle ? "Remove Key" : "Add Key"}
                  </button>
                </div>
                {addKeyToggleTimeOut && (
                  <div className={!addKeyToggle ? "removeKeyContainer" : ""}>
                    <span>Key:</span>
                    <div className="encrypted-div">
                      <button
                        onMouseDown={() => setEncrypt(false)}
                        onMouseUp={() => setEncrypt(true)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                      <input
                        value={userKey}
                        className={encrypt ? "encrypted" : ""}
                        onChange={(e) =>
                          setUserKey(e.target.value.replace(/\s+/g, ""))
                        }
                      />
                    </div>
                  </div>
                )}
                <div
                  className="normalRow"
                  style={{
                    display: "flex",
                    alignItems: "end",
                    marginLeft: "15px",
                    width: "fit-content",
                  }}
                >
                  <button
                    style={{ width: "224px" }}
                    className="button-regular OnBoarding-container-right-button"
                    onClick={() => setAddEmailToggle(!addEmailToggle)}
                  >
                    {addEmailToggle ? "Use PD E-mail" : "Use Manual E-mail"}
                  </button>
                </div>
              </div>
            )}
            {userKey ? (
              userKeySubdomain ? (
                <label className="onboardingIconLabelDiv">
                  You are working as:{" "}
                  <span className="onboardingsubdomainKeyLabelDiv">
                    {userKeySubdomain}
                  </span>
                </label>
              ) : (
                <label className="onboardingIconLabelDiv">
                  This is not a valid Key{" "}
                  <FontAwesomeIcon
                    className="onboardingRedIcon"
                    icon={faTimes}
                  />
                </label>
              )
            ) : null}
            <div className="normalRow">
              {addEmailToggleTimeOut && advanceModeToggle && (
                <label>E-mail Owner:</label>
              )}
              <div id="OnBoarding-container-email">
                {addEmailToggleTimeOut && advanceModeToggle && (
                  <div className={!addEmailToggle ? "removeKeyContainer" : ""}>
                    <label>E-mail Owner:</label>
                    <input
                      value={emailOwner}
                      onChange={(e) => setEmailOwner(e.target.value)}
                    />
                  </div>
                )}
              </div>
              {!validateEmail() && advanceModeToggle && emailOwner && (
                <label
                  style={{
                    fontWeight: "normal",
                    color: "red",
                    marginBottom: "10px",
                  }}
                >
                  E-mail is not valid *
                </label>
              )}
            </div>
            {addKeyToggle && advanceModeToggle && (
              <div id="step1FormAndButtonContainer">
                <div id="step1FormContainer">
                  <div className="normalRow">
                    <label>E-mail Accepted Domain Names:</label>
                    <div className="rowInputButton">
                      <div className="ADiv" style={{ cursor: "default" }}>
                        @
                      </div>
                      <input
                        disabled={
                          emailOwner && !validateEmail() && addEmailToggle
                        }
                        className="ADivInput"
                        placeholder="something.com"
                        value={domainNamesInput}
                        onChange={(e) =>
                          setDomainNamesInput(e.target.value.replace("@", ""))
                        }
                      />
                      <button
                        className="OnBoarding-container-right-button button-regular"
                        disabled={!domainNamesInput || !isEmailValid}
                        onClick={() => addDomainName()}
                      >
                        Add
                      </button>
                    </div>
                    <div id="step1TableDiv">
                      {domainNamesArray.map((domain, index) => (
                        <div key={domain.value}>
                          {domain.value}
                          {"@" + token.sub.split("@")[1] !== domain.value && (
                            <button onClick={() => removeDomainName(index)}>
                              <FontAwesomeIcon icon={faTimesCircle} />
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Chevron
              ref={stepOneRef}
              nameClass="OnBoarding-container-right-chevron-div"
              title="Team Information"
              chevronColor="white"
              startOpen={true}
            >
              <div ref={stepOneRefDiv}>
                <div className="normalRow">
                  <label>Team Name:</label>
                  <input
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                  />
                </div>
                <div className="normalRow">
                  <label>Team Tag:</label>
                  <input
                    value={teamTag}
                    onChange={(e) =>
                      setTeamTag(e.target.value.replace(/\s/g, ""))
                    }
                  />
                </div>
                <div className="normalRow">
                  <label>Team Description:</label>
                  <textarea
                    value={teamDescription}
                    onChange={(e) => setTeamDescription(e.target.value)}
                  />
                </div>
                <div className="OnBoarding-container-right-buttons-div">
                  <div></div>
                  <button
                    className="OnBoarding-container-right-button button-regular"
                    onClick={() => handleStepsMoveLiteral["Step2"]()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </Chevron>
            <Chevron
              ref={stepTwoRef}
              color={"#035F7F"}
              nameClass="OnBoarding-container-right-chevron-div"
              title="Team Manager"
              chevronColor="white"
            >
              <div ref={stepTwoRefDiv} id="OnBoarding-container-right-step2">
                {/* <div id="OnBoarding-container-right-step2-left">
                                    <div id="OnBoarding-container-right-DivUserPicture"
                                        style={{ backgroundImage: managerImage ? 'url(https://www.gravatar.com/avatar/' + managerImage + '?s=600)' : 'url(' + NoUser + ')' }}>
                                    </div>
                                </div> */}
                <div id="OnBoarding-container-right-step2-right">
                  <div className="normalRow">
                    <label>Name:</label>
                    <input
                      value={managerName}
                      onChange={(e) => setManagerName(e.target.value)}
                    />
                  </div>
                  <div className="normalRow">
                    <label>Email:</label>
                    <div className="Email">
                      <input
                        value={managerEmail}
                        onChange={(e) =>
                          setManagerEmail(e.target.value.replace("@", ""))
                        }
                      />
                      <DropdownO
                        options={domainNamesArray}
                        onChange={(value) => setManagerCurrentEmail(value)}
                        value={managerCurrentEmail}
                      />
                    </div>
                  </div>
                  <div className="normalRow">
                    <label>Base Role:</label>
                    <DropdownO
                      options={baseRoleDropdownOptions}
                      onChange={(value) => setManagerBaseRole(value)}
                      value={managerBaseRole}
                    />
                  </div>
                  <div className="normalRow">
                    <label>Team Role:</label>
                    <DropdownO
                      options={teamRoleDropdownOptions}
                      onChange={(value) => setManagerTeamRole(value)}
                      value={managerTeamRole}
                    />
                  </div>
                  <div className="OnBoarding-SeparationText">Phone</div>
                  <div className="normalRow">
                    <label>Cell Phone:</label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={managerCellPhone}
                      defaultCountry="US"
                      withCountryCallingCode={true}
                      international={true}
                      onChange={(e) => setManagerCellPhoneFunction(e)}
                    />
                  </div>
                  {/* <div className="normalRow">
                                        <label>Gravatar:</label>
                                        <div className="GravatarDiv">
                                            <span>www.gravatar.com/avatar/</span>
                                            <input value={managerImage} onChange={(e) => setManagerImage(e.target.value.replace("https://", "").replace("http://", "").replace("www.gravatar.com/avatar/", ""))} />
                                        </div>
                                    </div> */}
                </div>
              </div>
              <div className="OnBoarding-container-right-buttons-div">
                <button
                  className="button-regular OnBoarding-container-right-button"
                  onClick={() => handleStepsMoveLiteral["Step1"]()}
                >
                  Previous
                </button>
                <button
                  className="button-regular OnBoarding-container-right-button"
                  onClick={() => handleStepsMoveLiteral["Step3"]()}
                >
                  Next
                </button>
              </div>
            </Chevron>
            <Chevron
              ref={stepThreeRef}
              color={teamMembersErrorGlobal ? "#f82431" : "#035F7F"}
              nameClass="OnBoarding-container-right-chevron-div"
              title="Team Members"
              chevronColor="white"
            >
              <div ref={stepThreeRefDiv} id="OnBoarding-container-right-step2">
                {/* <div id="OnBoarding-container-right-step2-left">
                                    <div id="OnBoarding-container-right-DivUserPicture"
                                        style={{ backgroundImage: teamUserImage ? 'url(https://www.gravatar.com/avatar/' + teamUserImage + '?s=600)' : 'url(' + NoUser + ')' }}>
                                    </div>
                                </div> */}
                <div id="OnBoarding-container-right-step2-right">
                  <div style={{ alignSelf: "flex-end", marginRight: "20px" }}>
                    <input
                      type="file"
                      name="file"
                      id="CSV-Onboarding"
                      className="inputfile"
                      accept=".csv"
                      onChange={(e) => importCSV(e)}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                    <label htmlFor="CSV-Onboarding" style={{ margin: "0px" }}>
                      Import from CSV
                    </label>
                  </div>
                  <div className="normalRow">
                    <label>Name:</label>
                    <input
                      value={teamUserName}
                      onChange={(e) => setTeamUserName(e.target.value)}
                    />
                  </div>
                  <div className="normalRow">
                    <label>Email:</label>
                    <div className="Email">
                      <input
                        value={teamUserEmail}
                        onChange={(e) =>
                          setTeamUserEmail(e.target.value.replace("@", ""))
                        }
                      />
                      <DropdownO
                        options={domainNamesArray}
                        onChange={(value) => setTeamUserCurrentEmail(value)}
                        value={teamUserCurrentEmail}
                      />
                    </div>
                  </div>
                  <div className="normalRow">
                    <label>Base Role:</label>
                    <DropdownO
                      options={baseRoleDropdownOptions}
                      onChange={(value) => setTeamUserBaseRole(value)}
                      value={teamUserBaseRole}
                    />
                  </div>
                  <div className="normalRow">
                    <label>Team Role:</label>
                    <DropdownO
                      options={teamRoleDropdownOptions}
                      onChange={(value) => setTeamUserTeamRole(value)}
                      value={teamUserTeamRole}
                    />
                  </div>
                  <div className="OnBoarding-SeparationText">Phone</div>
                  <div className="normalRow">
                    <label>Cell Phone:</label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={teamUserCellPhone}
                      defaultCountry="US"
                      withCountryCallingCode={true}
                      international={true}
                      onChange={(e) => setTeamUserCellPhoneFunction(e)}
                    />
                  </div>
                  {/* <div className="normalRow">
                                        <label>Gravatar:</label>
                                        <div className="GravatarDiv">
                                            <span>www.gravatar.com/avatar/</span>
                                            <input value={teamUserImage} onChange={(e) => setTeamUserImage(e.target.value.replace("https://", "").replace("http://", "").replace("www.gravatar.com/avatar/", ""))} />
                                        </div>
                                    </div> */}
                  <button
                    className="button-regular"
                    disabled={
                      !teamUserName ||
                      !teamUserEmail ||
                      !teamUserCountryCode ||
                      !teamUserCellPhone ||
                      !teamUserBaseRole ||
                      !teamUserTeamRole
                    }
                    onClick={() => addTeamMember()}
                  >
                    {editMemberActive ? "Modify Member" : "Add Member"}
                  </button>
                </div>
              </div>
              {teamMembersError && (
                <p className="OnBoarding-container-right-DivUserPicture-warningText">
                  {teamMembersError}
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  margin: "0px 20px",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontWeight: "bold" }}>
                  Remaining users allowed: {30 - teamMembersArray.length}
                </div>
                <div style={{ fontWeight: "bold", color: "red" }}>
                  Minimum users allowed 2 and maximum 30
                </div>
              </div>
              <div id="OnBoarding-container-right-step3-bottom-div-users">
                {teamMembersArray.map((member, index) => (
                  <div
                    className="OnBoarding-container-right-DivUserPicture-mini-div"
                    key={member.name + index}
                  >
                    <div
                      className="OnBoarding-container-right-DivUserPicture-mini-div-closeIcon"
                      onClick={() => removeTeamMember(index)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                    {member.error && (
                      <div className="OnBoarding-container-right-DivUserPicture-mini-div-warningIcon">
                        <FontAwesomeIcon icon={faWarning} />
                      </div>
                    )}
                    <div
                      className="OnBoarding-container-right-DivUserPicture-mini"
                      onClick={() => modifyUser(index)}
                      style={{
                        backgroundImage: member.image
                          ? "url(https://www.gravatar.com/avatar/" +
                            member.image +
                            "?s=200)"
                          : "url(" + NoUser + ")",
                      }}
                    ></div>
                    {member.name}
                  </div>
                ))}
              </div>
              <div id="OnBoarding-container-right-step2-right-button-top-container">
                <button
                  id="OnBoarding-container-right-step2-right-button-top-container-link"
                  onClick={() => {
                    exportCSV(
                      [
                        {
                          Name: "",
                          Email: "",
                          "Cell Phone (With Country Code)": "",
                          "Base Role": "",
                          "Team Role": "",
                        },
                      ],
                      "Onboarding-Team-Members-Template"
                    );
                  }}
                >
                  {" "}
                  Get Team Members CSV template
                </button>
              </div>
              <div className="OnBoarding-container-right-buttons-div">
                <button
                  className="button-regular OnBoarding-container-right-button"
                  onClick={() => handleStepsMoveLiteral["Step2"]()}
                >
                  Previous
                </button>
                <button
                  className="button-regular OnBoarding-container-right-button"
                  onClick={() => handleStepsMoveLiteral["Step4"]()}
                >
                  Next
                </button>
              </div>
            </Chevron>
            <Chevron
              ref={stepFourRef}
              nameClass="OnBoarding-container-right-chevron-div"
              title="Ready for Duty Configurations"
              chevronColor="white"
            >
              <div ref={stepFourRefDiv} className="normalRow">
                <label className="OnBoarding-Incident-container-title">
                  Escalation Policies - Major Incident
                </label>
                <div className="OnBoarding-Incident-container-div">
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Prefix:</label>
                    <EmojiPicker
                      onChange={(value) => setMajorIncidentPrefix(value)}
                      value={majorIncidentPrefix}
                    />
                  </div>
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Suffix:</label>
                    <EmojiPicker
                      onChange={(value) => setMajorIncidentSuffix(value)}
                      value={majorIncidentSuffix}
                    />
                  </div>
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Final Name:</label>
                    <input
                      disabled={true}
                      value={`${
                        majorIncidentPrefix && majorIncidentPrefix + "-"
                      }${teamName}${
                        majorIncidentSuffix && "-" + majorIncidentSuffix
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div ref={stepFourRefDiv} className="normalRow">
                <label className="OnBoarding-Incident-container-title">
                  Response Play Name - Major Incident
                </label>
                <div className="OnBoarding-Incident-container-div">
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Prefix:</label>
                    <EmojiPicker
                      onChange={(value) => setMajorIncidentRPNPrefix(value)}
                      value={majorIncidentRPNPrefix}
                    />
                  </div>
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Suffix:</label>
                    <EmojiPicker
                      onChange={(value) => setMajorIncidentRPNSuffix(value)}
                      value={majorIncidentRPNSuffix}
                    />
                  </div>
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Final Name:</label>
                    <input
                      disabled={true}
                      value={`${
                        majorIncidentRPNPrefix && majorIncidentRPNPrefix + "-"
                      }${teamName}${
                        majorIncidentRPNSuffix && "-" + majorIncidentRPNSuffix
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div className="normalRow">
                <label className="OnBoarding-Incident-container-title">
                  Escalation Policies - Non Major Incident
                </label>
                <div className="OnBoarding-Incident-container-div">
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Prefix:</label>
                    <EmojiPicker
                      onChange={(value) => setNonMajorIncidentPrefix(value)}
                      value={nonMajorIncidentPrefix}
                    />
                  </div>
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Suffix:</label>
                    <EmojiPicker
                      onChange={(value) => setNonMajorIncidentSuffix(value)}
                      value={nonMajorIncidentSuffix}
                    />
                  </div>
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Final Name:</label>
                    <input
                      disabled={true}
                      value={`${
                        nonMajorIncidentPrefix && nonMajorIncidentPrefix + "-"
                      }${teamName}${
                        nonMajorIncidentSuffix && "-" + nonMajorIncidentSuffix
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div className="normalRow">
                <label className="OnBoarding-Incident-container-title">
                  Response Play Name - Non Major Incident
                </label>
                <div className="OnBoarding-Incident-container-div">
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Prefix:</label>
                    <EmojiPicker
                      onChange={(value) => setNonMajorIncidentRPNPrefix(value)}
                      value={nonMajorIncidentRPNPrefix}
                    />
                  </div>
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Suffix:</label>
                    <EmojiPicker
                      onChange={(value) => setNonMajorIncidentRPNSuffix(value)}
                      value={nonMajorIncidentRPNSuffix}
                    />
                  </div>
                  <div className="OnBoarding-Incident-container-div-inner">
                    <label>Final Name:</label>
                    <input
                      disabled={true}
                      value={`${
                        nonMajorIncidentRPNPrefix &&
                        nonMajorIncidentRPNPrefix + "-"
                      }${teamName}${
                        nonMajorIncidentRPNSuffix &&
                        "-" + nonMajorIncidentRPNSuffix
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div className="normalRow">
                <label className="OnBoarding-Incident-container-title">
                  Schedules
                </label>
                <div className="normalRow">
                  <label>Primary Schedule Type:</label>
                  <DropdownO
                    options={scheduleTypes}
                    onChange={(value) => setPrimaryScheduleType(value)}
                    value={primaryScheduleType}
                  />
                  {primaryScheduleTypeDescription && (
                    <p className="OnBoarding-container-right-description-text ">
                      {primaryScheduleTypeDescription}
                    </p>
                  )}
                </div>
                <div className="normalRow">
                  <label>Secondary Schedule Type:</label>
                  <DropdownO
                    options={scheduleTypes}
                    disabled
                    onChange={(value) => setsecondaryScheduleType(value)}
                    value={secondaryScheduleType}
                  />
                  {secondaryScheduleTypeDescription && (
                    <p className="OnBoarding-container-right-description-text ">
                      {secondaryScheduleTypeDescription}
                    </p>
                  )}
                </div>
              </div>
              <div className="OnBoarding-container-right-buttons-div">
                <button
                  className="OnBoarding-container-right-button button-regular"
                  onClick={() => handleStepsMoveLiteral["Step3"]()}
                >
                  Previous
                </button>
                <div></div>
              </div>
            </Chevron>
            <div className="OnBoarding-submit-container-right-buttons-div">
              <button
                style={{ marginRight: "15px" }}
                className="OnBoarding-container-right-button button-regular"
                onClick={() => delete_Draft()}
              >
                Remove Draft
              </button>
              <button
                style={{ marginRight: "15px" }}
                className="OnBoarding-container-right-button button-regular"
                onClick={() => saveAsAdraft()}
              >
                Save as a Draft
              </button>
              <button
                id="OnBoarding-container-right-button-submit"
                disabled={
                  step1Percentage < 100 ||
                  step2Percentage < 100 ||
                  step3Percentage < 100 ||
                  step4Percentage < 100 ||
                  teamMembersErrorGlobal ||
                  (addKeyToggle && (!userKey || !userKeySubdomain)) ||
                  (addEmailToggle &&
                    ((emailOwner && !validateEmail()) || !emailOwner))
                }
                className="button-regular"
                onClick={() => setConfirmationModalOpen(true)}
              >
                Submit
              </button>
            </div>
            <div>
              <Modal
                show={FAQModalOpen}
                title="FAQ"
                handleClose={() => setFAQModalOpen(!FAQModalOpen)}
                modalFitContent
              >
                <Questions questionsArray={questionsArray} color='#035F7F' />
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalObject}
        title={
          modalObject?.success_operations
            ? "Summary of Results - (Successful)"
            : "Summary of Results - (Unsuccessful)"
        }
        handleClose={() => setModalObject(null)}
        modalFitContent
      >
        <>
          <div id="modalContainerErrorOnboardingDivColorSpanContainer">
            <span className="modalContainerErrorOnboardingDivColorSpan">
              <div
                className="modalContainerErrorOnboardingDivColorCircle"
                style={{ backgroundColor: "rgb(0 90 36)" }}
              />{" "}
              Success
            </span>
            <span className="modalContainerErrorOnboardingDivColorSpan">
              <div
                className="modalContainerErrorOnboardingDivColorCircle"
                style={{ backgroundColor: "rgb(205 129 16)" }}
              />{" "}
              Not started
            </span>
            <span className="modalContainerErrorOnboardingDivColorSpan">
              <div
                className="modalContainerErrorOnboardingDivColorCircle"
                style={{ backgroundColor: "rgb(137 16 16)" }}
              />{" "}
              Error
            </span>
          </div>
          {modalObject && Object.hasOwn(modalObject, "success_operations") && (
            <div id="modalContainerErrorOnboardingDivC">
              <Chevron
                color={
                  !modalObject.success_operations
                    ? "#f82431"
                    : "#035F7F"
                }
                nameClass="Onboarding-container-modal-chevron"
                title="Principal Operations"
                chevronColor="white"
              >
                <div
                  className="modalContainerErrorOnboardingDiv"
                  style={{ width: "calc(80vw - 20px)" }}
                >
                  {modalObject.principal_operations.map((element) => (
                    <div
                      className="modalContainerErrorOnboardingDivContainer"
                      style={{
                        border: `2px solid ${
                          modalPrimaryColorLiteral.hasOwnProperty(
                            element.section
                          )
                            ? modalPrimaryColorLiteral[element.section]()
                            : "#035F7F"
                        }`,
                        backgroundColor:
                          modalSecundaryColorLiteral.hasOwnProperty(
                            element.section
                          )
                            ? modalSecundaryColorLiteral[element.section]()
                            : "rgba(0, 95, 127, 0.521)",
                      }}
                    >
                      <label
                        className="modalContainerErrorOnboardingDivContainerTextTitle"
                        style={{
                          backgroundColor:
                            modalPrimaryColorLiteral.hasOwnProperty(
                              element.section
                            )
                              ? modalPrimaryColorLiteral[element.section]()
                              : "#035F7F",
                        }}
                      >
                        {element.section}
                      </label>
                      <div className="modalContainerErrorOnboardingDivContainerInner">
                        <div>
                          {" "}
                          <FillingCircle
                            color={
                              element.code === 200
                                ? "rgb(0 90 36)"
                                : element.code === 305
                                ? "rgb(205 129 16)"
                                : "rgb(137 16 16)"
                            }
                            percentage={element.percentage}
                            diameter={(height - 270) / 5}
                          />
                        </div>
                        <div className="modalContainerErrorOnboardingDivContainerText">
                          <label>
                            <b>{element.title}</b>
                          </label>
                          {element.code !== 404 && (
                            <label className="modalContainerErrorOnboardingDivContainerTextMessage">
                              {element.message}
                            </label>
                          )}
                          {element.code === 404 && (
                            <ul className="modalContainerErrorOnboardingDivContainerTextMessage">
                              {Array.isArray(element.errorsList) ? (
                                element.errorsList.map((error) => (
                                  <li>{error}</li>
                                ))
                              ) : (
                                <>{element.errorsList}</>
                              )}
                            </ul>
                          )}
                          <label className="modalContainerErrorOnboardingDivContainerTextCode">
                            Code:{" "}
                            <span className="modalContainerErrorOnboardingDivContainerTextCodeInner">
                              {element.code}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Chevron>

              <Chevron
                color={
                  !modalObject.success_operations
                    ? "#f82431"
                    : "#035F7F"
                }
                nameClass="Onboarding-container-modal-chevron"
                title="Internal Operations"
                chevronColor="white"
              >
                <div
                  className="modalContainerErrorOnboardingDiv"
                  style={{ width: "calc(80vw - 20px)" }}
                >
                  {modalObject.internal_operations.map((element) => (
                    <div
                      className="modalContainerErrorOnboardingDivContainer"
                      style={{
                        border: `2px solid ${
                          modalPrimaryColorLiteral.hasOwnProperty(
                            element.section
                          )
                            ? modalPrimaryColorLiteral[element.section]()
                            : "#035F7F"
                        }`,
                        backgroundColor:
                          modalSecundaryColorLiteral.hasOwnProperty(
                            element.section
                          )
                            ? modalSecundaryColorLiteral[element.section]()
                            : "rgba(0, 95, 127, 0.521)",
                      }}
                    >
                      <label
                        className="modalContainerErrorOnboardingDivContainerTextTitle"
                        style={{
                          backgroundColor:
                            modalPrimaryColorLiteral.hasOwnProperty(
                              element.section
                            )
                              ? modalPrimaryColorLiteral[element.section]()
                              : "#035F7F",
                        }}
                      >
                        {element.section}
                      </label>
                      <div className="modalContainerErrorOnboardingDivContainerInner">
                        <div>
                          {" "}
                          <FillingCircle
                            color={
                              element.code === 200
                                ? "rgb(0 90 36)"
                                : element.code === 305
                                ? "rgb(205 129 16)"
                                : "rgb(137 16 16)"
                            }
                            percentage={element.percentage}
                            diameter={(height - 270) / 5}
                          />
                        </div>
                        <div className="modalContainerErrorOnboardingDivContainerText">
                          <label>
                            <b>{element.title}</b>
                          </label>
                          {element.code !== 404 && (
                            <label className="modalContainerErrorOnboardingDivContainerTextMessage">
                              {element.message}
                            </label>
                          )}
                          {element.code === 404 && (
                            <ul className="modalContainerErrorOnboardingDivContainerTextMessage">
                              {Array.isArray(element.errorsList) ? (
                                element.errorsList.map((error) => (
                                  <li>{error}</li>
                                ))
                              ) : (
                                <>{element.errorsList}</>
                              )}
                            </ul>
                          )}
                          <label className="modalContainerErrorOnboardingDivContainerTextCode">
                            Code:{" "}
                            <span className="modalContainerErrorOnboardingDivContainerTextCodeInner">
                              {element.code}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Chevron>

              {modalObject.team_members_operations.map((member) => (
                <Chevron
                  color={
                    !modalObject.success_operations
                      ? "#f82431"
                      : "#035F7F"
                  }
                  nameClass="Onboarding-container-modal-chevron"
                  title={`Team member - (${member.key})`}
                  chevronColor="white"
                >
                  <div
                    className="modalContainerErrorOnboardingDiv"
                    style={{ width: "calc(80vw - 20px)" }}
                  >
                    {member.value.map((element) => (
                      <div
                        className="modalContainerErrorOnboardingDivContainer"
                        style={{
                          border: `2px solid ${
                            modalPrimaryColorLiteral.hasOwnProperty(
                              element.section
                            )
                              ? modalPrimaryColorLiteral[element.section]()
                              : "#035F7F"
                          }`,
                          backgroundColor:
                            modalSecundaryColorLiteral.hasOwnProperty(
                              element.section
                            )
                              ? modalSecundaryColorLiteral[element.section]()
                              : "rgba(0, 95, 127, 0.521)",
                        }}
                      >
                        <label
                          className="modalContainerErrorOnboardingDivContainerTextTitle"
                          style={{
                            backgroundColor:
                              modalPrimaryColorLiteral.hasOwnProperty(
                                element.section
                              )
                                ? modalPrimaryColorLiteral[element.section]()
                                : "#035F7F",
                          }}
                        >
                          {element.section}
                        </label>
                        <div className="modalContainerErrorOnboardingDivContainerInner">
                          <div>
                            {" "}
                            <FillingCircle
                              color={
                                element.code === 200
                                  ? "rgb(0 90 36)"
                                  : element.code === 305
                                  ? "rgb(205 129 16)"
                                  : "rgb(137 16 16)"
                              }
                              percentage={element.percentage}
                              diameter={(height - 270) / 5}
                            />
                          </div>
                          <div className="modalContainerErrorOnboardingDivContainerText">
                            <label>
                              <b>{element.title}</b>
                            </label>
                            {element.code !== 404 && (
                              <label className="modalContainerErrorOnboardingDivContainerTextMessage">
                                {element.message}
                              </label>
                            )}
                            {element.code === 404 && (
                              <ul className="modalContainerErrorOnboardingDivContainerTextMessage">
                                {Array.isArray(element.errorsList) ? (
                                  element.errorsList.map((error) => (
                                    <li>{error}</li>
                                  ))
                                ) : (
                                  <>{element.errorsList}</>
                                )}
                              </ul>
                            )}
                            <label className="modalContainerErrorOnboardingDivContainerTextCode">
                              Code:{" "}
                              <span className="modalContainerErrorOnboardingDivContainerTextCodeInner">
                                {element.code}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Chevron>
              ))}

              <Chevron
                color={
                  !modalObject.success_operations
                    ? "#f82431"
                    : "#035F7F"
                }
                nameClass="Onboarding-container-modal-chevron"
                title="Rollback Operations"
                chevronColor="white"
              >
                <div
                  className="modalContainerErrorOnboardingDiv"
                  style={{ width: "calc(80vw - 20px)" }}
                >
                  {modalObject.rollback_operations.map((element) => (
                    <div
                      className="modalContainerErrorOnboardingDivContainer"
                      style={{
                        border: `2px solid ${
                          modalPrimaryColorLiteral.hasOwnProperty(
                            element.section
                          )
                            ? modalPrimaryColorLiteral[element.section]()
                            : "#035F7F"
                        }`,
                        backgroundColor:
                          modalSecundaryColorLiteral.hasOwnProperty(
                            element.section
                          )
                            ? modalSecundaryColorLiteral[element.section]()
                            : "rgba(0, 95, 127, 0.521)",
                      }}
                    >
                      <label
                        className="modalContainerErrorOnboardingDivContainerTextTitle"
                        style={{
                          backgroundColor:
                            modalPrimaryColorLiteral.hasOwnProperty(
                              element.section
                            )
                              ? modalPrimaryColorLiteral[element.section]()
                              : "#035F7F",
                        }}
                      >
                        {element.section}
                      </label>
                      <div className="modalContainerErrorOnboardingDivContainerInner">
                        <div>
                          {" "}
                          <FillingCircle
                            color={
                              element.code === 200
                                ? "rgb(0 90 36)"
                                : element.code === 305
                                ? "rgb(205 129 16)"
                                : "rgb(137 16 16)"
                            }
                            percentage={element.percentage}
                            diameter={(height - 270) / 5}
                          />
                        </div>
                        <div className="modalContainerErrorOnboardingDivContainerText">
                          <label>
                            <b>{element.title}</b>
                          </label>
                          {element.code !== 404 && (
                            <label className="modalContainerErrorOnboardingDivContainerTextMessage">
                              {element.message}
                            </label>
                          )}
                          {element.code === 404 && (
                            <ul className="modalContainerErrorOnboardingDivContainerTextMessage">
                              {Array.isArray(element.errorsList) ? (
                                element.errorsList.map((error) => (
                                  <li>{error}</li>
                                ))
                              ) : (
                                <>{element.errorsList}</>
                              )}
                            </ul>
                          )}
                          <label className="modalContainerErrorOnboardingDivContainerTextCode">
                            Code:{" "}
                            <span className="modalContainerErrorOnboardingDivContainerTextCodeInner">
                              {element.code}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Chevron>
            </div>
          )}
        </>
      </Modal>
      <Modal
        show={confirmationModalOpen}
        title={`Onboarding Session for ${
          userKey && userKeySubdomain ? userKeySubdomain : token.subdomain
        }`}
        handleClose={() => setConfirmationModalOpen(false)}
        modalFitContent
      >
        <div style={{ margin: "20px" }}>
          <h5 style={{ maxWidth: "500px" }}>
            Are you sure you want to onboard this team to{" "}
            {userKey && userKeySubdomain ? userKeySubdomain : token.subdomain}?
          </h5>
          <div>
            <button
              className="OnBoarding-container-right-button button-regular"
              onClick={() => setConfirmationModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="OnBoarding-container-right-button button-regular"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
