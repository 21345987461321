import { useState, forwardRef, useEffect, useImperativeHandle, } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    SubTitle,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    Title,
    SubTitle,
    Tooltip,
    Legend
);

const BarChart = forwardRef(({ labels, data, backgroundColors, values, messages, title, subTitle, onClickElement, legends }, Ref) => {
    
    const [forceUpdate, setForceUpdate] = useState(true);

    const getRandomColor = () => {
        return Math.floor(Math.random()*16777215).toString(16);
    }

    useEffect((
        () => {
            setForceUpdate(!forceUpdate);
        }
    ), [data]);

    useEffect((
        () => {
            setForceUpdate(!forceUpdate);
        }
    ), [values]);

    const setOpacity = (hex, alpha) => {
        var r = hex ? parseInt(hex.slice(1, 3), 16) : parseInt(getRandomColor().slice(1, 3), 16),
            g = hex ? parseInt(hex.slice(3, 5), 16) : parseInt(getRandomColor().slice(1, 3), 16),
            b = hex ? parseInt(hex.slice(5, 7), 16) :parseInt(getRandomColor().slice(1, 3), 16);
        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }
    const [chartData, setChartData] = useState({
        datasets: [],
    });
    const [chartOptions, setChartOptions] = useState({});
    useEffect(() => {
        setChartData({
            labels: labels,
            datasets: [
                {
                    label: messages,
                    data: values,
                    borderColor: backgroundColors,
                    backgroundColor: backgroundColors.map(color => setOpacity(color, 0.7)),
                    datalabels: {
                        anchor: 'end',
                        align: 'top'
                      },
                },
            ],
        });
        setChartOptions({
            responsive: true,
            onClick: function(evt, element) {
                if (element.length > 0) {
                    onClickElement(element);
                  // you can also get dataset of your selected element
                }},
            plugins: {
              datalabels: {
                color: '#000',
                textAlign: 'start',
              },
                subtitle: {
                    position: "left",
                    display: !!subTitle,
                    text: subTitle,
                },
                legend: {
                    display: legends === true ? true : false,
                    onClick: (evt, legendItem, legend) => {
                        const index = legend.chart.data.labels.indexOf(legendItem.text);
                        legend.chart.toggleDataVisibility(index);
                        legend.chart.update();
                    },
                    labels: {
                        generateLabels : (chart) => {
                            let visibility = [];
                            for(let i = 0; i < chart.data.labels.length; i++) {
                                if(chart.getDataVisibility(i)=== true) {
                                    visibility.push(false)
                                } else {
                                    visibility.push(true)
                                }
                            }
                            return chart.data.labels.map((label, index) => ({
                                text: label,
                                strokeStyle: chart.data.datasets[0].borderColor[index],
                                fillStyle: chart.data.datasets[0].backgroundColor[index],
                                hidden: visibility[index]
                            }))
                        }
                    }
                },
                title: {
                    position: "top",
                    display: !!title,
                    text: title,
                    offset:10
                },
            },
        });
        setForceUpdateFunction();
    }, [data]);

    useImperativeHandle(Ref, () => ({
        setForceUpdateFunction
    }));

    const setForceUpdateFunction = () => {
        setForceUpdate(!forceUpdate);
    };


    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Bar height="100%" options={chartOptions} data={chartData} />
        </div>
    );
}
);
export default BarChart;