import React, { useState, useEffect, useRef } from "react";
import pageyUser from "../../assets/pageyUser.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import listCheck from "../../assets/list__check.svg";
import money from "../../assets/money.svg";
import cloud from "../../assets/cloud.svg";
import linkedin from "../../assets/linkedin.png";
import gong from "../../assets/gong.png"
import ai from "../../assets/ai.svg";
import aiWhite from "../../assets/aiWhite.svg";
import tablaeu from "../../assets/tablaeu.png";
import { apiRoutes, apiRequest } from "../../services";
import pagey from "../../assets/pagey.png"
import "./CustomerSnapshots.css";

export default function CustomerSnapshots() { 
	const [dataSnapshot, setDataSnapshot] = useState([]);
	const [selectedOption, setSelectedOption] = useState('current_team');
	const [selectedOptionPricing, setSelectedOptionPricing] = useState('current_plan');
	const [loadingSnapshot, setLoadingSnapshot] = useState(false);
	const [filterSubdomain, setFilterSubdomain] = useState({});
	const [isOpenDropdownSubdomain, setIsOpenDropdownSubdomain] = useState(false);
	const [searchTermSubdomain, setSearchTermSubdomain] = useState('');
	const [subdomain, setSubdomain] = useState([]);
	const dropdownRefSubdomain = useRef(null);

	

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleOptionChangePricing = (option) => {
    setSelectedOptionPricing(option);
  };


	const timelineArr = [
		{
			'title': 'Gong',
			'subTitle': 'Recent call',
			'day': '3 days ago',
			'Sentiment': 'Negative',
			'description': 'xxxxxxxxxxxxxxxxxxx',
		},
		{
			'title': 'Salesforce',
			'subTitle': 'AE left a note',
			'day': '3 days ago',
			'Sentiment': 'Neutral',
			'description': 'xxxxxxxxxxxxxxxxxxx',

		},
		{
			'title': 'Gong',
			'subTitle': 'Recent call',
			'day': '3 days ago',
			'Sentiment': 'Positive',
			'description': 'xxxxxxxxxxxxxxxxxxx',

		}
	]

	useEffect(() => {
		getCustomer()

		const closeDropdown = (event) => {
      if (dropdownRefSubdomain.current && !dropdownRefSubdomain.current.contains(event.target)) {
        setIsOpenDropdownSubdomain(false);
      }
    };

    document.addEventListener('click', closeDropdown);

    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  const handleDropdownClickSubdomain = () => {
    setIsOpenDropdownSubdomain(!isOpenDropdownSubdomain);
  };


  const getSnapshot = async (option) => {
		setLoadingSnapshot(true)
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.snapshot}/${option}`,
    });


		if (data?.status_code === 200) {
			setLoadingSnapshot(false)
			setDataSnapshot(data.subdomains.snapshot.sections[0])
		} else {
			setLoadingSnapshot(false)
		}
  };

	const getCustomer = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.snapshotcustomers,
    });


		if (data?.status_code === 200) {
			setSubdomain(data?.customers)
		} else {
		}
  };

	const returnCssSentiment = (sentiment) => {
    const sentimentItem = {
      'Negative': 'sentiment__negative',
      'Positive': 'sentiment__positive',
			'Neutral': 'sentiment__neutral'
    };

    return sentimentItem[sentiment] || '';
  }

	const returnOMMScore = (oms) => {
    const OMMScore = {
      'Reactive': 'reactive',
      'Manual': 'manual',
			'Responsive': 'responsive',
			'Proactive': 'proactive',
			'Preventative': 'preventative'

    };

    return OMMScore[oms] || '';
  }

	const customerTypeClassMap = (type) => {
    const customerTypeClass = {
      SILVER: 'silver',
      GOLD: 'gold',
    };
    return customerTypeClass[type] || 'silver';
  }

	 const formatNumberWithCommas = (number=1) => {
			const parts = number.toString().split('.');
			const integerPart = parts[0];
			const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

      return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + decimalPart;
  	}
	
		const handleSearchChangeSubdomains = (e) => {
			setSearchTermSubdomain(e.target.value);
		};
	

		const toggleCheckboxSubdomain = (option) => {
			setDataSnapshot([])
			setFilterSubdomain(option)
			getSnapshot(option)
			setIsOpenDropdownSubdomain(false);
		}

		const returnBackground = (type) => {
			const background = {
				churn: '#f2d2d2de',
				downgrade: '#DD6B20',
				maintain:'#e8c458',
				upgrade: '#2C7D54',
				migrated: '#e1dfd9'
			};
			return background[type] || 'silver';
		}
	

		const returnShowIcon = (type) => {
			return ['churn','downgrade'].includes(type);
		}
	

		const filteredOptionsSubdomains = subdomain?.filter(option =>
			option?.toLowerCase().includes(searchTermSubdomain.toLowerCase())
		);

	
    return (
        <>
					<div className="customerSnapshots__container">
					<div className="container__selectSubdomain">
							<div className="title__headerSection">
								Select Subdomain:
							</div>
							<div className="container__multiSelectSubdomain">
								<div className="multiSelect__subdomainHome" ref={dropdownRefSubdomain}>
									<button className="dropdown-toggleButtonSubdomain" onClick={() => handleDropdownClickSubdomain()} >
										{Object.keys(filterSubdomain).length === 0 ? 'Filter by subdomain' : filterSubdomain}
									</button>
									{isOpenDropdownSubdomain && (
										<div className="dropdown__subdomainHomeSnapshots ">
											<input
												className="search__subdomainHomeFilter"
												type="text"
												placeholder="Search client..."
												value={searchTermSubdomain}
												onChange={handleSearchChangeSubdomains}
											/>
											<ul>
												{filteredOptionsSubdomains?.sort((a, b) => a - b).map(option => (
													<li key={option.project_id}>
														<label>
															<input
																type="radio"
																checked={filterSubdomain === option}
																onChange={() => toggleCheckboxSubdomain(option)}
															/>
															<span className="customer_name">{option}</span>
														</label>
													</li>
												))}
											</ul>
										</div>
									)}
								</div>
							</div>
          </div>
					{loadingSnapshot &&
						 <div className="spinner">
								<span></span>
								<span></span>
								<span></span>
					 		</div>
						}
					{dataSnapshot &&  Object.keys(dataSnapshot).length > 0 && 
						 <div>
								<div className="container__sectionCustomerSnapshots">
								<div className="container__profileCustomerSnapshots">
									<div className='profile__CustomerSnapshots container__startEndSilver'>
										<div className="container__infoCustomerSnapshots">
											<div>
												<div className="container__imgPageyUser">
													<img
														className="imgPagerUser__customerSnapshots"
														src={pageyUser}
													/>
												</div>
												<div className={customerTypeClassMap('SILVER')}>SILVER</div>
											</div>
											<div className="header__infoProfileCustomerSnapshots">
												<div className="container__titleHeaderCustomerSnapshots" >
														<div className="title__headerCustomerSnapshots">{dataSnapshot?.account_name}</div>
														<div className="link__headerCustomerSnapshots"><a href= "#">{dataSnapshot?.subdomain}.pagerduty.com</a></div>
												</div>
												<div className="subtitle__headerCustomerSnapshots">
													<strong className="strong__text">ARR:</strong>${formatNumberWithCommas(dataSnapshot?.arr)} ARR
													<div className="type__headerCustomerSnapshots">👑 xxxxxxxx</div>
												</div>
												<div className="subtitle__headerCustomerSnapshots"><strong>Renewal:</strong><span className="renewal">{dataSnapshot?.renewal}</span>({dataSnapshot?.days_to_renewal})</div>
												<div className="container__tooltipInfoCustomerSnapshot">
													<div className="tooltip-container">
														{/* <span className="tooltip__home">Company Name</span> */}
														<div>
															<img src={cloud} className="icon__infoHeader"  />
															<span className="text">{'View in Salesforce'}</span>
														</div>
													</div>
													<div className="tooltip-container">
														{/* <span className="tooltip__home">Subdomain</span> */}
														<div>
															<img src={listCheck} className="icon__infoHeader" />
															<span className="text">{'View success plan'}</span>
														</div>
													</div>
													<div className="tooltip-container" >
														{/* <span className="tooltip__home">Account Manager</span> */}
														<div>
															<img src={money} className="icon__infoHeader"   />
															<span className="text">{'View renewal opportunity'}</span>
														</div>
													</div>
													<div className="tooltip-container" >
														{/* <span className="tooltip__home">Customer Success Manager</span> */}
														<div>
															<img src={linkedin} className="icon__infoHeader"   />
															<span className="text">{'View company profile'}</span>
														</div>
													</div>
											</div>
											</div>
										</div>
									</div>
								</div>
								</div>
								<div className="container__accountHigh" style={{background: returnBackground(dataSnapshot?.ews_multi_class_tag)}}>
										{returnShowIcon(dataSnapshot?.ews_multi_class_tag) && <div className="icon__TriangleExclamation"><FontAwesomeIcon icon={faTriangleExclamation} /></div>}
										<div className="executiveSummary">
											<div className="title__status">{dataSnapshot?.executive_summary_account_score_health.substring(2)}</div>
											<ul className="list__accountHig">
												<li>{dataSnapshot?.executive_summary_primary_impact_driver}</li>
												<li>{dataSnapshot?.executive_summary_primary_opportunity_status?.substring(2)}</li>
											</ul>
										</div>
								</div>
								<div className="card__infoAccountData"> 
										<div>
											<div className="title__headerSection">
												Account Data
											</div>
											<div className="container__cardAccountData">
												<div className="cardAccountData" >
													<div className="title__cardAccountData">Days until next renewal </div>
													<div>
															<div className="subtitle__cardAccountData">{dataSnapshot?.days_to_renewal}</div>
															<div className="text__subtitleCardAccountData">{dataSnapshot?.renewal}</div>
													</div>
												</div>
												<div className="cardAccountData" >
													<div className="title__cardAccountData">ARR and yearly trend</div>
													<div className="subtitle__cardAccountData">${formatNumberWithCommas(dataSnapshot?.arr)}</div>
												</div>
												<div className="cardAccountData" >
													<div className="title__cardAccountData">EWS Score</div>
													<div>
														<div className="subtitle__cardAccountData text__red"><FontAwesomeIcon className="icon__cardTriangleExclamation" icon={faTriangleExclamation} />{dataSnapshot?.ews_score}</div>
														<div className="text__subtitleCardAccountData">+42 since last period</div>
													</div>
												</div>
												<div className="cardAccountData">
													<div className="title__cardAccountData">OMM Score</div>
													<div className="container__oms">
														<div className="omm__subtitle">{dataSnapshot?.omm_score}</div>
														<div className="text__subtitleCardAccountData">+1% since last period</div>
														<div className="text__subtitleCardAccountData">+0% since last  BIC</div>
													</div>
												</div>
												<div className="cardAccountData">
													<div className="title__cardAccountData">Red Account EscalationsARR at risk</div>
													<div className="subtitle__cardAccountData">$ -</div>
												</div>
												<div className="cardAccountData">
													<div className="title__cardAccountData">90 day active users</div>
													<div className="subtitle__cardAccountData">{Math.floor((dataSnapshot['30d_active_users']/1)*100)}%</div>
												</div>
												<div className="cardAccountData">
													<div className="title__cardAccountData">Deployment %</div>
													<div className="subtitle__cardAccountData">{Math.floor((dataSnapshot?.pct_users_deployed/1)*100)}%</div>
												</div>
											</div>
										</div>
								</div>
								<div className="section__secondAll">	
										<div  className="section__first">
											<div className="conatiner__businessAiOps">
												<div className="businessAiOps__firts">
													<div className="title__headerSection">
															Account Personnel
														</div>
														<div className="container__infoBusinessAiOps">
															<div className="segmented-control">
																<button
																	className={`segmented-control-button ${selectedOption === 'current_team' && 'selected'}`}
																	onClick={() => handleOptionChange('current_team')}
																>
																	Current Team
																</button>
																<button
																	className={`segmented-control-button ${selectedOption === 'option2' && 'selected'}`}
																	onClick={() => handleOptionChange('option2')}
																>
																	Past team
																</button>
																<button
																	className={`segmented-control-button ${selectedOption === 'option3' && 'selected'}`}
																	onClick={() => handleOptionChange('option3')}
																>
																	Customer Contacts
																</button>
															</div>
														</div>
														{selectedOption === 'current_team' && 
															<div className="container__itemSelectedButton">
																<div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoAccountPersonnel__subInfo">CSM</div>
																			<div>{dataSnapshot?.csm_name}</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoAccountPersonnel__subInfo">CMS manager</div>
																			<div>{dataSnapshot?.csm_team_manager_name}</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoAccountPersonnel__subInfo">Sales rep</div>
																			<div>{dataSnapshot?.sales_rep_name}</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoAccountPersonnel__subInfo">Sales rep team</div>
																			<div>{dataSnapshot?.sales_rep_team}</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoAccountPersonnel__subInfo">Region</div>
																			<div>{dataSnapshot?.region}</div>
																	</div>
																</div>
															</div>}
												</div>
												<div className="businessAiOps__second">
													<div className="title__headerSection">
														Pricing and Opportunities
													</div>
													<div className="container__infoBusinessAiOps">
															<div className="segmented-control">
																<button
																	className={`segmented-control-button ${selectedOptionPricing === 'current_plan' && 'selected'}`}
																	onClick={() => handleOptionChangePricing('current_plan')}
																>
																	Current Plan
																</button>
																<button
																	className={`segmented-control-button ${selectedOptionPricing === 'option2' && 'selected'}`}
																	onClick={() => handleOptionChangePricing('option2')}
																>
																	Opportunities
																</button>
															</div>
															{selectedOptionPricing === 'current_plan' && 
															<div className="container__itemSelectedButton">
																<div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoAccountPersonnel__subInfo">Plan Type</div>
																			<div>{dataSnapshot?.business_plan_type}</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoAccountPersonnel__subInfo">Seats</div>
																			<div>{dataSnapshot?.business_seats}</div>
																	</div>
																	{/* <div className="description__infoBusinessAiOps">
																			<div className="infoAccountPersonnel__subInfo">Stake. Seats</div>
																			<div>xxxxx</div>
																	</div> */}
																	<div className="description__infoBusinessAiOps">
																			<div className="infoAccountPersonnel__subInfo pricing">Pricing</div>
																			{/* <div>Caruthers B Sweeping</div> */}
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoAccountPersonnel__subInfo">Business</div>
																			<div>
																				<div>${formatNumberWithCommas(dataSnapshot?.business_arr)} (${dataSnapshot?.business_arr_per_seat} / seat)</div>
																				<div>discount ${formatNumberWithCommas(dataSnapshot?.business_discount)}</div>
																			</div>
																	</div>
																	{/* <div className="description__infoBusinessAiOps automation">
																			<div className="infoAccountPersonnel__subInfo">Automation</div>
																			<div>
																				<div>xxx</div>
																				<div>xxx</div>
																			</div>
																	</div> */}
																</div>
															</div>}
													</div>
												</div>
											</div>
											<div className="container__productAdoption">
												<div className="title__headerSection">
													Product Adoption
														<div className="gen__ai">
															<img src={ai} className="icon__infoHeader "  />
															Gen AI
														</div>
												</div>
												<div className="info__productAdoption">
														<div className="section__productAdoption">
																<div><span className="title__productAdoption">OMM Score:</span> <span className={returnOMMScore('Reactive')}>{dataSnapshot?.omm_score}</span></div>
																<div>{dataSnapshot?.product_adoption_omm_score_digest}</div>
														</div>
														<div className="section__productAdoption">
																<div className="title__productAdoption">Top recommendations to drive deeper engagement within their existing plan</div>
																<ul className="list__accountHig">
																	{dataSnapshot?.product_adoption_top_3_recommendations?.map(top => (
																				<li>{top}</li>
																	))}
																</ul>
														</div>
														<div>
																<div className="title__productAdoption">Competitive risks</div>
																<ul className="list__accountHig">
																	{dataSnapshot?.competitive_risk?.map(competitive => (
																					<li>{competitive}</li>
																		))}
																</ul>
														</div>
												</div>
											</div>
											<div className="container__dataNumber">
												<div className="container__cardAccountData">
													<div className="cardAccountData dataNumber" >
														<div className="title__cardAccountData">Events(last 3 months) </div>
														<div>
															<div className="subtitle__cardAccountData">{formatNumberWithCommas(dataSnapshot?.average_monthly_events)}</div>
															<div className="text__subtitleCardAccountData">+20% since last period</div>
															<div className="text__subtitleCardAccountData">+20% since last  BIC</div>
														</div>
													</div>
													<div className="cardAccountData dataNumber" >
														<div className="title__cardAccountData">High Urgency Incidents(last 3 months) </div>
														<div>
															<div className="subtitle__cardAccountData">{formatNumberWithCommas(dataSnapshot?.high_urgency_incidents)}</div>
															<div className="text__subtitleCardAccountData">+50% since last period</div>
															<div className="text__subtitleCardAccountData">+0% since last  BIC</div>
														</div>
													</div>
													<div className="cardAccountData dataNumber" >
														<div className="title__cardAccountData">High Urgency MTTA (last 3 months) </div>
														<div>
															<div className="subtitle__cardAccountData">{parseFloat(dataSnapshot?.high_urgency_inc_mtta_minutes).toFixed(2).toString()}</div>
															<div className="text__subtitleCardAccountData">+50% since last period</div>
															<div className="text__subtitleCardAccountData">+0% since last  BIC</div>
														</div>
													</div>
													<div className="cardAccountData dataNumber" >
														<div className="title__cardAccountData">High Urgency MTTR(last 3 months) </div>
														<div>
															<div className="subtitle__cardAccountData">{parseFloat(dataSnapshot?.high_urgency_inc_mttr_minutes).toFixed(2).toString()}</div>
															<div className="text__subtitleCardAccountData">+50% since last period</div>
															<div className="text__subtitleCardAccountData">+0% since last  BIC</div>
														</div>
													</div>
													<div className="cardAccountData dataNumber" >
														<div className="title__cardAccountData">High Urgency Resp. Effort(last 3 months) </div>
														<div>
															<div className="subtitle__cardAccountData">{formatNumberWithCommas(parseFloat(dataSnapshot?.response_cost_high_urgency_inc).toFixed(2).toString())}</div>
															<div className="text__subtitleCardAccountData">+50% since last period</div>
															<div className="text__subtitleCardAccountData">+0% since last  BIC</div>
														</div>
													</div>
												</div>
											</div>
											<div className="conatiner__businessAiOps">
													<div className="businessAiOps__firts">
														<div className="header__businessAiOps">
																<div>
																		<div className="title__headerSection title__businessAiOps">Key Metrics Summary for plan:</div>
																		<div className="title__headerSection subTitle__businessAiOps">Business (last 3 months)</div>
																</div>
																<div className="container__tablaeu"><img className="icon__infoHeader " src={tablaeu} /><span className="text__tablaeu">View in Tablaeu</span></div>
														</div>
														<div className="container__infoBusinessAiOps">
															<div>
																<div className="description__infoBusinessAiOps">
																		<div className="infoBusinessAiOps__subInfo">Bi-directional integrations </div>
																		<div>{dataSnapshot.bidirectional_integrations}</div>
																</div>
															</div>
															<div>
																<div className="description__infoBusinessAiOps">
																		<div className="infoBusinessAiOps__subInfo">Collab integrations</div>
																		<div>{dataSnapshot.monitoring_integrations}</div>
																</div>
															</div>
															<div>
																<div className="description__infoBusinessAiOps">
																		<div className="infoBusinessAiOps__subInfo">Monitoring integrations</div>
																		<div>{dataSnapshot.collab_integrations}</div>
																</div>
															</div>
															<div>
																<div className="title__infoBusinessAiOps">Top 3 event types</div>
																<div className="description__infoBusinessAiOps">Datadog, Splunk, Sentry</div>
															</div>
															<div>
																<div className="title__infoBusinessAiOps">Top 3 bi-directional integrations</div>
																<div className="description__infoBusinessAiOps">ServiceNow, JIRA</div>
															</div>
															<div>
																	<div className="title__infoBusinessAiOps">Adoption metrics</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">EPs with 2+ lvls</div>
																			<div>1785 (70%)</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">% Users on an EP</div>
																			<div>20 (62%)</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">Users with mobile app</div>
																			<div>1301 (51%)</div>
																	</div>
																</div>
																<div>
																	<div className="title__infoBusinessAiOps">Incidents using...</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">Workflows</div>
																			<div>2130 (3%)</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">Custom Fields</div>
																			<div>852 (1%)</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">Status Updates</div>
																			<div>5680 (8%)</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">Tech. Service Dependencies</div>
																			<div>9940 (14%)</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">Biz. Service Dependencies</div>
																			<div>4260 (6%)</div>
																	</div>
																</div>
																<div>
																	<div className="title__infoBusinessAiOps">Service dependencies</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">Tech. Service Dependencies</div>
																			<div>676 (53%)</div>
																	</div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">Biz. Service Dependencies</div>
																			<div>78 (6%)</div>
																	</div>
																</div>
														</div>
													</div>
													<div className="businessAiOps__second">
														<div className="header__businessAiOps">
															<div>
																	<div className="title__headerSection title__businessAiOps">Key Metrics Summary for plan:</div>
																	<div className="title__headerSection subTitle__businessAiOps">AIOps (last 3 months)</div>
															</div>
															<div className="container__tablaeu"><img className="icon__infoHeader " src={tablaeu} /><span className="text__tablaeu">View in Tablaeu</span></div>
														</div>
														<div className="container__infoBusinessAiOps">
																<div>
																	<div className="description__infoBusinessAiOps">
																			<div className="infoBusinessAiOps__subInfo">Avg. Daily Events</div>
																			<div>32</div>
																	</div>
																</div>
																<div>
																		<div className="title__infoBusinessAiOps">Noise reduction</div>
																		<div className="description__infoBusinessAiOps">
																				<div className="infoBusinessAiOps__subInfo">Events averted w/ noise red.</div>
																				<div>29 (97%)</div>
																		</div>
																		<div className="description__infoBusinessAiOps">
																				<div className="infoBusinessAiOps__subInfo">Services w/ noise red. enabled</div>
																				<div>20 (62%)</div>
																		</div>
																		<div className="description__infoBusinessAiOps">
																				<div className="infoBusinessAiOps__subInfo">Transient Alerts</div>
																				<div>1 (1%)</div>
																		</div>
																</div>
																<div>
																		<div className="title__infoBusinessAiOps">Event Orchestration</div>
																		<div className="description__infoBusinessAiOps">
																				<div className="infoBusinessAiOps__subInfo">Events processed by EO</div>
																				<div>34 (18%)</div>
																		</div>
																</div>
																<div>
																		<div className="title__infoBusinessAiOps">Top 3 event types</div>
																		<div className="description__infoBusinessAiOps">add notes, automation actions, change priority,</div>
																</div>
														</div>
													</div>
											</div>
											<div className="container__productAdoption">
												<div className="title__headerSection">
													Success Plan Summary
													<div className="gen__ai">
															<img src={ai} className="icon__infoHeader "  />
															Gen AI
													</div>
												</div>
												<div className="info__productAdoption">
														<div className="section__productAdoption">
															{dataSnapshot?.success_plan_summary?.map(item => (
																	<div className="text__successPlanSummary">{item}</div>
															))}
																{/* <div className="title__productAdoption">Proposed Playbook</div>
																<div>Proposed playbook (generated from AI) - some form of combo of data-driven and playbook content</div>
																<div>Playbooks in seismic</div>
																<div>Why are we proposing this? Why is this helpful?</div> */}
														</div>
													{/*<div className="section__productAdoption">
																<div className="title__productAdoption">Onestops</div>
																<ul className="list__accountHig">
																	<li>Resource 1</li>
																	<li>Resource 2</li>
																	<li>Resource 3</li>
																</ul>
														</div>*/}
												</div>
											</div>
											<div className="container__productAdoption">
												<div className="title__headerSection">
													Ask anything about this account
													<div className="gen__ai">
															<img src={ai} className="icon__infoHeader "  />
															Gen AI
													</div>
												</div>
												<div className="info__productAdoption">
														<div className="containerInput__ia">
																<input className="input__ia" placeholder="Ask AI anything about this account" />
																<div className="button__ia "> <img src={aiWhite} className="icon__infoHeader "  />Ask</div>
														</div>
												</div>
											</div>
										</div>
										<div className="section__second">
											<div className="title__headerSection">
													Timeline
											</div>
											{timelineArr?.map(item => (
												<div className="card__timeline">
														<div className="card__timelineHeader"> 
																<img src={gong} />
																<div className="timelineHeader">
																		<div>{item.title}: </div>
																		<div className="timeline__subtitle">{item.subTitle}</div>
																</div>
														</div>
														<div className="timeline__day">{item.day}</div>
														<div className="timeline__sentiment">
																<div className="timeline__sentimentTitle">
																<img src={ai} className="icon__infoHeader"  />
																	Sentiment:</div>
																<div className={`${returnCssSentiment(item.Sentiment)}`}>{item.Sentiment}</div>
														</div>
														<div className="timeline__description">{item.description}</div>
														<div className="footer__timeline">
															<img src={gong} />
															<div className="timeline__view">View in {item.title}</div>
														</div>
												</div>
											))}
										</div>
								</div>
						 </div>
					}
					{dataSnapshot.length === 0 && !loadingSnapshot &&
						<div className="container__pageyCustomer">
							<img className="container__imgNoAlerts" src={pagey}></img>
						</div>}
					</div>
        </>
    )

}
