import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";
import Jwt from "./../../utils/jwt";
import {
  LeftDiv,
  MenuRegularIcon,
  NavbarContainer,
  SearchDiv,
  SearchDivIcon,
  SearchInput,
  RowSearchResults,
  SearchDivContainer,
  OptionSearch,
  SearchTitleRow
} from "./NavbarStyled.js";
import {
  faSearch,
  faBars,
  faCartShopping,
  faTrash,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css";
import { controlTour, openMenuSubject, reconnectModal, resetTourSteps, stepsState } from "../../services/subjects.js";
import { apiRequest, apiRoutes } from "../../services/index";
import { useNavigate } from "react-router-dom";
import { cartSolution} from "../../services/subjects.js";
import { showCartAlert} from "../../services/subjects.js";
import healthCheck from "../../assets/wallpaper1.png";
import pageyUser from "../../assets/pageyUser.png";
import Start from "../../assets/Start.svg";
import { useAuth } from "../../UserContext.js";

interface Props {
  menuItems: any;
}

const Navbar = forwardRef(({ menuItems }: Props, Ref) => {
  const { cartAlert } = useAuth()
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<any>(null);
  const [userOptions, setUserOptions] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [feedbackRating, setFeedbackRating] = useState<null | number>(null);
  const [feedbackDescription, setFeedbackDescription] = useState("");
  const [thankYouState, setThankYouState] = useState(false);
  const [detailCart, setDetailCart] = useState([]);
  const [showDetailCart, setShowDetailCart] = useState(false);
  const [requestSentSuccess, setRequestSentSuccess] = useState(false);
  const [loadindRequestSent, setLoadindRequestSent] = useState(false);
  const pathname = window.location.pathname;
  const [userRole, setUserRole] = useState("");
  useImperativeHandle(Ref, () => ({}));
  const detailCartRef = useRef(null);

  const [steps, setSteps] = useState([]);

useEffect(() => {
  // Suscribirse al BehaviorSubject para obtener el estado actual de los pasos
  const subscription = stepsState.subscribe((steps: any) => {
    setSteps(steps);
  });

  // Limpiar la suscripción cuando el componente se desmonte
  return () => subscription.unsubscribe();
}, []);
  const getMenu = (tokenData) => {
    let menu = tokenData.claims.roles.map((role) => {
      setUserRole(role.role);
        return role?.apps?.map((app) => {
            return {appName: app.app};
        });
    });
    setUserOptions(menu[0]);
};

  useEffect(() => {
    const tokenData = Jwt.getItem("all");
    if (tokenData !== null) {
        getMenu(tokenData);
    }
}, []);

  useEffect(() => {
    cartSolution.subscribe((state: any) => {
      setDetailCart(state)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleClickOutside = (event) => {
    if (!event.target.closest('.container__cart') && !event.target.classList.contains('icon__cart') && detailCartRef.current && !detailCartRef.current.contains(event.target)) {
      setShowDetailCart(false);
    }
  };


  const extractLabels = (menuItems: { sectionLabel: any; items: any[] }[]) => {
    const labels: any[] = [];

    menuItems.forEach((menuItem: { sectionLabel: any; items: any[] }) => {
      labels.push(menuItem.sectionLabel);

      menuItem.items.forEach((item) => {
        labels.push({appName: item.appName, to: item.to, label: item.label});
      });
    });
    return labels;
  };

  const submitFeedbackButton = async () => {
    setThankYouState(true);
    await apiRequest({
      method: "post",
      url: apiRoutes.userFeedback,
      data: {
        comments: feedbackDescription,
        rating: feedbackRating,
        page_reference: feedbackNameLiteral.hasOwnProperty(pathname)
          ? feedbackNameLiteral[pathname]()
          : "Dashboard",
        pathname,
      },
    });
    setTimeout(() => {
      setThankYouState(false);
      setModalOpen(false);
      setFeedbackDescription("");
      setFeedbackRating(null);
    }, 4000);
  };

  const closeFeedbackButton = async () => {
    setModalOpen(false);
    setFeedbackDescription("");
    setFeedbackRating(null);
  };

  const feedbackNameLiteral = {
    "/": () => "Portal",
    "/home": () => "Portal",
    "/success-on-demand": () => "Success on Demand App",
    "/onboarding": () => "Onboarding App",
    "/users": () => "Administration App",
    "/health-check": () => "Health Check App",
    "/health-check-client": () => "Health Check App",
    "/health-check-data-viewer": () => "Health Check Data Viewer",
    "/migration-wizard": () => "Migration Visualization App",
    "/ps-status": () => "PS Status App",
    "/pd-university": () => "PDU Status App",
    "/deck-generator": () => "Deck Generator",
  };

  useEffect(() => {
    openMenuSubject.next(menuOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  const onShowDetailCart = () => {
    setShowDetailCart(!showDetailCart)
  }

  const deleteCart = () => {
    setDetailCart([])
    localStorage.setItem("cart", JSON.stringify([]));
    cartSolution.next([]);
  }

  const deleteProduct  = (product) => {
    const filterProduct = detailCart?.filter((item) => item?.solution_id !== product)
    setDetailCart(filterProduct)
    cartSolution.next(filterProduct);
    localStorage.setItem("cart", JSON.stringify(filterProduct));
  }

  const solutionsCar = async () => {
    setLoadindRequestSent(true)
    const products = detailCart?.map((item:any) => {
      return {...item, quantity: 1 }})

    const [error, data] = userRole === "Free Trial Viewer" ? await apiRequest({
      method: "post",
      url: apiRoutes.trialContact,
      data: {
        "request_details":products,
        section: "Solution Gallery"
      }
    }) : await apiRequest({
      method: "post",
      url: apiRoutes.solutionCar,
      data: {
        "solutions":products
      }
    })
    if (data?.status_code === 200) {
      deleteCart()
      setShowDetailCart(false)
      setRequestSentSuccess(true)
      setTimeout(() => {
        setRequestSentSuccess(false)
      }, 1000);
      setLoadindRequestSent(false)
    }
  }

  const totalCost = detailCart.reduce((accumulator, currentValue) => {
    if(currentValue?.classification === "SRA" ) {
      const hour = parseInt(currentValue?.solution_hour_cost)
      return accumulator + hour
    } else {
      return accumulator
    }
  }, 0)

  const sendAndOpenReconnect = () => {
    if(window.location.pathname !== '/') {
      navigate('')
      setTimeout(() => {
        reconnectModal.next(true);
      }, 1500);
    } else reconnectModal.next(true);
  }
  
  const StartTour = () => {
    const currentSteps = stepsState.getValue();
    const updatedSteps = { ...currentSteps };
  
    resetTourSteps.next(1);
  
    if (window.location.pathname !== '/') {
      updatedSteps.step1.isLoaded = true; // Actualiza el paso específico
      stepsState.next(updatedSteps);
      navigate('');
    } else {
      controlTour.next(true);
    }
  };

  return (
    <NavbarContainer>
      <LeftDiv>
        <MenuRegularIcon
          className={"center-corner"}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <FontAwesomeIcon icon={faBars} />
        </MenuRegularIcon>
      </LeftDiv>
      <SearchDivContainer>
        <SearchDiv>
          <SearchInput
            placeholder="Search by application"
            value={searchInput}
            onChange={(e: any) => setSearchInput(e.target.value)}
          />
          <SearchDivIcon>
            <FontAwesomeIcon icon={faSearch} />
          </SearchDivIcon>
        </SearchDiv>
        {searchInput?.length > 0 && (
          <RowSearchResults>
            <SearchTitleRow>Application Routes</SearchTitleRow>
            {extractLabels(menuItems)
              .filter((element) =>
                element?.appName?.toLowerCase().includes(searchInput.toLowerCase()) && userOptions.some((option) =>  option.appName === element.appName)
              )?.map((element) => (
                <OptionSearch
                  onClick={() => {
                    navigate(element.to);
                    setSearchInput("");
                  }}
                >
                  {element.label}
                </OptionSearch>
              ))}
          </RowSearchResults>
        )}
      </SearchDivContainer>
      {userRole === "Free Trial Viewer" && <div className="premiumInnovationDemoContainerTop">
        <div className="premiumInnovationDemoContainer">
        <div
              className="round__icon__modal"
              style={{ backgroundColor: "#005A24" }}
            >
              <img src={Start} alt="" role="display" />
            </div>
        <div className="premiumInnovationDemoText">Premium Customer Portal Experience</div>
        </div>
        <button className="heartbeatAnimation highlightButton" onClick={() => sendAndOpenReconnect()}>Explore Features <span><FontAwesomeIcon className='ms-2' icon={faPlus} /></span></button>
        <button className='highlightButton' onClick={() => StartTour()}>Start Tour </button>
        </div>}
      {pathname.includes('/solution-gallery') && 
        <div className="container__cart" onClick={() => onShowDetailCart()}>
          {detailCart.length > 0 && <div className="total__cart">{detailCart.length}</div>}
          <div className="icon__cart" style={userRole === "Free Trial Viewer" ? {marginRight: '24px'} : {}}><FontAwesomeIcon  icon={faCartShopping} /> </div>
        </div>}
        {pathname === '/my-alerts-app' && 
          <div className="container__cart" onClick={() => showCartAlert.next(true)}>
            {cartAlert.length > 0 && <div className="total__cart">{cartAlert.length}</div>}
            <div className="icon__cart" style={userRole === "Free Trial Viewer" ? {marginRight: '24px'} : {}}><FontAwesomeIcon  icon={faCartShopping} /> </div>
          </div>}
      {showDetailCart && 
        <div className="detail__cart" ref={detailCartRef}>
          {loadindRequestSent && 
            <div className="spinner">
              <span></span>
              <span></span>
              <span></span>
            </div>}
            {!loadindRequestSent && 
              <div>
                <div className="clean__cart" onClick={() => deleteCart()}>
                  <span className="cleanCart__text">Clear Cart</span>
                  <FontAwesomeIcon icon={faTrash} />
                </div>
                <div className="container__cardCart">
                    {detailCart?.map(item => (
                        <div className="cardHorizontal__cart">
                          <div className="header__cardCart">
                            <img
                                className="img__cardCart"
                                src={healthCheck}
                              />
                          </div>
                          <div className="body__cardCart">
                            <div className="name__cardCart">
                              <div>{item?.solution_name}</div>
                            </div>
                            <div className="category__nameCar" >{item?.category_name}</div>
                            <div className="footer__hourCart">
                              <div className="hour__cost">{item?.solution_hour_cost}</div>
                              <div className="hour__cost" onClick={() => deleteProduct(item.solution_id)}><FontAwesomeIcon icon={faTrash} /> </div>
                            </div>
                          </div>
                        </div>
                    ))}
                </div>
                <div className="footer__detailCart">
                  {detailCart.length && totalCost > 0 && <div className="total__hours">Total Hours: {totalCost} (minimum)</div>}
                  {(detailCart.length && totalCost > 0) || <div className="btn__placeholder"></div>}
                  {detailCart.length && <button onClick={() =>solutionsCar()} className="btn__submitCart">Submit</button>}
                </div>
                {!detailCart.length  && 
                  <div className="cart__isEmpty">
                    <div className="container__imgUserCart">
                      <img
                        className="img__pageyUserCart"
                        src={pageyUser}
                      />
                    </div>
                    <div className="not__cart">Your shopping cart is empty</div>
                  </div>}
              </div>}
        </div>}
        {requestSentSuccess &&
          <div className="detail__cartSucces" >
            <div className="cart__isEmptySend">
                <div className="container__imgUserCart">
                  <img
                    className="img__pageyUserCart"
                    src={pageyUser}
                  />
                </div>
                <div>Your request has been sent, we will contact you</div>
            </div>
          </div>}
      {/* <RightDiv>
        <FeedbackIcon
          onClick={() => setModalOpen(true)}
          className={"center-corner"}
        >
          <Like scale={0.025} />
        </FeedbackIcon>
        <FontAwesomeIcon style={{marginRight: '9px'}} icon={faMicrophone} />
      </RightDiv> */}
    </NavbarContainer>
  );
});

export default Navbar;
