const designVariables = {
  COLOR_BASE_PRIMARY: "#005a24",
  COLOR_BASE_SECONDARY: "#0D174D",
  COLOR_BASE_NEUTRAL: "#1A2035",
  COLOR_BASE_GREEN: "#18A854",
  COLOR_BASE_YELLOW: "#F0C135",
  COLOR_BASE_RED: "#e23e32",
  COLOR_BRAND_PRIMARY_BASE: "#005a24",
  COLOR_BRAND_PRIMARY_LIGHT: "rgba(#005a24, 0.1)",
  COLOR_BRAND_SECONDARY_BASE: "#0D174D",
  COLOR_BRAND_SECONDARY_LIGHT: "rgba(#0D174D, 0.1)",
  COLOR_SUCCESS_BASE: "#18A854",
  COLOR_WARNING_BASE: "#F0C135",
  COLOR_DANGER_BASE: "#e23e32",
  COLOR_FONT_BASE: "#1A2035",
  COLOR_FONT_SECONDARY: "#474747",
  COLOR_FONT_TERTIARY: "#9096A3",
  COLOR_NEUTRAL: "#1A2035",
  COLOR_NEUTRAL_90: "#474747",
  COLOR_NEUTRAL_80: "#606875",
  COLOR_NEUTRAL_70: "#9096A3",
  COLOR_NEUTRAL_60: "#E8E8E8",
  COLOR_NEUTRAL_50: "#F2F2F2",
  SIZE_BASE_SIZE: "14px",
  SIZE_FONT_DISPLAY_LARGE: "32px",
  SIZE_FONT_DISPLAY_SMALL: "20px",
  SIZE_FONT_HEADING: "16px",
  SIZE_FONT_BODY: "14px",
  SIZE_FONT_CAPTION: "12px",
  SIZE_FONT_WEIGHT_BOLD: "700",
  SIZE_FONT_WEIGHT_MEDIUM: "500",
  SIZE_FONT_WEIGHT_REGULAR: "400",
  SIZE_FONT_WEIGHT_DISPLAY_LARGE: "700",
  SIZE_FONT_WEIGHT_DISPLAY_SMALL: "500",
  SIZE_FONT_WEIGHT_HEADING: "500",
  SIZE_FONT_WEIGHT_BODY: "400",
  SIZE_FONT_WEIGHT_CAPTION: "500",
  SIZE_RADIUS_SMALL: "2px",
  SIZE_RADIUS_REGULAR: "4px",
  SIZE_RADIUS_LARGE: "6px",
  SIZE_RADIUS_ROUNDED: "290486px",
  SIZE_SPACING_EXTRA_TIGHT: "4px",
  SIZE_SPACING_TIGHT: "8px",
  SIZE_SPACING_BASE_TIGHT: "12px",
  SIZE_SPACING_REGULAR: "16px",
  SIZE_SPACING_BASE_LOOSE: "20px",
  SIZE_SPACING_LOOSE: "32px",
  SIZE_SPACING_EXTRA_LOOSE: "40px"
}

export default designVariables;