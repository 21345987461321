import { forwardRef, useImperativeHandle, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMedicalAlt, faChessQueen, faBook, faExchangeAlt, faShip, faChartLine, faSwatchbook,faFilePowerpoint, faCompass, faImage } from '@fortawesome/free-solid-svg-icons';
import './AppIcon.css';
import logo from "../../assets/P-WhiteRGB.svg";

const AppIcon = forwardRef(({ type, diameter }, Ref) => {

    useImperativeHandle(Ref, () => ({}));

    const primaryColorLiteral = {
        'Onboarding': () => 'rgb(0, 96, 127)',
        'HealthCheck': () => '#08de49',
        'Portal': () => '#117170',
        'SuccessOnDemand': () => '#f82431',
        'Administration': () => '#4b4f4f',
        'Migration': () => '#fa640a',
        'Status': () => '#94b2a5',
        'PDU': () => '#ffe664',
        'DeckGenerator': () => '#ffe664',
        'healthCheckDataViewer': () => '#dd3af0',
        'SolutionGallery': () => '#94b2a5'
    }

    const secondaryColorLiteral = {
        'Onboarding': () => 'rgb(1, 49, 65)',
        'HealthCheck': () => '#06ac38',
        'Portal': () => '#117170',
        'SuccessOnDemand': () => '#ac0606',
        'Administration': () => '#fff',
        'Migration': () => 'rgb(135 54 5)',
        'Status': () => '#fff',
        'PDU': () => '#fff',
        'DeckGenerator': () => '#fff',
        'healthCheckDataViewer': () => '#fff',
        'SolutionGallery': () => '#fff'
    }

    const iconLiteral = {
        'Onboarding': () => faShip,
        'HealthCheck': () => faFileMedicalAlt,
        'SuccessOnDemand': () => faChessQueen,
        'Administration': () => faBook,
        'Migration': () => faExchangeAlt,
        'Status': () => faChartLine,
        'PDU': () => faSwatchbook,
        'DeckGenerator' : () => faFilePowerpoint,
        'healthCheckDataViewer' : () => faCompass,
        'SolutionGallery' : () => faImage
    }

    return (
        <div className="Icon-container" style={{ width: diameter || '50px', height: diameter || '50px' }}>
            <div className="Icon-container-Inner">
                <div className="Icon-container-sandbox" style={{ width: diameter || '50px', height: diameter || '50px', backgroundColor: primaryColorLiteral[type](), boxShadow: `inset 0px 0px 0px 3px ${secondaryColorLiteral[type]()}` }}>
                {iconLiteral.hasOwnProperty(type) ? <FontAwesomeIcon icon={iconLiteral[type]()} className='Icon-icon' /> : <img style={{height: '23px', position: 'absolute', right: '12px'}} src={logo} />}
                </div>
            </div>
        </div>
    );
}
);

export default AppIcon;