import { useState, forwardRef, useEffect, useImperativeHandle, } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    SubTitle,
    Tooltip,
    Legend
);

const StackedBarChart = forwardRef(({ labels, data, datasets, title, subTitle, onClickElement }, Ref) => {
    
  const [forceUpdate, setForceUpdate] = useState(true);

  useEffect((
      () => {
          setForceUpdate(!forceUpdate);
      }
  ), [data]);

  const [chartData, setChartData] = useState({
      datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
      setChartData({
          labels: labels,
          datasets: datasets,
      });
      setChartOptions({
          responsive: true,
          aspectRatio: 15 / 3,
          elements: {
            bar: {
              borderWidth: 2,
            }
          },
          legend: {
            position: 'right',
          },
          title: {
            display: true,
          },
          onClick: function(evt, element) {
              if (element.length > 0) {
                  onClickElement(element);
                // you can also get dataset of your selected element
              }},
          scales: {
            r: {
              max: 5,
              min: 0,
              ticks: {
                  stepSize: 5
              }
            },
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              ticks: {
                stepSize: 5
              }
            },
          },
          maintainAspectRatio: false,
          plugins: {
              subtitle: {
                position: "left",
                display: !!subTitle,
                text: subTitle,
              },
              legend: {
                display: true
              },
              title: {
                position: "top",
                display: !!title,
                text: title,
                offset:10
              },
          },
      });
      setForceUpdateFunction();
  }, [data]);

    useImperativeHandle(Ref, () => ({
        setForceUpdateFunction
    }));

    const setForceUpdateFunction = () => {
        setForceUpdate(!forceUpdate);
    };


    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Bar height="100%" options={chartOptions} data={chartData} />
        </div>
    );
}
);

export default StackedBarChart;