import React, {useState, useEffect} from "react";
import { useNavigate} from "react-router-dom";
import {apiRoutes, apiRequest} from "../../services";
import Paths from "../../paths";
import './ValidateOauth.css'
import LoadingPagey from "../../components/LoadingPagey";
import jwt_decode from "jwt-decode";

export default function ValidateOauth() {
  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('token'));

  const createSession = async (code) => {
    const [error, data] = await apiRequest({
        method: "post",
        url: `${apiRoutes.loginOauth}`,
        data: {"code": code}
    });
    if (!error) {
      setIsLoading(false);
      localStorage.setItem("token", data.csg_pd_access_token);
      setToken(data.csg_pd_access_token);
      const tokenValue = jwt_decode(data.csg_pd_access_token, { header: false });
      const isAdmin = tokenValue.claims.roles.find((role) => {
        return  role.role === "Admin";
      })
      history(Paths.Home);
      // if(isAdmin) {
      //   history(Paths.SelectClient);
      // } else {
      //   history(Paths.Home);
      // }
    } else {
      setIsLoading(false);
      history(Paths.Unauthorized);
    }
  };

  useEffect(() => {
   
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const code = params.get("code");
    createSession(code);
}, []);

    return (
      <div>
        {!isLoading ? <>
          </>
          : <>
            <div className="validate-oauth-container">
                <div className="area-container">
                    <div className="elements-container">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    </div>
                </div>
                <div className="validate-oauth-icon_container">
                  <LoadingPagey state={0}></LoadingPagey>
                </div>
            </div>
            </>}
        </div>
    );
}
