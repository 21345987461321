
import Auth from "./routes";
import React, { useEffect, useState } from "react";
import { sessionModal } from "./services/subjects";
import AuthorizationModal from "./../src/components/AuthorizationModal/AuthorizationModal";
function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const subscription = sessionModal.subscribe({
      next: (isOpen) => {
        setIsModalOpen(isOpen); // Assuming the emitted value is a boolean
      }
    });

    // Unsubscribe on component unmount
    return () => {
      subscription.unsubscribe();
    };
  }, []);


  return (
    <div style={{ height: '100%' }}>
      {isModalOpen && <AuthorizationModal />}
     <Auth/>
    </div>
  );
}

export default App;





