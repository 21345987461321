import React, { useState, useEffect, useRef } from "react";
import "./PsStatus.css";
import Modal from "../../components/Modal"
import StackedBarChart from '../../components/StackedBarChart';
import SortingTable from "../../components/SortingTable"
import AppIcon from '../../components/AppIcon';
import { apiRequest, apiRoutes } from "./../../services";
import LoadingSpinner from "../../components/LoadingSpinner";
import MultiSelect from "../../components/MultiSelect";
import Jwt from "../../utils/jwt";
import BarChart from '../../components/BarChart2';
import GroupedBarChart from "../../components/GroupedBarChart/GroupedBarChart";
import GeneralReport from "./GeneralReport";

 
export default function Revenue(props) {
  const tab = props.tab;
  const reportTab = props.reportTab;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [modalFilterOpen, setModalFilterOpen] = useState(false);
  const [reportTypeTab, setReportTypeTab] = useState('');
  const [countData, setCountData] = useState(null);
  const [countDataRecent, setCountDataRecent] = useState(null);
  const [skus, setSkus] = useState([]);
  const [skusApi, setSkusApi] = useState([]);
  const [currentTeam, setCurrentTeam] = useState([]);
  const [skusPillFilter, setSkusPillFilter] = useState(null);
  const [skusFilter, setSkusFilter] = useState(null);
  const [skusApiFilter, setSkusApiFilter] = useState(null);
  const [countLabels, setCountLabels] = useState(null);
  const [countLabelsRecent, setCountLabelsRecent] = useState(null);
  const [sumLabels, setSumLabels] = useState(null);
  const [sumLabelsRecent, setSumLabelsRecent] = useState(null);
  const [countArray, setCountArray] = useState([]);
  const [countRow, setCountRow] = useState([]);
  const [sumRow, setSumRow] = useState([]);
  const [sumColums, setSumColumns] = useState([]);
  const [revenueUpToDateTableColumns, setRevenueUpToDateTableColumns] = useState(null);
  const [revenueUpToDateTableData, setRevenueUpToDateTableData] = useState(null);
  const [sumData, setSumData] = useState(null);
  const [sumDataRecent, setSumDataRecent] = useState(null);
  const [projectsUpToDateTableColumns, setProjectsUpToDateTableColumn] = useState(null);
  const [projectsUpToDateTableData, setProjectsUpToDateTableData] = useState(null);
  const [exportGeneralReportUrl, setGeneralReportUrl] = useState(null);
  const [exportRevenueReportUrl, setRevenueReportUrl] = useState(null);

  const colorLibrary = [
    '06ac38',
    '005a24',
    '94b2a5',
    'fa640a',
    'f82431',
    '00607f',
    'ffe664',
    'ffaaf0'
  ]
  const generateURLQuery = () => {
   const skus = `${skusFilter && skusFilter.length > 0 ? 'skus=['+ "'" + skusFilter.join("','") + "'" + ']' :skusApiFilter && skusApiFilter.length > 0 ? 'skus=['+ "'" + skusApiFilter.join("','") + "'" + ']' : ''}`
   return (
           skus ? `?${skus}`: '');
  }
 
  const fetch_count_data = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.psStatusCount}${generateURLQuery()}`,
    });
    if (data) {
      const labels = data.map(element => {
        return element.portfolios.map(port => port.name)
      })
      setCountLabels([...new Set(labels.flat())].reverse())
      contructTable(data,[...new Set(labels.flat())], 'count' )
      setCountData(data.reverse());
      setRevenueReportUrl(`https://backend-test.innovation.csg.pagerduty.com${apiRoutes.psStatusExportRevenueHistorical}`);
      setIsLoading(false);
      fetch_sum_data();
      
    } else {
      console.log(error)
      setIsLoading(false);
    }
  };

  const fetch_count_data_recent = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.psStatusCountRecent}${generateURLQuery()}`,
    });
    if (data) {
      const labels = data.map(element => {
        return element.name
      })
      setCountLabels(null)
      setCountData(null)
      setCountLabelsRecent([...new Set(labels)])
      contructTableRecent(data,[...new Set(labels)], 'count' )
      setCountDataRecent(data);
      setRevenueReportUrl(`https://backend-test.innovation.csg.pagerduty.com${apiRoutes.psStatusExportRevenueUpToDate}`);
      setIsLoading(false);
      fetch_sum_data_recent();
    } else {
      console.log(error)
      setIsLoading(false);
    }
  };

  const fetch_skus = async () => {
    const [error, res] = await apiRequest({
        method: "get",
        url: apiRoutes.psStatusSkus,
    });
    if (res) {
        setSkusApi(res.map(element => { return { value: element, element } }));
    }
    if (error) console.log('err', error)
  };
  
  const fetch_sum_data = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.psStatusValueSum}${generateURLQuery()}`,
    });
    if (data) {
      const labels = data.map(element => {
        return element.portfolios.map(port => port.name)
      })
      setSumLabels([...new Set(labels.flat())].reverse())
      contructTable(data,[...new Set(labels.flat())], 'sum' )
      setSumData(data.reverse());
      setIsLoading(false);
      setSumLabelsRecent(null)
      setSumDataRecent(null)
    } else {
      console.log(error)
      setIsLoading(false);
    }
  };

  const fetch_sum_data_recent = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.psStatusValueSumRecent}${generateURLQuery()}`,
    });
    if (data) { 
      const labels = data.map(element => {
        return element.name
      })
      setSumData(null);
      setSumLabels(null)
      setSumLabelsRecent(labels)
      contructTableRecent(data,[...new Set(labels)], 'sum' )
      setSumDataRecent(data);
      setIsLoading(false);
    } else {
      console.log(error)
      setIsLoading(false);
    }
  };

  const applyFilters = () => {
    openFilterModal(false);
    switch(reportTypeTab) {
      case 'Historical':
      fetch_count_data();
       break;
      case 'UpToDate':
       fetch_count_data_recent();
      break;
    }
  };
  
  const handleChangeMultiSelectSkus = async (values) => {
    setSkusPillFilter(values);
    let options = values.map(a => a.value);
    setSkusFilter(values !== [] ? options: null);
  };

  const handleChangeMultiSelectSkusApi = async (values) => {
    let options = values.map(a => a.value);
    setSkusApiFilter(values !== [] ? options: null);
  };
  
  const openFilterModal = (value) => {
    setModalFilterOpen(value)
  };

  useEffect(() => {
    if(props.tab === 'Graph') {
      fetch_count_data_recent();
      fetch_skus();
      setReportTypeTab('UpToDate');
    } else {
    }
  
      
  }, [props]);

  useEffect(() => {
    reportTypeTabLiteral.hasOwnProperty(reportTypeTab) && reportTypeTabLiteral[reportTypeTab]();
  }, [reportTypeTab]);

  const reportTypeTabLiteral = {
    'UpToDate': () =>  fetch_count_data_recent(),
    'Historical': () =>  fetch_count_data(),
  }

  const contructTable = (data,labels,type) => {
    const options2 = { style: 'currency', currency: 'USD' };
    const numberFormat = new Intl.NumberFormat('en-US', options2);
    const getInfo = (item, label) => {
      if(item.portfolios.find(i => i.name === label)) {
        return  type === 'count' ? item.portfolios.find(i => i.name === label).count : numberFormat.format(item.portfolios.find(i => i.name === label).count)
      } else {
        return 0
      }
    }

   const rowGenerator =  labels.map((label,i) => {
    let columnsArray = [];
    const row = data.map(item => {
      columnsArray.unshift({ label: `${item.month_name}`, valueLabel: `${item.month_name}`, sortable: true, widthPX: 200, type: 'String', innerType: 'String' })    
      return {
        portfolio_name: label, 
        [`${item.month_name}`]: getInfo(item, label)}
    })
    columnsArray.unshift({ label: 'SKU', valueLabel: 'portfolio_name', sortable: true, widthPX: 250, type: 'String', innerType: 'String' })
    type === 'count' ? setCountArray(columnsArray) : setSumColumns(columnsArray)
      return Object.assign({}, ...row)
    })
    type === 'count' ? setCountRow(rowGenerator): setSumRow(rowGenerator)
  }

  const contructTableRecent = (data,labels,type) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const d = new Date();
    let month = monthNames[d.getMonth()];

   const rowGenerator =  data.map((item,i) => {
    let columnsArray = [{ label: 'SKU', valueLabel: 'portfolio_name', sortable: true, widthPX: 250, type: 'String', innerType: 'String' },
    { label: `${month}`, valueLabel: `Month`, sortable: true, widthPX: 200, type: 'String', innerType: 'String' }];

    const options2 = { style: 'currency', currency: 'USD' };
    const numberFormat = new Intl.NumberFormat('en-US', options2);
    type === 'count' ? setProjectsUpToDateTableColumn(columnsArray) : setRevenueUpToDateTableColumns
    (columnsArray)
      return {
        portfolio_name: item.name, 
        Month: type === 'count' ? item.count:numberFormat.format(item.count)}

    })

    type === 'count' ? setProjectsUpToDateTableData(rowGenerator): setRevenueUpToDateTableData(rowGenerator)
  }

  const getDataset = (report) => {
    const labels = report === 'count' ? countLabels : sumLabels;
    const dataSource = report === 'count' ? countData : sumData;
  const getData = (key) => {
    const filterArray = labels.map((label) => {
     return key.find((item) => item.name === label ? (item.count ): 0);
   });
   const data = filterArray.map((item) => item ? ( item.count): 0);
   return data;
 }
  const dataset = dataSource.map(function (key,i) {
    return {
      label: key.month_name,
      data: getData(key.portfolios.flat()),
      backgroundColor: `#${colorLibrary[i]}`,
      stack: `Stack ${i}`,
      barThickness: 40,
      barPercentage: 0.5,   
      datalabels: {
        anchor: 'end',
        align: 'top'
      },
    };
  });
  return dataset;
    
  }
  return (
  <>
  {isLoading && <div id="SuccessOnDemand-loader"><LoadingSpinner /></div>}



  {props.reportTab === 'Revenue' && props.tab === 'Data'  ? <GeneralReport tab={props.tab} reportTab={props.reportTab} canSeeReport={props.canSeeReport}/> : 
    <div id="table-container-revenue">
    <div className="filter-panel-statusApp">
      <div className="pills-container">
        {<ul className="pills-list">
          <li className={reportTypeTab === 'UpToDate' ? 'pill-list-item-active' : 'pill-list-item'}  onClick={() => { setReportTypeTab('UpToDate') }}>Up to date</li>
          <li className={reportTypeTab === 'Historical'  ? 'pill-list-item-active' : 'pill-list-item'}  onClick={() => { setReportTypeTab('Historical') }}>Historical</li>
        </ul>}
      </div>
      <a className="button-export" onClick={() => { setModalFilterOpen(true) }}>Filter</a>
    </div>
    <div id="PS-Status-container-right-groupBars">
      {countData && reportTypeTab === 'Historical' && <GroupedBarChart
        title='Ps Revenue Report /N of Sku'
        subTitle='Total Projects'
        data={countData}
        labels={countLabels}
        datasets={getDataset('count')}
      />}
      {countDataRecent && reportTypeTab === 'UpToDate' && <div style={{width: '700px', margin:'auto'}}><BarChart
        title='Ps Revenue Report /N of Sku'
        subTitle='Total Projects'
        labels={countLabelsRecent}
        backgroundColors={countDataRecent.map((element, i) => colorLibrary[i])}
        values={countDataRecent.map(element => element.count)}
        data={countDataRecent}
        messages={''}
      /></div>}
      {reportTypeTab === 'Historical' && countArray && countRow && <div id="PS-Status-container-right-top10-table">
        <SortingTable tableName='TOP10Table' columnsArray={countArray} rowsArray={countRow} iSortDescending={false} hideCheckbox={true} tableHeight='100%' showElementsPerPage={10} elementOnClick={(e) => { }}dontShowPagination={true} dontShowFilters={true}/>
      </div>}
      {reportTypeTab === 'UpToDate' && projectsUpToDateTableColumns && projectsUpToDateTableData && <div id="PS-Status-container-right-top10-table" style={{width: '1000px', margin:'auto'}}>
        <SortingTable tableName='TOP10Table' columnsArray={projectsUpToDateTableColumns} rowsArray={projectsUpToDateTableData} iSortDescending={false} hideCheckbox={true} tableHeight='100%' showElementsPerPage={10} elementOnClick={(e) => { }} dontShowPagination={true} dontShowFilters={true}/>
      </div>} 
      {<div id="">
        <div id="PS-Status-container-right-groupBars">
          {sumData && reportTypeTab === 'Historical' && <GroupedBarChart
            title='Sum of Order Value Portfolio'
            subTitle='Revenue'
            data={sumData}
            labels={sumLabels}
            datasets={getDataset('sum')}
          />}
          {sumDataRecent && reportTypeTab === 'UpToDate' && <div style={{width: '700px', margin:'auto'}}><BarChart
            title='Sum of Order Value Portfolio'
            subTitle='Revenue'
            labels={sumLabelsRecent}
            backgroundColors={sumDataRecent.map((element,i ) => colorLibrary[i])}
            values={sumDataRecent.map(element => element.count)}
            data={sumDataRecent}
            messages={''}
          /></div>}
          </div>
          {sumColums && sumRow && reportTypeTab === 'Historical' && <div id="PS-Status-container-right-top10-table">
              <SortingTable tableName='TOP10Table' columnsArray={sumColums} rowsArray={sumRow} iSortDescending={false} hideCheckbox={true} tableHeight='100%' showElementsPerPage={10} elementOnClick={(e) => { }} dontShowPagination={true} dontShowFilters={true}/>
            </div>}
            {reportTypeTab === 'UpToDate' && revenueUpToDateTableColumns && revenueUpToDateTableData && <div id="PS-Status-container-right-top10-table" style={{width: '1000px', margin:'auto'}}>
              <SortingTable tableName='TOP10Table' columnsArray={revenueUpToDateTableColumns} rowsArray={revenueUpToDateTableData} iSortDescending={false} hideCheckbox={true} tableHeight='100%' showElementsPerPage={10} elementOnClick={(e) => { }} dontShowPagination={true} dontShowFilters={true}/>
          </div>}
        </div>}
      </div>
    </div>}
    <>
      <Modal show={modalFilterOpen} title={`Filters`} handleClose={() => openFilterModal(false)}>
        <div id="SuccessOnDemand-modal-container">
          {skus.length > 0 && currentTeam && reportTab !== 'PSGeneralReport' && <div>
          <p>SKU</p>
          <MultiSelect
          options={skus}
          onChange={(e) => handleChangeMultiSelectSkus(e)}
          valueToDisplay={'value'}
          allowOnlyOne={false}
          defaultValues={null}
        /></div>}
        {skusApi.length > 0 && currentTeam && reportTab !== 'PSGeneralReport' && <div>
          <p>SKU</p>
          <MultiSelect
          options={skusApi}
          onChange={(e) => handleChangeMultiSelectSkusApi(e)}
          valueToDisplay={'value'}
          allowOnlyOne={false}
          defaultValues={null}
        /></div>}
        </div>
        <div id="SOD-form-row-buttons">
          <button style={{ backgroundColor: '#DEE2E8' }} className="button-modal" onClick={() => openFilterModal(false)}>Cancel</button>
          <button className="button-modal" onClick={() => {applyFilters() }}>Apply Filters</button>
        </div>
      </Modal>
    </>
  </>
  )
}