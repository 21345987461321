import { forwardRef, useImperativeHandle } from 'react';
import './FillingCircle.css';


const FillingCircle = forwardRef(({ percentage = 0, color, diameter }, Ref) => {
    useImperativeHandle(Ref, () => ({}));

    return (
        <div className="center" style={{height: `${diameter || 100 }px`, width: `${diameter || 100 }px`}}>
            <div className="circle">
                <div className="wave" style={{ backgroundColor: color || '#00607f', top: diameter - ((diameter/ 100) * percentage) }}></div>
                <div className="waveText">{Math.floor(percentage <= 100 ? percentage : 100)}%</div>
                <div className="background-wrap">
                    {[...Array(100).keys()].map( bubble => 
                    (<div key={'bubble' + bubble} className="bubble x1" style={{animationDuration: `${Math.floor(Math.random()*5) + 3}s`,  top: Math.ceil(Math.random() * 100) * (Math.round(Math.random()) ? 1 : -1), left: Math.ceil(Math.random() * 100) * (Math.round(Math.random()) ? 1 : -1)}}></div>)
                        )}
                </div>
            </div>
        </div>
    );
}
);

export default FillingCircle;