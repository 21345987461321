import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Contenedor, DatePickerContainer } from "./DatePickerStyled.tsx";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
interface Props {
  label: string;
  pickerWidth?: string;
  onValue: (data: any, isValid: boolean) => void;
  disabled?: boolean;
  value: Date | null;
  filterFunction?: (day: Date) => boolean;
}
const DatePickerComponent = forwardRef(
  (
    {
      label,
      pickerWidth = "250px",
      onValue,
      disabled,
      value,
      filterFunction,
    }: Props,
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState<any>(null);
    useImperativeHandle(ref, () => ({}));
    useEffect(() => {
      const valueToSet = value ? new Date(value) : null;
      if (
        valueToSet !== null &&
        typeof valueToSet.getTime === "function" &&
        !isNaN(valueToSet.getTime())
      ) {
        setCurrentValue(dayjs(valueToSet));
      }
    }, [value]);
    const handleDateChange = (newValue: any) => {
      if (onValue) {
        setCurrentValue(newValue);
        onValue(
          newValue,
          newValue !== null && !isNaN(new Date(newValue).getTime())
        );
      }
    };
    return (
      <DatePickerContainer>
        <Contenedor style={{ width: pickerWidth }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={label}
              value={currentValue}
              disabled={disabled}
              onChange={(newValue) => handleDateChange(newValue)}
              sx={{
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "hsl(0, 0%, 80%)",
                  },
                },
              }}
              shouldDisableDate={(day) =>
                filterFunction ? filterFunction(day) : false
              }
            />
          </LocalizationProvider>
        </Contenedor>
        <FontAwesomeIcon
          icon={faXmarkCircle}
          onClick={() => handleDateChange(null)}
        />
      </DatePickerContainer>
    );
  }
);
export default DatePickerComponent;