import React from "react";
import styles from "./TourCard.module.css";

const CloseIcon = (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    aria-hidden="true"
    style={{
      height: "1em",
      width: "1em",
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    ></path>
  </svg>
);

function TourCard({
  title,
  description,
  currentStep,
  totalSteps,
  onBack,
  onNext,
  onClose,
}) {
  const progressPercentage = (currentStep / totalSteps) * 100;
  return (
    <>
      <div className={styles.tourCardTitleContainer}>
        <h3 className={styles.tourCardTitle}>{title}</h3>
        <button className={styles.tourCardCloseButton} onClick={() => onClose()}>{CloseIcon}</button>
      </div>
      <p className={styles.tourCardDescription}>{description}</p>
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{
              width: `${progressPercentage}%`,
            }}
          ></div>
        </div>
        <div className={styles.progressText}>
          {currentStep} / {totalSteps}
        </div>
      </div>
      <div
        className={styles.tourCardFooter}
        style={{
          justifyContent:
            onBack && onNext ? "space-between" : onBack ? "flex-start" : "flex-end",
        }}
      >
        {onBack && (
          <button
            onClick={() => onBack()}
            className={styles.tourCardButtonSecondary}
          >
            Back
          </button>
        )}
        {onNext && (
          <button
            onClick={() => onNext()}
            className={styles.tourCardButtonPrimary}
          >
            {currentStep !== totalSteps ? "Next" : "Close"}
          </button>
        )}
      </div>
    </>
  );
}

export default TourCard;
