import './ClientDeliverySkeleton.css'

const ClientDeliverySkeleton = () => {
  return(
    <div style={{ marginTop: "10px", maxWidth: '100%', backgroundColor:"White" , padding:".5rem", borderRadius: "5px",boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
      <div>
        <h1 className="skeleton__animation__header" style={{ fontSize: '26px', height: '40px',fontWeight: '600', lineHeight: '24px', color: '#117170' }}></h1>
      </div>
      <div style={{ minWidth: '100%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '24px', paddingRight: '24px' }}>
        <table style={{ minWidth: '100%', borderCollapse: 'separate', borderColor: '#d1d5db' }}>
          <thead>
            <tr>
              <th style={{ padding: '5px', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}></th>
              <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}></th>
              <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}></th>
              <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}></th>
              <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}></th>
              <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}></th>
            </tr>
          </thead>
          <tbody style={{ borderColor: '#e5e7eb', backgroundColor: '#ffffff' }}>
             { Array.from({ length: 5 }).map((_, i) => (
                <tr key={i}>
                  <td className="skeleton__animation" style={{ whiteSpace: 'nowrap', fontSize: '14px', borderBottom: 'solid #F7F7F7', height: '40px' }}></td>
                  <td className="skeleton__animation" style={{ whiteSpace: 'nowrap', fontSize: '14px', borderBottom: 'solid #F7F7F7', height: '40px' }}></td>
                  <td className="skeleton__animation" style={{ whiteSpace: 'nowrap', fontSize: '14px', borderBottom: 'solid #F7F7F7', height: '40px' }}></td>
                  <td className="skeleton__animation" style={{ whiteSpace: 'nowrap', fontSize: '14px', borderBottom: 'solid #F7F7F7', height: '40px' }}></td>
                  <td className="skeleton__animation" style={{ whiteSpace: 'nowrap', fontSize: '14px', borderBottom: 'solid #F7F7F7', height: '40px' }}></td>
                  <td className="skeleton__animation" style={{ whiteSpace: 'nowrap', fontSize: '14px', borderBottom: 'solid #F7F7F7', height: '40px' }}></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
    );
    
}
export default ClientDeliverySkeleton