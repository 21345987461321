import './TableSkeleton.css';

const SkeletonTable = () => {
  return ( 
    <div className="SOD-contain">
      <div className="SOD-title"></div>
      <div className="tabs-container">
          <div className="tab-list-sod" style={{display:'flex', margin: '13px'}}>
            <label className="skeleton-text-sod"></label>
            <label className="skeleton-text-sod"></label>
            <label className="skeleton-text-sod"></label>
          </div>
        </div>
      <div style={{display:'flex', justifyContent:'end', marginBottom: '10px'}}>
        <input className="skeleton-input-sod"/>
        <input className="skeleton-input-sod"/>
      </div>
      <div className="skeleton-table">
        <div className="skeleton-row-title">
          <div className="skeleton-cell header">
            <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell header">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell header">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell header">
          <div className="skeleton-cell-text"></div>
          </div>
        </div>
        <div className="skeleton-row">
          <div className="skeleton-cell">
            <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
        </div>
        <div className="skeleton-row">
          <div className="skeleton-cell">
            <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
        </div>
        <div className="skeleton-row">
          <div className="skeleton-cell">
            <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
        </div>
        <div className="skeleton-row">
          <div className="skeleton-cell">
            <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
        </div>
        <div className="skeleton-row">
          <div className="skeleton-cell">
            <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
        </div>
        <div className="skeleton-row">
          <div className="skeleton-cell">
            <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
          <div className="skeleton-cell">
          <div className="skeleton-cell-text"></div>
          </div>
        </div>
      </div>
    </div>

  )
};

export default SkeletonTable;

