import styled from "styled-components";
import pxToRem from "./../pxToTem";

import designVariables from "./../utils";

export default styled.div`
  align-items: center;
  border: 2px dashed #e2e2e2;
  boxSizing: border-box;
  border-radius: ${pxToRem(4)};
  color: ${designVariables.COLOR_NEUTRAL};
  display: flex;
  flex-direction: column;
  font-family: ${designVariables.TYPOGRAPHY_FAMILY_BODY};
  height: ${pxToRem(205)};
  justify-content: space-around;
  margin-top: ${pxToRem(12)};
  padding: ${pxToRem(42)};
`;

export const InputFileItem = styled.input`
  display: none;
`;

export const FileContent = styled.div`
  display: flex;
  height: 48px;
  background-color: #F3F5F7;
  color: ${designVariables.COLOR_NEUTRAL};
  padding: ${pxToRem(10)};
  justify-content: space-between;
  align-items: center;
  margin-top: ${pxToRem(4)};
  margin-bottom: ${pxToRem(4)};
`;

export const NameFile = styled.p`
  font-size: ${pxToRem(14)};
  max-width: 80%;
  width: 70%;
  padding-left: ${pxToRem(11)};
`;

export const FileSize = styled.p`
  color: #4B546C;
  font-size: ${pxToRem(14)};
  min-width: 15%;
  width: 20%;
  padding-right: ${pxToRem(20)};
  text-align: right;
`;

export const ButtonDeleteFile = styled.button`
  border: 0;
  background: transparent;
`;