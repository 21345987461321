import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import "./HealthCheck.css";
import SortingTable from "../../components/SortingTable"
import Modal from "../../components/Modal"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faDotCircle, faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import Tabs from '../../components/Tabs';
import BarChart from '../../components/BarChart2';
import DoughnutChart from '../../components/DoughnutChart';
import Toggle from '../../components/Toggle';
import AppIcon from '../../components/AppIcon';
import { apiRequest, apiRoutes } from "./../../services";
import Dropdown from "../../components/Dropdown";
import MultiSelect from "../../components/MultiSelect";


export default function HealthCheck() {
    const location = useLocation();
    const [rowsArray, setRowsArray] = useState([]);
    const [usersRowsArray, setUsersRowsArray] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalBulkFixOpen, setModalBulkFixOpen] = useState(false);
    const [startModalOpen, setStartModalOpen] = useState(false);
    const [bulkFixSelectedElement, setBulkFixSelectedElement] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [rulesObject, setRulesObject] = useState({});
    const [activeRow, setActiveRow] = useState('Dashboard');
    const [incidentActiveTab, setIncidentActiveTab] = useState('Top10');
    const [detailsActiveTab, setDetailsActiveTab] = useState('Escalation Policies');
    const [detailsIncidentsActiveTab, setDetailsIncidentsActiveTab] = useState('90DaysBreakdownTable');
    const [topTenIncidentsView, setTopTenIncidentsView] = useState([]);
    const [bestPracticeIncidentsView, setBestPracticeIncidentsView] = useState([]);
    const [bestPracticeIncidentsViewUsers, setBestPracticeIncidentsViewUsers] = useState([]);
    const [metricsIncidentsView, setMetricsIncidentsView] = useState([]);
    const [apiKey, setAPIKey] = useState('');
    const [lastLoadedDate, setLastLoadedDate] = useState('');
    const [entitiesArray, setEntitiesArray] = useState([]);
    const [currentEntitie, setCurrentEntitie] = useState('Services');
    const [teamsArray, setTeamsArray] = useState([]);
    const [currentTeam, setCurrentTeam] = useState('');
    const [teamsInfo, setTeamsInfo] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(true);
    const [currentStack, setCurrentStack] = useState(location.state.stack);

    const EscalationPoliciesColumnsArray = [
        { label: 'Escalation Policy Name', valueLabel: 'summary', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Layers', valueLabel: 'layer_count', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: 'Loops', valueLabel: 'loops', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: 'Ghosts', valueLabel: 'ghost_count', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
    ];

    const SchedulesColumnsArray = [
        { label: 'Schedule Name', valueLabel: 'summary', sortable: true, widthPX: 500, type: 'String', innerType: 'String' },
        { label: 'Ghosts', valueLabel: 'ghost_count', sortable: true, widthPX: 500, type: 'Color', innerType: 'Number' },
    ];

    const ServicesColumnsArray = [
        { label: 'Service Name', valueLabel: 'summary', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Last Incident', valueLabel: 'last_incident', sortable: true, widthPX: 200, type: 'Date', innerType: 'Date' },
        { label: 'Last 90 Days Incidents', valueLabel: 'last_90_days_incidents', sortable: true, widthPX: 300, type: 'Color', innerType: 'Number' },
        { label: '% Transient', valueLabel: 'transient_percentage', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: 'Total Triggered', valueLabel: 'total_triggered', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: "Total Ack'd", valueLabel: 'total_ack', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: 'Total Resolved', valueLabel: 'total_resolved', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
    ];

    const teamMembersColumnsArray = [
        { label: 'User Name', valueLabel: 'summary', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Contact Methods', valueLabel: 'contact_methods_count', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: 'Notification Rules', valueLabel: 'notification_rules_count', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: 'Team Role', valueLabel: 'team_role', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Email', valueLabel: 'email', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
    ];

    const columnsArrayBulkFix = [
        { label: 'Service Name', valueLabel: 'service_name', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Service Id', valueLabel: 'service_id', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
    ];

    const columnsArrayBulkFixTeams = [
        { label: 'Service Summary', valueLabel: 'summary', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Service Id', valueLabel: 'id', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
    ];

    const columnsArray = [
        { label: 'Service Name', valueLabel: 'service_name', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Service Id', valueLabel: 'service_id', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Description', valueLabel: 'description', sortable: true, widthPX: 300, type: 'Color', innerType: 'String' },
        { label: 'Escalation Policy', valueLabel: 'escalation_policy', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Team', valueLabel: 'team', sortable: true, widthPX: 300, type: 'Color', innerType: 'String' },
        { label: 'Total Incidents Last 90 days', valueLabel: 'total_incidents_90', sortable: true, widthPX: 330, type: 'Color', innerType: 'Number' },
        { label: 'Last incident', valueLabel: 'last_incident', sortable: true, widthPX: 200, type: 'Date', innerType: 'Date' },
        { label: 'Created at', valueLabel: 'created_at', sortable: true, widthPX: 200, type: 'Date', innerType: 'Date' },
        { label: 'Status', valueLabel: 'status', sortable: true, widthPX: 200, type: 'Color', innerType: 'String' },
        { label: 'Create incident', valueLabel: 'create_incident', sortable: true, widthPX: 300, type: 'Color', innerType: 'String' },
        { label: 'Alert grouping', valueLabel: 'alert_grouping', sortable: true, widthPX: 230, type: 'Color', innerType: 'String' },
        { label: 'Incident urgency', valueLabel: 'incident_urgency', sortable: true, widthPX: 250, type: 'Color', innerType: 'String' },
        { label: 'Auto resolve timeout', valueLabel: 'auto_resolve_timeout', sortable: true, widthPX: 300, type: 'Color', innerType: 'Number' },
        { label: 'Response play', valueLabel: 'response_play', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Service URL', valueLabel: 'url', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },

    ];

    const columnsArray90DaysBreakdown = [
        { label: 'Total Incidents Last 90 days', valueLabel: 'total_incidents_90', sortable: true, widthPX: 330, type: 'Color', innerType: 'Number' },
        { label: 'Service Name', valueLabel: 'service_name', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Service Id', valueLabel: 'service_id', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Total Acknowledged', valueLabel: 'incidents_last_90_days_ack_count', sortable: true, widthPX: 250, type: 'Number', innerType: 'Number' },
        { label: 'Total Triggered', valueLabel: 'incidents_last_90_days_triggered_count', sortable: true, widthPX: 250, type: 'Number', innerType: 'Number' },
        { label: 'Total Resolved', valueLabel: 'incidents_last_90_days_resolved_count', sortable: true, widthPX: 250, type: 'Number', innerType: 'Number' },
    ];

    const columnsArrayTransientIncidents = [
        { label: 'Service Name', valueLabel: 'service_name', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Service Id', valueLabel: 'service_id', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'minutes > 2', valueLabel: 'transient_incident120', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '2 < minutes > 5', valueLabel: 'transient_incident300', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '5 < minutes > 10', valueLabel: 'transient_incident600', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '10 < minutes > 15', valueLabel: 'transient_incident900', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '15 < minutes > 30', valueLabel: 'transient_incident1800', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '30 < minutes > 60', valueLabel: 'transient_incident3600', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '1 < hours > 4', valueLabel: 'transient_incident14400', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '1 < hours > 8', valueLabel: 'transient_incident28800', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '1 < hours > 12', valueLabel: 'transient_incident43200', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '1 < hours > 24', valueLabel: 'transient_incident86400', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '1 < hours > 48', valueLabel: 'transient_incident172800', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: '1 < hours > 72', valueLabel: 'transient_incident259200', sortable: true, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: 'more than 72 hours', valueLabel: 'transient_incident259201', sortable: true, widthPX: 250, type: 'Number', innerType: 'Number' },
    ];

    const columnsArrayTransientIncidentsBreakdown = [
        { label: 'Service Name', valueLabel: 'service_name', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Service Id', valueLabel: 'service_id', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Name', valueLabel: 'transient_incidents_breakdown0integration_summary', sortable: false, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Total', valueLabel: 'transient_incidents_breakdown0total', sortable: false, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: 'Integration Name', valueLabel: 'transient_incidents_breakdown1integration_summary', sortable: false, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Total', valueLabel: 'transient_incidents_breakdown1total', sortable: false, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: 'Integration Name', valueLabel: 'transient_incidents_breakdown2integration_summary', sortable: false, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Total', valueLabel: 'transient_incidents_breakdown2total', sortable: false, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: 'Integration Name', valueLabel: 'transient_incidents_breakdown3integration_summary', sortable: false, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Total', valueLabel: 'transient_incidents_breakdown3total', sortable: false, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: 'Integration Name', valueLabel: 'transient_incidents_breakdown4integration_summary', sortable: false, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Total', valueLabel: 'transient_incidents_breakdown4total', sortable: false, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: 'Integration Name', valueLabel: 'transient_incidents_breakdown5integration_summary', sortable: false, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Total', valueLabel: 'transient_incidents_breakdown5total', sortable: false, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: 'Integration Name', valueLabel: 'transient_incidents_breakdown6integration_summary', sortable: false, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Total', valueLabel: 'transient_incidents_breakdown6total', sortable: false, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: 'Integration Name', valueLabel: 'transient_incidents_breakdown7integration_summary', sortable: false, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Total', valueLabel: 'transient_incidents_breakdown7total', sortable: false, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: 'Integration Name', valueLabel: 'transient_incidents_breakdown8integration_summary', sortable: false, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Total', valueLabel: 'transient_incidents_breakdown8total', sortable: false, widthPX: 200, type: 'Number', innerType: 'Number' },
        { label: 'Integration Name', valueLabel: 'transient_incidents_breakdown9integration_summary', sortable: false, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Integration Total', valueLabel: 'transient_incidents_breakdown9total', sortable: false, widthPX: 200, type: 'Number', innerType: 'Number' },
    ];

    const UsersColumnsArray = [
        { label: 'User Name', valueLabel: 'name', sortable: true, widthPX: 200, type: 'Color', innerType: 'String' },
        { label: 'User ID', valueLabel: 'id', sortable: true, widthPX: 200, type: 'Color', innerType: 'String' },
        { label: 'Active Incidents', valueLabel: 'active_incidents_count', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: 'Active Sessions', valueLabel: 'active_sessions_count', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: 'Billed', valueLabel: 'billed', sortable: true, widthPX: 200, type: 'Color', innerType: 'String' },
        { label: 'Contact Methods', valueLabel: 'contact_methods_count', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: 'Coordinated Incidents Count', valueLabel: 'coordinated_incidents_count', sortable: true, widthPX: 300, type: 'Color', innerType: 'Number' },
        { label: 'Email', valueLabel: 'email', sortable: true, widthPX: 200, type: 'Color', innerType: 'String' },
        { label: 'Email Contact Methods Count', valueLabel: 'email_contact_methods_count', sortable: true, widthPX: 300, type: 'Color', innerType: 'Number' },
        { label: 'Notification Rules Count', valueLabel: 'notification_rules_count', sortable: true, widthPX: 300, type: 'Color', innerType: 'Number' },
        { label: 'Phone Contact Methods Count', valueLabel: 'phone_contact_methods_count', sortable: true, widthPX: 300, type: 'Color', innerType: 'Number' },
        { label: 'Push Contact Methods Count', valueLabel: 'push_contact_methods_count', sortable: true, widthPX: 300, type: 'Color', innerType: 'Number' },
        { label: 'Teams Membership Count', valueLabel: 'teams_membership_count', sortable: true, widthPX: 300, type: 'Color', innerType: 'Number' },
        { label: 'On Call Count', valueLabel: 'on_call_count', sortable: true, widthPX: 200, type: 'Color', innerType: 'Number' },
        { label: 'Role', valueLabel: 'role', sortable: true, widthPX: 200, type: 'Color', innerType: 'String' },
        { label: 'Self', valueLabel: 'self', sortable: true, widthPX: 200, type: 'Color', innerType: 'String' },
        { label: 'SMS Contact Methods Count', valueLabel: 'sms_contact_methods_count', sortable: true, widthPX: 300, type: 'Color', innerType: 'Number' },
        { label: 'Summary', valueLabel: 'summary', sortable: true, widthPX: 200, type: 'Color', innerType: 'String' },
    ];

    const isNAN = ['service_name', 'service_id', 'description', 'escalation_policy', 'team', 'last_incident', 'created_at', 'status', 'create_incident', 'alert_grouping', 'incident_urgency', 'response_play', 'url'];

    const rulesColorSeverityLiteral = {
        'minor': () => '#06ac38',
        'low': () => '#FFD300',
        'medium': () => '#FF8C00',
        'high': () => '#FF0000',
    }

    const nameToTitleLiteral = {
        'service_name': () => 'Service Name',
        'service_id': () => 'Service Id',
        'description': () => 'Description',
        'escalation_policy': () => 'Escalation Policy',
        'team': () => 'Team',
        'total_incidents_90': () => 'Total incidents Last 90 days',
        'last_incident': () => 'Last incident',
        'created_at': () => 'Created at',
        'status': () => 'Status',
        'create_incident': () => 'Create incident',
        'alert_grouping': () => 'Alert grouping',
        'incident_urgency': () => 'Incident urgency',
        'auto_resolve_timeout': () => 'Auto Resolve Timeout',
        'response_play': () => 'Response play',
        'url': () => 'Service URL',
        'summary': () => 'Summary',
    }

    const nameToTitleLiteralDashboard = {
        'description': () => 'Best Practice: Service Description',
        'total_incidents_90': () => 'Best Practice: Service Incident Activity',
        'status': () => 'Best Practice: Service Status',
        'create_incident': () => 'Best Practice: Alert & Incident Creation',
        'alert_grouping': () => 'Best Practice: Alert Grouping',
        'incident_urgency': () => 'Best Practice: Incident Urgency',
        'auto_resolve_timeout': () => 'Best Practice : Auto Resolve Timeout',
        'summary': () => 'Best Practice: Service Naming',
    }

    const nameToTitleLiteralTeams = {
        'layer_count': () => 'Layer Count',
        'ghost_count': () => 'Ghost Count',
        'loops': () => 'Loops',
        'transient_percentage': () => 'Transient Percentage',
        'total_triggered': () => 'Total Triggered',
        'total_ack': () => 'Total Ack',
        'total_resolved': () => 'Total Resolved',
        'contact_methods_count': () => 'Contact Methods Count',
        'notification_rules_count': () => 'Notification Rules Count',
    }

    useEffect(() => {
        if (currentEntitie === 'Teams' && (activeRow === 'Table' || activeRow === 'Details Incidents' || activeRow === 'Table Users')) {
            setActiveRow('Details');
        }
        if (currentEntitie === 'Services' && (activeRow === 'Details' || activeRow === 'Details Incidents' || activeRow === 'Table Users')) {
            setActiveRow('Table');
        }
        if (currentEntitie === 'Incidents' && (activeRow === 'Details' || activeRow === 'Table' || activeRow === 'Table Users')) {
            setActiveRow('Details Incidents');
        }
        if (currentEntitie === 'Users' && (activeRow === 'Details' || activeRow === 'Details Incidents' || activeRow === 'Table')) {
            setActiveRow('Table Users');
        }
    }, [currentEntitie]);

    useEffect(() => {
        teamsArray.length > 0 && setCurrentTeam([teamsArray[0]]);
    }, [teamsArray]);

    useEffect(() => {
        if (currentTeam) { fetch_team_info(); }
    }, [currentTeam]);

    const fetch_team_info = async (tab) => {
        if (currentTeam && currentTeam.length > 0) {
            const [error, res] = await apiRequest({
                method: "get",
                url: `${apiRoutes.healthCkeckTeamInfo}${currentTeam.flatMap(element => element.id).flat().toString()}`,
                headers: {
                    path_name: currentStack
                }
            });
            if (res) {
                setTeamsInfo(res.teams.map(team => {
                    if (team.escalation_policies.length > 0) {
                        team.escalation_policies.ruleObject = {};
                        team.escalation_policies.map(escalation_policie => {
                            for (const rule of escalation_policie.rules) {
                                if (!team.escalation_policies.ruleObject[rule.field]) team.escalation_policies.ruleObject[rule.field] = { Action: [], ActionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } }, Omission: [], OmissionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } } }
                                if (rule.accomplished) {
                                    team.escalation_policies.ruleObject[rule.field]['Action'].push({ ...escalation_policie, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                                    team.escalation_policies.ruleObject[rule.field]['ActionSeverity'][rule.severity].value = team.escalation_policies.ruleObject[rule.field]['ActionSeverity'][rule.severity].value + 1;
                                    team.escalation_policies.ruleObject[rule.field]['ActionSeverity'][rule.severity].message = rule.message;
                                } else {
                                    team.escalation_policies.ruleObject[rule.field]['Omission'].push({ ...escalation_policie, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                                    team.escalation_policies.ruleObject[rule.field]['OmissionSeverity'][rule.severity].value = team.escalation_policies.ruleObject[rule.field]['OmissionSeverity'][rule.severity].value + 1;
                                    team.escalation_policies.ruleObject[rule.field]['OmissionSeverity'][rule.severity].message = rule.message;
                                }
                                if (typeof escalation_policie[rule.field] !== 'object') {
                                    escalation_policie[rule.field] = {
                                        value: escalation_policie[rule.field] ? escalation_policie[rule.field].toString() : isNAN.includes(rule.field) ? '' : 0,
                                        color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9',
                                        ...rule
                                    }
                                }
                            }
                            return escalation_policie
                        })
                        team.escalation_policies.ruleObject = Object.entries(team.escalation_policies.ruleObject).map(rule => [rule[0], { ...rule[1], ActionChartState: true, OmissionChartState: true, activeTab: rule[1].Action.length > 0 ? 'Action' : 'Omission' }])
                        team.escalation_policies.show = false;
                    }
                    if (team.schedules.length > 0) {
                        team.schedules.ruleObject = {};
                        team.schedules.map(schedule => {
                            for (const rule of schedule.rules) {
                                if (!team.schedules.ruleObject[rule.field]) team.schedules.ruleObject[rule.field] = { Action: [], ActionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } }, Omission: [], OmissionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } } }
                                if (rule.accomplished) {
                                    team.schedules.ruleObject[rule.field]['Action'].push({ ...schedule, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                                    team.schedules.ruleObject[rule.field]['ActionSeverity'][rule.severity].value = team.schedules.ruleObject[rule.field]['ActionSeverity'][rule.severity].value + 1;
                                    team.schedules.ruleObject[rule.field]['ActionSeverity'][rule.severity].message = rule.message;
                                } else {
                                    team.schedules.ruleObject[rule.field]['Omission'].push({ ...schedule, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                                    team.schedules.ruleObject[rule.field]['OmissionSeverity'][rule.severity].value = team.schedules.ruleObject[rule.field]['OmissionSeverity'][rule.severity].value + 1;
                                    team.schedules.ruleObject[rule.field]['OmissionSeverity'][rule.severity].message = rule.message;
                                }
                                if (typeof schedule[rule.field] !== 'object') {
                                    schedule[rule.field] = {
                                        value: schedule[rule.field] ? schedule[rule.field].toString() : isNAN.includes(rule.field) ? '' : 0,
                                        color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9',
                                        ...rule
                                    }
                                }
                            }
                            return schedule
                        })
                        team.schedules.ruleObject = Object.entries(team.schedules.ruleObject).map(rule => [rule[0], { ...rule[1], ActionChartState: true, OmissionChartState: true, activeTab: rule[1].Action.length > 0 ? 'Action' : 'Omission' }])
                        team.schedules.show = false;
                    }
                    if (team.services.length > 0) {
                        team.services.map(service => {
                            team.services.ruleObject = {};
                            for (const rule of service.rules) {
                                if (!team.services.ruleObject[rule.field]) team.services.ruleObject[rule.field] = { Action: [], ActionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } }, Omission: [], OmissionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } } }
                                if (rule.accomplished) {
                                    team.services.ruleObject[rule.field]['Action'].push({ ...service, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                                    team.services.ruleObject[rule.field]['ActionSeverity'][rule.severity].value = team.services.ruleObject[rule.field]['ActionSeverity'][rule.severity].value + 1;
                                    team.services.ruleObject[rule.field]['ActionSeverity'][rule.severity].message = rule.message;
                                } else {
                                    team.services.ruleObject[rule.field]['Omission'].push({ ...service, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                                    team.services.ruleObject[rule.field]['OmissionSeverity'][rule.severity].value = team.services.ruleObject[rule.field]['OmissionSeverity'][rule.severity].value + 1;
                                    team.service.ruleObject[rule.field]['OmissionSeverity'][rule.severity].message = rule.message;
                                }
                                if (typeof service[rule.field] !== 'object') {
                                    service[rule.field] = {
                                        value: service[rule.field] ? service[rule.field].toString() : isNAN.includes(rule.field) ? '' : 0,
                                        color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9',
                                        ...rule
                                    }
                                }
                            }
                            return service
                        })
                        team.services.ruleObject = Object.entries(team.services.ruleObject).map(rule => [rule[0], { ...rule[1], ActionChartState: true, OmissionChartState: true, activeTab: rule[1].Action.length > 0 ? 'Action' : 'Omission' }])
                        team.services.show = false;
                    }
                    if (team.team_members.length > 0) {
                        team.team_members.ruleObject = {};
                        team.team_members.map(team_member => {
                            for (const rule of team_member.rules) {
                                if (!team.team_members.ruleObject[rule.field]) team.team_members.ruleObject[rule.field] = { Action: [], ActionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } }, Omission: [], OmissionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } } }
                                if (rule.accomplished) {
                                    team.team_members.ruleObject[rule.field]['Action'].push({ ...team_member, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                                    team.team_members.ruleObject[rule.field]['ActionSeverity'][rule.severity].value = team.team_members.ruleObject[rule.field]['ActionSeverity'][rule.severity].value + 1;
                                    team.team_members.ruleObject[rule.field]['ActionSeverity'][rule.severity].message = rule.message;
                                } else {
                                    team.team_members.ruleObject[rule.field]['Omission'].push({ ...team_member, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                                    team.team_members.ruleObject[rule.field]['OmissionSeverity'][rule.severity].value = team.team_members.ruleObject[rule.field]['OmissionSeverity'][rule.severity].value + 1;
                                    team.team_members.ruleObject[rule.field]['OmissionSeverity'][rule.severity].message = rule.message;
                                }
                                if (typeof team_member[rule.field] !== 'object') {
                                    team_member[rule.field] = {
                                        value: team_member[rule.field] ? team_member[rule.field].toString() : isNAN.includes(rule.field) ? '' : 0,
                                        color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9',
                                        ...rule
                                    }
                                }
                            }
                            return team_member
                        })
                        team.team_members.ruleObject = Object.entries(team.team_members.ruleObject).map(rule => [rule[0], { ...rule[1], ActionChartState: true, OmissionChartState: true, activeTab: rule[1].Action.length > 0 ? 'Action' : 'Omission' }])
                        team.team_members.show = false;
                    }
                    return team
                }));
            }
            if (error) console.log('err', error)
        } else setTeamsInfo([]);
    };

    const handleChangeMultiSelect = async (values) => {
        setCurrentTeam(values);
    };

    const fetch_entities = async (tab) => {
        const [error, res] = await apiRequest({
            method: "get",
            url: apiRoutes.healthCkeckEntities,
            headers: {
                path_name: currentStack
            }
        });
        if (res) {
            setEntitiesArray(res.map(element => { return { value: element } }));
        }
        if (error) console.log('err', error)
    };

    const fetch_Teams = async (tab) => {
        const [error, res] = await apiRequest({
            method: "get",
            url: apiRoutes.healthCkeckTeams,
            headers: {
                path_name: currentStack
            }
        });
        if (res) {
            setTeamsArray(res.map(element => { return { value: element.name, ...element } }));
        }
        if (error) console.log('err', error)
    };

    const fetch_last_data_loaded = async (tab) => {
        const [error, res] = await apiRequest({
            method: "get",
            url: apiRoutes.healthCkecklastDataLoaded,
            headers: {
                path_name: currentStack
            }
        });
        if (res) {
            const month = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "Octuber", "November", "December");
            const weakDay = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");
            const f = new Date(res[0].date);
            const lastLoadedStringDate = `${weakDay[f.getDay()]}, ${month[f.getMonth()]} ${f.getDate()}, ${f.getFullYear()} at ${f.getHours()}:${f.getMinutes()}`;
            setLastLoadedDate(lastLoadedStringDate);
        }
        if (error) console.log('err', error)
    };

    const fetch_data = async (tab) => {
        const [error, res] = await apiRequest({
            method: "get",
            url: `${apiRoutes.HealthCkeckService}`,
            headers: {
                path_name: currentStack
            }
        });
        if (res) {
            let ruleObject = {};
            const rowArray = res.services.map(element => {
                for (const rule of element.rules) {
                    if (!ruleObject[rule.field]) ruleObject[rule.field] = { Action: [], ActionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } }, Omission: [], OmissionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } } }
                    if (rule.accomplished) {
                        ruleObject[rule.field]['Action'].push({ ...element, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                        ruleObject[rule.field]['ActionSeverity'][rule.severity].value = ruleObject[rule.field]['ActionSeverity'][rule.severity].value + 1;
                        ruleObject[rule.field]['ActionSeverity'][rule.severity].message = rule.message;
                    } else {
                        ruleObject[rule.field]['Omission'].push({ ...element, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                        ruleObject[rule.field]['OmissionSeverity'][rule.severity].value = ruleObject[rule.field]['OmissionSeverity'][rule.severity].value + 1;
                        ruleObject[rule.field]['OmissionSeverity'][rule.severity].message = rule.message;
                    }
                    if (typeof element[rule.field] !== 'object') {
                        element[rule.field] = {
                            value: element[rule.field] ? element[rule.field].toString() : isNAN.includes(rule.field) ? '' : 0,
                            color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9',
                            ...rule
                        }
                    }
                }

                for (const transient_incident of element.transient_incidents) {
                    element['transient_incident' + transient_incident.interval_in_seconds] = transient_incident.range_account
                }

                for (let i = 0; i < 10; i++) {
                    element['transient_incidents_breakdown' + i + 'integration_summary'] = element.transient_incidents_breakdown[i]?.integration_summary;
                    element['transient_incidents_breakdown' + i + 'total'] = element.transient_incidents_breakdown[i]?.total;
                }
                return element
            });
            ruleObject = Object.entries(ruleObject).map(rule => [rule[0], { ...rule[1], ActionChartState: true, OmissionChartState: true, activeTab: rule[1].Action.length > 0 ? 'Action' : 'Omission' }])
            setRowsArray(rowArray);
            setRulesObject(ruleObject);
        }
        if (error) console.log('err', error)
    };
    const fetchDataIncidentsTop10 = async (tab) => {
        const [error, res] = await apiRequest({
            method: "get",
            url: apiRoutes.healthCkeckTopTen,
            headers: {
                path_name: currentStack
            }
        });
        if (res) {
            setTopTenIncidentsView(res[tab] ? res[tab].map(element => { return { ...element, total_incidents_90: { value: element.total_incidents_90, color: getColor(element.total_incidents_90), message: element.rule.length > 0 ? element.rule[0].message : '' } } }): []);
        }
        if (error) {
            console.log('err', error);
        }
    };
    const fetchUsersTable = async (tab) => {
        const [error, res] = await apiRequest({
            method: "get",
            url: apiRoutes.healthCkeckUsers,
            headers: {
                path_name: currentStack
            }
        });
        if (res) {
            let ruleObject = {};
            const rowArray = res.map(element => {
                for (const rule of element.rules) {
                    if (!ruleObject[rule.field]) ruleObject[rule.field] = { Action: [], ActionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } }, Omission: [], OmissionSeverity: { minor: { value: 0, message: '' }, low: { value: 0, message: '' }, medium: { value: 0, message: '' }, high: { value: 0, message: '' } } }
                    if (rule.accomplished) {
                        ruleObject[rule.field]['Action'].push({ ...element, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                        ruleObject[rule.field]['ActionSeverity'][rule.severity].value = ruleObject[rule.field]['ActionSeverity'][rule.severity].value + 1;
                        ruleObject[rule.field]['ActionSeverity'][rule.severity].message = rule.message;
                    } else {
                        ruleObject[rule.field]['Omission'].push({ ...element, [`${rule.field}Severity`]: { color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9', field: `${rule.field}Severity`, value: rule.severity, message: rule.message } });
                        ruleObject[rule.field]['OmissionSeverity'][rule.severity].value = ruleObject[rule.field]['OmissionSeverity'][rule.severity].value + 1;
                        ruleObject[rule.field]['OmissionSeverity'][rule.severity].message = rule.message;
                    }
                    if (typeof element[rule.field] !== 'object') {
                        element[rule.field] = {
                            value: element[rule.field] ? element[rule.field].toString() : isNAN.includes(rule.field) ? '' : 0,
                            color: rulesColorSeverityLiteral.hasOwnProperty(rule.severity) ? rulesColorSeverityLiteral[rule.severity]() : '#D9D9D9',
                            ...rule
                        }
                    }
                }
                return element
            });
            ruleObject = Object.entries(ruleObject).map(rule => [rule[0], { ...rule[1], ActionChartState: true, OmissionChartState: true, activeTab: rule[1].Action.length > 0 ? 'Action' : 'Omission' }])
            setUsersRowsArray(rowArray.map(element => { return {...element, billed: element.billed ? 'TRUE': 'False'}}));
            // setRulesObject(ruleObject);
        }
        if (error) {
            console.log('err', error);
        }
    };

    const fetchDataIncidentsBestPractice = async () => {
        const [error, res] = await apiRequest({
            method: "get",
            url: apiRoutes.healthCkeckBestPractice,
            headers: {
                path_name: currentStack
            }
        });
        if (res) {
            setBestPracticeIncidentsView(res.map(element => { return { ...element, count: { value: element.count, color: '#FFD300', message: element.commercial_description } } }));
        }
        if (error) console.log('err', error);
    };

    const fetchDataIncidentsBestPracticeUsers = async () => {
        const [error, res] = await apiRequest({
            method: "get",
            url: apiRoutes.healthCkeckBestPracticeUsers,
            headers: {
                path_name: currentStack
            }
        });
        if (res) {
            setBestPracticeIncidentsViewUsers(res.map(element => { return { ...element, count: { value: element.count, color: '#FFD300', message: element.commercial_description } } }));
        }
        if (error) console.log('err', error);
    };

    const fetchDataIncidentsMetrics = async () => {
        const [errorMetricsCore, resMetricsCore] = await apiRequest({
            method: "get",
            url: apiRoutes.healthCkeckMetricsCore,
            headers: {
                path_name: currentStack
            }
        });
        if (resMetricsCore && resMetricsCore.metrics) {
            let id = null;
            for(let metric of resMetricsCore.metrics){
                if( metric.metric_name === "Services_Incidents"){
                    id = metric.id
                }
            }
            const [error, res] = await apiRequest({
                method: "get",
                url: apiRoutes.healthCkeckMetrics + id + '/False',
                headers: {
                    path_name: currentStack
                }
            });
            if (res) {
                const ArrayToUse = res.response.range_results.sort((a, b) => parseInt(a.current_range.start) - parseInt(b.current_range.start)).filter((element) => !(element.current_range.start === 'null' || element.current_range.start === 'oor'))
                const arrayOfColors = [];
                if (ArrayToUse.length - 3 > 0) {
                    arrayOfColors.push(...getColorRange(6, 172, 56, 255, 211, 0, parseInt(ArrayToUse.length % 2 ? (ArrayToUse.length / 2) + 1 : ArrayToUse.length / 2)))
                    arrayOfColors.push(...getColorRange(255, 211, 0, 255, 0, 0, parseInt(ArrayToUse.length / 2)))
                } else arrayOfColors.push('rgb(6, 172, 56)', 'rgb(255,211,0)', 'rgb(255,0,0)');
                const newRes = { ...res.response.resume, range: ArrayToUse.map((element, index) => { return { description: element.current_range.description, label: `From ${element.current_range.start} to ${element.current_range.end}`, value: { value: element.total_in_range, color: arrayOfColors[index], message: element.current_range.criticality_level } } }) }
                setMetricsIncidentsView(newRes);
            }
            if (error) console.log('err', error);
        }
        if (errorMetricsCore) console.log('err', errorMetricsCore);
    };

    const getColorRange = (R1, G1, B1, R2, G2, B2, NumberOfIntersection) => {
        const ArrayToReturn = [];
        for (let i = 0; i < NumberOfIntersection; i++) {
            const Ri = R1 + (R2 - R1) * i / NumberOfIntersection
            const Bi = B1 + (B2 - B1) * i / NumberOfIntersection
            const Gi = G1 + (G2 - G1) * i / NumberOfIntersection
            ArrayToReturn.push(`rgb(${parseInt(Ri)}, ${parseInt(Gi)}, ${parseInt(Bi)})`);
        }
        return ArrayToReturn
    }

    const getColor = (number) => {
        if (number <= 500) return '#06ac38';
        if (number > 500) return '#FFD300';
        if (number > 1000) return '#FF8C00';
        if (number > 2000) return '#FF0000';
    }

    useEffect(() => {
        fetch_data('services')
        fetchDataIncidentsTop10('services');
        fetch_last_data_loaded();
        fetch_entities();
        fetch_Teams();
        fetchUsersTable();
    }, []);

    useEffect(() => {
        incidentActiveTabLiteral.hasOwnProperty(incidentActiveTab) && incidentActiveTabLiteral[incidentActiveTab]();
    }, [incidentActiveTab]);

    const incidentActiveTabLiteral = {
        'Top10': () => fetchDataIncidentsTop10(),
        'Best Practice': () => fetchDataIncidentsBestPractice(),
        'Metrics': () => fetchDataIncidentsMetrics(),
        'Users Summary': () => fetchDataIncidentsBestPracticeUsers(),
    }

    const elementOnClick = (selectedItem) => {
        // setSelectedItem(selectedItem);
        // setModalOpen(true);
    }

    const onTabChange = (tab, name) => {
        if (rulesObject && rulesObject.length > 0) {
            setRulesObject(rulesObject.map(element => element[0] === name ? [element[0], { ...element[1], activeTab: tab }] : element))
        }
    }


    const toggleElementBool = (field, name) => {
        if (rulesObject && rulesObject.length > 0) {
            setRulesObject(rulesObject.map(element => element[0] === name ? [element[0], { ...element[1], [field]: !element[1][field] }] : element))
        }
    }

    const BulkFix = (element) => {
        setBulkFixSelectedElement([element[0], {
            ...element[1], [element[1].activeTab]: element[1][element[1].activeTab].map(element => {
                return { ...element, description: typeof element.description === 'object' ? { ...element.description, value: formatBulkFixDescription(element.description.value, element) } : formatBulkFixDescription(element.description, element) }
            })
        }]);
        setModalBulkFixOpen(true)
    }

    const formatBulkFixDescription = (text, element) => {
        return text ||
            `[Tech Owner] Bobby Builder
[Business Owner] Sally Smart
[Run Book] ${element.url || ''}
[Github] my_code_repo
[Chat] #channel_name 
[Support Hours] 24x7
[SLA] Platinum`
    }

    const changeElementValue = (field, isObject, value, index) => {
        if (isObject) {
            bulkFixSelectedElement[1][bulkFixSelectedElement[1].activeTab][index][field].value = value
        } else {
            bulkFixSelectedElement[1][bulkFixSelectedElement[1].activeTab][index][field] = value
        }
        setBulkFixSelectedElement([...bulkFixSelectedElement])
    }

    const onSubmitBulkFix = () => {
        console.log(bulkFixSelectedElement[1][bulkFixSelectedElement[1].activeTab])
        setModalBulkFixOpen(false)
    }
    const toggleRuleObject = (object) => {
        object.show = !object.show;
        setForceUpdate(!forceUpdate);
    }

    const createDinamicTables = (element) => {
        return <div className="HealthCheck-bulk-fix-div-container">
            <div className="HealthCheck-bulk-fix-div-row-container">
                <div className="HealthCheck-bulk-fix-div-row-container-Charts" >
                    {element[1].activeTab === 'Action' && <div className="HealthCheck-bulk-fix-div-row-container-Charts-Action">
                        {element[1].ActionChartState && <div style={{ width: '60%', margin: 'auto' }}>
                            <BarChart
                                title='Total Services with Health Check Alerts (Action)'
                                subTitle='Total Services'
                                labels={['Minor', 'Low', 'Medium', 'High']}
                                backgroundColors={['#06ac38', '#FFD300', '#FF8C00', '#FF0000']}
                                values={[element[1].ActionSeverity.minor.value, element[1].ActionSeverity.low.value, element[1].ActionSeverity.medium.value, element[1].ActionSeverity.high.value]}
                                messages={''}
                            />
                        </div>
                        }
                        {!element[1].ActionChartState && <div style={{ width: '20%', margin: 'auto' }}>
                            <DoughnutChart
                                labels={['Minor', 'Low', 'Medium', 'High']}
                                backgroundColors={['#06ac38', '#FFD300', '#FF8C00', '#FF0000']}
                                values={[element[1].ActionSeverity.minor.value, element[1].ActionSeverity.low.value, element[1].ActionSeverity.medium.value, element[1].ActionSeverity.high.value]}
                            />
                        </div>}
                        <div style={{ width: '30%', alignSelf: 'flex-start' }}>
                            <div className="HealthCheck-bulk-fix-div-row-container-Charts-toggle-div">
                                <label className={element[1].ActionChartState ? "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-in" : "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-out"}>Histogram</label>
                                <Toggle state={element[1].ActionChartState} onChangeState={() => { element[1].ActionChartState = !element[1].ActionChartState; setForceUpdate(!forceUpdate) }} />
                                <label className={!element[1].ActionChartState ? "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-in" : "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-out"}>Doughnut</label>
                            </div>
                            {element[1].ActionSeverity.minor.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#06ac38', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].ActionSeverity.minor.message}</p>}
                            {element[1].ActionSeverity.low.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FFD300', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].ActionSeverity.low.message}</p>}
                            {element[1].ActionSeverity.medium.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FF8C00', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].ActionSeverity.medium.message}</p>}
                            {element[1].ActionSeverity.high.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FF0000', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].ActionSeverity.high.message}</p>}
                        </div>

                    </div>}

                    {element[1].activeTab === 'Omission' && <div className="HealthCheck-bulk-fix-div-row-container-Charts-Omission" >
                        {element[1].OmissionChartState && <div style={{ width: '60%', margin: 'auto' }}>
                            <BarChart
                                title='Total Services with Health Check Alerts (Omission)'
                                subTitle='Total Services'
                                labels={['Minor', 'Low', 'Medium', 'High']}
                                backgroundColors={['#06ac38', '#FFD300', '#FF8C00', '#FF0000']}
                                values={[element[1].OmissionSeverity.minor.value, element[1].OmissionSeverity.low.value, element[1].OmissionSeverity.medium.value, element[1].OmissionSeverity.high.value]}
                                messages={null} />
                        </div>
                        }
                        {!element[1].OmissionChartState && <div style={{ width: '20%', margin: 'auto' }}>
                            <DoughnutChart
                                labels={['Minor', 'Low', 'Medium', 'High']}
                                backgroundColors={['#06ac38', '#FFD300', '#FF8C00', '#FF0000']}
                                values={[element[1].OmissionSeverity.minor.value, element[1].OmissionSeverity.low.value, element[1].OmissionSeverity.medium.value, element[1].OmissionSeverity.high.value]}
                            />
                        </div>}
                        <div style={{ width: '30%', alignSelf: 'flex-start' }}>
                            <div className="HealthCheck-bulk-fix-div-row-container-Charts-toggle-div">
                                <label className={element[1].OmissionChartState ? "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-in" : "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-out"}>Histogram</label>
                                <Toggle state={element[1].OmissionChartState} onChangeState={() => { element[1].OmissionChartState = !element[1].OmissionChartState; setForceUpdate(!forceUpdate) }} />
                                <label className={!element[1].OmissionChartState ? "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-in" : "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-out"}>Doughnut</label>
                            </div>
                            {element[1].OmissionSeverity.minor.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#06ac38', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].OmissionSeverity.minor.message}</p>}
                            {element[1].OmissionSeverity.low.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FFD300', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].OmissionSeverity.low.message}</p>}
                            {element[1].OmissionSeverity.medium.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FF8C00', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].OmissionSeverity.medium.message}</p>}
                            {element[1].OmissionSeverity.high.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FF0000', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].OmissionSeverity.high.message}</p>}
                        </div>
                    </div>}
                </div>
                <div className="HealthCheck-bulk-fix-div-row-container-table">
                    <Tabs Tabs={element[1].Action.length > 0 && element[1].Omission.length > 0 ? [{ label: 'Action', enable: true }, { label: 'Omission', enable: true }] : element[1].Action.length > 0 ? [{ label: 'Action', enable: true }] : [{ label: 'Omission', enable: true }]} onTabChange={(tab) => { element[1].activeTab = tab; setForceUpdate(!forceUpdate) }}>
                        {element[1].activeTab === 'Action' && <SortingTable hideCheckbox='true' iSortDescending={false} columnsArray={[
                            { label: nameToTitleLiteralTeams[element[0]](), valueLabel: `${element[0]}`, sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
                            { label: 'Health Check Alert Severity', valueLabel: `${element[0]}Severity`, sortable: false, widthPX: 200, type: 'Color', innerType: 'String' },
                            ...columnsArrayBulkFixTeams,
                        ]} rowsArray={element[1].Action} tableHeight='37vh' showElementsPerPage={10} elementOnClick={(e) => { }} />}
                        {element[1].activeTab === 'Omission' && <SortingTable hideCheckbox='true' iSortDescending={false} columnsArray={[
                            { label: nameToTitleLiteralTeams[element[0]](), valueLabel: `${element[0]}`, sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
                            { label: 'Health Check Alert Severity', valueLabel: `${element[0]}Severity`, sortable: false, widthPX: 200, type: 'Color', innerType: 'String' },
                            ...columnsArrayBulkFixTeams,
                        ]} rowsArray={element[1].Omission} tableHeight='37vh' showElementsPerPage={10} elementOnClick={(e) => { }} />}
                    </Tabs>
                </div>
            </div>
        </div>
    }



    return (
        <>
            {<div id="HealthCheck-container">
                <div id="HealthCheck-title-container">
                    <div id="HealthCheck-title-container-time"><label><b>Last Loaded:</b></label><span>{lastLoadedDate}</span></div>
                    <h4>{(location.state.stack).toUpperCase()}</h4>
                    <div style={{ display: 'flex' }}>
                        <AppIcon type='HealthCheck' />
                        <h2 id="HealthCheck-title">Health Check</h2>
                    </div>
                </div>
                <div id="HealthCheck-table-tabs-container" style={{ height: (activeRow === 'Table' || activeRow === '90DaysBreakdownTable' || activeRow === 'Table Users') ? 'calc( 100% - 116px)' : 'unset' }}>
                    <div id="HealthCheck-button-container">
                        <div id="HealthCheck-title-container-buttons-div">
                            <button className="button-regular" onClick={() => { setActiveRow('Dashboard') }}>Dashboard</button>
                            {currentEntitie === 'Services' && <button className="button-regular" onClick={() => { setActiveRow('Table') }}>Table</button>}
                            {currentEntitie === 'Teams' && <button className="button-regular" onClick={() => { setActiveRow('Details') }}>Details</button>}
                            {currentEntitie === 'Incidents' && <button className="button-regular" onClick={() => { setActiveRow('Details Incidents') }}>Details</button>}
                            {currentEntitie === 'Users' && <button className="button-regular" onClick={() => { setActiveRow('Table Users') }}>Table</button>}
                            <Dropdown options={entitiesArray} onChange={(value) => setCurrentEntitie(value)} value={currentEntitie} />
                        </div>
                        <div>
                            {activeRow === 'Dashboard' && incidentActiveTab === 'Top10' && currentEntitie === 'Services' && <>
                                <a className="buttonstyle-regular-link" href="https://backend-test.innovation.csg.pagerduty.com/ms-optimizer/health-check/reports/" download>Download Health Check Report</a>
                            </>}
                            {activeRow === 'Details' && currentEntitie === 'Teams' && <>
                                <MultiSelect
                                    options={teamsArray}
                                    onChange={(e) => handleChangeMultiSelect(e)}
                                    valueToDisplay={'value'}
                                    allowOnlyOne={false}
                                    defaultValues={currentTeam}
                                />
                            </>}
                        </div>
                    </div>
                    {activeRow === 'Table' && <div style={{ height: 'calc(100% - 55px)' }}>
                        <SortingTable columnsArray={columnsArray} rowsArray={rowsArray} hideCheckbox={true} tableHeight='calc(100% -2px)' showElementsPerPage={25} elementOnClick={(e) => elementOnClick(e)} />
                    </div>}
                    {activeRow === 'Details Incidents' && <div style={{ height: 'calc(100% - 55px)' }}>
                        <div id="HC-Incidents-container">
                            <div id="HC-Incidents-container-left">
                                <div className={'HC-Incidents-container-right-menu-titles'}>Details</div>
                                <div onClick={() => setDetailsIncidentsActiveTab('90DaysBreakdownTable')} className={detailsIncidentsActiveTab === '90DaysBreakdownTable' ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Best Practice: Last 90 Days Incident Analysis</div>
                                <div onClick={() => setDetailsIncidentsActiveTab('TransientIncidents')} className={detailsIncidentsActiveTab === 'TransientIncidents' ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Best Practice: Last 90 Days Transient Analysis</div>
                                <div onClick={() => setDetailsIncidentsActiveTab('TransientIncidentsBreakdown')} className={detailsIncidentsActiveTab === 'TransientIncidentsBreakdown' ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Best Practice: Service Incident Activity by Integration</div>
                            </div>
                            <div id="HC-Incidents-container-right">
                                {detailsIncidentsActiveTab === '90DaysBreakdownTable' && <div style={{ height: 'calc(100% - 55px)' }}>
                                    <SortingTable columnsArray={columnsArray90DaysBreakdown} rowsArray={rowsArray} hideCheckbox={true} tableHeight='calc(100% -2px)' showElementsPerPage={25} iSortDescending={false} />
                                </div>}
                                {detailsIncidentsActiveTab === 'TransientIncidents' && <div style={{ height: 'calc(100% - 55px)' }}>
                                    <SortingTable columnsArray={columnsArrayTransientIncidents} rowsArray={rowsArray} hideCheckbox={true} tableHeight='calc(100% -2px)' showElementsPerPage={25} />
                                </div>}
                                {detailsIncidentsActiveTab === 'TransientIncidentsBreakdown' && <div style={{ height: 'calc(100% - 55px)' }}>
                                    <SortingTable columnsArray={columnsArrayTransientIncidentsBreakdown} rowsArray={rowsArray} hideCheckbox={true} tableHeight='calc(100% -2px)' showElementsPerPage={25} />
                                </div>}
                            </div>
                        </div>
                    </div>}
                    {activeRow === 'Details' && <div style={{ height: 'calc(100% - 55px)' }}>
                        <div id="HC-Incidents-container">
                            <div id="HC-Incidents-container-left">
                                <div className={'HC-Incidents-container-right-menu-titles'}>Details</div>
                                <div className="HC-Incidents-container-right-menu-titles-inner">
                                    {teamsInfo.map(team => (
                                        <>
                                            <div className={'HC-Incidents-container-right-menu-subtitles'}>{team.team_name}</div>
                                            {team.escalation_policies.length > 0 && <div onClick={() => setDetailsActiveTab('Escalation Policies' + team.team_id)} className={detailsActiveTab === 'Escalation Policies' + team.team_id ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Escalation Policies</div>}
                                            {team.schedules.length > 0 && <div onClick={() => setDetailsActiveTab('Schedules' + team.team_id)} className={detailsActiveTab === 'Schedules' + team.team_id ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Schedules</div>}
                                            {team.services.length > 0 && <div onClick={() => setDetailsActiveTab('Services' + team.team_id)} className={detailsActiveTab === 'Services' + team.team_id ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Services</div>}
                                            {team.team_members.length > 0 && <div onClick={() => setDetailsActiveTab('Team Members' + team.team_id)} className={detailsActiveTab === 'Team Members' + team.team_id ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Team Members</div>}
                                            {team.escalation_policies.length > 0 && team.escalation_policies.filter(team => team.rules.length > 0).length > 0 && <div className={'HC-Incidents-container-right-menu-subtitles-chevron'}><span className="HC-Incidents-container-right-menu-subtitles-chevron-text">{`${team.team_name} Escalation Policies Details`}</span><span className="HC-Incidents-container-right-menu-chevron" onClick={() => toggleRuleObject(team.escalation_policies)}><FontAwesomeIcon icon={team.escalation_policies.show ? faChevronCircleUp : faChevronCircleDown} /></span></div>}
                                            {team.escalation_policies.length > 0 && team.escalation_policies.show && team.escalation_policies.ruleObject.map(rule => <div onClick={() => setDetailsActiveTab(`${rule[0]}${team.team_id}escalation_policies`)} className={detailsActiveTab === `${rule[0]}${team.team_id}escalation_policies` ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>{`${nameToTitleLiteralTeams[rule[0]]()}`}</div>)}
                                            {team.schedules.length > 0 && team.schedules.filter(team => team.rules.length > 0).length > 0 && <div className={'HC-Incidents-container-right-menu-subtitles-chevron'}><span className="HC-Incidents-container-right-menu-subtitles-chevron-text">{`${team.team_name} Schedules Details`}</span><span className="HC-Incidents-container-right-menu-chevron" onClick={() => toggleRuleObject(team.schedules)}><FontAwesomeIcon icon={team.schedules.show ? faChevronCircleUp : faChevronCircleDown} /></span></div>}
                                            {team.schedules.length > 0 && team.schedules.show && team.schedules.ruleObject.map(rule => <div onClick={() => setDetailsActiveTab(`${rule[0]}${team.team_id}schedules`)} className={detailsActiveTab === `${rule[0]}${team.team_id}schedules` ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>{`${nameToTitleLiteralTeams[rule[0]]()}`}</div>)}
                                            {team.services.length > 0 && team.services.filter(team => team.rules.length > 0).length > 0 && <div className={'HC-Incidents-container-right-menu-subtitles-chevron'}><span className="HC-Incidents-container-right-menu-subtitles-chevron-text">{`${team.team_name} Services Details`}</span><span className="HC-Incidents-container-right-menu-chevron" onClick={() => toggleRuleObject(team.services)}><FontAwesomeIcon icon={team.services.show ? faChevronCircleUp : faChevronCircleDown} /></span></div>}
                                            {team.services.length > 0 && team.services.show && team.services.ruleObject.map(rule => <div onClick={() => setDetailsActiveTab(`${rule[0]}${team.team_id}services`)} className={detailsActiveTab === `${rule[0]}${team.team_id}services` ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>{`${nameToTitleLiteralTeams[rule[0]]()}`}</div>)}
                                            {team.team_members.length > 0 && team.team_members.filter(team => team.rules.length > 0).length > 0 && <div className={'HC-Incidents-container-right-menu-subtitles-chevron'}><span className="HC-Incidents-container-right-menu-subtitles-chevron-text">{`${team.team_name} Team Members Details`}</span><span className="HC-Incidents-container-right-menu-chevron" onClick={() => toggleRuleObject(team.team_members)}><FontAwesomeIcon icon={team.team_members.show ? faChevronCircleUp : faChevronCircleDown} /></span></div>}
                                            {team.team_members.length > 0 && team.team_members.show && team.team_members.ruleObject.map(rule => <div onClick={() => setDetailsActiveTab(`${rule[0]}${team.team_id}team_members`)} className={detailsActiveTab === `${rule[0]}${team.team_id}team_members` ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>{`${nameToTitleLiteralTeams[rule[0]]()}`}</div>)}
                                        </>
                                    ))}

                                </div>
                            </div>
                            <div id="HC-Incidents-container-right">
                                {teamsInfo.map(team => (
                                    <>
                                        {detailsActiveTab === ('Escalation Policies' + team.team_id) && team.escalation_policies.length > 0 && <div style={{ height: 'calc(100% - 55px)' }}>
                                            <SortingTable columnsArray={EscalationPoliciesColumnsArray} rowsArray={team.escalation_policies} hideCheckbox={true} tableHeight='calc(100% -2px)' showElementsPerPage={25} />
                                        </div>}
                                        {detailsActiveTab === ('Schedules' + team.team_id) && team.schedules.length > 0 && <div style={{ height: 'calc(100% - 55px)' }}>
                                            <SortingTable columnsArray={SchedulesColumnsArray} rowsArray={team.schedules} hideCheckbox={true} tableHeight='calc(100% -2px)' showElementsPerPage={25} />
                                        </div>}
                                        {detailsActiveTab === ('Services' + team.team_id) && team.services.length > 0 && <div style={{ height: 'calc(100% - 55px)' }}>
                                            <SortingTable columnsArray={ServicesColumnsArray} rowsArray={team.services} hideCheckbox={true} tableHeight='calc(100% -2px)' showElementsPerPage={25} />
                                        </div>}
                                        {detailsActiveTab === ('Team Members' + team.team_id) && team.team_members.length > 0 && <div style={{ height: 'calc(100% - 55px)' }}>
                                            <SortingTable columnsArray={teamMembersColumnsArray} rowsArray={team.team_members} hideCheckbox={true} tableHeight='calc(100% -2px)' showElementsPerPage={25} />
                                        </div>}
                                        {team.escalation_policies.length > 0 && team.escalation_policies.ruleObject.map(rule =>
                                            detailsActiveTab === (`${rule[0]}${team.team_id}escalation_policies`) ? createDinamicTables(rule) : null)}
                                        {team.schedules.length > 0 && team.schedules.ruleObject.map(rule =>
                                            detailsActiveTab === (`${rule[0]}${team.team_id}schedules`) ? createDinamicTables(rule) : null)}
                                        {team.services.length > 0 && team.services.ruleObject.map(rule =>
                                            detailsActiveTab === (`${rule[0]}${team.team_id}services`) ? createDinamicTables(rule) : null)}
                                        {team.team_members.length > 0 && team.team_members.ruleObject.map(rule =>
                                            detailsActiveTab === (`${rule[0]}${team.team_id}team_members`) ? createDinamicTables(rule) : null)}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>}
                    {activeRow === 'Table Users' && <div style={{ height: 'calc(100% - 55px)' }}>
                        <SortingTable columnsArray={UsersColumnsArray} rowsArray={usersRowsArray} hideCheckbox={true} tableHeight='calc(100% -2px)' showElementsPerPage={25} elementOnClick={(e) => elementOnClick(e)} />
                    </div>}
                    {activeRow === 'Dashboard' && <div id="HC-Incidents-container">
                        <div id="HC-Incidents-container-left">
                            <div className={'HC-Incidents-container-right-menu-titles'}>Best Practice Dashboards</div>
                            <div onClick={() => setIncidentActiveTab('Top10')} className={incidentActiveTab === 'Top10' ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Top 10 Service Incident Activity</div>
                            <div onClick={() => setIncidentActiveTab('Best Practice')} className={incidentActiveTab === 'Best Practice' ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Services Best Practice Summary</div>
                            <div onClick={() => setIncidentActiveTab('Metrics')} className={incidentActiveTab === 'Metrics' ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Best Practice: Incident Activity</div>
                            <div onClick={() => setIncidentActiveTab('Users Summary')} className={incidentActiveTab === 'Users Summary' ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>Users Summary</div>
                            <div className={'HC-Incidents-container-right-menu-titles'} style={{ boxShadow: '0px -3px 3px rgb(120 120 120 / 39%)' }}>Best Practice Analysis</div>
                            {rulesObject && rulesObject.length > 0 && rulesObject.map((element) => (
                                <div onClick={() => setIncidentActiveTab('BulkFix' + nameToTitleLiteralDashboard[element[0]]())} className={incidentActiveTab === 'BulkFix' + nameToTitleLiteralDashboard[element[0]]() ? 'HC-Incidents-container-right-menu-active' : 'HC-Incidents-container-right-menu'}>{nameToTitleLiteralDashboard[element[0]]()}</div>
                            ))}
                        </div>
                        <div id="HC-Incidents-container-right">
                            {incidentActiveTab === 'Top10' && topTenIncidentsView.length > 1 && <div style={{ height: '100%' }}>
                                <div id="HC-Incidents-container-right-top10-lineBars">
                                    <BarChart
                                        title='Top 10 Services with Incidents (Last 90 Days)'
                                        subTitle='Total Incidents'
                                        labels={topTenIncidentsView.map(element => element.service_name)}
                                        backgroundColors={topTenIncidentsView.map(element => element.total_incidents_90.color)}
                                        values={topTenIncidentsView.map(element => element.total_incidents_90.value)}
                                        messages={''}
                                    />
                                </div>
                                <div id="HC-Incidents-container-right-top10-table">
                                    <SortingTable tableName='TOP10Table' columnsArray={[
                                        { label: 'Total incidents Last 90 days', valueLabel: 'total_incidents_90', sortable: true, widthPX: 500, type: 'Color', innerType: 'Number' },
                                        { label: 'Service Name', valueLabel: 'service_name', sortable: true, widthPX: 500, type: 'String', innerType: 'String' },
                                    ]} rowsArray={topTenIncidentsView} iSortDescending={false} hideCheckbox={true} tableHeight='100%' showElementsPerPage={10} elementOnClick={(e) => { }} />
                                </div>
                            </div>}
                            {incidentActiveTab === 'Best Practice' && <div style={{ height: '100%' }}>
                                <div style={{ height: 'calc(100% - 70px)' }}>
                                    <SortingTable columnsArray={[
                                        { label: 'Count', valueLabel: 'count', sortable: true, widthPX: 500, type: 'Color', innerType: 'Number' },
                                        { label: 'Best Practice Metric', valueLabel: 'description', sortable: true, widthPX: 500, type: 'String', innerType: 'String' },
                                    ]} rowsArray={bestPracticeIncidentsView} iSortDescending={false} hideCheckbox={true} tableHeight='100%' showElementsPerPage={10} elementOnClick={(e) => { }} />
                                </div>
                            </div>}
                            {incidentActiveTab === 'Metrics' && metricsIncidentsView.range && metricsIncidentsView.range.length > 0 && <div style={{ height: '100%' }}>
                                <div id="HC-Incidents-container-right-metrics-pie-container">
                                    <div className="HC-Incidents-container-right-metrics-pie">
                                        <DoughnutChart
                                            labels={['Total', 'Not Evaluated']}
                                            backgroundColors={['#e6e3e3', '#FFD300']}
                                            values={[metricsIncidentsView.total_universe, metricsIncidentsView.total_out_of_ranges]}
                                        />
                                    </div>
                                    <div className="HC-Incidents-container-right-metrics-pie">
                                        <DoughnutChart
                                            labels={['Total', 'Not Incident Activity']}
                                            backgroundColors={['#e6e3e3', '#06ac38',]}
                                            values={[metricsIncidentsView.total_universe, metricsIncidentsView.total_null_values]}
                                        />
                                    </div>
                                </div>
                                <div style={{ height: 'calc(100% - 300px)' }}>
                                    <SortingTable columnsArray={[
                                        { label: 'Services', valueLabel: 'value', sortable: false, widthPX: 500, type: 'Color', innerType: 'Number' },
                                        { label: 'Incident Activity Range', valueLabel: 'description', sortable: false, widthPX: 500, type: 'String', innerType: 'String' },
                                    ]} rowsArray={metricsIncidentsView.range} iSortDescending={false} hideCheckbox={true} tableHeight='100%' showElementsPerPage={10} elementOnClick={(e) => { }} />
                                </div>
                            </div>}
                            {incidentActiveTab === 'Users Summary' && <div style={{ height: '100%' }}>
                                <div style={{ height: 'calc(100% - 70px)' }}>
                                    <SortingTable columnsArray={[
                                        { label: 'Count', valueLabel: 'count', sortable: true, widthPX: 500, type: 'Color', innerType: 'Number' },
                                        { label: 'Users Summary Metric', valueLabel: 'description', sortable: true, widthPX: 500, type: 'String', innerType: 'String' },
                                    ]} rowsArray={bestPracticeIncidentsViewUsers} iSortDescending={false} hideCheckbox={true} tableHeight='100%' showElementsPerPage={10} elementOnClick={(e) => { }} />
                                </div>
                            </div>}
                            {rulesObject && rulesObject.length > 0 && rulesObject.map((element) => (
                                incidentActiveTab === 'BulkFix' + nameToTitleLiteralDashboard[element[0]]() && <div className="HealthCheck-bulk-fix-div-container">
                                    {/* <div className="HealthCheck-bulk-fix-div-container-title_buttons">
                                    <button className="button-regular" style={{ backgroundColor: 'rgb(255, 0, 0)' }} onClick={() => BulkFix(element)}>Bulk Fix</button>
                                </div> */}
                                    <div className="HealthCheck-bulk-fix-div-row-container">
                                        <div className="HealthCheck-bulk-fix-div-row-container-Charts" >
                                            {element[1].activeTab === 'Action' && <div className="HealthCheck-bulk-fix-div-row-container-Charts-Action">
                                                {element[1].ActionChartState && <div style={{ width: '60%', margin: 'auto' }}>
                                                    <BarChart
                                                        title='Total Services with Health Check Alerts (Action)'
                                                        subTitle='Total Services'
                                                        labels={['Minor', 'Low', 'Medium', 'High']}
                                                        backgroundColors={['#06ac38', '#FFD300', '#FF8C00', '#FF0000']}
                                                        values={[element[1].ActionSeverity.minor.value, element[1].ActionSeverity.low.value, element[1].ActionSeverity.medium.value, element[1].ActionSeverity.high.value]}
                                                        messages={''}
                                                    // messages={[element[1].ActionSeverity.minor.message, element[1].ActionSeverity.low.message, element[1].ActionSeverity.medium.message, element[1].ActionSeverity.high.message]}
                                                    />
                                                </div>
                                                }
                                                {!element[1].ActionChartState && <div style={{ width: '20%', margin: 'auto' }}>
                                                    <DoughnutChart
                                                        labels={['Minor', 'Low', 'Medium', 'High']}
                                                        backgroundColors={['#06ac38', '#FFD300', '#FF8C00', '#FF0000']}
                                                        values={[element[1].ActionSeverity.minor.value, element[1].ActionSeverity.low.value, element[1].ActionSeverity.medium.value, element[1].ActionSeverity.high.value]}
                                                    />
                                                </div>}
                                                <div style={{ width: '30%', alignSelf: 'flex-start' }}>
                                                    <div className="HealthCheck-bulk-fix-div-row-container-Charts-toggle-div">
                                                        <label className={element[1].ActionChartState ? "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-in" : "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-out"}>Histogram</label>
                                                        <Toggle state={element[1].ActionChartState} onChangeState={() => toggleElementBool('ActionChartState', element[0])} />
                                                        <label className={!element[1].ActionChartState ? "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-in" : "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-out"}>Doughnut</label>
                                                    </div>
                                                    {element[1].ActionSeverity.minor.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#06ac38', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].ActionSeverity.minor.message}</p>}
                                                    {element[1].ActionSeverity.low.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FFD300', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].ActionSeverity.low.message}</p>}
                                                    {element[1].ActionSeverity.medium.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FF8C00', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].ActionSeverity.medium.message}</p>}
                                                    {element[1].ActionSeverity.high.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FF0000', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].ActionSeverity.high.message}</p>}
                                                </div>

                                            </div>}

                                            {element[1].activeTab === 'Omission' && <div className="HealthCheck-bulk-fix-div-row-container-Charts-Omission" >
                                                {element[1].OmissionChartState && <div style={{ width: '60%', margin: 'auto' }}>
                                                    <BarChart
                                                        title='Total Services with Health Check Alerts (Omission)'
                                                        subTitle='Total Services'
                                                        labels={['Minor', 'Low', 'Medium', 'High']}
                                                        backgroundColors={['#06ac38', '#FFD300', '#FF8C00', '#FF0000']}
                                                        values={[element[1].OmissionSeverity.minor.value, element[1].OmissionSeverity.low.value, element[1].OmissionSeverity.medium.value, element[1].OmissionSeverity.high.value]}
                                                        messages={null}
                                                    // messages={[element[1].OmissionSeverity.minor.message, element[1].OmissionSeverity.low.message, element[1].OmissionSeverity.medium.message, element[1].OmissionSeverity.high.message]}
                                                    />
                                                </div>
                                                }
                                                {!element[1].OmissionChartState && <div style={{ width: '20%', margin: 'auto' }}>
                                                    <DoughnutChart
                                                        labels={['Minor', 'Low', 'Medium', 'High']}
                                                        backgroundColors={['#06ac38', '#FFD300', '#FF8C00', '#FF0000']}
                                                        values={[element[1].OmissionSeverity.minor.value, element[1].OmissionSeverity.low.value, element[1].OmissionSeverity.medium.value, element[1].OmissionSeverity.high.value]}
                                                    />
                                                </div>}
                                                <div style={{ width: '30%', alignSelf: 'flex-start' }}>
                                                    <div className="HealthCheck-bulk-fix-div-row-container-Charts-toggle-div">
                                                        <label className={element[1].OmissionChartState ? "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-in" : "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-out"}>Histogram</label>
                                                        <Toggle state={element[1].OmissionChartState} onChangeState={() => toggleElementBool('OmissionChartState', element[0])} />
                                                        <label className={!element[1].OmissionChartState ? "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-in" : "HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-out"}>Doughnut</label>
                                                    </div>
                                                    {element[1].OmissionSeverity.minor.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#06ac38', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].OmissionSeverity.minor.message}</p>}
                                                    {element[1].OmissionSeverity.low.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FFD300', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].OmissionSeverity.low.message}</p>}
                                                    {element[1].OmissionSeverity.medium.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FF8C00', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].OmissionSeverity.medium.message}</p>}
                                                    {element[1].OmissionSeverity.high.message && <p className="HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text"><span style={{ color: '#FF0000', marginRight: '5px' }}><FontAwesomeIcon icon={faDotCircle} /></span>{element[1].OmissionSeverity.high.message}</p>}
                                                </div>
                                            </div>}
                                        </div>
                                        <div className="HealthCheck-bulk-fix-div-row-container-table">
                                            <Tabs Tabs={element[1].Action.length > 0 && element[1].Omission.length > 0 ? [{ label: 'Action', enable: true }, { label: 'Omission', enable: true }] : element[1].Action.length > 0 ? [{ label: 'Action', enable: true }] : [{ label: 'Omission', enable: true }]} onTabChange={(tab) => { onTabChange(tab, element[0]) }}>
                                                {element[1].activeTab === 'Action' && <SortingTable hideCheckbox='true' iSortDescending={false} columnsArray={[
                                                    { label: nameToTitleLiteral[element[0]](), valueLabel: `${element[0]}`, sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
                                                    { label: 'Health Check Alert Severity', valueLabel: `${element[0]}Severity`, sortable: false, widthPX: 200, type: 'Color', innerType: 'String' },
                                                    ...columnsArrayBulkFix,
                                                ]} rowsArray={element[1].Action} tableHeight='37vh' showElementsPerPage={10} elementOnClick={(e) => { }} />}
                                                {element[1].activeTab === 'Omission' && <SortingTable hideCheckbox='true' iSortDescending={false} columnsArray={[
                                                    { label: nameToTitleLiteral[element[0]](), valueLabel: `${element[0]}`, sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
                                                    { label: 'Health Check Alert Severity', valueLabel: `${element[0]}Severity`, sortable: false, widthPX: 200, type: 'Color', innerType: 'String' },
                                                    ...columnsArrayBulkFix,
                                                ]} rowsArray={element[1].Omission} tableHeight='37vh' showElementsPerPage={10} elementOnClick={(e) => { }} />}
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    }
                </div>
            </div>}
            <Modal show={modalOpen} title={'Service Information'} handleClose={() => setModalOpen(false)}>
                {selectedItem?.service_name && <div id='HealthCheck-form'>
                    <div className="HealthCheck-form-column">
                        <div className="HealthCheck-form-row"><label>Service Name:</label><input disabled value={selectedItem.service_name} className="HealthCheck-form-input" />
                            <div className="HealthCheck-form-row"><label>Service Id:</label><input disabled value={selectedItem.service_id} className="HealthCheck-form-input" /></div>
                            <div className="HealthCheck-form-row"><label>Description:</label><input disabled value={selectedItem.description.value} className="HealthCheck-form-input" />
                                {!selectedItem.description.acomplished && selectedItem.description.message && <div className="HealthCheck-form-warning-div"><label className="HealthCheck-form-warning-label" style={{ color: selectedItem.description.color }}><FontAwesomeIcon icon={faExclamationTriangle} />{' '}{selectedItem.description.message}</label></div>}</div>
                            <div className="HealthCheck-form-row"><label>Escalation Policy:</label><input disabled value={selectedItem.escalation_policy} className="HealthCheck-form-input" /></div>
                            <div className="HealthCheck-form-row"><label>Team:</label><input disabled value={selectedItem.team.value} className="HealthCheck-form-input" />
                                {!selectedItem.team.acomplished && selectedItem.team.message && <div className="HealthCheck-form-warning-div"><label className="HealthCheck-form-warning-label" style={{ color: selectedItem.team.color }}><FontAwesomeIcon icon={faExclamationTriangle} />{' '}{selectedItem.team.message}</label></div>}</div>
                            <div className="HealthCheck-form-row"><label>Total incidents Last 90 days:</label><input disabled value={selectedItem.total_incidents_90.value} className="HealthCheck-form-input" /></div>
                            {!selectedItem.total_incidents_90.acomplished && selectedItem.total_incidents_90.message && <div className="HealthCheck-form-warning-div"><label className="HealthCheck-form-warning-label" style={{ color: selectedItem.total_incidents_90.color }}><FontAwesomeIcon icon={faExclamationTriangle} />{' '}{selectedItem.total_incidents_90.message}</label></div>}</div>
                        <div className="HealthCheck-form-row"><label>Last incident:</label><input disabled value={selectedItem.last_incident} className="HealthCheck-form-input" /></div>
                        <div className="HealthCheck-form-row"><label>Created at:</label><input disabled value={selectedItem.created_at} className="HealthCheck-form-input" /></div>
                    </div>
                    <div className="HealthCheck-form-column">
                        <div className="HealthCheck-form-row"><label>Status:</label><input disabled value={selectedItem.status} className="HealthCheck-form-input" /></div>
                        <div className="HealthCheck-form-row"><label>Create incident:</label><input disabled value={selectedItem.create_incident} className="HealthCheck-form-input" /></div>
                        <div className="HealthCheck-form-row"><label>Alert grouping:</label><input disabled value={selectedItem.alert_grouping} className="HealthCheck-form-input" /></div>
                        <div className="HealthCheck-form-row"><label>Incident urgency:</label><input disabled value={selectedItem.incident_urgency} className="HealthCheck-form-input" /></div>
                        <div className="HealthCheck-form-row"><label>Auto resolve timeout:</label><input disabled value={selectedItem.auto_resolve_timeout} className="HealthCheck-form-input" /></div>
                        <div className="HealthCheck-form-row"><label>Response play:</label><input disabled value={selectedItem.response_play} className="HealthCheck-form-input" /></div>
                        <div className="HealthCheck-form-row"><label>Service URL:</label><input disabled value={selectedItem.url} className="HealthCheck-form-input" /></div>
                    </div>
                </div>}
            </Modal>
            {bulkFixSelectedElement[1] && <Modal show={modalBulkFixOpen} title={'Bulk Fix ' + nameToTitleLiteral[bulkFixSelectedElement[0]]()} handleClose={() => setModalBulkFixOpen(false)}>
                <div id="HealthCheck-bulk-fix-div-top">
                    {bulkFixSelectedElement[1][bulkFixSelectedElement[1].activeTab].map((element, index) => (<div>
                        {typeof element[bulkFixSelectedElement[0]] === 'object' ?
                            <div className="HealthCheck-bulk-fix-div-modal">{element.service_name}
                                {bulkFixSelectedElement[0] === 'description' && <textarea className="HealthCheck-bulk-fix-div-textarea-modal" value={element[bulkFixSelectedElement[0]].value} onChange={(newValue) => { changeElementValue(bulkFixSelectedElement[0], true, newValue.target.value, index) }} />}
                            </div> :
                            <div className="HealthCheck-bulk-fix-div-modal">{element.service_name}
                                {bulkFixSelectedElement[0] === 'description' && <textarea className="HealthCheck-bulk-fix-div-textarea-modal" value={element[bulkFixSelectedElement[0]]} onChange={(newValue) => { changeElementValue(bulkFixSelectedElement[0], false, newValue.target.value, index) }} />}
                            </div>
                        }
                    </div>))}
                </div>
                <div id="HealthCheck-bulk-fix-div-bottom">
                    <button className="button-regular" onClick={() => onSubmitBulkFix()}>Submit</button>
                </div>
            </Modal>}
            <Modal modalFitContent={true} dontShowCloseButton={true} show={startModalOpen} title={''} handleClose={() => setStartModalOpen(false)}>
                <div id="HealthCheck-startmodal-div">
                    <h2>Please Enter the following information</h2>
                    <label>API Key:</label>
                    <input value={apiKey} onChange={(e) => setAPIKey(e.target.value)} className="HealthCheck-form-input" />
                    <div id="HealthCheck-startmodal-div-buttons">
                        <button disabled={!apiKey} className="button-regular" onClick={() => setStartModalOpen(false)}>START</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
