import styled, { keyframes } from "styled-components";

const appearIntoView = keyframes`
    0% { width: 60px; } 
  100% { left: 200px; }
`;

export const NavbarContainer = styled.div`
  background-color: #06ac38;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  color: white;
`;

export const LeftDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 26px;
`;

export const RightDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
`;

export const MenuRegularIcon = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 20px;
`;

export const MenuIconBell = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const MenuIconBellNotification = styled.div`
  position: absolute;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15px;
  height: 15px;
  width: fit-content;
  border-radius: 50%;
  top: 10px;
  right: 11px;
  font-size: 10px;
  display: flex;
  justify-content: center;
`;

export const SearchDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
`;

export const SearchDivContainer = styled.div`
  width: 100%;
`;

export const RowSearchResults = styled.div`
  width: calc(100% - 22px);
  border: 1px solid #80808026;
  background-color: white;
  color: black;
  z-index: 10;
  position: relative;
  top: 4px;
  border-radius: 5px;
  padding: 10px;
`;

export const OptionSearch = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid #80808014;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export const SearchDivIcon = styled.button`
  position: absolute;
  right: 17px;
  color: white;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  height: 40px;
  align-self: center;
  display: flex;
  border-radius: 30px;
  outline: none;
  border: none;
  background-color: #ffffff4d;
  color: white;
  padding: 0px 50px 0px 20px;
  width: 99%;
  &::placeholder {
    color: white;
  }
  &::-ms-input-placeholder {
    color: white;
  }
`;

export const SearchTitleRow = styled.div`
  font-weight: bold;
  padding: 15px 0px 4px 10px;
`;

export const FeedbackIcon = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const FeedbackSpan = styled.span`
  font-weight: bold;
  color: rgb(6, 172, 56);
`;

export const FeedbackButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px 15px;
  border: 1px solid gray;
  border-radius: 5px;
  font-weight: bold;
  width: 105px;

  &:hover {
    color: rgb(6, 172, 56);
    border: 1px solid rgb(6, 172, 56);
  }
`;

export const FeedbackTextareaDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: calc(100% - 40px);

  &: textarea {
    margin: 20px;
    width: calc(100% - 40px);
    border: 1px solid #808080b5;
    border-radius: 5px;
    padding: 20px;
  }
`;

export const FeedbackSubmitButton = styled.button`
  background-color: rgb(6, 172, 56);
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  outline: none;
  margin-right: 15px;
  border: 1px solid rgb(6, 172, 56);

  &:disabled {
    background-color: #e7e7e7 !important;
    border: 1px solid #e1e1e1;
  }
`;

export const FeedbackCloseButton = styled.button`
  background-color: #ffffff;
  padding: 10px 20px;
  color: rgb(6, 172, 56);
  border-radius: 5px;
  outline: none;
  margin-right: 15px;
  border: 1px solid #afafaf;

  &:disabled {
    background-color: #e7e7e7 !important;
    border: 1px solid #e1e1e1;
  }
`;

export const FeedbackDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  color: black;
  gap: 15px;
`;
