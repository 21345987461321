import React, { useState, useEffect, useContext } from "react";
import Joyride from 'react-joyride';
import "./DeckGenerator.css";
import Modal from "../../components/Modal";
import { apiRequest, apiRoutes, API_BASE_URL } from "./../../services";
import ErrorHandler from "../Error/ErrorHandler/ErrorHandler";
import MultiSelect from "../../components/MultiSelect";
import SkeletonDeckGenerator from "../../components/Skeletons/DeckGenerator/SkeletonDeckGenerator";
import { notificationSubject } from "../../services/subjects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Questions from "../../components/Questions";
import Jwt from "../../utils/jwt";
import ThankYou from "../../components/ThankYou";
import TerribleFace from "../../assets/SVGs/Faces/terribleFace";
import BadFace from "../../assets/SVGs/Faces/badFace";
import OkayFace from "../../assets/SVGs/Faces/okayFace";
import GoodFace from "../../assets/SVGs/Faces/goodFace";
import { NotificationContext } from './../../NotificationContext';
import AmazingFace from "../../assets/SVGs/Faces/amazingFace";
const io = require('socket.io-client');

export default function DeckGenerator() {
  const pathname = window.location.pathname;
  const [token] = useState(Jwt.getItem("all"));
  const [disableForm, setDisableForm] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [errorHandler, setErrorHandler] = useState(null);
  const [cronJobTime, setLastCronJobTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [FAQModalOpen, setFAQModalOpen] = useState(false);
  const [email, setEmail] = useState(token.sub);
  const [accountId, setAccountId] = useState(null);
  const [value, setValue] = useState(0);
  const [deckAccounts, setDeckAccounts] = useState([]);
  const [accounts, setAccounts] = useState(null);
  const [emailArray, setEmailArray] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [formFields, setFormFields] = useState([]);
  const [typeSelectedLabel, setTypeSelectedLabel] = useState("Standard");
  const [typeSelected, setTypeSelected] = useState("standard");
  const [error, setError] = useState('');
  const [ccError, setCCError] = useState('');
  const [feedbackRating, setFeedbackRating] = useState(null);
  const [feedbackDescription, setFeedbackDescription] = useState("");
  const [thankYouState, setThankYouState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [input, setInput] = useState('');


  const socket = io(API_BASE_URL, {
    path: "/ms-nextgen/socket.io", // path
    pingInterval: 120000, // how often to ping/pong.
    pingTimeout: 20000 // time after which the connection is considered timed-out.
   });

   const { addNotification, updateNotification, updateFinishNotification } = useContext(NotificationContext);

   useEffect(() => {
   
 
     socket.on('start', (data) => {
       const numberStr = data.start.split("_")[1];
       const dataKey = parseInt(numberStr, 10);
       console.log(data,'start')
       addNotification({ id: dataKey, message: data.message, progress: 0,type:'info' });
     });
 
     socket.on('progress', (data) => {
      const progressDataKey = Object.keys(data).find(key => key.startsWith('progress_'));
      if (progressDataKey) {
        const id = parseInt(progressDataKey.split('_')[1], 10); 
        updateNotification(id,data[progressDataKey]);
      }
    });

    socket.on('finish', (data) => {
      // Extract account ID from data
      const numberStr = Object.keys(data).find(key => key.startsWith('finish_'));
      const id = parseInt(numberStr.split('_')[1], 10);  // Replace with actual property name for the account ID
      updateFinishNotification(id,data.message);
    });
 
     socket.on('disconnect', () => {
       console.log('Disconnected from the server');
     });
 

   }, []);
 
  const steps = [
    {
      target: '#Deck-generator-title-container',
      content: "Back by popular demand - the automated Quarterly Business Reviews! This deck generator produces detailed decks from Google Slides, complete with in-depth analytical insights about your customers. Your feedback will drive future iterations. Now that the deck generator runs on scalable, flexible infrastructure, we can more quickly update and refine the content. Please share your insights using the feedback button located in the top-right corner. We are actively investigating changes to metrics and deck content - stay tuned for future enhancements!"
    },
    {
      target: '#email',
      content: 'This auto-populated email is associated with the portal login. It is non-editable to ensure accuracy',
    },
    {
      target:"#type",
      content: 'Designate your preferred presentation format here. Currently, it’s only “Quarterly Business Review”. We will continuously explore expanding our offerings based on business need.',
    },
    {
      target:"#modelType",
      content: 'Choose “Standard”, “Conservative”, or “Very Conservative.” The ROI will adjust by varying the number of incidents avoided.',
    },
    {
      target:"#accountName",
      content: 'Use this to pinpoint the precise account name, enabling the system to efficiently locate and generate the requisite decks.',
    },
    {
      target:"#cc",
      content: <><label>Can add more emails separated by a comma.</label><button  className="deck-tour-button" onClick={() => closeTour4Ever()}>Close and don´t show again</button></>,
    
    }
  ]

  const handleKeyDown = (event) => {
    // Regular expression for letters (both uppercase and lowercase)
    const regex = /^[A-Za-z]+$/;
  
    // Allow non-character keys like backspace, enter, and tab
    if (event.key === 'Backspace' || event.key === 'Enter' || event.key === 'Tab') {
      if (event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault();
        const trimmedInput = input.trim();
        if (trimmedInput) {
          setCCError('');
          setEmails([...emails, trimmedInput]);
          setInput('');
        }
      }
    } else {
      // Get the key pressed by the user
      const key = event.key;
  
      // Prevent default if the key is not a letter
      if (!regex.test(key)) {
        event.preventDefault();
      }
    }
  };
  

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  const removeEmail = (object) => {
    if(emails.length === 1) {
      setEmails([]);
    } else {
      setEmails(emails.filter((value) => JSON.stringify(value) !== JSON.stringify(object)));
    }
  };

  const addEmail = (input) => {
      const trimmedInput = input.trim();
      if (trimmedInput) {
        setCCError('');
        setEmails([...emails, trimmedInput]);
        setInput('');
      } else {
        setCCError('Only PagerDuty emails are allowed');
      }
  };

  useEffect(() => {
    fetch_RunTour();
    getAccounts();
  }, []);

  const questionsArray = [
    {
      question: `What are the available Financial Model Types?`,
      answer: [
        `Standard Deck: A balanced financial model, providing a comprehensive overview suitable for a general audience.`,
        `Conversation Deck: An interactive model designed to facilitate discussions, ideal for meetings and collaborative sessions.`,
        `Very Conservative Deck: A prudent and cautious financial model, emphasizing risk mitigation and conservative estimates.`
      ]
    },
    {
      question: `What is the Account?`,
      answer: `The account is the formal account name for your customer’s PagerDuty domain. Ensure to enter the account name accurately to to facilitate seamless data retrieval and deck creation.`,
    },
    {
      question: `Where do I find the account name for my customer’s PagerDuty domain?`,
      answer: ` PagerDuty Admin -> Salesforce Account -> PD Data`,
    },
    {
      question: `Where can I get help interpreting the data in my QBR deck?`,
      answer: `Ask for help in these Slack channels: #digital-insights-sales-enablement, #csg-qbr-deck-generator-app`,
    },
    {
      question: `Where can I get help with the Deck Generator application?`,
      answer: `Ask for help in this Slack channel: #csg-qbr-deck-generator-app`,
    },
  ];

  const submitFeedbackButton = async () => {
    setThankYouState(true);
    await apiRequest({
      method: "post",
      url: apiRoutes.userFeedback,
      data: {
        comments: feedbackDescription,
        rating: feedbackRating,
        page_reference: feedbackNameLiteral.hasOwnProperty(pathname)
          ? feedbackNameLiteral[pathname]()
          : "Portal",
        pathname,
      },
    });
    setTimeout(() => {
      setThankYouState(false);
      setModalOpen(false);
      setFeedbackDescription("");
      setFeedbackRating(null);
    }, 4000);
  };

  const fetch_RunTour = async () => {
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.deckTour,
    });
    if (!res || (res && res.require_tour  === 0)) {
      setRunTour(true);
    }
  };

  const closeTour4Ever = async () => {
    setRunTour(false);
    const [error, res] = await apiRequest({
      method: "put",
      url: apiRoutes.deckTour,
    });
  };

  const closeFeedbackButton = async () => {
    setModalOpen(false);
    setFeedbackDescription("");
    setFeedbackRating(null);
  };

  const feedbackNameLiteral = {
    "/": () => "Portal",
    "/home": () => "Portal",
    "/success-on-demand": () => "Success on Demand App",
    "/onboarding": () => "Onboarding App",
    "/users": () => "Administration App",
    "/health-check": () => "Health Check App",
    "/health-check-client": () => "Health Check App",
    "/health-check-data-viewer": () => "Health Check Data Viewer",
    "/migration-wizard": () => "Migration Visualization App",
    "/ps-status": () => "PS Status App",
    "/deck-generator": () => "Deck Generator",
    "/my-alerts-app": () => "My Alerts App",
    "/customer-snapshots": () => "Customer snapshots",
  };

  const [type, setType] = useState([
    { value: "Long", label: "Long" },
    {
      value: "Short(only available unscoped)",
      label: "Short(only available unscoped)",
    },
    {
      value: "ServiceSource CSMs/ No ROI",
      label: "ServiceSource CSMs/ No ROI",
    },
  ]);
  const [modelType, setModelType] = useState([
    { value: "Standard", apiValue: "standard" },
    { value: "Conservative", apiValue: "conservative" },
    { value: "Very Conservative", apiValue: "veryConservative" },
  ]);
  const [theme, setTheme] = useState([
    { value: "Standard", label: "Standard" },
    { value: "2", label: "Short(only available unscoped)" },
    { value: "3", label: "ServiceSource CSMs/ No ROI" },
  ]);
  const [decks, setDeckOptions] = useState([
    { value: "Quarter bussines review", label: "Standard" },
  ]);
  const [deck, setDeck] = useState(null);

  const handleChangeMultiSelectAccount = async (values) => {
    let options = values.map((a) => a.ACCOUNT_ID);
    setAccountId(values !== [] ? options : null);
    setError('');
  };

  const getAccounts = async () => {
    setIsLoading(true);
    const [error, accounts] = await apiRequest({
      method: "get",
      url: apiRoutes.deckAccounts,
    });
    if (accounts) {
      setLastCronJobTime(accounts.cron_job_last_run);
      const activeAccounts = accounts.clients.filter(account => account.ACCOUNT_STATE_END === 'active');
      setDeckAccounts(activeAccounts.map(element => { return { value: element.SUBDOMAIN
        , ...element } }))
      setIsLoading(false);
    }
    if (error) {
      console.log(error);
      notificationSubject.next({
        message: "We’re currently encountering a technical challenge getting accounts, and our team is diligently working to rectify the situation. Attempt the process again in a few minutes.",
        type: "ERROR",
      });
    }
    setIsLoading(false);
  };

  const handleSubmitKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const generateDeck = async (event) => {
    event.preventDefault();
    if (accountId === null || accountId === undefined || accountId.length === 0) {
      setError('Please search for an Account.');
    } else {
      setError('');
      setCCError('');
      setDisableForm(true);
      const accounts = accountId;
      if (emails !== null) {
        const newEmailArray = emails.map((email) => { 
          return email + '@pagerduty.com'
        });
        setEmailArray(newEmailArray);
      }
      socket.on('connect', () => {
        console.log('Connected to the server');
      });
      
      const [error, res] = await apiRequest({
        method: "post",
        url:apiRoutes.createDeck,
        data: { 
          accounts: accounts,
          deck_slide_share_email: email,
          model_type: typeSelected,
          CC: emails !== null ? emailArray : null,
        },
      });

      if (res) {
        if(res.decks[0].result === false) {
          notificationSubject.next({
            message: "We’re currently encountering a technical challenge, and our team is diligently working to rectify the situation. Attempt the process again in a few minutes.",
            type: "ERROR",
          });
        } else {
          setEmails(null);
          setAccountId(null);
          setIsLoading(false);
          notificationSubject.next({
            message: "Successfully sent the deck, please take a moment to check your inbox emails.",
            type: "SUCCESS",
          });
        }
      }
      if (error) {
        if(error.response.status === 500) {
          notificationSubject.next({
            message: "We’re currently encountering a technical challenge, and our team is diligently working to rectify the situation. Attempt the process again in a few minutes.",
            type: "ERROR",
          });
        } else {
          setErrorHandler(error.response.status);
        }
        setIsLoading(false);
      }
    };
  };

  useEffect(() => {
  }, [accountId]);

  return (
    <>
     {isLoading ? <SkeletonDeckGenerator /> : errorHandler ?  <ErrorHandler statusCode={errorHandler} /> : <div id="Deck-generator-container">
      <Joyride
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          run={runTour}
          steps={steps}/>
        <div id="Deck-generator-title-container">
          <div style={{ display: "flex", alignItems:"baseline" }}>
            <p id="Deck-generator-title">Deck Generator</p>
            <span className="beta-tag">Beta v1.1</span>
          </div>
          <div>
            <div id="DeckGenerator-FAQ" onClick={() => setFAQModalOpen(true)}>
              Frequently Asked Questions
              <FontAwesomeIcon
                id="DeckGenerator-FAQIcon"
                icon={faQuestionCircle}
              />
            </div>
            <div id="SuccessOnDemand-container-time">
              <label><b>This data was loaded at:</b></label>
              <span>{cronJobTime}</span>
            </div> 
          </div>
        </div>
        <div className="contain">
          <div className="form">
            <div style={{ display:"flex",justifyContent: "space-between"}}>
            <p className="deck-generator-title"> Create a New QBR Deck</p>
              <button style={{ padding:" 5px 10px",border: "none", backgroundColor: "#082b56", color:"#FFF", borderRadius: "5px", fontSize: "16px", marginLeft: "10px", display: "block", width: "100%", textAlign: "center", width: "200px" }} onClick={() => setModalOpen(true)}>Feedback</button>
            </div>
            <p className="info-message">Select the Financial Model Type and Account to have the QBR Deck sent to your email</p>
            <form action="" onKeyDown={handleSubmitKeyDown}>
              <div className="form-input__container">
                <p id="deck-form">
                  <label for="">Email</label>
                    <input
                      multiple={true}
                      className="deck-generator"
                      id="email"
                      type="email"
                      value={email}
                      disabled={true}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                  ></input>
                </p>
                <p id="deck-form">
                  <div style={{display: 'flex', alignItems:'baseline'}}>
                    <label for="cc" >CC:</label> 
                    <p style={{margin:"0 0 0 10px", padding: 0, color: "grey", fontSize:'11px' }}>(Optional) Max 5 emails</p>
                  </div>
            
                  <div className="email-input__container">
                    <div className="email-input__domain">
                      <input 
                        type="text" 
                        value={input}
                        id="cc"
                        disabled={disableForm}
                        className="email-input"
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}/>
                      <span className="email-domain">@pagerduty.com</span>
                    </div>
                    <div onClick={() => addEmail(input)} className="input-close">
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    {ccError && <div className="error-message">{ccError}</div>}
                  </div>
                  {emails && <div style={{marginLeft: "10px"}} className="Flex" key={value}>
                    {emails.map((value) => (
                            <><p className="tag">{value}</p><div onClick={() => removeEmail(value)} className="tag-icon">
                        <FontAwesomeIcon icon={faTimes} />
                      </div></>
                        ))} 
                  </div>}
                </p>
              </div>
              <div className="dropdown-deck__container"style={{display:'flex', justifyContent:'flex-start'}}>
                <p id="deck-form">
                  <label for="">Presentation Type</label>
                  <select className="dropdown-deck" id="type" onChange={(e) => console.log(e)}>
                    <option>{"Quarterly Business Review (QBR)"}</option>
                  </select>
                </p>
                <p id="deck-form">
                  <label for="">Financial Model Types</label>
                  <select className="dropdown-deck" id="modelType" onChange={(e) => {setTypeSelected(e.target.value); setTypeSelectedLabel(e.target.selectedOptions[0].text)}}>
                  {modelType.map((element) => (
                    <option key={element.apiValue} value={element.apiValue}>{element.value}</option>
                  ))}
                  </select>
                </p>
              </div>
              <div className="form-input__container">
                <p id="deck-form" className="deck-generator-multi">
                  <div style={{display: 'flex', alignItems:'baseline'}}>
                    <label style={{marginBottom:"0px" }} for="" >Account</label>                 
                    <p style={{marginLeft:"10px", padding: 0, color: "grey", fontSize:'11px' }}>Max 5 accounts</p>
                  </div>
                  <div id="accountName">
                    <MultiSelect
                      options={deckAccounts}
                      onChange={(e) => handleChangeMultiSelectAccount(e)}
                      valueToDisplay={'value'}
                      maxItems={5} 
                      disabled={disableForm}
                      defaultValues={null}
                      type={'hide'}
                      className={error ? 'error' : ''}
                    />
                    {error && <div className="error-message">{error}</div>}
                  </div>
                </p>
                <p id="deck-form" className="deck-generator-multi"></p>
              </div>
              <p id="deck-form">
                <button className="deck-generator-button" disabled={deckAccounts.length === 0 ||  accountId === null || accountId.length === 0} onClick={generateDeck}>Generate</button>
              </p>
            </form>
          </div>
          <div className="card-dashboard-fit">
            Copyright © {new Date().getFullYear()} Csg Innovation Services -
            Pagerduty, Inc. All rights reserved.
          </div>
        </div>
      </div>}
      <div>
        <Modal
          show={FAQModalOpen}
          type="small"
          title="FAQ"
          handleClose={() => setFAQModalOpen(!FAQModalOpen)}
          modalFitContent
        >
          <Questions questionsArray={questionsArray} color='rgb(0, 96, 127)' />
        </Modal>
      </div>
      <Modal
        show={modalOpen}
        title="Feedback"
        type="small"
        handleClose={() => closeFeedbackButton()}
        modalFitContent
      >
        {thankYouState ? (
          <ThankYou />
        ) : (
          <>
            <label>
              How would you rate your experiences with the{" "}
              <span className="feedbackSpan">
                {feedbackNameLiteral.hasOwnProperty(pathname)
                  ? feedbackNameLiteral[pathname]()
                  : "Portal"}
              </span>
              ?
            </label>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => setFeedbackRating(1)}
                style={
                  feedbackRating === 1
                    ? {
                        border: "1px solid rgb(6, 172, 56)",
                        color: "rgb(6, 172, 56)",
                      }
                    : {}
                }
                className="feedbackButton"
              >
                <TerribleFace scale="0.2" />
                Terrible
              </button>
              <button
                onClick={() => setFeedbackRating(2)}
                style={
                  feedbackRating === 2
                    ? {
                        border: "1px solid rgb(6, 172, 56)",
                        color: "rgb(6, 172, 56)",
                      }
                    : {}
                }
                className="feedbackButton"
              >
                <BadFace scale="0.2" />
                Bad
              </button>
              <button
                onClick={() => setFeedbackRating(3)}
                style={
                  feedbackRating === 3
                    ? {
                        border: "1px solid rgb(6, 172, 56)",
                        color: "rgb(6, 172, 56)",
                      }
                    : {}
                }
                className="feedbackButton"
              >
                <OkayFace scale="0.2" />
                Okay
              </button>
              <button
                onClick={() => setFeedbackRating(4)}
                style={
                  feedbackRating === 4
                    ? {
                        border: "1px solid rgb(6, 172, 56)",
                        color: "rgb(6, 172, 56)",
                      }
                    : {}
                }
                className="feedbackButton"
              >
                <GoodFace scale="0.2" />
                Good
              </button>
              <button
                onClick={() => setFeedbackRating(5)}
                style={
                  feedbackRating === 5
                    ? {
                        border: "1px solid rgb(6, 172, 56)",
                        color: "rgb(6, 172, 56)",
                      }
                    : {}
                }
                className="feedbackButton"
              >
                <AmazingFace scale="0.2" />
                Amazing
              </button>
            </div>
            <div className="feedbackTextareaDiv">
              <label>
                Do you have any feedback or comments on improving the{" "}
                <span className="feedbackSpan">
                  {feedbackNameLiteral.hasOwnProperty(pathname)
                    ? feedbackNameLiteral[pathname]()
                    : "Portal"}
                </span>
                ?
              </label>
              <textarea
                value={feedbackDescription}
                onChange={(e) => setFeedbackDescription(e.target.value)}
              />
            </div>
            <div>
              <button
                className="feedbackSubmitButton"
                onClick={() => submitFeedbackButton()}
                disabled={!feedbackRating || !feedbackDescription}
              >
                Submit
              </button>
              <button
                className="feedbackCloseButton"
                onClick={() => closeFeedbackButton()}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
