import "./News.css"

const News = () => {
  return (
    <div className="container__newsLoading">
      <div className="container__cardSkeletonNews skeleton__animation "/>
      <div className="container__cardSkeletonNews skeleton__animation "/>
      <div className="container__cardSkeletonNews skeleton__animation "/>
      <div className="container__cardSkeletonNews skeleton__animation "/>
      <div className="container__cardSkeletonNews skeleton__animation "/>
      <div className="container__cardSkeletonNews skeleton__animation "/>
      <div className="container__cardSkeletonNews skeleton__animation "/>
      <div className="container__cardSkeletonNews skeleton__animation "/>
    </div>
  );
};

export default News;