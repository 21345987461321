import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import MultiCheckbox from "./MultiCheckbox";

const TOOLTIP_TEXT = {
  services:
    "Select Technical Service(s) in order to add them to the Terraformation Plan.",
  users: "Select User(s) in order to add them to the Terraformation Plan.",
};

/** @param {Object} props
 * @param {import("./index").TFerReducerState} props.tferState
 * @param {import("./index").TFerDispatchSetPlan} props.setPlan
 */
export const ResourcesPicker = ({ tferState, setPlan }) => {
  const options = {
    services: tferState.resources.services.data || [],
    users: tferState.resources.users.data || [],
  };

  const [searchEntityService, setSearchEntityServices] = useState("");
  const [filterEntitiesServices, setFilterEntitiesServices] = useState([]);
  const toggleCheckboxEntitiesServices = (entities) => {
    toggleCheckboxAll(entities, setFilterEntitiesServices);
  };

  const [searchEntityUser, setSearchEntityUsers] = useState("");
  const [filterEntitiesUsers, setFilterEntitiesUsers] = useState([]);
  const toggleCheckboxEntitiesUsers = (entities) => {
    toggleCheckboxAll(entities, setFilterEntitiesUsers);
  };

  const filteredOptions = (options, searchState, field) => {
    return options?.filter((option) =>
      (field ? option[field] : option)
        ?.toLowerCase()
        .includes(searchState?.toLowerCase()),
    );
  };

  const filteredOptionsTypeEntityServices = filteredOptions(
    options.services,
    searchEntityService,
    "summary",
  );
  const filteredOptionsTypeEntityUsers = filteredOptions(
    options.users,
    searchEntityUser,
    "summary",
  );

  const toggleCheckboxAll = async (item, setState) => {
    setState((prevState) => {
      const newState = [...prevState];
      const itemIndex = newState.indexOf(item);

      if (itemIndex === -1) {
        newState.push(item);
      } else {
        newState?.splice(itemIndex, 1);
      }
      return newState;
    });
  };

  /** @param {string} key
   * @param {string[]} ids
   * @returns import("./index").PagerDutyResourceData[]
   */
  function pickResourceUpdate(key, ids) {
    return options[key].filter((item) => ids.includes(item.id));
  }

  useEffect(() => {
    // Had to add this becuase filterEntities were having empty string as first element
    const trimedServices = filterEntitiesServices.filter(Boolean);
    const trimedUsers = filterEntitiesUsers.filter(Boolean);

    setPlan("services", pickResourceUpdate("services", trimedServices));
    setPlan("users", pickResourceUpdate("users", trimedUsers));
  }, [filterEntitiesServices, filterEntitiesUsers]);

  return (
    <div>
      <div className="container__filterAlerts tfer-app__multicheckbox-container">
        <MultiCheckbox
          disabled={tferState.resources.services.status !== "success"}
          filterState={filterEntitiesServices}
          filteredOptions={filteredOptionsTypeEntityServices}
          isOpenDropdown={true}
          label={
            <>
              Services{" "}
              {tferState.resources.services.status === "pending" && (
                <FontAwesomeIcon className="tfer-app__spin" icon={faSpinner} />
              )}
            </>
          }
          mapFunction={(item) => item.id}
          nameLabel={(item) => item.summary}
          searchState={searchEntityService}
          setFilterState={setFilterEntitiesServices}
          setSearchState={setSearchEntityServices}
          toggleCheckbox={toggleCheckboxEntitiesServices}
          tooltip={true}
          tooltipText={TOOLTIP_TEXT.services}
        />

        <MultiCheckbox
          disabled={tferState.resources.users.status !== "success"}
          filterState={filterEntitiesUsers}
          filteredOptions={filteredOptionsTypeEntityUsers}
          isOpenDropdown={true}
          label={
            <>
              Users{" "}
              {tferState.resources.services.status === "pending" && (
                <FontAwesomeIcon className="tfer-app__spin" icon={faSpinner} />
              )}
            </>
          }
          mapFunction={(item) => item.id}
          nameLabel={(item) => item.summary}
          searchState={searchEntityUser}
          setFilterState={setFilterEntitiesUsers}
          setSearchState={setSearchEntityUsers}
          toggleCheckbox={toggleCheckboxEntitiesUsers}
          tooltip={true}
          tooltipText={TOOLTIP_TEXT.users}
        />
      </div>
    </div>
  );
};
