import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Paths from "../../../paths";
import loginBackgound1 from "./../../../assets/loginBackgound1.png";
import './Unauthorized.css'

export default function Unauthorized() {
  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");
  const [error, setError] = useState(false);
  const [loginErrorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [userError, setUserError] = useState(false);
  const [envError, setEnvError] = useState(false);

  const login =  () => {
    history(Paths.Login)
  };

  return (
    <div className="login-container">
      {!isLoading ? <> 
      </>
      : <> <div className="unauthorized-container">
      <main className="unathorized-message_container">
        <div style={{flex:1, alignItems:'center', display:'flex', margin: '0 auto', flexDirection: 'column'}}>
          <p className="unauthorized-message_error">401</p>
          <h1 className="unathorized-message_header">Unauthorized</h1>
          <p className="unathorized-message_paragraph">
          Your login attempt was unsuccessful. Please verify your credentials and try again.
          </p>
          <p className="unathorized-message_paragraph">If you need assistance, please contact our support team on the Slack channel <strong>#csg-innovation-portal</strong></p>
          <p className="unathorized-message_paragraph"> Thank you, CSG Innovation Services Team.</p>
          <div className="mt-10">
            <button className="unathorized-login_button" onClick={() => login()}>Login</button>
          </div>
        </div>
        <div className="unathorized-image_container">
          <img
            src="https://d274pwa32h7vmw.cloudfront.net/loginBackgound1.png"
            alt=""
            className="unathorized-image"
          />
        </div>
      </main>
    </div>
        </>}
    </div> 
  );
}
