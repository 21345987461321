import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faCancel,
  faSave,
  faFilter,
  faSortAlphaAsc,
  faLink,
  faWarning,
  faCircleExclamation,
  faCircleInfo,
  faCircleCheck,
  faFileCsv,
  faUser,
  faProjectDiagram
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, Fragment } from "react";
import { apiRequest, apiRoutes } from "../../services";
import {formatDay} from "../../utils/formatDate";
import MultiSelect from "../../components/MultiSelect";
import ClientDeliverySkeleton from "../../components/Skeletons/ClientDeliverySkeleton/ClientDeliverySkeleton";
import useCSV from "../../components/DownloadCSV/index.js"
import { cleanAndSortCustomers } from "../../utils/customers";
import Paths from "../../paths";
import LoadingSpinner from "../../components/LoadingSpinner";
import './Dashboard.css';

export default function Dashboard({ role, name, clientRole}) {
  const history = useNavigate();
  const [isSuperAdminView, setIsSuperAdmin] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [manager, setManager] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [userName, setUserName] = useState(name);
  const [userRole, setUserRole] = useState(role);
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [alertsSummary, setAlertsSummary] = useState([]);
  const [pduSummary, setPduSummary] = useState([]);
  const [psStatusSummary, setPsStatusSummary] = useState([]);
  const [productSummary, setProductSummary] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [premiumSupport, setPremiumSupport] = useState([]);
  const [serviceRequest, setServiceRequest] = useState([]);
  const [deliveryData, setDeliveryData] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const [originalDeliveryData, setOriginalDeliveryData] = useState({});
  const [showPDU, setShowPdu] = useState(true);
  const [currentTab, setCurrentTab] = useState("All");
  const [showPS, setShowPS] = useState(true);
  const [showProducts, setShowProducts] = useState(true);
  const [showAlerts, setShowAlerts] = useState(true);
  const [csm, setCSM] = useState('');
  const [showServiceRequest, setShowServiceRequest] = useState(true);
  const [delivery, setDelivery] = useState(true);
  const [showPremiumSupport, setShowPremiumSupport] = useState(true);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedTab, setSelectedTab] = useState([]);
  const [isProductExpanded, setIsProductExpanded] = useState({});
  const [isAlertExpanded, setIsAlertExpanded] = useState({});
  const [deliveryModels, setDeliveryModels] = useState([]);
  const [filters, setFilters] = useState(null);
  const [editData, setEditData] = useState({});
  const { downloadCSV } = useCSV();
  const [sectionVisibility, setSectionVisibility] = useState({
    pdu_summary: true,
    ps_status_summary: true,
    delivery: true,
    products_summary: true,
    premium_support_summary: true,
    health_check_alerts_summary: true,
    services_request_summary: true,
  });

  const [activeFilter, setActiveFilter] = useState(false);

  const goToPath = (path) => {
    history(path);
  }

  const getRoles = async() => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.clientDeliveryTypesOptions,
    });
    if (!error) {
      setRoles(data);
    }
  };

  const getUsers = async() => {
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.clientDeliveryMembers,
      data: [selectedRole]
    });
    if (!error) {
      const formatUsers = data.map(user => ({
        ...user, value: user.full_name

      }));
      setUsers(formatUsers);
    }
  };

  const getTeamManager = async(selectedUser) => {
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.teamManager,
      data: selectedUser
    });
    if (!error) {
      setSelectedUser(data);
    }
  };

  useEffect(() => {
    const expandState = {};
    productSummary.length > 0 && productSummary.forEach(item => {
      expandState[item.customer_name] = false;
    });
    setIsProductExpanded(expandState);
  }, [productSummary]);


  useEffect(() => {
    const expandState = {};
    alertsSummary.forEach(item => {
      expandState[item.customer_name] = false;

    });
    setIsAlertExpanded(expandState);
  }, [alertsSummary]);

  const handleEdit = (index) => {
    setEditIndex(index);
  };

  const handleChange = (field, value) => {
    setEditData(prevData => ({
      ...prevData,
      ...(field.includes('.')
        ? {
            [field.split('.')[0]]: {
              ...prevData[field.split('.')[0]],
              [field.split('.')[1]]: value
            }
          }
        : { [field]: value })
    }));
  };

  const handleSave = (data,index) => {
    setEditIndex(null);
    editDeliveryData(data);
  }

  const setAlertExpanded = (alert, expanded) => {
    setIsAlertExpanded(prevState => ({
      ...prevState,
      [alert]: expanded
    }));
  };

  const setProductExpanded = (customerName, expanded) => {
    setIsProductExpanded(prevState => ({
      ...prevState,
      [customerName]: expanded
    }));
  };

  const buildAvailableFilters = (data) => {
    const uniqueCustomerTypes = new Set();
    const uniquePremiumTypes = new Set();
    data.forEach(item => {
      uniqueCustomerTypes.add(item.theater);
      uniquePremiumTypes.add(item.customer_type);
    });
    
    const filters = {
      customerType: Array.from(uniqueCustomerTypes).map(type => ({
        label: type,
        checked: false,
        id: "theater"
      })),
      premiumType: Array.from(uniquePremiumTypes).map(type => ({
        label: type,
        checked: false,
        id: "customer_type"
      }))
    };
  
    if (selectedUser.length > 0) {
      filters.DeliveryManager = Array.from(selectedUser).map(csm => ({
        label: csm.full_name,
        checked: false,
        id: "csm"
      }));
    }
    setFilters(filters);
  };

  const filterUniqueCustomers = (data) => {
    const uniqueCustomerNames = new Set();
      const uniqueNames = data.filter(item => {
        if (!uniqueCustomerNames.has(item.customer_name)) {
          uniqueCustomerNames.add(item.customer_name);
          return true;
        }
        return false;
      }).map(item => item.customer_name); 
    return uniqueNames;
  };

  const sortOptions = [
    { name: 'Most Popular', href: '#', current: true },
    { name: 'Best Rating', href: '#', current: false },
    { name: 'Newest', href: '#', current: false },
  ];
  
  const [tabs, setTabs] = useState([
    { name: 'All', href: '#', current: true },
    { name: 'Client Delivery', href: '#', current: false },
    { name: 'PagerDuty University', href: '#', current: false },
    { name: 'Service Request', href: '#', current: false },
    { name: 'Professional Services', href: '#', current: false },
    { name: 'Product Services', href: '#', current: false },
    { name: 'Premium Support', href: '#', current: false },
    { name: 'Health Check Alerts', href: '#', current: false },
  ]);

  const buttonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '0.375rem',
    padding: '0.5rem 0.75rem',
    fontSize: '0.875rem',
    fontWeight: '600',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    outline: 'none',
    backgroundColor: "#048a24"
  };
  useEffect(() => {
    if (clientRole === 'SUPER_USER') {
      setIsSuperAdmin(true);
      getRoles();
      setLoading(false);
    } else {
      getData();
      getDeliveryModels();
    }
  }, []);

  const hoverStyle = {
    backgroundColor: '#f3f4f6',
  };

  const filterSearch = (options) => {
    const filterData = {
      pdu_summary: [],
      ps_status_summary: [],
      products_summary: [],
      premium_support_summary: [],
      services_request_summary: [],
      health_check_alerts_summary: []
    };
  
    const safeFilterAndPush = (sourceArray, targetArray) => {
      if (Array.isArray(sourceArray)) {
        sourceArray.forEach((item) => {
          if (item && options.includes(item.customer_name)) {
            targetArray.push(item);
          }
        });
      }
    };

    let newData = originalDeliveryData;
    newData = newData.filter(customer => options.includes(customer.customer_name));
    
    setDeliveryData(newData);
  
    const cleanPdu = Array.isArray(originalData.pdu_summary) 
      ? originalData.pdu_summary.filter((item) => item !== null)
      : [];
    
    safeFilterAndPush(cleanPdu, filterData.pdu_summary);
    safeFilterAndPush(originalData.products_summary, filterData.products_summary);
    safeFilterAndPush(originalData.ps_status_summary, filterData.ps_status_summary);
    safeFilterAndPush(originalData.premium_support_summary, filterData.premium_support_summary);
    safeFilterAndPush(originalData.health_check_alerts_summary, filterData.health_check_alerts_summary);
    safeFilterAndPush(originalData.services_request_summary, filterData.services_request_summary);
    
    return filterData;
  };

  const handleChangeMultiSelect = async (values) => {
    if(values.length > 0) {
      let options = values.map(a => a.value);
      const renderData = filterSearch(options);
      const cleanData = renderData.pdu_summary?.filter((item) => item !== null);
      setPduSummary(renderData.pdu_summary);
      setPsStatusSummary(renderData.ps_status_summary);
      setProductSummary(renderData.products_summary);
      setPremiumSupport(renderData.premium_support_summary);
      const serviceRequestClean = formatServiceRequest(renderData.services_request_summary);
      setServiceRequest(serviceRequestClean);
      sortAlertData(renderData.health_check_alerts_summary);
    } else {
      setDeliveryData(originalDeliveryData);
      setData(originalData);
      const cleanData = originalData.pdu_summary?.filter((item) => item !== null);
      sortAlertData(originalData.health_check_alerts_summary)
      setPduSummary(cleanData);
      setPsStatusSummary(originalData.ps_status_summary);
      setPremiumSupport(originalData.premium_support_summary);
      setProductSummary(originalData.products_summary);
      const serviceRequestClean = formatServiceRequest(originalData.services_request_summary);
      setServiceRequest(serviceRequestClean);
      sortAlertData(originalData.health_check_alerts_summary);
    }
  };

  function daysBetweenDates(date1, date2) {
    const oneDay = 1000 * 60 * 60 * 24;
    const date1Ms = date1.getTime();
    const date2Ms = date2.getTime();
    const differenceMs = Math.abs(date1Ms - date2Ms);
    return Math.ceil(differenceMs / oneDay)
  }

  const sortAlertData = (alertsData) => {
    const alerts = alertsData.map(alert => {
      if (alert.calculations) {
        const lastThreeMonths = alert.calculations.slice(-3);
        return {
          customer_name: alert.customer_name,
          data: lastThreeMonths.map(calc => {
            const getCount = (index, type) => {
              return calc.calculation[index] ? calc.calculation[index][`${type}_count`] || 0 : 0;
            };
            
            return {
              month: calc.month,
              calculations: [
                {
                  label: "Critical",
                  user: getCount(0, 'critical'),
                  schedule: getCount(1, 'critical'),
                  escalation: getCount(2, 'critical'),
                  service: getCount(3, 'critical'),
                  team: getCount(4, 'critical')
                },
                {
                  label: "Major",
                  user: getCount(0, 'major'),
                  schedule: getCount(1, 'major'),
                  escalation: getCount(2, 'major'),
                  service: getCount(3, 'major'),
                  team: getCount(4, 'major')
                },
                {
                  label: "High",
                  user: getCount(0, 'high'),
                  schedule: getCount(1, 'high'),
                  escalation: getCount(2, 'high'),
                  service: getCount(3, 'high'),
                  team: getCount(4, 'high')
                },
                {
                  label: "Mid",
                  user: getCount(0, 'mid'),
                  schedule: getCount(1, 'mid'),
                  escalation: getCount(2, 'mid'),
                  service: getCount(3, 'mid'),
                  team: getCount(4, 'mid')
                },
                {
                  label: "Low",
                  user: getCount(0, 'low'),
                  schedule: getCount(1, 'low'),
                  escalation: getCount(2, 'low'),
                  service: getCount(3, 'low'),
                  team: getCount(4, 'low')
                },
                {
                  label: "Minor",
                  user: getCount(0, 'minor'),
                  schedule: getCount(1, 'minor'),
                  escalation: getCount(2, 'minor'),
                  service: getCount(3, 'minor'),
                  team: getCount(4, 'minor')
                }
              ]
            };
          })
        };
      } else {
        return null;
      }
    }).filter(alert => alert !== null);
    setAlertsSummary(alerts);
  };

  const exportToCsv = async(section) => {
    const res = await downloadCSV(`${apiRoutes.clientDeliveryCsvExport}=${section}`, `${section}`, "csv");
    if (res) {
      console.log(res);
    }
  };

  const formatServiceRequest = (data) => {
    const serviceRequest = data.map((item) => {
      const days = daysBetweenDates(new Date(item.project_current_active_quarter_end_date) , new Date())
        return {
          customer_name: item.customer_name,
          hours_available: item.hours_available,
          hours_used: item.hours_used,
          project_current_active_quarter_end_date: item.project_current_active_quarter_end_date,
          days_remaining: daysBetweenDates(new Date(item.project_current_active_quarter_end_date) , new Date()),
          status: days >= 60 ? '#048a24' :
            days > 31 && item.hours_available >= 30 ? 'orange' : 
            days > 31 && item.hours_available >= 20 ? '#FACC14' :
            days <= 31 && item.hours_available < 20 ? '#048a24' : 
            days <= 30 && item.hours_available >= 30 ? '#F77171':
            days <= 30 && item.hours_available >= 20  ? 'orange':
            days <= 30 && item.hours_available >= 10 ? '#FACC14': 
             '#048a24',
        }
      });
    return serviceRequest;
  };

  const getAllCustomerNames = (data) => {
    const customerNames = data.map((item) => {
     return { 
      customer_name: item.customer_name,
      subdomain: item.subdomain,
      }
    });
    return customerNames;
  };

  useEffect(() => {
  if (selectedRole) {
      getUsers();
    }
  }, [selectedRole]);

  useEffect(() => {

   if (selectedUser) {
      getData(true);
      getDeliveryData(true, selectedUser);
    }

  }, [selectedUser]);


  const getPsBackgroundColor = (status) => {
    switch (status) {
      case 'Complete':
        return '#DCFCE6';
      case 'On track - New Order':
      case 'On track - Scheduled - Customer Intro':
        return 'rgb(30,144,255, 0.2)';
      case 'Off Track - Not Started - Customer':
        return '#FEFCE8';
      case 'At Risk - Not Started - Customer':
        return '#FDE2E1';
      case 'Expired':
        return 'rgb(156, 163, 175 , 0.2)';
      default:
        return '#9CA3AF';
    }
  };

  const sortByName = (array) => {
    return array.sort((a, b) => {
      let nameA = a.company_name ? a.company_name.toLowerCase() : a.customer_name.toLowerCase();
      let nameB = b.company_name ? b.company_name.toLowerCase():b.customer_name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  };

  const uniqueCustomerFilter = (array) => {
    const seen = new Set();
    return array.filter(item => {
      if (!item) return false;
      
      const key = `${item.customer_name}|${item.project_owner}`;
      
      if (!item.customer_name || seen.has(key)) {
        return false;
      }
      
      seen.add(key);
      return true;
    });
  };
  
  const getData = async (flag) => {
    const [error, data] = await apiRequest({
      method: flag ? "post" : "get",
      url: `${apiRoutes.getCSMdashboard}${flag ? "/query" : ''}`,
      ...(flag && { data: selectedUser.length > 0 ? selectedUser : [selectedUser] })
    });
  
    if (!error) {
      const safeUniqueFilter = (array) => {
        if (selectedUser.length > 0 && Array.isArray(array)) {
          return uniqueCustomerFilter(array);
        }
        return array;
      };

      const processedData = {
        ...data,
        pdu_summary: safeUniqueFilter(data.pdu_summary?.filter((item) => item !== null)),
        services_request_summary: safeUniqueFilter(formatServiceRequest(data.services_request_summary)),
        products_summary: safeUniqueFilter(data.products_summary),
        premium_support_summary: safeUniqueFilter(data.premium_support_summary),
        health_check_alerts_summary: safeUniqueFilter(data.health_check_alerts_summary)
      };
  
      for (let key in processedData) {
        if (Array.isArray(processedData[key])) {
          processedData[key] = sortByName(processedData[key]);
        }
      }
  
      setOriginalData(processedData); 
      setCSM(processedData.csm_selected);
      setData(processedData);
      setPduSummary(processedData.pdu_summary);
      setServiceRequest(processedData.services_request_summary);
      setPsStatusSummary(processedData.ps_status_summary);
      setProductSummary(processedData.products_summary);
      setPremiumSupport(processedData.premium_support_summary);
      sortAlertData(processedData.health_check_alerts_summary);
  
      if (isSuperAdminView && flag) {
        setIsSuperAdmin(false);
      }
  
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isSuperAdminView && clientRole !== 'SUPER_USER') {
     getDeliveryData();
    }
  }, []);

  const getDeliveryModels = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getCSMdashboardModels,
    });
    if (!error) {
      setDeliveryModels(data);
    }
  };

  const getDeliveryData = async (flag) => {
    const [error, data] = await apiRequest({
      method: flag ? "post": "get",
      url: `${apiRoutes.getCSMdashboardDeliveryData}${flag ? "/query" : ''}`,
      ...(flag && { data: selectedUser.length > 0 ? selectedUser : [selectedUser]})
    });
    if (!error && data.length > 0) {
      buildAvailableFilters(data)
      const uniqueSubdomains = {};
      const newData = [];
      const customers = getAllCustomerNames(data);
      setCustomers(cleanAndSortCustomers(customers));
      data.forEach(item => {
        if (!uniqueSubdomains[item.subdomain]) {
          uniqueSubdomains[item.subdomain] = true;
    
          const matchingCustomer = customers.find(customer => customer.subdomain === item.subdomain);

          newData.push({
            ...item,
            customer_name: matchingCustomer ? matchingCustomer.customer_name : item.customer_name
          });
        }
      });
      newData.sort((a, b) => {
        let nameA = a.customer_name.toUpperCase(); 
        let nameB = b.customer_name.toUpperCase(); 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      if (isSuperAdminView && flag) {
        setIsSuperAdmin(false);
      }
      setOriginalDeliveryData(newData);
      setDeliveryData(newData);
      setLoading(false);
    } else {
      setError(true);
    }
  };

  const handleCustomerType = (selectedCheckboxes) => {
    let newData = originalDeliveryData;
    const csmFilters = selectedCheckboxes.filter(filter => filter.id === "csm");
    const theaterFilters = selectedCheckboxes.filter(filter => filter.id === "theater");
    const customerTypeFilters = selectedCheckboxes.filter(filter => filter.id === "customer_type");
    if (csmFilters.length > 0) {
      newData = newData.filter(customer =>
        csmFilters.some(filter => 
          (manager.full_name === 'Alonso Lobos' 
            ? customer.portfolio_success_manager === filter.label 
            : customer.delivery_manager === filter.label)
        )
      );
    }
    if (theaterFilters.length > 0) {
      newData = newData.filter(customer =>
        theaterFilters.some(filter => customer.theater === filter.label)
      );
    }
 
    if (customerTypeFilters.length > 0) {
      newData = newData.filter(customer => 
        customerTypeFilters.some(filter => customer.customer_type === filter.label)
      );
    }
    const customersName = newData.map(customer => customer.customer_name);
    const filterData = filterSearch(customersName);
    setDeliveryData(newData);
    setPduSummary(filterData.pdu_summary);
    setPsStatusSummary(filterData.ps_status_summary);
    setProductSummary(filterData.products_summary);
    setPremiumSupport(filterData.premium_support_summary);
    const serviceRequestClean = formatServiceRequest(filterData.services_request_summary);
    setServiceRequest(serviceRequestClean);
    sortAlertData(filterData.health_check_alerts_summary);
  
    return newData;
  };

  const handleChangeUserMultiSelect = (value) => {
    if (value.length > 0) {
      const selectedEmail = value[0].email;
      const selectedUser = users.find(user => user.email === selectedEmail);
      if (selectedRole.type_key === "DELIVERY_MANAGER_TEAM_MANAGER" ) {
        getTeamManager(selectedUser);
        setManager(selectedUser);
      } else {
        setSelectedUser(selectedUser);
      }
      setLoading(true);
    }
  };


  const editDeliveryData = async (originalData) => {
    const updatedData = { 
      account_id: originalData.account_id, 
      ...editData.delivery_summary_data 
    };
    const [error, data] = await apiRequest({
      method: "put",
      url: apiRoutes.getCSMdashboardDeliveryData,
      data: updatedData
    });
    if (!error) {
      getDeliveryData(true);    
    }
    setEditData({});
  };

  const handleCheckboxChange = (e, label, key, id) => {
    const isChecked = e.target.checked;
  
    setSelectedCheckboxes((prev) => {
      const newSelectedCheckboxes = isChecked
        ? [...prev, { label, id }]
        : prev.filter((item) => item.label !== label || item.id !== id);
      const finalSelectedCheckboxes = newSelectedCheckboxes.length > 0 ? newSelectedCheckboxes : [];
    
      handleCustomerType(finalSelectedCheckboxes);
    
      return finalSelectedCheckboxes;
    });
  };

  const handleTabChange = (name) => {
    setCurrentTab(name);
    setTabs(tabs.map(tab => ({
      ...tab,
      current: tab.name === name
    })));
  };

  const handleToggleUI = (label) => {
    switch (label) {
      case 'PDU':
        setSectionVisibility((prev) => ({ ...prev, pdu_summary: !prev.pdu_summary }));
        break;
      case 'PS Status':
        setSectionVisibility((prev) => ({ ...prev, ps_status_summary: !prev.ps_status_summary }));
        break;
      case 'Health Check Alerts':
        setSectionVisibility((prev) => ({ ...prev, health_check_alerts_summary: !prev.health_check_alerts_summary }));
        break;
      case 'Product Services':
        setSectionVisibility((prev) => ({ ...prev, products_summary: !prev.products_summary }));
      break;
      case 'Premium Support':
        setSectionVisibility((prev) => ({ ...prev, premium_support_summary: !prev.premium_support_summary }));
      break;
      case 'Service Request':
        setSectionVisibility((prev) => ({ ...prev, services_request_summary: !prev.services_request_summary }));
      break;
      default:
        break;
    }
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
  <div className="dashboard-header">
    <h1 className="dashboard-title">Client Delivery Dashboard</h1>

    {loading ? <LoadingSpinner height="125px"/> : isSuperAdminView ? (
      <div className="super-admin-view">
        <div className="super-admin-container">
          <h2 className="super-admin-title">Welcome to client delivery dashboard</h2>
          <p className="super-admin-description">
            Please select from the dropdown below which role and then search for a user you would like to view the dashboard for.
          </p>
          <div>
            <label htmlFor="role" className="form-label">Role</label>
            <select
              id="role"
              name="role"
              onChange={(e) => {
                const selectedTypeKey = e.target.value;
                const selectedRole = roles.find(role => role.type_key === selectedTypeKey);
                setSelectedRole(selectedRole);
              }}
              className="form-select"
            >
              <option>Select a role</option>
              {roles.map((role) => ( 
                <option key={role.type_key} value={role.type_key}>{role.description}</option>
              ))}
            </select>
            {selectedRole && (
              <>
                <label htmlFor="user" className="form-label">User</label>
                <MultiSelect
                  options={users}
                  onChange={(e) => handleChangeUserMultiSelect(e)}
                  valueToDisplay={"value"}
                  allowOnlyOne={true}
                  defaultValues={null}
                  placeholder={"User name search..."}
                />
              </>
            )}
          </div>
        </div>
      </div>
    ) : (
      <>
        {clientRole === 'SUPER_USER' && (
          <div className="warning-box">
            <div className="warning-content">
              <div className="warning-icon">
                <FontAwesomeIcon icon={faWarning} className="warning-icon-svg" aria-hidden="true" />
              </div>
              <div className="warning-text">
                <h3 className="warning-title">Attention</h3>
                <div className="warning-message">
                  <p>
                    The information comes from <strong>{ manager !== '' ? manager.csg_member_type.description :selectedUser.csg_member_type.description}</strong> - <strong>{manager !== '' ? manager.full_name :selectedUser.full_name}</strong>
                  </p>
                  <button 
                    className="warning-button"
                    onClick={() => {
                      setSelectedRole(null);
                      setSelectedUser(null);
                      setSelectedCheckboxes([]);
                      setManager('');
                      setUsers([]);
                      setIsSuperAdmin(true);
                      setFilters([])
                    }}
                  >
                    See other user
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <h2 className="user-name">{userName || "Giselle Vazquez"}</h2>
        <div className="user-info">
          <div className="user-info-item">
            <FontAwesomeIcon icon={faBriefcase} className="user-info-icon" aria-hidden="true" />
            {userRole || "Customer Success Manager"}
          </div>
          <div className="user-info-item">
            <FontAwesomeIcon icon={faUser} className="user-info-icon" aria-hidden="true" />
            {customers.length || 0} Customers
          </div>
          <div className="user-info-item">
            <FontAwesomeIcon icon={faProjectDiagram} className="user-info-icon" aria-hidden="true" />
            {psStatusSummary.length || 0} Projects
          </div>
        </div>
        <div className="dashboard-body">
          <section className="dashboard-section">
            <div>
              <div className="section-header">
                <h3 className="section-title">Sections</h3>
              </div>
              <nav className="tabs-nav" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={`tab ${tab.current ? 'tab-active' : ''}`}
                    aria-current={tab.current ? 'page' : undefined}
                    onClick={(e) => handleTabChange(tab.name)}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
            <div className="filter-section">
              <div className="search-container">
                <MultiSelect
                  options={customers}
                  onChange={(e) => handleChangeMultiSelect(e)}
                  valueToDisplay={"value"}
                  allowOnlyOne={false}
                  defaultValues={null}
                  placeholder={"Customer Name search..."}
                />
                <div className="search-shortcut">
                  <kbd className="shortcut-key">⌘K</kbd>
                </div>
              </div>
              <div className="filter-controls">
                <button onClick={() => setActiveFilter(!activeFilter)} className="filter-button">
                  Filters 
                  <FontAwesomeIcon icon={faFilter} className="filter-icon" aria-hidden="true" />
                </button>
               
              </div>
            </div>
            {activeFilter && (
              <div className="active-filters">
                <div className="filter-grid">
                  {Object.keys(filters).map((key) => (
                    <fieldset key={key} className="filter-fieldset">
                      <legend className="filter-legend">{key.charAt(0).toUpperCase() + key.slice(1)}</legend>
                      <div className="filter-options">
                        {filters[key].map((option, index) => (
                          <div key={index} className="filter-option">
                            <input
                              id={option.id}
                              type="checkbox"
                              className="filter-checkbox"
                              defaultChecked={option.checked}
                              value={option.label}
                              onChange={(e) => handleCheckboxChange(e, option.label, key,option.id)}
                            />
                            <label className="filter-label">{option.label}</label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  ))}
                </div>
              </div>
            )}
          </section>
        </div>
      </>
    )}
  </div>
</div>
      <>
      {loading ? <ClientDeliverySkeleton /> : (sectionVisibility.delivery && !isSuperAdminView  && (currentTab === 'All' || currentTab === 'Client Delivery')) &&
        <div style={{ marginTop: "12px", maxWidth: '100%', backgroundColor: "White", padding: ".5rem", borderRadius: "5px", boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
          <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <div>
              <h1 style={{ fontSize: '24px', fontWeight: '600', lineHeight: '24px', color: '#117170' }}>Client Delivery Overview</h1>
            </div>
            <div style={{ width: '2rem' }} onClick={() => setDelivery(!delivery)}>
              {delivery ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="icon-style">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="icon-style">
                  <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                </svg>
              }
            </div>
          </div>
          {delivery ? (
            <div style={{ marginTop: '12px', overflowX: 'auto' }}>
              <div style={{ minWidth: '100%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '24px', paddingRight: '24px' }}>
                <table style={{ minWidth: '100%', borderCollapse: 'separate', borderColor: '#d1d5db' }}>
                  <thead>
                    <tr>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Name</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Domain</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Customer Type</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Premium Service Type</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Contract Start</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Contract End</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Account Manager</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Delivery Manager</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Portafolio Success Manager</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Program Manager</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Strategy Lead</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Delivery Model</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Project Plan</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>12mo Roadmap</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Current/Future State</th>
                      <th style={{ padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: '600', color: '#1f2937' }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryData.length > 0 ? deliveryData.map((item, i) => (
                      <tr key={item.account_id}>
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#000', fontWeight: "500",borderBottom: 'solid #F7F7F7' }}>
                        {item.customer_name}
                        </td>
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#000', fontWeight: "500",borderBottom: 'solid #F7F7F7' }}>
                          {item.subdomain}
                        </td>
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280' ,borderBottom: 'solid #F7F7F7' }}>
                            {item.theater}
                        </td>
                        <td style={{ padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280' ,borderBottom: 'solid #F7F7F7'}}>
                            {item.customer_type}
                        </td>
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280' ,borderBottom: 'solid #F7F7F7' }}>
                            { item.delivery_summary_data?.close_date}
                        </td>
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280' ,borderBottom: 'solid #F7F7F7' }}>
                            {item.end_date}
                        </td>
                        <td style={{padding: "0.5rem",whiteSpace: "nowrap",fontSize: "14px",color: "rgb(107, 114, 128)", textAlign: "left", borderBottom: "solid rgb(247, 247, 247)",width: "200px",maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis"}}>
                            {item.account_manager}
                        </td>
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280' ,textAlign:'center',borderBottom: 'solid #F7F7F7' }}>
                            {item.delivery_manager}
                        </td>
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280' ,textAlign:'center',borderBottom: 'solid #F7F7F7' }}>
                            {item.portfolio_success_manager}
                        </td>
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280' ,textAlign:'center',borderBottom: 'solid #F7F7F7' }}>
                            {item.program_manager}
                        </td> 
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280' , textAlign:'center',borderBottom: 'solid #F7F7F7' }}>
                            {item.strategy_lead}
                        </td>
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280', textAlign:'center' ,borderBottom: 'solid #F7F7F7' }}>
                          {item.delivery_model?.description || ''}
                        </td>
                        <td style={{padding:".5rem", whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280', textAlign: 'center', borderBottom: 'solid #F7F7F7'}}>
                          {editIndex === i ? (
                            <input
                              type="text"
                              onChange={e => handleChange('delivery_summary_data.delivery_project_plan', e.target.value)}  
                              defaultValue={item.delivery_summary_data?.delivery_project_plan}
                              style={{ width: '100%', border: "1px solid hsl(0, 0%, 80%)", borderRadius: "0.25rem" }}
                            />
                          ) : (
                            item.delivery_summary_data?.delivery_project_plan && <a href={item.delivery_summary_data?.delivery_project_plan} target="_blank"><FontAwesomeIcon icon={faLink} /></a>
                          )}
                        </td>
                        <td style={{padding:".5rem",whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280', textAlign:'center' ,borderBottom: 'solid #F7F7F7'}}>
                          {editIndex === i ? <input type="text"onChange={e => handleChange('delivery_summary_data.delivery_12m_roadmap', e.target.value)}  defaultValue={item.delivery_summary_data?.delivery_12m_roadmap} style={{ width: '100%',border:" 1px solid hsl(0, 0%, 80%)" ,borderRadius: "0.25rem" }} /> :
                          item.delivery_summary_data?.delivery_12m_roadmap && <a href={item.delivery_summary_data?.delivery_12m_roadmap} target="_blank"><FontAwesomeIcon icon={faLink}></FontAwesomeIcon></a>}
                        </td>
                        <td style={{padding:".5rem", whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280', textAlign: 'center', borderBottom: 'solid #F7F7F7'}}>
                          {editIndex === i ? (
                            <input
                              type="text"
                              onChange={e => handleChange('delivery_summary_data.delivery_cs_fs', e.target.value, i)} 
                              defaultValue={item.delivery_summary_data?.delivery_cs_fs}
                              style={{ width: '100%', border: "1px solid hsl(0, 0%, 80%)", borderRadius: "0.25rem" }}
                            />
                          ) : (
                            item.delivery_summary_data?.delivery_cs_fs && <a href={item.delivery_summary_data?.delivery_cs_fs} target="_blank"><FontAwesomeIcon icon={faLink} /></a>
                          )}
                        </td>
                        <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280', textAlign:'center' ,borderBottom: 'solid #F7F7F7' }}>
                          {editIndex === i ? (
                            <>
                              <FontAwesomeIcon onClick={() => handleSave(item,i)} icon={faSave} style={{ cursor: 'pointer',marginRight: '0.375rem', height: '20px', width: '20px', color: '#048a24' }} />
                              <FontAwesomeIcon onClick={() => setEditIndex(null)} icon={faCancel} style={{ cursor: 'pointer',marginRight: '0.375rem', height: '20px', width: '20px', color: 'red' }} />
                            </>
                            
                          ) : (
                            <a style={{color: "#048a24", fontSize: "14px",fontWeight: "600", cursor:"pointer"}} onClick={() => handleEdit(i)}>Edit</a>
                          )}
                        </td>

                      </tr>
                    )) : null}


                  </tbody>
                </table>
              </div>
            </div>
          ) : <ClientDeliverySkeleton /> }
        </div>
      }

      {loading ? <ClientDeliverySkeleton /> : (sectionVisibility.pdu_summary && !isSuperAdminView  && (currentTab === 'All' || currentTab === 'PagerDuty University')) &&
      <div style={{ marginTop: "12px", maxWidth: '100%', backgroundColor:"White" , padding:".5rem", borderRadius: "5px",boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
        <div style={{ display: 'flex', justifyContent:"space-between"}}>
          <div style={{display:"inline-flex", alignItems:"baseline"}}>
            <h1 style={{ fontSize: '24px', fontWeight: '600', lineHeight: '24px', color: '#117170', marginRight:"12px" }}>PagerDuty University</h1>
            {showPDU && <FontAwesomeIcon icon={faFileCsv} style={{ cursor: 'pointer', color: '#117170' }} onClick={() => exportToCsv('pdu_summary')} />}
          </div>
          <div style={{ width: '2rem'}} onClick={() => setShowPdu(!showPDU)}>
            {
              showPDU ? 
              <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="nz sb uo axo"
            >
              <path
                color='#117170'
                fill='#117170'
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
              :<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="nz sb uo axo"><path color='#117170' fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"></path></svg>}
          </div>
        </div>
    { pduSummary.length > 0 && showPDU && <div style={{ marginTop: '12px', overflowX: 'auto' }}>
          <div style={{ minWidth: '100%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '24px', paddingRight: '24px' }}>
            <table style={{ minWidth: '100%', borderCollapse: 'separate', borderColor: '#d1d5db' }}>
              <thead>
                <tr>
                  <th style={{ padding: '5px', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Name</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Contract End Date</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>ILT Remaining</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Registered Courses</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Completed Courses</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Certifications</th>
                </tr>
              </thead>
              <tbody style={{ borderColor: '#e5e7eb', backgroundColor: '#ffffff' }}>
                {pduSummary.map((item, i) => (
                  <tr key={item.asana_sod_id}>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px',borderBottom: 'solid #F7F7F7'}}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginLeft: '16px' }}>
                          <div onClick={() => goToPath(Paths.PDUApp)} style={{ cursor:"pointer", marginTop: '4px', fontWeight: '500', color: '#1f2937',  padding: '.5rem'  }}>{item.customer_name}</div>
                        </div>
                      </div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>
                      <div style={{ marginTop: '4px', textAlign:'center' }}>{formatDay(item.contract_expiry)}</div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280', textAlign:'center' ,borderBottom: 'solid #F7F7F7' }}>{item.ilt_remaining}</td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280', textAlign:'center',borderBottom: 'solid #F7F7F7'  }}>{item.registered_courses}</td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280', textAlign:'center',borderBottom: 'solid #F7F7F7'  }}>{item.completed_courses}</td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>
                      <div style={{ color: '#6b7280',textAlign:'center' }}>{item.certificates}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>}
      </div>}
      {loading ? <ClientDeliverySkeleton /> : (sectionVisibility.services_request_summary  && !isSuperAdminView  && (currentTab === 'All' || currentTab === 'Service Request')) && <div style={{ marginTop: "12px", maxWidth: '100%', backgroundColor:"White" , padding:".5rem", borderRadius: "5px",boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
        <div style={{ display: 'flex', justifyContent:"space-between"}}>
          <div style={{display:"inline-flex", alignItems:"baseline"}}>
            <h1 style={{ fontSize: '24px', fontWeight: '600', lineHeight: '24px', color: '#117170', marginRight:"12px" }}>Service Request Summary</h1>
            {showServiceRequest && <FontAwesomeIcon icon={faFileCsv} style={{ cursor: 'pointer', color: '#117170' }} onClick={() => exportToCsv('services_request_summary')} />}
          </div>
          <div style={{ width: '2rem'}} onClick={() => setShowServiceRequest(!showServiceRequest)}>
            {
              showServiceRequest ? 
              <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="nz sb uo axo"
            >
              <path
                color='#117170'
                fill='#117170'
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
              :<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="nz sb uo axo"><path color='#117170' fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"></path></svg>}
          </div>
        </div>
    { serviceRequest.length > 0 && showServiceRequest && <div style={{ marginTop: '12px', overflowX: 'auto' }}>
          <div style={{ minWidth: '100%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '24px', paddingRight: '24px' }}>
            <table style={{ minWidth: '100%', borderCollapse: 'separate', borderColor: '#d1d5db' }}>
              <thead>
                <tr>
                  <th style={{ padding: '5px', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Name</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Status</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Hours Available</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Hours Used</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Expiration Date</th>
                </tr>
              </thead>
              <tbody style={{ borderColor: '#e5e7eb', backgroundColor: '#ffffff' }}>
                {serviceRequest.map((item, i) => (
                  <tr key={item.asana_sod_id}>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px',borderBottom: 'solid #F7F7F7'}}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginLeft: '16px' }}>
                          <div style={{ marginTop: '4px', fontWeight: '500', color: '#1f2937',  padding: '.5rem'  }}>{item.customer_name}</div>
                        </div>
                      </div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280',borderBottom: 'solid #F7F7F7', display: 'flex', margin: 'auto', alignItems: 'center', height:'44.5px' }}>
                    <FontAwesomeIcon icon={item.status === '#048a24' ? faCircleCheck : item.status === "#FACC14" ? faWarning: faCircleExclamation} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: `${item.status}` }} aria-hidden="true" /> {item.days_remaining} days
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>
                    <div style={{ color: item.hours_available > 0 ? '#6b7280' : 'red', textAlign: 'center' }}>
                      {item.hours_available} hrs
                    </div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>
                      <div style={{ marginTop: '4px', textAlign:'center',fontWeight:`${item.hours_used === 0 ? '600': '500' }`, color: `${item.hours_used === 0 ? 'red': 'gray' }`}}>{item.hours_used} hrs</div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280', textAlign:'center' ,borderBottom: 'solid #F7F7F7' }}>{item.project_current_active_quarter_end_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>}
      </div>}
      {loading ? <ClientDeliverySkeleton /> : (sectionVisibility.ps_status_summary && !isSuperAdminView  && (currentTab === 'All' || currentTab === 'Professional Services')) && <div style={{ marginTop: "12px", minWidth: '100%',boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', backgroundColor:"White" , padding:".5rem",borderRadius: "5px"}}>
        <div style={{ display: 'flex', justifyContent:"space-between"}}>
          <div style={{display:"inline-flex", alignItems:"baseline"}}>
            <h1 style={{ fontSize: '24px', fontWeight: '600', lineHeight: '24px', color: '#117170', marginRight:"12px" }}>Professional Services</h1>
            {showPS && <FontAwesomeIcon icon={faFileCsv} style={{ cursor: 'pointer', color: '#117170' }} onClick={() => exportToCsv('ps_status_summary')} />}
          </div>
          <div style={{ width: '2rem'}} onClick={() => setShowPS(!showPS)}>
            {
              showPS ?  <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="nz sb uo axo"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>:
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="nz sb uo axo"><path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"></path></svg>}
          </div>
        </div>
        { psStatusSummary.length > 0 && showPS && <div style={{ marginTop: '12px', overflowX: 'auto' }}>
          <div style={{ minWidth: '100%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '24px', paddingRight: '24px' }}>
            <table style={{ minWidth: '100%', borderCollapse: 'separate', borderColor: '#d1d5db' }}>
              <thead>
                <tr>
                  <th style={{ padding: '5px', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Customer Name</th>
                  <th style={{ padding: '5px', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Project Name</th>
                  <th style={{ padding: '5px', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Status</th>
                  <th style={{ padding: '5px', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Percentage</th>
                </tr>
              </thead>
              <tbody style={{ borderColor: '#e5e7eb', backgroundColor: '#ffffff' }}>
                {psStatusSummary.map((item, i) => (
                  <tr key={i}>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginLeft: '16px',borderBottom: 'solid #F7F7F7', width: '100%'  }}>
                          <div onClick={() => goToPath(Paths.PsStatus)} style={{ cursor: "pointer",fontWeight: '500', color: '#1f2937' }}>{item.customer_name}</div>
                          <div style={{ marginTop: '4px', fontSize: '14px', color: '#6b7280' }}>{item.engagement_manager}</div>
                        </div>
                      </div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginLeft: '16px',borderBottom: 'solid #F7F7F7', width: '100%'  }}>
                          <div style={{ cursor: "pointer",fontWeight: '500', color: '#1f2937' }}>{item.project_name}</div>
                          <div style={{ marginTop: '4px', fontSize: '14px', color: '#6b7280' }}>{item.project_owner}</div>
                        </div>
                      </div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>
                      <div style={{ marginTop: '4px', borderRadius: '9999px', padding: '0.125rem 0.625rem', width: 'fit-content',backgroundColor: getPsBackgroundColor(item.ps_status)}}>{item.ps_status}</div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>{item.progress_percentage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>}
      </div>}
      {loading ? <ClientDeliverySkeleton /> : (sectionVisibility.products_summary && !isSuperAdminView  && (currentTab === 'All' || currentTab === 'Product Services')) && <div style={{ marginTop: "12px", boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', minWidth: '100%', backgroundColor:"White" , padding:".5rem",borderRadius: "5px"}}>
          <div style={{ display: 'flex', justifyContent:"space-between"}}>
            <div style={{display:"inline-flex", alignItems:"baseline"}}>
              <h1 style={{ fontSize: '24px', fontWeight: '600', lineHeight: '24px', color: '#117170', marginRight:"12px" }}>Product Subscriptions & Add Ons</h1>
              {showProducts && <FontAwesomeIcon icon={faFileCsv} style={{ cursor: 'pointer', color: '#117170' }} onClick={() => exportToCsv('products_summary')} />}
            </div>
            <div style={{ width: '2rem'}} onClick={() => setShowProducts(!showProducts)}>
              {
                showProducts ?
                <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                className="nz sb uo axo"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              :
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="nz sb uo axo"><path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"></path></svg>
              }
            </div>
          </div>
          { productSummary.length > 0 && showProducts && <div style={{ marginTop: '12px', overflowX: 'auto' }}>
          <div style={{ minWidth: '100%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '24px', paddingRight: '24px' }}>
            <table style={{  minWidth: '100%', borderCollapse: 'separate', borderColor: '#d1d5db'}}>
              <thead style={{ backgroundColor: '#ffffff' }}>
                <tr>
                  <th style={{ padding: '0.875rem', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#111827', }}>Customer Product Name</th>
                  <th style={{ padding: '0.875rem', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#111827' }}>Available Licenses</th>
                  <th style={{ padding: '0.875rem', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#111827' }}>Deployed Licenses</th>
                  <th style={{ padding: '0.875rem', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#111827' }}>Purchased Licenses</th>
                  <th style={{ padding: '0.875rem', textAlign: 'right', fontSize: '16px', fontWeight: '600', color: '#111827' }}>
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: '#ffffff' }}>
                {productSummary.map((item, i) => (
                  <>
                    <tr key={item.customer_name} style={{ borderTop: '1px solid #e5e7eb', backgroundColor: 'rgb(18, 113, 112,0.2)' }}>
                      <th
                        colSpan={5}
                        scope="colgroup"
                        style={{ padding: '0.5rem 0.875rem', textAlign: 'left', fontSize: '0.875rem', fontWeight: '600', color: '#117170' }}
                      >
                        {item.customer_name}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          width={"20px"}
                          aria-hidden="true"
                          className="nz sb uo axo"
                          onClick={() => setProductExpanded(item.customer_name, !isProductExpanded[item.customer_name])}
                        >
                          <path
                            fillRule="evenodd"
                            d={isProductExpanded[item.customer_name] ? 'M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z' : 'M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'}
                            clipRule="evenodd"
                          />
                        </svg>
                      </th>
                    </tr>
                    {isProductExpanded[item.customer_name] &&
                      item.products.map((product, index) => (
                        <tr
                          key={index}
                          style={{
                            borderTop: `1px solid ${index === 0 ? '#cbd5e1' : '#e5e7eb'}`,
                          }}
                        >
                          <td style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginLeft: '16px',borderBottom: 'solid #F7F7F7', width: '100%'  }}>
                                  <div onClick={() => goToPath(Paths.PsStatus)} style={{ cursor: "pointer",fontWeight: '500', color: '#1f2937' }}>{product.customer_product_name}</div>
                                  <div style={{ marginTop: '4px', fontSize: '14px', color: '#6b7280' }}>{product.licence_type}</div>
                                </div>
                              </div>
                            </td>
                          <td style={{ whiteSpace: 'nowrap', padding: '1rem', fontSize: '0.875rem', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>{product.customer_available_licenses}</td>
                          <td style={{ whiteSpace: 'nowrap', padding: '1rem', fontSize: '0.875rem', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>{product.customer_deployed_licenses}</td>
                          <td style={{ whiteSpace: 'nowrap', padding: '1rem', fontSize: '0.875rem', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>{product.customer_purchased_licenses}</td>
                        </tr>
                      ))}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>}
      </div>}
      {loading ? <ClientDeliverySkeleton /> : (sectionVisibility.premium_support_summary && !isSuperAdminView  && (currentTab === 'All' || currentTab === 'Premium Support')) && <div style={{ marginTop: "12px",boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', minWidth: '100%', backgroundColor:"White" , padding:".5rem",borderRadius: "5px"}}>
        <div style={{ display: 'flex', justifyContent:"space-between"}}>
          <div style={{display:"inline-flex", alignItems:"baseline"}}>
            <h1 style={{ fontSize: '24px', fontWeight: '600', lineHeight: '24px', color: '#117170', marginRight:"12px" }}>Premium Support</h1>
            {showPremiumSupport && <FontAwesomeIcon icon={faFileCsv} style={{ cursor: 'pointer', color: '#117170' }} onClick={() => exportToCsv('premium_support_summary')} />}
          </div>
          <div style={{ width: '2rem'}} onClick={() => setShowPremiumSupport(!showPremiumSupport)}>
            {
              showPremiumSupport ? 
              <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="nz sb uo axo"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
              :<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="nz sb uo axo"><path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"></path></svg>}
          </div>
        </div>
        { premiumSupport.length > 0 && showPremiumSupport && <div style={{ marginTop: '12px', overflowX: 'auto' }}>
          <div style={{ minWidth: '100%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '24px', paddingRight: '24px' }}>
            <table style={{ minWidth: '100%', borderCollapse: 'separate', borderColor: '#d1d5db' }}>
              <thead>
                <tr>
                  <th style={{ padding: '5px', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Name</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Open Cases</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Closed</th>
                  <th style={{ padding: '5px', textAlign: 'center', fontSize: '16px', fontWeight: '600', color: '#1f2937' }}>Total Cases</th>
                </tr>
              </thead>
              <tbody style={{ borderColor: '#e5e7eb', backgroundColor: '#ffffff' }}>
                {premiumSupport.map((item, i) => (
                  <tr key={item.subdomain}>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px' ,borderBottom: 'solid #F7F7F7' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginLeft: '16px' }}>
                          <div style={{ marginTop: '4px', fontWeight: '500', color: '#1f2937',  padding: '.5rem' }}>{item.customer_name}</div>
                        </div>
                      </div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>
                      <div style={{ marginTop: '4px', textAlign:'center' }}>{item.open_cases}</div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>
                      <div style={{ color: '#6b7280',textAlign:'center' }}>{item.closed_cases}</div>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#6b7280', textAlign:'center' ,borderBottom: 'solid #F7F7F7' }}>{item.total_cases}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>}
      </div>}
      {loading ? <ClientDeliverySkeleton /> : (sectionVisibility.health_check_alerts_summary && !isSuperAdminView  &&  (currentTab === 'All' || currentTab === 'Health Check Alerts')) && <div style={{ marginTop: "12px", boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', minWidth: '100%', backgroundColor:"White" , padding:".5rem",borderRadius: "5px"}}>
        <div style={{ display: 'flex', justifyContent:"space-between"}}>
          <div style={{display:"inline-flex", alignItems:"baseline"}}>
            <h1 style={{ fontSize: '24px', fontWeight: '600', lineHeight: '24px', color: '#117170', marginRight:"12px" }}>Health Check Alerts</h1>
          {showAlerts && <FontAwesomeIcon icon={faFileCsv} style={{ cursor: 'pointer', color: '#117170' }} onClick={() => exportToCsv('health_check_alerts_summary')} />}
          </div>
          <div style={{ width: '2rem'}} onClick={() => setShowAlerts(!showAlerts)}>
            {
              showAlerts ? 
              <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="nz sb uo axo"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
              :<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="nz sb uo axo"><path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"></path></svg>}
          </div>
        </div>
        { alertsSummary.length > 0 && showAlerts && <div style={{ marginTop: '12px', overflowX: 'auto' }}>
          <div style={{ minWidth: '100%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '24px', paddingRight: '24px' }}>
            <table style={{  minWidth: '100%', borderCollapse: 'separate', borderColor: '#d1d5db'}}>
              <thead style={{ backgroundColor: '#ffffff' }}>
                <tr>
                  <th style={{ padding: '0.875rem', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#111827' }}></th>
                  <th style={{ padding: '0.875rem', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#111827' }}>User</th>
                  <th style={{ padding: '0.875rem', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#111827' }}>Schedule</th>
                  <th style={{ padding: '0.875rem', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#111827' }}>Escalation Policy</th>
                  <th style={{ padding: '0.875rem', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#111827' }}>Service</th>
                  <th style={{ padding: '0.875rem', textAlign: 'left', fontSize: '16px', fontWeight: '600', color: '#111827' }}>Team</th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: '#ffffff' }}>
                {alertsSummary.map((alert) => (
                    <Fragment key={alert.customer_name}>
                        <tr style={{ borderTop: '1px solid #e5e7eb', backgroundColor: 'rgb(18, 113, 112, 0.2)' }}>
                            <th colSpan={6}  style={{ padding: '0.5rem 0.875rem', textAlign: 'left', fontSize: '0.875rem', fontWeight: '600', color: '#117170'}}>
                                {alert.customer_name}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  width={"20px"}
                                  aria-hidden="true"
                                  className="nz sb uo axo"
                                  onClick={() => setAlertExpanded(alert.customer_name, !isAlertExpanded[alert.customer_name])}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d={isAlertExpanded[alert.customer_name] ? 'M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z' : 'M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'}
                                    clipRule="evenodd"
                                  />
                                </svg>
                            </th>
                        </tr>
                        {isAlertExpanded[alert.customer_name] && alert.data.map((monthData, idx) => (
                            <Fragment key={idx}>
                              <tr>
                                <td colSpan={6} style={{textAlign:"center", fontWeight:"600", width: "100%", backgroundColor:"rgba(156, 163, 175, 0.2)"}}>{monthData.month}</td>
                              </tr>
                                {monthData.calculations.map((calc, index) => (
                              <tr key={index}>
                                <td style={{ whiteSpace: 'nowrap', padding: '1rem', fontSize: '0.875rem', fontWeight: '500', color: '#111827' }}>
                                {calc.label}
                                </td>
                                <td style={{ whiteSpace: 'nowrap', padding: '1rem', fontSize: '0.875rem', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>
                                { index === 0  ? 
                                    <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(255, 0, 0)' }} aria-hidden="true" />: index === 1 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(255, 135, 46)' }} aria-hidden="true" /> :
                                    index === 2 ? <FontAwesomeIcon icon={faCircleExclamation} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(247, 198, 4)' }} aria-hidden="true" /> :
                                    index === 3 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(247, 198, 4)' }} aria-hidden="true" /> :
                                    index === 4 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(105, 3, 117)' }} aria-hidden="true" /> :
                                    index === 5 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(50, 156, 173)' }} aria-hidden="true" /> :
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: '#5FA5F9' }} aria-hidden="true" />
                                    }{calc.user}</td>
                                <td style={{ whiteSpace: 'nowrap', padding: '1rem', fontSize: '0.875rem', color: '#6b7280' ,borderBottom: 'solid #F7F7F7' }}>
                                  { index === 0  ? 
                                    <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(255, 0, 0)' }} aria-hidden="true" />: index === 1 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(255, 135, 46)' }} aria-hidden="true" /> :
                                    index === 2 ? <FontAwesomeIcon icon={faCircleExclamation} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(247, 198, 4)' }} aria-hidden="true" /> :
                                    index === 3 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(247, 198, 4)' }} aria-hidden="true" /> :
                                    index === 4 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(105, 3, 117)' }} aria-hidden="true" /> :
                                    index === 5 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(50, 156, 173)' }} aria-hidden="true" /> :
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: '#5FA5F9' }} aria-hidden="true" />
                                    }{calc.schedule}</td>
                                <td style={{ whiteSpace: 'nowrap', padding: '1rem', fontSize: '0.875rem', color: '#6b7280' ,borderBottom: 'solid #F7F7F7' }}>
                                { index === 0  ? 
                                    <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(255, 0, 0)' }} aria-hidden="true" />: index === 1 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(255, 135, 46)' }} aria-hidden="true" /> :
                                    index === 2 ? <FontAwesomeIcon icon={faCircleExclamation} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(247, 198, 4)' }} aria-hidden="true" /> :
                                    index === 3 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(247, 198, 4)' }} aria-hidden="true" /> :
                                    index === 4 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(105, 3, 117)' }} aria-hidden="true" /> :
                                    index === 5 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(50, 156, 173)' }} aria-hidden="true" /> :
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: '#5FA5F9' }} aria-hidden="true" />
                                    }{calc.escalation}</td>
                                <td style={{ whiteSpace: 'nowrap', padding: '1rem', fontSize: '0.875rem', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>
                                { index === 0  ? 
                                    <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(255, 0, 0)' }} aria-hidden="true" />: index === 1 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(255, 135, 46)' }} aria-hidden="true" /> :
                                    index === 2 ? <FontAwesomeIcon icon={faCircleExclamation} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(247, 198, 4)' }} aria-hidden="true" /> :
                                    index === 3 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(247, 198, 4)' }} aria-hidden="true" /> :
                                    index === 4 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(105, 3, 117)' }} aria-hidden="true" /> :
                                    index === 5 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(50, 156, 173)' }} aria-hidden="true" /> :
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: '#5FA5F9' }} aria-hidden="true" />
                                    }{calc.service}</td>
                                <td style={{ whiteSpace: 'nowrap', padding: '1rem', fontSize: '0.875rem', color: '#6b7280',borderBottom: 'solid #F7F7F7'  }}>
                                { index === 0  ? 
                                    <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(255, 0, 0)' }} aria-hidden="true" />: index === 1 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(255, 135, 46)' }} aria-hidden="true" /> :
                                    index === 2 ? <FontAwesomeIcon icon={faCircleExclamation} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(247, 198, 4)' }} aria-hidden="true" /> :
                                    index === 3 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(247, 198, 4)' }} aria-hidden="true" /> :
                                    index === 4 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(105, 3, 117)' }} aria-hidden="true" /> :
                                    index === 5 ? <FontAwesomeIcon icon={faWarning} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: 'rgb(50, 156, 173)' }} aria-hidden="true" /> :
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: '0.375rem', height: '15px', width: '15px', color: '#5FA5F9' }} aria-hidden="true" />
                                    }{calc.team}</td>
                              </tr>
                                ))}
                            </Fragment>
                        ))}
                    </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>}
      </div>}
      </>
    </div>
  )
}