import React from "react";

import Button from "./Button"
import {
  SectionHeader,
  SectionHeaderTitleContainer,
  SectionHeaderActions,
  Title,
} from "./PageSection.styles";

const PageSectionHeader = ({
  icon,
  title,
  primaryAction,
  secondaryActions,
}) => {
  return (
    <SectionHeader>
      <SectionHeaderTitleContainer>
        {title && <Title>{title}</Title>}
      </SectionHeaderTitleContainer>
      <SectionHeaderActions>
        {secondaryActions &&
          secondaryActions.length > 0 &&
          secondaryActions.map((action, i) => (
            <Button
              key={i}
              style="secondary"
              text={action.text}
              onClick={action.onAction}
              icon={action.icon}
              loading={action.loading}
              disabled={action.disabled}
            />
          ))} 
        {primaryAction && (
          <Button
            text={primaryAction.text}
            onClick={primaryAction.onAction}
            icon={primaryAction.icon}
            style="primary"
            loading={primaryAction.loading}
            disabled={primaryAction.disabled}
          />
        )}
      </SectionHeaderActions>
    </SectionHeader>
  );
};

export default PageSectionHeader;
