import { forwardRef, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate  } from "react-router-dom";
import './ImgSlider.css'


interface Props {
  menuItems: any;
}

const ImgSlider = forwardRef(({ menuItems }: Props, ref) => {
  const [startIndex, setStartIndex] = useState(0);
  const sliderRef = useRef(null);
  const scrollAmount = 100; 
  const navigate = useNavigate();

  return (
    <div className="App">
      <button
        className="nav-btn"
        onClick={() =>
          setStartIndex((startIndex - 3 > 0) ? startIndex - 3 : 0)
        }
        disabled={startIndex === 0}
      >
        <FontAwesomeIcon icon={faChevronCircleLeft} />
      </button>
        <div className="container__imgSlider" ref={sliderRef}>
          {menuItems?.slice(startIndex, startIndex + 3).filter(item => item.icon).map((item) => {
            return (
              <div className="imgSlider__img" onClick={() => navigate(item.url)}>
                 {item.icon && 
                     <FontAwesomeIcon icon={item.icon} className='imgSlider__icon' />}
                <div className="appName">{item.appName}</div>
              </div>
            );
          })}
        </div>
        <button
          className="nav-btn"
          onClick={() => setStartIndex(startIndex + 3)}
              disabled={startIndex + 3 >= (menuItems?.length - 1)}
        >
          <FontAwesomeIcon icon={faChevronCircleRight} />
        </button>
    </div>
  )
})

export default ImgSlider;