import { forwardRef, useImperativeHandle } from 'react';
import './Toggle.css';


const Toggle = forwardRef(({ state, onChangeState }, Ref) => {

    useImperativeHandle(Ref, () => ({}));

    return (
        <label className="switch">
            <input type="checkbox" defaultChecked={state} onClick={() => onChangeState && onChangeState(!state)}/>
                <span className="slider round"></span>
        </label>
    );
}
);

export default Toggle;