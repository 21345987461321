const AlertsMock = [
  {
    id: 2175198,
    title: "Schedule Inactive Users Count - Jhon Doe Schedule",
    subdomain: "sanglobaltech",
    account_name: null,
    news_type_id: 5,
    status: 1,
    status_label: "Created",
    description:
      "The Schedules named Jhon Doe Schedule has the following high urgency recommendation : Schedules should not have inactive users . \n The current value is : 1  \n The expected value is : Value = 0",
    team: {
      id: 1,
      name: "CSG Innovation Services",
      responsable: "Bcolina",
      description: "Develop team for CSG",
    },
    severity: 1,
    creation_date: "2024-06-17",
    pd_entities: "schedule",
    pd_entities_labels: "Schedules",
    entity_name: "Jhon Doe Schedule",
    entity_id: "PNPHF6K",
    gwp_id: 113,
    sra_data: [
      {
        id: 19,
        url: "https://drive.google.com/file/d/1d490PTonhKF-TeCn_O3N8McdjnWZ9Oq7/view?usp=sharing",
        name: "Audit for Inactive (Deleted) Users",
      },
      {
        id: 27,
        url: "https://drive.google.com/file/d/1KjIOdkd6PVmkiPTcv3dgZZm5DPkeKeXr/view?usp=sharing",
        name: "Optimize for Deleted Users",
      },
    ],
    relations_data: {
      users: [],
      services: [],
      schedules: [],
      escalation_policies: [],
    },
    one_pager_data: [
      {
        pd_object: "Schedule",
        hc_rule: "Schedules should not have inactive users",
        urgency: ["Critical / Urgent Recommendation"],
        why: "Inactive users on Schedules that are used in an Escalation Policy, can lead to incidents not being created, resulting in extended outages and business impact. Taking action to address this Health Check Alert will ensure proper incident creation, on-call responder notification, escalation, and effective incident management minimizing business impact.",
        what: "This Health Check Alert represents the condition where one or more inactive users exist on a Schedule. An inactive user is one that has been deleted from the system, but traces remain within the Schedule as a reminder that updates are required. An inactive user could appear in one or more Schedule layers, in any time period.",
        what_object_type: "Schedule",
        what_evaluation_method: ["PagerDuty API (/schedules)"],
        what_alerts_values: [
          "Schedule Name, total number of inactive users, expected value (0)",
        ],
        how: "This Health Check Alert has the following parts:",
        how_discovery: [
          "All Schedules are discovered in the customer's instance.",
          "Inactive users on each Schedule layer are identified.",
        ],
        how_analysis: [
          "The internal metric 'Schedule is healthy' is analyzed validating if the inactive users count follows the expected value of zero (0).",
        ],
        how_alerting: [
          "For each internal metric evaluated greater than zero (0), a Health Check Alert alert will be generated.",
        ],
        gwp: null,
        sra: [
          "Audit for Inactive (Deleted) Users",
          "Optimize for Deleted Users.",
        ],
        status: "1",
        long_urgency: 0,
      },
    ],
  },
  {
    id: 2175201,
    title: "Schedule Inactive Users Count - ITSI - N1",
    subdomain: "sanglobaltech",
    account_name: null,
    news_type_id: 5,
    status: 1,
    status_label: "Created",
    description:
      "The Schedules named ITSI - N1 has the following high urgency recommendation : Schedules should not have inactive users . \n The current value is : 4  \n The expected value is : Value = 0",
    team: {
      id: 1,
      name: "CSG Innovation Services",
      responsable: "Bcolina",
      description: "Develop team for CSG",
    },
    severity: 1,
    creation_date: "2024-06-17",
    pd_entities: "schedule",
    pd_entities_labels: "Schedules",
    entity_name: "ITSI - N1",
    entity_id: "PHYB9LX",
    gwp_id: 113,
    sra_data: [
      {
        id: 19,
        url: "https://drive.google.com/file/d/1d490PTonhKF-TeCn_O3N8McdjnWZ9Oq7/view?usp=sharing",
        name: "Audit for Inactive (Deleted) Users",
      },
      {
        id: 27,
        url: "https://drive.google.com/file/d/1KjIOdkd6PVmkiPTcv3dgZZm5DPkeKeXr/view?usp=sharing",
        name: "Optimize for Deleted Users",
      },
    ],
    relations_data: {
      users: [],
      services: [],
      schedules: [],
      escalation_policies: [],
    },
    one_pager_data: [
      {
        pd_object: "Schedule",
        hc_rule: "Schedules should not have inactive users",
        urgency: ["Critical / Urgent Recommendation"],
        why: "Inactive users on Schedules that are used in an Escalation Policy, can lead to incidents not being created, resulting in extended outages and business impact. Taking action to address this Health Check Alert will ensure proper incident creation, on-call responder notification, escalation, and effective incident management minimizing business impact.",
        what: "This Health Check Alert represents the condition where one or more inactive users exist on a Schedule. An inactive user is one that has been deleted from the system, but traces remain within the Schedule as a reminder that updates are required. An inactive user could appear in one or more Schedule layers, in any time period.",
        what_object_type: "Schedule",
        what_evaluation_method: ["PagerDuty API (/schedules)"],
        what_alerts_values: [
          "Schedule Name, total number of inactive users, expected value (0)",
        ],
        how: "This Health Check Alert has the following parts:",
        how_discovery: [
          "All Schedules are discovered in the customer's instance.",
          "Inactive users on each Schedule layer are identified.",
        ],
        how_analysis: [
          "The internal metric 'Schedule is healthy' is analyzed validating if the inactive users count follows the expected value of zero (0).",
        ],
        how_alerting: [
          "For each internal metric evaluated greater than zero (0), a Health Check Alert alert will be generated.",
        ],
        gwp: null,
        sra: [
          "Audit for Inactive (Deleted) Users",
          "Optimize for Deleted Users.",
        ],
        status: "1",
        long_urgency: 0,
      },
    ],
  },
  {
    id: 2175202,
    title:
      "Schedule Inactive Users Count - Francisco Jose Guirado Gomez Schedule",
    subdomain: "sanglobaltech",
    account_name: null,
    news_type_id: 5,
    status: 1,
    status_label: "Created",
    description:
      "The Schedules named Francisco Jose Guirado Gomez Schedule has the following high urgency recommendation : Schedules should not have inactive users . \n The current value is : 1  \n The expected value is : Value = 0",
    team: {
      id: 1,
      name: "CSG Innovation Services",
      responsable: "Bcolina",
      description: "Develop team for CSG",
    },
    severity: 1,
    creation_date: "2024-06-17",
    pd_entities: "schedule",
    pd_entities_labels: "Schedules",
    entity_name: "Francisco Jose Guirado Gomez Schedule",
    entity_id: "PSCCR17",
    gwp_id: 113,
    sra_data: [
      {
        id: 19,
        url: "https://drive.google.com/file/d/1d490PTonhKF-TeCn_O3N8McdjnWZ9Oq7/view?usp=sharing",
        name: "Audit for Inactive (Deleted) Users",
      },
      {
        id: 27,
        url: "https://drive.google.com/file/d/1KjIOdkd6PVmkiPTcv3dgZZm5DPkeKeXr/view?usp=sharing",
        name: "Optimize for Deleted Users",
      },
    ],
    relations_data: {
      users: [],
      services: [],
      schedules: [],
      escalation_policies: [],
    },
    one_pager_data: [
      {
        pd_object: "Schedule",
        hc_rule: "Schedules should not have inactive users",
        urgency: ["Critical / Urgent Recommendation"],
        why: "Inactive users on Schedules that are used in an Escalation Policy, can lead to incidents not being created, resulting in extended outages and business impact. Taking action to address this Health Check Alert will ensure proper incident creation, on-call responder notification, escalation, and effective incident management minimizing business impact.",
        what: "This Health Check Alert represents the condition where one or more inactive users exist on a Schedule. An inactive user is one that has been deleted from the system, but traces remain within the Schedule as a reminder that updates are required. An inactive user could appear in one or more Schedule layers, in any time period.",
        what_object_type: "Schedule",
        what_evaluation_method: ["PagerDuty API (/schedules)"],
        what_alerts_values: [
          "Schedule Name, total number of inactive users, expected value (0)",
        ],
        how: "This Health Check Alert has the following parts:",
        how_discovery: [
          "All Schedules are discovered in the customer's instance.",
          "Inactive users on each Schedule layer are identified.",
        ],
        how_analysis: [
          "The internal metric 'Schedule is healthy' is analyzed validating if the inactive users count follows the expected value of zero (0).",
        ],
        how_alerting: [
          "For each internal metric evaluated greater than zero (0), a Health Check Alert alert will be generated.",
        ],
        gwp: null,
        sra: [
          "Audit for Inactive (Deleted) Users",
          "Optimize for Deleted Users.",
        ],
        status: "1",
        long_urgency: 0,
      },
    ],
  },
];


export default AlertsMock;