import { useState, useEffect, useRef, useCallback } from "react";
import {
  faCaretDown,
  faSitemap,
  faGears,
  faPeopleGroup,
  faUser,
  faCalendarDays,
  faTrash,
  faX,
  faInfoCircle,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../components/Modal";
import Pagination from "../../components/PaginationA";
import Copyright from "../../components/Copyright"
import useCSV from "../../components/DownloadCSV/index.js"
import { apiRoutes, apiRequest } from "../../services";
import SkeletonNews from "../../components/Skeletons/News/News";
import pager from "../../assets/pagey.png"
import ThankYou from "../../components/ThankYou";
import './MyAlertsApp.css'
import { useNavigate  } from "react-router-dom";
import pagerUser from "../../assets/pageyUser.png";
import { controlTour } from "../../services/subjects.js";
import { showCartAlert } from "../../services/subjects.js";
import MultiCheckbox from "../../components/MultiCheckbox";
import Dashboard from "./Dashboard/Dashboard.js"
import DashboardTeam from "./DashboardTeam/DashboardTeam.js"
import ModalHealthCheckAlert from  "./ModalHealthCheckAlert.js"
import Jwt from "../../utils/jwt";
import { useAuth } from "../../UserContext.js";
import Spinner from '../../components/Spinner/Spinner'
import {
  SEVERITY_CRITICAL_ID,
  SEVERITY_MAJOR_ID,
  SEVERITY_HIGH_ID,
  SEVERITY_MEDIUM_ID,
  SEVERITY_LOW_ID,
  SEVERITY_MINOR_ID,
  SEVERITY_CRITICAL,
  SEVERITY_MAJOR,
  SEVERITY_HIGH,
  SEVERITY_MEDIUM,
  SEVERITY_LOW,
  SEVERITY_MINOR,
} from "../../utils/constants.js";


export default function MyAlertsApp() {
  const { addAlertToCart, deleteAlertsInCart, cartAlert, clearAllAlerts } = useAuth()
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const [checkedSortByAlphabetically, setCheckedSortByAlphabetically] = useState(false);
  const [onSearchNews, setOnSearchNews] = useState('');
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [newsCurrent, setNewsCurrent] = useState([]);
  const [loadingNews, setLoadingNews] = useState(false);
  const [severity, setSeverity] = useState([]);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [, setIsOpenDropdownEntity] = useState(false);
  const [isOpenDropdownEntityTeams, setIsOpenDropdownEntityTeams] = useState(false);
  const dropdownRef = useRef(null);
  const [filterSeverity, setFilterSeverity] = useState([]);
  const [filterServices, setFilterServices] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [filterEscalationPolicy, setFilterEscalationPolicy] = useState([]);
  const [filterSchedules, setFilterSchedules] = useState([]);
  const [filterEntitiesTeams, setFilterEntitiesTeams] = useState([]);
  const [showModalCustomerRequest, setShowModalCustomerRequest] = useState(false);
  const [loadingRequestForm, setLoadingRequestForm] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [formValues, setFormValues] = useState({
    requestType: 'Account Team Request',
    requestUrgency: "Normal Urgency 7-10 Business Days",
    requestDetails: ''
  });
  const [showErrorCustomerRequest, setShowErrorCustomerRequest] = useState(false);
  const [premiumRequest, setPremiumRequest] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState({});
  const [, setFirstElement] = useState(0);
  const [, setLastElement] = useState(7);
  const [queryParams, setQueryParams] = useState({});
  const [isOpenDropdownUsers, setIsOpenDropdownUsers] = useState(false);
  const [isOpenDropdownServices, setIsOpenDropdownServices] = useState(false);
  const [isOpenDropdownEscalationPolicy, setIsOpenDropdownEscalationPolicy] = useState(false);
  const [isOpenDropdownSchedules, setIsOpenDropdownSchedules] = useState(false);
  const [showModalOpenCart, setShowModalOpenCart] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [loadingSubmitCart, setLoadingSubmitCart] = useState(false);
  const [showGWP, setShowGWP] = useState(false);
  const [GWP, setGWP] = useState([]);
  const [selectedGWP, setSelectedGWP] = useState({});
  const [loadingGWP, setLoadingGWP] = useState(false);
  const [searchSeverity, setSearchSeverity] = useState('');
  const [searchUsers, setSearchUsers] = useState('');
  const [searchServices, setSearchServices] = useState('');
  const [searchEscalationPolicy, setSearchEscalationPolicy] = useState('');
  const [searchSchedules, setSearchSchedules] = useState('');
  const [currentSession, setCurrentSession] = useState('team');
  const [totalAlerts, setTotalAlerts] = useState('0');
	const [totalByTypeEntity, setTotalByTypeEntity] = useState([]);
  const [showModalYesNot, setShowModalYesNot] = useState(false);
  const dropdownEntityTeamsRef = useRef(null);
  const dropdownRefUsers = useRef(null);
  const dropdownServicesRef = useRef(null);
  const dropdownEscalationPolicyRef = useRef(null);
  const dropdownSchedulesRef = useRef(null);
  const [showModalHealthCheckAlert, setShowModalHealthCheckAlert] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadMonthlyLoading, setDownloadMonthlyLoading] = useState(false);
  const [onePage, setOnePage] = useState({});
  const [entitiesTeams, setEntitiesTeams] = useState([]);
  const [entitiesUsers, setEntitiesUsers] = useState([]);
  const [entitiesServices, setEntitiesServices] = useState([]);
  const [escalationPolicy, setEscalationPolicy] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [errorReport, setErrorReport] = useState(false);
  const [isUsersEmpty, setIsUsersEmpty] = useState(false);
  const [isServicesEmpty, setIsServicesEmpty] = useState(false);
  const [isEscalationPolicyEmpty, setIsEscalationPolicyEmpty] = useState(false);
  const [isSchedulesEmpty, setIsSchedulesEmpty] = useState(false);
  const [selectNew, setSelectNew] = useState({});
  const [searchEntityTeam, setSearchEntityTeams] = useState('');
  const [profileRegion, setProfileRegion] = useState('');
  const [userRole, setUserRole] = useState("");
  const [pageNavigationClick, setPageNavigationClick] = useState(false);
  const { downloadCSV } = useCSV();


  useEffect(() => {
    getProfile()
    getPremiumRequest()
    getSeverity()
    getTotalAlert()
    getEntityTeams('Teams',setEntitiesTeams)


    showCartAlert.subscribe((state) => {
      setShowModalOpenCart(state)
    });

    const tokenData = Jwt.getItem("all");
    if (tokenData !== null) {
        getRole(tokenData);
    }
  }, [])
  useEffect(() => {
    const currentStep = sessionStorage.getItem("currentStep");
    setTimeout(() => {
      if (currentStep === "6") {
        onChangeCurrentSession('alerts');
          controlTour.next(true);
      }
    }, 100);
  }, [sessionStorage.getItem("currentStep")]);

  useEffect(() => {
    if(filterEntitiesTeams.length > 0) {
      getAllFilters()
    } else {
    setEntitiesUsers([])
    setEntitiesServices([])
    setEscalationPolicy([])
    setSchedules([])
  }

  if(filterEntitiesTeams.length === 0  && (filterUsers.length > 0 || filterEscalationPolicy.length > 0 || filterSchedules.length > 0 )) {
    delete queryParams.entity_id
    setFilterEntitiesTeams([])
    setFilterUsers([])
    setFilterServices([])
    setFilterEscalationPolicy([])
    setFilterSchedules([])
    setOnSearchNews('')
    setNewsCurrent(news)
    setTotalPage(totalAlerts)
  }
  }, [filterEntitiesTeams]);


  useEffect(() => {
    const sortNews = (newsArray, ascending) => {
      return newsArray.sort((a, b) => 
        ascending ? a?.title.localeCompare(b?.title) : b?.title.localeCompare(a?.title)
      );
    };

    const sortedNewsCurrent = sortNews([...newsCurrent], checkedSortByAlphabetically);
    const sortedNews = sortNews([...news], checkedSortByAlphabetically);

    setNews(sortedNews);
    setNewsCurrent(sortedNewsCurrent);
  }, [checkedSortByAlphabetically]);


  useEffect(() => {
    if(Object.keys(queryParams).length > 0) {
      setCurrentPage(0, getNewsParams())
    } else {
      currentSession === 'alerts' && getNews()
    }
  }, [queryParams]);

  useEffect(() => {
    if(pageNavigationClick) {
      getNewsParams()
      setPageNavigationClick(false)
    }
    if(currentPage === 0) {
      setNewsCurrent(news)
    }
  }, [currentPage]);


  useEffect(() => {
    if (onSearchNews === '') {
      addQueryParam('search_text', onSearchNews)
    }
  }, [onSearchNews]);


  const closeDropdowns = useCallback((event) => {
    const dropdowns = [
      { ref: dropdownRef, setter: setIsOpenDropdown },
      { ref: dropdownRefUsers, setter: setIsOpenDropdownUsers },
      { ref: dropdownServicesRef, setter: setIsOpenDropdownServices },
      { ref: dropdownEscalationPolicyRef, setter: setIsOpenDropdownEscalationPolicy },
      { ref: dropdownSchedulesRef, setter: setIsOpenDropdownSchedules },
      { ref: dropdownEntityTeamsRef, setter: setIsOpenDropdownEntityTeams }
    ];

    dropdowns.forEach(({ ref, setter }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setter(false);
      }
    });
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeDropdowns);
    return () => document.removeEventListener('click', closeDropdowns);
  }, [closeDropdowns]);


  const getRole = (tokenData) => {
    tokenData.claims.roles.map((role) => {
      setUserRole(role.role);
    });
  };


  const getPremiumRequest = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.premiumRequest,
    });
    if (!error) {
      setPremiumRequest(data?.data?.request_severity)
    }
  }

  const getAllFilters = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getFilterTeams}?entity_id=${filterEntitiesTeams}`,
    });

    if(Object.keys(data).length > 0) {
      setEntitiesUsers(data['users'])
      setEntitiesServices(data['services'])
      setEscalationPolicy(data['escalation_policies'])
      setSchedules(data['schedules'])
      setIsUsersEmpty(data['users'].length === 0);
      setIsServicesEmpty(data['services'].length === 0);
      setIsEscalationPolicyEmpty(data['escalation_policies'].length === 0);
      setIsSchedulesEmpty(data['schedules'].length === 0);
    }
  }

  const getTotalAlert = async () => {
    setLoadingTotal(true);
    try {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.getTotalAlert,
      });

      if (data?.status_code === 200) {
        data?.news_summary?.total_rows && setTotalAlerts(data?.news_summary?.total_rows);
        data?.news_summary?.news_summary && setTotalByTypeEntity(data?.news_summary?.news_summary);
      } else {
        console.error('Error fetching total alerts:', error);
      }
    } catch (error) {
      console.error('Error during API request:', error);
    } finally {
      setLoadingTotal(false);
    }
  }

  const getNews = async () => {
    setNews([])
    setNewsCurrent([])
    setLoadingNews(true)
    setErrorAlert(false)

    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getNews}?limit=100&page=${currentPage + 1}`,
    });


    if (data?.news?.news) {
      setNews(data?.news?.news)
      setTotalPage(data?.news?.total_rows)
      setNewsCurrent(data?.news.news)
      setLoadingNews(false)
    } else {
      setErrorAlert(true)
      setLoadingNews(false)
      console.error('Error fetching:', error);
    }
  };
  
  const getNewsParams = async () => {
    setNewsCurrent([])
    setLoadingNews(true)
    setErrorAlert(false)
    let updatedQueryParams = queryParams

    if (
      (filterEntitiesTeams.length > 0 && filterSeverity.length > 0 && 
       filterUsers.length === 0 &&
       filterServices.length === 0 &&
       filterEscalationPolicy.length === 0 &&
       filterSchedules.length === 0 &&
       onSearchNews === '') || 
      (filterEntitiesTeams.length > 0 && filterSeverity.length === 0 && 
       filterUsers.length === 0 &&
       filterServices.length === 0 &&
       filterEscalationPolicy.length === 0 &&
       filterSchedules.length === 0 &&
       onSearchNews === '') 
    ) {
      const matchedRelations = entitiesTeams
        .filter(entity => filterEntitiesTeams.includes(entity.entity_id))
        .map(entity => entity.relations_data)
        .flat();

      const updatedEntityIds = [...new Set([...matchedRelations, ...(queryParams.entity_id || [])])];
    
      updatedQueryParams = {
        ...queryParams,
        entity_id: updatedEntityIds,
      };
    }
    

    const newQueryString = Object.keys(updatedQueryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(updatedQueryParams[key])}`)
    .join('&');


      const page = Object.keys(queryParams).length > 0 ? 1 : currentPage + 1
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.getNews}?${newQueryString}&limit=100&page=${page}`,
      });

      if (data.status_code === 200) {
          setLoadingNews(false)
          setNewsCurrent(data?.news.news);
          setTotalPage(data?.news?.total_rows)
      } else {
        setErrorAlert(true)
        setLoadingNews(false)
        console.error('Error fetching:', error);
      }
    // }
  };


  const handleToggleAlphabetically = () => setCheckedSortByAlphabetically(!checkedSortByAlphabetically);

  const handleDropdownClick = () => {
    setIsOpenDropdownEntity(false); 
    setIsOpenDropdown(!isOpenDropdown);
  };

  const handleDropdownEntityTeamsClick = () => setIsOpenDropdownEntityTeams(!isOpenDropdownEntityTeams)
  
  const handleDropdownUsersClick = () => setIsOpenDropdownUsers(!isOpenDropdownUsers)
  
  const handleDropdownServicesClick = () => setIsOpenDropdownServices(!isOpenDropdownServices)
  
  const handleDropdownEscalationPolicyClick = () => setIsOpenDropdownEscalationPolicy(!isOpenDropdownEscalationPolicy)
  
  const handleDropdownClickSchedules = () => setIsOpenDropdownSchedules(!isOpenDropdownSchedules)
  

  const onSubmit = async () => {
    setLoadingRequestForm(true)
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.premiumRequest,
      data: {
        "request_type": 'Account Team Request',
        "request_urgency": formValues?.requestUrgency,
        "request_details": formValues?.requestDetails,
        'request_alert_title': selectedAlert?.title
      }
    });
    if (data?.status === 200) {
      setShowThankYou(true)
      setLoadingRequestForm(false)
      setTimeout(() => {
        setShowThankYou(false)
        setFormValues({
          requestType: 'Account Team Request',
          requestUrgency: "Critical Urgency 1-3 Business Days",
          requestDetails: ''
        })
        setShowModalCustomerRequest(!showModalCustomerRequest)
      }, 1000);
    } else {
      setLoadingRequestForm(false)
      setShowErrorCustomerRequest(true)
      console.error('Error fetching:', error);
    }
  }

  const handleInputChange = (e) => {
    const fieldName = e.target.name;

    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: e.target.value,
    }))
  }

  const showTextSeverity = (severity) => {
    const severityMapping = {
      1: 'Critical',
      13: 'Major',
      11: 'High',
      4: 'Medium',
      2: 'Low',
      12: 'Minor',
    };
    return severityMapping[severity] || '';
  }

  const backgroundStatus = (status) => {
    const severityMapping = {
      1: { 'backgroundStatus': '#FFE7E7', 'color': '#f00' },
      13: { 'backgroundStatus': '#ff872e', 'color': '#fff' },
      11: { 'backgroundStatus': '#f7c604', 'color': '#fff' },
      4: { 'backgroundStatus': '#690375', 'color': '#fff' },
      2: { 'backgroundStatus': '#329cad', 'color': '#fff' },
      12: { 'backgroundStatus': '#cecece', 'color': '#808080' },
    };
    return severityMapping[status] || '';
  }

  const returnIcon = (entity) => {
    const icon = {
      'team': faPeopleGroup,
      'service': faGears,
      'escalation_policy': faSitemap,
      'user': faUser,
      'schedule': faCalendarDays,
      'schedules': faCalendarDays,
      'teams': faPeopleGroup,
      'users': faUser,
      'escalation policy': faSitemap,
    };

    return icon[entity.toLowerCase()] || faCalendarDays;
  }

  const toggleCheckbox = (severity) => {
    addQueryParam('severity', severity);
    toggleCheckboxAll(severity, setFilterSeverity)
  }

  const  toggleCheckboxEntitiesTeams  = (entities) => { 
    addQueryParam('entity_id', entities);
    toggleCheckboxAll(entities, setFilterEntitiesTeams)
  }

  const toggleCheckboxUsers = (entities) => {
    addQueryParam('entity_id', entities);
    toggleCheckboxAll(entities, setFilterUsers)
  }

  const toggleCheckboxServices = (entities) => {
    addQueryParam('entity_id', entities);
    toggleCheckboxAll(entities, setFilterServices)
  }

  const toggleCheckboxEscalationPolicy = (entities) => {
    addQueryParam('entity_id', entities);
    toggleCheckboxAll(entities, setFilterEscalationPolicy)
  }

  const toggleCheckboxSchedules = (entities) => {
    addQueryParam('entity_id', entities);
    toggleCheckboxAll(entities, setFilterSchedules)
  }

  const addQueryParam = (key, value) => {
    setQueryParams(prevParams => {
      const newParams = { ...prevParams };
      const isEmptyValue = value === undefined || value === null || value === '';

      if (!isEmptyValue) {
        if (newParams.hasOwnProperty(key)) {
          const index = newParams[key].indexOf(value);
          if (index !== -1) {
            newParams[key] = newParams[key].filter(v => v !== value);
            if (newParams[key].length === 0) {
              delete newParams[key];
            }
          } else {
            newParams[key] = [...newParams[key], value];
          }
        } else {
          newParams[key] = [value];
        }
      } else {
        const { [key]: removedParam, ...restParams } = newParams;
        return restParams;
      }
  
      return newParams;
    });
  };
  

  const getSeverity = async () => {
    try {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.getSeverity,
      });
      if (data?.data) {
        setSeverity(data.data)
      }
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  const onSearchAlerts = () => {
    addQueryParam('search_text', onSearchNews)
  }


  const onSubmitCart = async () => {
    setLoadingSubmitCart(true)
    const body = buildRequestBody(cartAlert);

    const [error, data] =userRole === "Free Trial Viewer" ? await apiRequest({
      method: "post",
      url: apiRoutes.trialContact,
      data: {
        "request_details":body,
        section: "My Alerts"
      }
    }) : await apiRequest({
      method: "post",
      url: apiRoutes.multiservicerequest,
      data: body
    });

    if (data?.status === 200) {
      setLoadingSubmitCart(false)
      setSubmitSuccess(true)
      clearAllAlerts()
      setFormValues({
        requestType: 'Account Team Request',
        requestUrgency: "Normal Urgency 7-10 Business Days",
        requestDetails: ''
      });
      if(Object.keys(queryParams).length  > 0) {
        getNewsParams()
      } else {
        getNews()
      }
      setTimeout(() => {
        setSubmitSuccess(false)
        setShowModalOpenCart(false)
      }, 1500);
    }
  }

  const createAlertObject = (alerts) =>
    alerts.map(alert => ({
      alert_id: alert.id,
      request_alert_title: alert.title,
    }));
  
  const buildRequestBody = (cartAlert) => {
    const alertsRequested = cartAlert?.filter(alert => !alert.multiple);
  
    
    return {
      request_type: 'Account Team Request',
      request_urgency: formValues.requestUrgency,
      request_details: formValues.requestDetails,
      alerts_requested: createAlertObject(alertsRequested),
      alerts_requested_multiple: returnArrayMultipleCart(),
    };
  };

  const returnArrayMultipleCart = () => {
    const alertsRequestedMultiple = cartAlert?.filter(alert => alert.multiple);
    const arrayMultiple  = alertsRequestedMultiple?.reduce((acc, alert) => {
      let team = acc.find(item => item.team_name === alert.team_name);
      if (!team) {
        team = { team_name: alert.team_name, alerts: [] };
        acc.push(team);
      }
      team.alerts.push({
        alert_id: alert.id,
        request_alert_title: alert.title,
      });
    
      return acc;
    }, []);
    return arrayMultiple
  }


  const onShowGWP = async (gwpId) => {
    setLoadingGWP(true)
    setGWP([])
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getGWP}/${gwpId}`,
      newDomain: true,
    });
    if (data?.length > 0) {
      setGWP(data)
      setLoadingGWP(false)
    } else {
      setLoadingGWP(false)
    }
  }

  const goToSra = (sraSelect) => {
    let sra = sraSelect?.map(item => item?.name).join(','); 
    navigate(`/solution-gallery/${sra}`)
  }

  const getSelectedGWP = (alert) => setSelectedGWP(alert)
  

  const handleBackSpace = (e) => {
    if (!queryParams?.search_text?.includes(onSearchNews) && e.key === 'Enter') {
      addQueryParam('search_text', '');
      onSearchAlerts(); 
    } 
  }

  const toggleCheckboxAll = async (item, setState) => {
    setState(prevState => {
      const newState = [...prevState];
      const itemIndex = newState.indexOf(item);

      if (itemIndex === -1) {
        newState.push(item);
      } else {
        newState?.splice(itemIndex, 1);
      }
      return newState;
    });
  };

  const filteredOptions = (options, searchState, field) => {
    return options?.filter(option =>
      (field ? option[field] : option)?.toLowerCase().includes(searchState?.toLowerCase()) 
    );
  };

  const onChangeCurrentSession = (item) => {
    allAlert()
    setCurrentSession(item)
  }
  
  const downloadAlerts = async (status) => {
    setDownloadLoading(true)
    setShowModalYesNot(false)
    let params = ''
    if (status === 'yes') {
      params = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');
    }

    try {
      await downloadCSV(`${apiRoutes.downloadAlerts}?${params}`, "Alerts", "csv");
    } catch (error) {
      console.error('Error downloading support summary CSV:', error);
    } finally {
      setDownloadLoading(false);
    }
  } 

  const downloadMonthlyOverview =  async (status) => {
    setDownloadMonthlyLoading(true);
    try {
      const resp  = await downloadCSV(apiRoutes.downloadMonthlyOverview, "MonthlyOverview", "pdf");
    
      if (resp[0].status === 404) {
        setErrorReport(true)
        setTimeout(() => setErrorReport(false), 1000);
      }
    } catch (error) {
      console.error('Error downloading support summary CSV:', error);
    } finally {
      setDownloadMonthlyLoading(false);
    }
  }

  const validateParameterToShowModal = async () => {
    let status = ''
    if (Object.keys(queryParams).length >  0) {
      setShowModalYesNot(true)
      status = 'yes'
      return
    }
    setShowModalYesNot(false)
    downloadAlerts(status)
  }

  const getOnePage = async (onePage, newItem) => { 
    setSelectNew(newItem)
    setOnePage(onePage)
  }

  const getEntityTeams = async (entities, setEntities) => {
    setEntities([])
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.filterEntity}?pd_entities=${entities}`,
    });

    if (data?.data) {
      setEntities(data?.data?.entities)
    }
  }

  const allAlert  = async () => {
    setFilterEntitiesTeams([])
    setFilterUsers([])
    setFilterServices([])
    setFilterEscalationPolicy([])
    setFilterSchedules([])
    setFilterSeverity([])
    setQueryParams({})
    setOnSearchNews('')
    setNewsCurrent(news)
  }

  const tryAgin = () => {
    if(Object.keys(queryParams).length  === 0) {
      getNews()
    } else {
      getNewsParams()
    }
  }

  const getProfile = async () => { 
    const tokenData = Jwt.getItem("all");
    if (tokenData) {
      tokenData?.claims?.roles.map(async (role) => {
        if(role.role  === 'Admin' || role.role === 'Premium Csg Member') {
          const region = localStorage.getItem("region");
          setProfileRegion(region)
        }
        if (role.role  === 'Customer') {
          const [error, data] = await apiRequest({
            method: "get",
            url: apiRoutes.userProfile,
          });
          if (data?.data) {
            setProfileRegion(data?.data.customer_region || []);
          } 
        }
      })
    }
  };

  const formatTime = (minutes) => {  
    if (minutes === 60) {
      return "1 hour";
    } else if (minutes % 60 === 0) {
      return `${minutes / 60} ${minutes / 60 === 1 ? 'hour' : 'hours' } `;
    } else if (minutes < 60) {
      return `${parseFloat(minutes).toFixed(0)} ${parseFloat(minutes).toFixed(0) === '1' ? 'minute' :  'minutes'}`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours > 0 ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : ''} ${remainingMinutes > 0 ? `${remainingMinutes.toFixed(0)}  ${remainingMinutes.toFixed(0) === 1 ?  'minute' :  'minutes' }` : ''}`.trim();
    }
  }


  const returnIdSeverity = (severity) => {
    const severityToIdMap = {
      [SEVERITY_CRITICAL]: SEVERITY_CRITICAL_ID,
      [SEVERITY_MAJOR]: SEVERITY_MAJOR_ID,
      [SEVERITY_HIGH]: SEVERITY_HIGH_ID,
      [SEVERITY_MEDIUM]: SEVERITY_MEDIUM_ID,
      [SEVERITY_LOW]: SEVERITY_LOW_ID,
      [SEVERITY_MINOR]: SEVERITY_MINOR_ID,
    };
  
    return severityToIdMap[severity] || ''; 
  };

  const filterBySeverityClickDashboard = (alert) => {


    let score = 0
    let severity = ""
    const criticalAlert = alert.alerts_count_by_severity.filter(severity => severity.alert_level === SEVERITY_CRITICAL)
    const criticalMajor = alert.alerts_count_by_severity.filter(severity => severity.alert_level === SEVERITY_MAJOR)
    const criticalHigh = alert.alerts_count_by_severity.filter(severity => severity.alert_level === SEVERITY_HIGH)

    if (criticalAlert.length > 0) {
      criticalAlert.forEach(alert => {
        if (alert.alert_count > score) {
          score = alert.alert_count
          severity = alert.alert_level
        }
      });
    } else if (criticalMajor.length > 0) {
      criticalMajor.forEach(alert => {
        if (alert.alert_count > score) {
          score = alert.alert_count
          severity = alert.alert_level
        }
      });
    } else if (criticalHigh.length > 0) {
      criticalHigh.forEach(alert => {
        if (alert.alert_count > score) {
          score = alert.alert_count
          severity = alert.alert_level
        }
      });
    }
    toggleCheckbox(returnIdSeverity(severity))
  }



  const filteredOptionsTypeEntityTeams = filteredOptions(entitiesTeams, searchEntityTeam, 'entity_name')
  const filteredOptionsUsers = filteredOptions(entitiesUsers, searchUsers, 'entity_name');
  const filteredOptionsServices = filteredOptions(entitiesServices, searchServices, 'entity_name');
  const filteredOptionsEscalationPolicy = filteredOptions(escalationPolicy, searchEscalationPolicy, 'entity_name');
  const filteredOptionsSchedules = filteredOptions(schedules, searchSchedules, 'entity_name');
  const filteredOptionsSeverity = filteredOptions(severity, searchSeverity, 'description');
  const disabledBtnSave = (formValues.requestType !== '' && formValues.requestUrgency !== '' && formValues.requestDetails !== '') ? '' : 'disabled__dtnSave'
  const simpleCart = createAlertObject(cartAlert?.filter(alert => !alert.multiple))
  const multipleCart = returnArrayMultipleCart()


  return (
    <div className='container__newsAdministrationAlerts'>
      <div className="container__filterAlert buttons__selectSession">
          <div className="container__buttons--selectSession">
            <div onClick={() => onChangeCurrentSession('team') } className={currentSession === 'team' ? "selectSession__button" : "disabled__selectSession"}>Team's Dashboard</div>  
            <div onClick={() => onChangeCurrentSession('alerts') } className={currentSession === 'alerts' ? "selectSession__button" : "disabled__selectSession"}>Health Check Alerts Overview</div>  
            <div onClick={() => onChangeCurrentSession('dashboard') } className={currentSession === 'dashboard' ? "selectSession__button" : "disabled__selectSession"}>Health Check Alerts Dashboard</div>  
          </div>
          <div onClick={() => navigate('/home')} className="container__backDashboard"> <FontAwesomeIcon  className="fa__ArrowLeft" icon={faArrowLeft} /> Back to Customer Dashboard</div>
      </div>
      {currentSession === 'dashboard' && 
        <Dashboard  
          returnIdSeverity={returnIdSeverity}
          toggleCheckbox={toggleCheckbox}
          searchedSelectedAlert={(alert, isFilterActive) => {
            allAlert()
            addQueryParam('search_text', alert?.alert_name || alert)
            setOnSearchNews(alert?.alert_name || alert)
            setCurrentSession('alerts')
            isFilterActive && filterBySeverityClickDashboard(alert)
          }}
        />}
      {currentSession === 'team' && <DashboardTeam
        searchedSelectedAlert={(alert) => {
          allAlert()
          addQueryParam('search_text', alert)
          setOnSearchNews(alert)
          setCurrentSession('alerts')
        }
        } 
        toggleCheckboxEntitiesTeams={toggleCheckboxEntitiesTeams}
      />}
      {currentSession === 'alerts' && 
        <div>
          <div>
            <div className='profile__CustomerSnapshots header__myAlerts'>
              <div className="container__infoCustomerSnapshots">
                  <div>
                    <div className="container__imgPagerUser imgPagerDuty">
                      <img
                        className="imgPagerUser__customerSnapshots"
                        src={pagerUser}
                        alt=''
                      />
                    </div>
                  </div>
                  <div className="header__infoDashboard">
                      <div className="" >
                          <div className="welcome__dashboard">{'Welcome to the PagerDuty Health Check Alerts App'}</div>
                          <div className="container__tooltipInfo">
                            <div className="tooltip-container tooltip__containerAlert">
                                <div>
                                  <div className="entities_labels">Total Alerts</div>
                                  {!loadingTotal && <span className="total__entities">{totalAlerts}</span>}
                                  {loadingTotal && 
                                    <div>
                                      <svg viewBox="25 25 50 50" className="small-svg">
                                        <circle r="20" cy="50" cx="50"></circle>
                                      </svg>
                                    </div>}
                                </div>
                              </div>
                            {totalByTypeEntity?.map((item, index )=> (
                              <div key={index}  className="tooltip-container tooltip__containerAlert">
                                <div>
                                  <div className="entities_labels">{item?.pd_entities_labels}</div>
                                  <span className="total__entities">{item?.total_news}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                      </div>
                      <div>	
                        <div className="container__buttonDownload">
                          <div className="selectSession__button button__andSpinner">
                              <div onClick={() => validateParameterToShowModal()} className="buttonDownload">Download Health Check Alerts</div>
                              {downloadLoading &&  <div>
                                <svg viewBox="25 25 50 50" className="small-svg">
                                <circle r="20" cy="50" cx="50"></circle>
                              </svg></div>}
                          </div>
                          <div className="selectSession__button button__andSpinner">
                              <div onClick={() => downloadMonthlyOverview()} className="buttonDownload">Historical Health Check Report</div>
                              {downloadMonthlyLoading &&  <div>
                                <svg viewBox="25 25 50 50" className="small-svg">
                                <circle r="20" cy="50" cx="50"></circle>
                              </svg></div>}
                          </div>
                        </div>
                        {errorReport && <div className="not__haveHistory">We do not have enough history at this time to generate this report.</div>}
                          {showModalYesNot && 
                            <div className="container__yesAndNot">
                              <div> Do you want to download it with the filters you have selected?</div>
                              <div className="buttons__yesNot">
                                  <button onClick={()=> downloadAlerts('yes')} className="selectSession__button buttonDownload">Yes</button>
                                  <button onClick={()=> downloadAlerts('no')} className="selectSession__button buttonDownload">No</button>
                                  <button onClick={()=> setShowModalYesNot(false)} className="selectSession__button buttonDownload">Close</button>
                              </div>
                          </div>}
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="container__filterAlert" id="advancedFilters">
            <div className="title__header">
                <div className="title__requestedFilter">Advanced Filters</div>
              <div className="show__cart">
                  <div onClick={() => clearAllAlerts()}  className="container__cartAlert" >
                    Clear cart
                  </div>
                </div>
            </div>
            <div className="container__filterAlerts"> 
              <MultiCheckbox
                label="Team Name"
                options={entitiesTeams}
                filterState={filterEntitiesTeams}
                setFilterState={setFilterEntitiesTeams}
                isOpenDropdown={isOpenDropdownEntityTeams}
                searchState={searchEntityTeam}
                setSearchState={setSearchEntityTeams}
                filteredOptions={filteredOptionsTypeEntityTeams}
                handleDropdownClick={handleDropdownEntityTeamsClick}
                toggleCheckbox={toggleCheckboxEntitiesTeams}
                mapFunction={(item) => item.entity_id}
                nameLabel={(item) => item.entity_name}
                dropdownRef={dropdownEntityTeamsRef}
                tooltip={true}
                disabled={!filteredOptionsTypeEntityTeams.length > 0}
                disabledItem={loadingNews}
                /> 
                <MultiCheckbox
                label="Users"
                options={entitiesUsers}
                filterState={filterUsers}
                setFilterState={setFilterUsers}
                searchState={searchUsers}
                setSearchState={setSearchUsers}
                filteredOptions={filteredOptionsUsers}
                handleDropdownClick={handleDropdownUsersClick}
                toggleCheckbox={toggleCheckboxUsers}
                isOpenDropdown={isOpenDropdownUsers}
                mapFunction={(item) => item.entity_id}
                nameLabel={(item) => item.entity_name}
                dropdownRef={dropdownRefUsers}
                disabled={filterEntitiesTeams.length === 0}
                isEmptyValue={isUsersEmpty}
                disabledItem={loadingNews}
                />
                <MultiCheckbox
                label="Services"
                options={entitiesServices}
                filterState={filterServices}
                setFilterState={setFilterServices}
                searchState={searchServices}
                setSearchState={setSearchServices}
                filteredOptions={filteredOptionsServices}
                handleDropdownClick={handleDropdownServicesClick}
                toggleCheckbox={toggleCheckboxServices}
                isOpenDropdown={isOpenDropdownServices}
                mapFunction={(item) => item.entity_id}
                nameLabel={(item) => item.entity_name}
                disabled={filterEntitiesTeams.length === 0}
                dropdownRef={dropdownServicesRef}
                isEmptyValue={isServicesEmpty}
                disabledItem={loadingNews}
                />
                <MultiCheckbox
                label="Escalation Policy"
                options={escalationPolicy}
                filterState={filterEscalationPolicy}
                setFilterState={setFilterEscalationPolicy}
                searchState={searchEscalationPolicy}
                setSearchState={setSearchEscalationPolicy}
                filteredOptions={filteredOptionsEscalationPolicy}
                handleDropdownClick={handleDropdownEscalationPolicyClick}
                toggleCheckbox={toggleCheckboxEscalationPolicy}
                isOpenDropdown={isOpenDropdownEscalationPolicy}
                mapFunction={(item) => item.entity_id}
                nameLabel={(item) => item.entity_name}
                disabled={filterEntitiesTeams.length === 0}
                dropdownRef={dropdownEscalationPolicyRef}
                isEmptyValue={isEscalationPolicyEmpty}
                disabledItem={loadingNews}
                />
                <MultiCheckbox
                label="Schedules"
                options={schedules}
                filterState={filterSchedules}
                setFilterState={setFilterSchedules}
                searchState={searchSchedules}
                setSearchState={setSearchSchedules}
                filteredOptions={filteredOptionsSchedules}
                handleDropdownClick={handleDropdownClickSchedules}
                toggleCheckbox={toggleCheckboxSchedules}
                isOpenDropdown={isOpenDropdownSchedules}
                mapFunction={(item) =>  item.entity_id}
                nameLabel={(item) => item.entity_name}
                dropdownRef={dropdownSchedulesRef}
                disabled={filterEntitiesTeams.length === 0}
                isEmptyValue={isSchedulesEmpty}
                disabledItem={loadingNews}
                />
                <MultiCheckbox
                label="Severity"
                options={severity}
                filterState={filterSeverity}
                setFilterState={setFilterSeverity}
                searchState={searchSeverity}
                setSearchState={setSearchSeverity}
                filteredOptions={filteredOptionsSeverity}
                handleDropdownClick={handleDropdownClick}
                toggleCheckbox={toggleCheckbox}
                isOpenDropdown={isOpenDropdown}
                mapFunction={(item) => item.id}
                nameLabel={(item) => item.description}
                dropdownRef={dropdownRef}
                disabled={loadingNews}
                />
            </div>
            <div className="container__searchWrapper">
              <div className="input__containerNewsAdministration">
                <input
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="search__input"
                  placeholder="Search by Health Check Alert Title..."
                  value={onSearchNews}
                  onChange={(e) => setOnSearchNews(e.target.value)}
                  onKeyDown={(e) => handleBackSpace(e)}
                />
              </div>
              <button 
              onClick={() => {
                if (!queryParams?.search_text?.includes(onSearchNews)) {
                  addQueryParam('search_text', '');
                  onSearchAlerts(); 
                } 
              }} 
              disabled={queryParams?.search_text?.some(text => text === onSearchNews) ? true : false}
              className="btn__search" >Search</button>
            </div>
            <div className="container__paginationAlerts">
              <Pagination
                numberOfElements={totalPage}
                showElementsPerPage={100}
                onPaginationChange={(firstElement, lastElement) => {
                  setFirstElement(firstElement);
                  setLastElement(lastElement);
                }}
                currentPage={(e) => setCurrentPage(e)}
                pageClickNavigation={(e) => setPageNavigationClick(e)}
                />
                <div className="newsAdministration__toggle filterAZ">
                  <input
                    type="checkbox"
                    id="toggleAlphabetically"
                    checked={checkedSortByAlphabetically}
                    onChange={handleToggleAlphabetically}
                    className="toggle__orderInput"
                  />
                  <label htmlFor="toggleAlphabetically" className="toggle__sliderMyAlerts" >
                    {!checkedSortByAlphabetically && <div className="toggle__active">Az</div>}
                    {checkedSortByAlphabetically && <div className="toggle__disabled">Za</div>}
                  </label>
                </div>
                <div className="show__cart" onClick={() => allAlert()}>Clear filter</div>
            </div>
        </div>
        <div className="container__alertAdministration">
          <div className="second-div">
            {loadingNews && !newsCurrent.length && <SkeletonNews />}
            <div className="alert-grid">
              {newsCurrent?.map((newItem, index) =>   (
                <div key={index} className="news-item__home news__alerts" style={{display: 'flex', flexDirection: 'column'}}>
                  <div className="container__titleAndInfo">
                    <div className="title__homeAlert">
                      {newItem?.title}
                    </div>
                    <button 
                    disabled={newItem?.one_pager_data?.length === 0}
                    onClick={() => {
                      setShowModalHealthCheckAlert(true)
                      getOnePage(newItem?.one_pager_data?.length && newItem?.one_pager_data[0], newItem)
                    }} 
                    className="icon__infoMyAlert"><FontAwesomeIcon icon={faInfoCircle} /></button>
                  </div>
                  <div className="container__entity">
                    <div className="icon__entity"><FontAwesomeIcon  icon={returnIcon(newItem?.pd_entities)} /></div>
                    <div>{newItem?.pd_entities_labels ? newItem?.pd_entities_labels : newItem?.pd_entities}</div>
                  </div>
                  <p className="description__newHome">
                    {newItem?.description?.split('The current value is : ')[0]?.replace('. .', '.') || 'N/A'}
                  </p>
                  <p className="description__newHome description__newAlert">
                    <b>The current value is : </b>
                    {(() => {
                      const parts = newItem?.description?.split('The current value is : ');
                      if (parts && parts.length > 1) {
                        const currentValue = parts[1].split(' The expected value is : ')[0];
                        return currentValue || 'N/A';
                      }
                      return 'N/A';
                    })()}
                  </p>
                  <p className="description__newHome">
                    <b>The expected value is : </b>
                    {(() => {
                      const parts = newItem?.description?.split('The current value is : ');
                      if (parts && parts.length > 1) {
                        const expectedParts = parts[1].split(' The expected value is : ');
                        if (expectedParts.length > 1) {
                          return expectedParts[1] || 'N/A';
                        }
                      }
                      return 'N/A';
                    })()}
                  </p>
                  <div className="meta__home" style={{marginTop: 'auto'}}>
                      <span className="urgency__home" style={{backgroundColor: backgroundStatus(newItem?.severity).backgroundStatus, color: backgroundStatus(newItem.severity).color}}>{showTextSeverity(newItem?.severity)}</span>
                      <span className="date__home">{newItem?.creation_date}</span>
                  </div>
                  <div className="btn__viewGwpSra">
                  <div className="tooltip-containerAlert ">
                        <button 
                        disabled={newItem?.sra_data?.length === 0}
                        onClick={() => goToSra(newItem?.sra_data)}
                        className="name__textGwpSra" 
                        style={{backgroundColor: "#edcf85"}}>View Related Service Request Accelerators</button>
                  </div>
                  <div className="tooltip-containerAlert ">
                    <div className="name__textGwpSra" onClick={() => 
                      {
                        onShowGWP(newItem?.gwp_id)
                        setShowGWP(true)
                        getSelectedGWP(newItem)
                      }}>
                        View Get Well Plan
                    </div>
                  </div>
                  </div>
                  <div>
                  {cartAlert?.some(alert => alert?.id === newItem?.id) ? 
                    <button onClick={() => {
                      deleteAlertsInCart(newItem?.id)
                      }} 
                      className="btn__requestSupport"><FontAwesomeIcon icon={faTrash} /></button> :
                    <button onClick={() => {
                        addAlertToCart(newItem)
                      }} 
                      className="btn__requestSupport">Submit Service Request for this Health Check Alert</button> 
                    }
                  </div> 
              </div>
              ) )}
            </div>
            {errorAlert && !newsCurrent.length &&
              <div className="container__dataNotFoundAlerts">
                <div>Oops, it looks like we weren’t able to find your Alerts information. We kindly request you to try Again later.</div>
                <button className="button__try" onClick={() => tryAgin()}>Try Again</button>
              </div>}
            {!newsCurrent.length && !loadingNews && !errorAlert && profileRegion !== "JAPAN" &&
              <div className="container__notAlerts">
                <div>
                  <div className="text__noAlert">No alerts found</div>
                  <img className="container__imgNoAlerts" src={pager}   alt=''></img>
                </div>
              </div>}
              {profileRegion === "JAPAN" &&  !loadingNews && !newsCurrent.length && !errorAlert &&
                <div className="container__japan">
                  Are you interested in seeing the health of your current PagerDuty environment? Please contact your PagerDuty Account Team and request to be onboarded for PagerDuty Automated Health Check services for Premium Customers.
                </div>}
          </div>
        </div>
        </div>}
      <div className="container__copy"><Copyright /></div>
      {showModalOpenCart && 
        <div className="container__detailAlert">
            <section className="container__sectionAlert">
              <div className="modal__containerDetailAlert">
                <div className="header__cartAlerts">
                    <div>Health Check Alerts Cart for Service Request Submission</div>
                    <div onClick={() => setShowModalOpenCart(false)}><FontAwesomeIcon icon={faX} /></div>
                </div>
                <div className="clearCart__alertsContainer">
                  <div>Total Alerts: {cartAlert?.length}</div>
                  <div  className="clearCart__alerts" onClick={() => clearAllAlerts()}>
                    Clear Cart <FontAwesomeIcon className="icon__clearCartAlert" icon={faTrash} />
                  </div>
                </div>
              {submitSuccess && <div className="container__thankYouAlert"><ThankYou /></div>}
                {cartAlert?.length === 0 && !submitSuccess &&
                  <div className="cart__isEmpty container__notCartAlert">
                    <div className="container__imgUserCartAlert imgUserCart__alerts">
                      <img
                        className="img__pagerUserCartAlert"
                        src={pagerUser}
                        alt=''
                      />
                    </div>
                    <div className="not__cart">Your shopping cart is empty</div>
                  </div>}
                {!submitSuccess && <div className="alerts__cartContainer"> 
                  {cartAlert?.map((newItem, index) => (   
                    <div key={index} className="news-item__home news__alerts" style={{display: 'flex', flexDirection: 'column'}}>
                      <div className="title__homeAlertCart">
                        <div>{newItem?.title}</div>
                        <div onClick={() =>   deleteAlertsInCart(newItem?.id)}><FontAwesomeIcon className="icon__clearCartAlert" icon={faTrash} /></div>
                      </div>
                      <div className="container__entity pd__entities">
                        <div className="icon__entity" ><FontAwesomeIcon  icon={returnIcon(newItem?.pd_entities_labels ? newItem?.pd_entities_labels : newItem?.pd_entities)} /></div>
                        <div>{newItem?.pd_entities_labels ? newItem?.pd_entities_labels : newItem?.pd_entities}</div>
                      </div>
                      <p className="description__newHome">{newItem?.description?.split('The current value is : ')[0].replace('. .','.')}</p>
                      <div className="meta__home meta__homeCart" style={{marginTop: 'auto'}}>
                          <span className="urgency__home" style={{backgroundColor: backgroundStatus(newItem?.severity).backgroundStatus, color: backgroundStatus(newItem.severity).color}}>{showTextSeverity(newItem.severity)}</span>
                          <span className="date__home">{newItem?.creation_date}</span>
                      </div>
                    </div>))}
                </div>}
                {cartAlert?.length > 0 && !submitSuccess && <div className="div__border"></div>}
                {cartAlert?.length > 0 && !submitSuccess &&
                  <div className="container__requestUrgency">
                    <div className="title__modal">Request Urgency</div>
                    <div>
                      {premiumRequest.length > 0 &&
                        <div className="container__urgencySelect">
                            <select
                            name="requestUrgency"
                            onChange={handleInputChange}
                            className="select__requestUrgency"
                            value={formValues.requestUrgency}
                          >
                            {premiumRequest?.map((item) => (
                              <option key={item?.id} value={item?.description}>{item?.description}</option>
                            ))}
                          </select>
                          <FontAwesomeIcon className="icon__caretDownAlertRequestUrgency" icon={faCaretDown} />
                        </div>}
                    </div>
                  </div>}
                {cartAlert?.length > 0 && !submitSuccess &&
                  <div className="container__requestDetail">
                    <div className="title__modal">Request Detail</div>
                    <div>
                      <textarea name='requestDetails' onChange={handleInputChange} value={formValues.requestDetails} className="textarea__requestDetail">
                      </textarea>
                    </div>
                  </div>}
                  {cartAlert?.length > 0 && !submitSuccess &&
                    <div className="btn__sendCartAlert">
                        {(simpleCart?.length >= 100 ||
                        multipleCart?.length >= 15) && <div>You have exceeded the cart limit, please remove alerts.</div>}
                      <div className="spinner__containerAlert">  
                        {loadingSubmitCart &&
                          <div className="spinner__alerts" style={{height: 'auto', marginLeft:'10px'}}>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        }
                      </div>
                      <button 
                      className={`btn__search ${disabledBtnSave}`}
                      disabled={ formValues.requestUrgency === '' || formValues.requestDetails === '' ||
                        simpleCart?.length >= 100 ||
                        multipleCart?.length >= 15
                      }
                      onClick={() => { onSubmitCart() }}
                      >Submit</button>

                    </div>
                    }
              </div>
            </section>
        </div>}
      <Modal
        show={showModalCustomerRequest}
        handleClose={() => setShowModalCustomerRequest(!showModalCustomerRequest)}
        modalFitContent
        title={'Customer Request Form'}
        type="small"
      >
        {loadingRequestForm && <Spinner/>}
        {!loadingRequestForm && !showThankYou &&
          <div className="projects mb-4">
            <div className="container__fields">
              <div className="title__modal">Request Type</div>
              <div className="text__typeRequest">{formValues?.requestType}</div>
            </div>
            <div className="container__fields">
              <div className="title__modal">Alert title</div>
              <div className="text__typeRequest">{selectedAlert?.title}</div>
            </div>
            <div className="container__fields">
              <div className="title__modal">Request Urgency</div>
              <div>
                {premiumRequest?.length > 0 &&
                  <select
                    name="requestUrgency"
                    onChange={handleInputChange}
                    className="select__requestUrgency"
                    value={formValues.requestUrgency}
                  >
                    {premiumRequest?.map((item) => (
                      <option key={item.id} value={item?.description}>{item?.description}</option>
                    ))}
                  </select>}
              </div>
            </div>
            <div className="container__fields">
              <div className="title__modal">Request Detail</div>
              <div>
                <textarea name='requestDetails' onChange={handleInputChange} value={formValues.requestDetails} className="textarea__requestDetailAlert">
                </textarea>
              </div>
            </div>
            {showErrorCustomerRequest &&
              <div className="container__fields container__error">
                <div> We apologize for the inconvenience caused. Our team is currently facing a technical issue and we are working hard to resolve it. We kindly request you to try Again later. If you require any assistance, please feel free to contact our support team on the Slack channel #csg-innovation-portal.Thank you for your understanding.CSG Innovation Services Team.</div>
                <button className="button__try"
                  onClick={() => {
                    setLoadingRequestForm(false)
                  }}>Try Again</button>
              </div>}
            <div>
              <button
                className={`submit__button ${disabledBtnSave}`}
                disabled={formValues.requestType === '' || formValues.requestUrgency === '' || formValues.requestDetails === ''}
                onClick={() => { onSubmit() }}
              >
                Submit
              </button>
            </div>
          </div>}

        {showThankYou && <div className="container__thankYou"><ThankYou /></div>}
      </Modal>
      <Modal
        show={showGWP}
        handleClose={() => setShowGWP(!showGWP)}
        modalFitContent
        title={`Get Well Plan: ${selectedGWP?.title}`}
        type="small"
      >
        <div>
          {loadingGWP &&  <Spinner/>}
          <div>
            {GWP?.map((item, index) =>
              <div key={index}>
                  <div className="title__steps">The following steps are provided to guide you in addressing this Health Check Alert. PagerDuty Professional Services is always available to help via a Service Request.</div>
                  <div className="description__steps">{item?.description}</div>
                  <div className="container__steps">
                    <div className="steps__title">
                      <div>Steps </div>
                      <div>Duration: {formatTime(item.total_effort)}</div>
                    </div>
                    <ul className="ul__steps">
                        {item?.steps?.map((item, index) => (
                          <li key={index} className="next_steps">
                            {item?.description}
                            <span className="effort__description">{formatTime(item.effort_value)}</span>
                          </li>
                        ))}
                    </ul>
                </div>
              </div>
            )}
          </div>
          {GWP.length === 0 && !loadingGWP &&
            <div className="not__foundGwp">
                No data found
            </div>
          }
        </div>
      </Modal>
      {showModalHealthCheckAlert && <ModalHealthCheckAlert  showGWP={true} getSelectedGWP={getSelectedGWP} setShowGWP={setShowGWP} onShowGWP={onShowGWP} newItem={selectNew} onePage={onePage} onShowModalHealthCheckAlert={setShowModalHealthCheckAlert}/>}
    </div>
  )
}