import React, { useRef } from "react";
import GenericTable from "../../components/GenericTable/GenericTable";

const PremiumSupportTable = ({
  dataArray,
  totalPageCaseSummary,
  currentPageCaseSummary,
  setCurrentPageCaseSummary,
  itemsPerPage,
  setItemsPerPage,
  isLoading
}) => {
  const tableRef = useRef(null);

  const handleSearch = (searchValue) => {
    console.log("Search value:", searchValue);
  };

  const titles = [
    { label: "Case Number", widthPX: 150, rowKey: "case_number", type: "String" },
    { label: "Case Subject", widthPX: 200, rowKey: "subject", type: "String" },
    { label: "Status", filtrable: true, widthPX: 150, rowKey: "status", type: "String" },
    { label: "Case Comments", filtrable: true, widthPX: 250, rowKey: "sub_status", type: "String" },
    { label: "Requester", sortable: true, widthPX: 200, rowKey: "submitted_by", type: "String" },
    { label: "Creation Date", widthPX: 200, rowKey: "created_date", type: "Date" },
  ];

  return (
    <div className="premium-request-table">
      <GenericTable
        ref={tableRef}
        title="Premium Support Table"
        columnsArray={titles}
        rowsArray={dataArray}
        onActionClick={(action, row) => console.log("Action Clicked:", action, row)}
        showElementsPerPage={itemsPerPage}
        elementOnClick={(row) => console.log("Row clicked:", row)}
        isLoading={isLoading}
        filterDateKey="creationDate"
        onSearchChange={handleSearch}
        onPaginationChange={setCurrentPageCaseSummary}
        actualPage={currentPageCaseSummary}
        setActualPage={setCurrentPageCaseSummary}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalItems={totalPageCaseSummary}
      />
    </div>
  );
};

export default PremiumSupportTable;