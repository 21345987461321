import React, { useState, useEffect  } from "react";
import 'lazysizes';
import { useNavigate } from "react-router-dom";
import { apiRequest, apiRoutes } from "../../../services";
import Paths from "../../../paths";
import './Logout.css'
import LoadingPagey from "./../../../components/LoadingPagey";

export default function Logout() {
  const ImageComponent = ({src}) => {
    return (
      <picture className="logout-image">
        <source srcset={src} type="image/webp"/>
        <source srcset={src} type="image/png"/> 
        <img alt="pagerduty-side" src={src} className="logout-image"/>
      </picture>
    )

  }
  

  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();

  const logoutService = async () => {
    setIsLoading(true);
    localStorage.removeItem("token");
    localStorage.removeItem("click_refresh");
    sessionStorage.removeItem("subdomain");
    sessionStorage.removeItem("mostrarModal");
    const [error, data] = await apiRequest({
      method: "put",
      url: `${apiRoutes.login}`,
    })
    if (data) {
      setIsLoading(false);
      history(Paths.Logout);
    } else {
      setIsLoading(false);
      history(Paths.Logout);
    }
  };

  useEffect(() => {
    logoutService();
  }, []);

  const login =  () => {
    history(Paths.Login)

  };

  return (
    <div className="login-container">
      {isLoading ? <>
        <div className="logout-container">
          <div className="area-container">
              <div className="elements-container">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              </div>
          </div>
          <div className="logout-icon_container">
            <LoadingPagey state={0}></LoadingPagey>
          </div>
        </div>
      </>
      :

      <div className="logout-container">
        <main className="logout-message_container">
          <div style={{flex:1, alignItems:'center', display:'flex', margin: '0 auto', flexDirection: 'column'}}>
            <p className="logout-message_error">Logout</p>
            <h1 className="logout-message_header">Your session has been closed</h1>
            <div className="mt-10">
              <button className="logout-login_button" onClick={() => login()}>Login</button>
            </div>
          </div>
          <div className="logout-image_container">
          <ImageComponent src="https://d274pwa32h7vmw.cloudfront.net/loginBackgound1.png"/>
          </div>
        </main>
      </div>

      }
    </div> 
  );
}
