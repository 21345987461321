import React, {useState, useEffect, useContext} from "react";
import { useNavigate} from "react-router-dom";
import {apiRoutes, apiRequest} from "./../../services";
import {useAuth} from "./../../UserContext"
import Paths from "../../paths";
import './Validate.css'
import Jwt from "../../utils/jwt";
import jwt_decode from "jwt-decode";
import LoadingPagey from "../../components/LoadingPagey";

export default function Validate() {
  const [isLoading, setIsLoading] = useState(true);
  const {profileSet, menuSet} = useAuth();

  const history = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('token'));

  const getProfile = async () => {
      const [error, data] = await apiRequest({
          method: "get",
          url: apiRoutes.userProfile,
      });
      if (!error) {
          profileSet(data);
      } else {
      }
      setIsLoading(false);
  };

  const createSession = async (code) => {
    const [error, data] = await apiRequest({
        method: "post",
        url: `${apiRoutes.login}`,
        data: {"code": code}
    });
    if (!error) {
      setIsLoading(false);
      localStorage.setItem("token", data.csg_pd_access_token);
      const tokenValue = jwt_decode(data.csg_pd_access_token, { header: false });
      const isAdmin = tokenValue.claims.roles.find((role) => {
        return  role.role === "Admin";
      })
      setToken(data.csg_pd_access_token);
      getProfile();
      history(Paths.Home);
      // if(isAdmin) {
      //   history(Paths.SelectClient);
      // } else {
      //   history(Paths.Home);
      // }
    } else {
      setIsLoading(false);
      history(Paths.Unauthorized);
    }
  };

  useEffect(() => {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const code = params.get("code");
      createSession(code);
  }, []);

    return (
      <div>
        {!isLoading ? <>
          </>
          : <>
            <div className="validate-container">
                <div className="area-container">
                    <div className="elements-container">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    </div>
                </div>
                <div className="validate-icon_container">
                  <LoadingPagey state={0}></LoadingPagey>
                </div>
            </div>
            </>}
        </div>
    );
}
