import { useCallback } from 'react';


const useCSV = () => {
    const exportCSV = useCallback((array, title) => {
        if (array.length > 0) {
            const titles = [...new Set(array.flatMap((element) => Object.keys(element)))];
            const arrayToSend = array.map((element) =>
                Array.from(Object.values(titles.map((key) => (element[key] ? element[key].toString() : ''))))
            );
            let csvContent =
                'data:text/csv;charset=utf-8,' + titles.join(',') + '\n' + arrayToSend.map((e) => e.join(',')).join('\n');
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', title + '.csv');
            link.click();
        }
    }, []);

    return {
        exportCSV,
    };
};

export default useCSV;
