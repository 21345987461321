import React, { useState } from "react";
import loginBackgound1 from "./../../../assets/loginBackgound1.png";
import './502.css'

export default function Error502() {
  const [isLoading, setIsLoading] = useState(true);

  const refresh = () => {
    window.location.reload();
  }

  return (
    <div >
      {!isLoading ? <> 
      </>
      : <> <div className="unauthorized-container">
      <main className="unathorized-message_container">
        <div style={{flex:1, alignItems:'center', display:'flex', margin: '0 auto', flexDirection: 'column'}}>
          <p className="unauthorized-message_error">502</p>
          <h1 className="unathorized-message_header">Unexpected Error</h1>
          <p className="unathorized-message_paragraph">
            We apologize for the inconvenience caused. 
          </p>
          <p className="unathorized-message_paragraph">Our team is currently facing a technical issue and we are working hard to resolve it.</p> <p> We kindly request you to refresh or try again later.</p>
          <p className="unathorized-message_paragraph">If you need assistance, please contact our support team on the Slack channel <strong>#csg-innovation-portal</strong></p>
          <p className="unathorized-message_paragraph"> Thank you, CSG Innovation Services Team.</p>
          <div className="mt-10">
            <button className="unathorized-login_button" onClick={() => refresh()}>Refresh</button>
          </div>
        </div>
        <div className="unathorized-image_container">
          <img
            src={loginBackgound1}
            alt=""
            className="unathorized-image"
          />
        </div>
      </main>
    </div>
        </>}
    </div> 
  );
}
