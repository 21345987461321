import React, { useState, useEffect, useRef } from "react";
import "./PDU.css";
import SkeletonTable from "../../components/Skeletons/TableSkeleton/TableSkeleton";
import SortingTable from "../../components/SortingTable";
import AppIcon from "../../components/AppIcon";
import { apiRequest, apiRoutes } from "./../../services";
import LoadingSpinner from "../../components/LoadingSpinner";
import Modal from "../../components/Modal";
import MultiSelect from "../../components/MultiSelect";
import { cleanAndSortCustomers } from "../../utils/customers";
import Jwt from "../../utils/jwt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Questions from "../../components/Questions";

export default function PsUApp() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSkeleton, setIsLoadingSkeleton] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ownerFilter, setOwnersOptions] = useState([]);
  const [accountOwnerFilter, setAccountOwnersOptions] = useState([]);
  const [accountCSM, setCsmsOptions] = useState([]);
  const [contract, setExpiryYearsOptions] = useState([]);
  const [step, setActionStepsOptions] = useState([]);
  const [modalTableData, setModalTableData] = useState([]);
  const [tokenData, setTokenData] = useState(null);
  const [lastLoadedDate, setLastLoadedDate] = useState("");
  const [customersArray, setCustomersArray] = useState([]);
  const [currentTeam, setCurrentTeam] = useState([]);
  const [modalFilterOpen, setModalFilterOpen] = useState(false);
  const [tableSkilljarDetailed, setSkilljarDetailed] = useState(null);
  const [tableVilt, setViltData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [reportTab, setReportTab] = useState("Gold");
  const [owners, setOwners] = useState([]);
  const [accountOwners, setAccountOwners] = useState([]);
  const [csms, setCsms] = useState([]);
  const [expiryYears, setExpiryYears] = useState([]);
  const [canSeeReports, setCanSeeReports] = useState(null);
  const [actionSteps, setActionSteps] = useState([]);
  const [FAQModalOpen, setFAQModalOpen] = useState(false);
  const [customerName, setCustomerName] = useState("");

  const questionsArray = [
    {
      question: `I’m unable to find my customer’s PDU information.`,
      answer: `If you’re unable to see your customer after logging in on the Gold, Silver or Skilljar tabs, , try searching via the customer search box. If you are still unable to find your customer, please let us know in the #csg-pdu-status-app Slack channel.`,
    },
  ];

  const cleanPage =() => {
    setOwnersOptions([]);
    setAccountOwnersOptions([]);
    setCsmsOptions([]);
    setExpiryYearsOptions([]);
    setActionStepsOptions([]);
    setModalTableData([]);
    setCsms([]);
    setOwners([]);
    setAccountOwners([]);
    setExpiryYears([]);
    setActionSteps([]);
    setCustomersArray([]);
    setTableData([]);
  }

  const fetch_gold_data = async () => {
    setIsLoadingSkeleton(true);
    cleanPage();
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.pduGold}`,
    });
    if (data) {
      const dataNewColumn = data.map((item) => {
        const customerName = item.customer_name.trim();
        return {
          ilt_taken: item.ilt_purchased - item.ilt_remaining,
          customer_name_format: customerName,
          ...item,
        };
      });
      fillFilters(dataNewColumn);
      setStatusData(dataNewColumn);
      setTableData(dataNewColumn);
      setCustomersArray(cleanAndSortCustomers(dataNewColumn));
      setIsLoadingSkeleton(false);
    } else {
      console.log(error);
      setIsLoadingSkeleton(false);
    }
  };

  const fetch_silver_data = async () => {
    setIsLoadingSkeleton(true);
    cleanPage();
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.pduSilver}`,
    });
    if (data) {
      const dataNewColumn = data.map((item) => {
        const customerName = item.customer_name.trim();
        return {
          ilt_taken: item.ilt_purchased - item.ilt_remaining,
          customer_name_format: customerName,
          ...item,
        };
      });
      fillFilters(dataNewColumn);
      setCustomersArray(cleanAndSortCustomers(dataNewColumn));
      setTableData(dataNewColumn);
      setStatusData(dataNewColumn);
      setIsLoadingSkeleton(false);
    } else {
      console.log(error);
      setIsLoadingSkeleton(false);
    }
  };

  const fetch_skill_group = async () => {
    setIsLoadingSkeleton(true);
    cleanPage();
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.pduSkilljarGroup}`,
    });
    if (data) {
      const dataNewColumn = data.map((item) => {
        const customerName = item.name.trim();
        return { customer_name_format: customerName, ...item };
      });
      fillFilters(dataNewColumn);
      setCustomersArray(cleanAndSortCustomers(dataNewColumn, true));
      setTableData(dataNewColumn);
      setStatusData(dataNewColumn);
      setIsLoadingSkeleton(false);
    } else {
      console.log(error);
      setIsLoadingSkeleton(false);
    }
  };

  const fetch_skill_detailed = async (id) => {
    setIsLoadingSkeleton(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.pduSkilljarGroup}/${id}/users`,
    });
    if (data) {
      setSkilljarDetailed(data);
      setIsLoadingSkeleton(false);
      setModalOpen(true);
    } else {
      console.log(error);
      setIsLoadingSkeleton(false);
    }
  };

  const fetch_vilt_metrics_data = async () => {
    setIsLoadingSkeleton(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.pduVilt}`,
    });
    if (data) {
      setViltData(data);
      setIsLoadingSkeleton(false);
    } else {
      console.log(error);
      setIsLoadingSkeleton(false);
    }
  };

  const fetch_itl_detail = async (id) => {
    setIsLoadingSkeleton(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.pduItlDetail}${id}/tasks`,
    });
    if (data) {
      const sorted = data.sort((a, b) => {
        return new Date(a.due_date).getTime() - new Date(b.due_date).getTime();
      });
      setModalTableData(sorted);
      setIsLoadingSkeleton(false);
      setModalOpen(true)
    } else {
      console.log(error);
      setIsLoadingSkeleton(false);
    }
  };

  const fetch_skill_filtered = async (id) => {
    setIsLoadingSkeleton(true);
    cleanPage();
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.pduSkilljarFiltered}`,
    });
    if (data) {
      const dataNewColumn = data.map((item) => {
        const customerName = item.name.trim();
        return { customer_name_format: customerName, ...item };
      });
      fillFilters(dataNewColumn);
      setCustomersArray(cleanAndSortCustomers(dataNewColumn, true));
      setTableData(dataNewColumn);
      setStatusData(dataNewColumn);
      setIsLoadingSkeleton(false);
    } else {
      console.log(error);
      setIsLoadingSkeleton(false);
    }
  };

  const openModal = (selectedItem) => {
    setCustomerName(selectedItem.customer_name_format)
    if (reportTab === "Gold" || reportTab === "Silver") {
      fetch_itl_detail(selectedItem.project_id);
    } else {
      fetch_skill_detailed(selectedItem.id);
    }
  };

  const handleModal = () => {
    setModalOpen(null);
    setSkilljarDetailed([]);
    setModalTableData([])
  };

  useEffect(() => {
    reportTabLiteral.hasOwnProperty(reportTab) && reportTabLiteral[reportTab]();
  }, [reportTab]);

  const handleChangeMultiSelect = async (values) => {
    if (values.length > 0) {
      let options = values.map((a) => a.value);
      const copyData = statusData;
      const filtered = copyData.filter((obj) => {
        return options.includes(obj.customer_name_format);
      });
      setTableData(filtered);
    } else {
      setTableData(statusData);
    }
  };

  const reportTabLiteral = {
    Gold: () => fetch_gold_data(),
    Silver: () => fetch_silver_data(),
    SkilljarGroup: () => fetch_skill_group(),
    SkilljarFiltered: () => fetch_skill_filtered(),
    ViltMetrics: () => fetch_vilt_metrics_data(),
  };

  const fetch_last_data_loaded = async (tab) => {
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.pduLoadTime,
    });
    if (res) {
      setLastLoadedDate(res.execution_date);
    }
    if (error) console.log("err", error);
  };

  const openFilterModal = (value) => {
    setModalFilterOpen(value);
  };

  const onSelectReport = (values, filter) => {
    let options = values.map((a) => a[filter]);
    switch (filter) {
      case "owner":
        setOwners(options);
        break;
      case "sales_rep":
        setAccountOwners(options);
        break;
      case "contract_expiry":
        setExpiryYears(options);
        break;
      case "customer_success_manager":
        setCsms(options);
        break;
      case "action_step":
        setActionSteps(options);
        break;
    }
  };

  const fillFilters = (tableData) => {
    const filterOwner = tableData.map((item, i) => {
      return {value:item.owner, ...item}
    })
    let cleanOwner = filterOwner.filter(function({owner}) {
      return owner && !this.has(owner) && this.add(owner);
    }, new Set)

    setOwnersOptions(cleanOwner);
    let accountOwners = tableData.map((item, i) => {
      return {value:item.sales_rep, ...item}
    })
    let cleanAccountOwner = accountOwners.filter(function({sales_rep}) {
      return sales_rep && !this.has(sales_rep) && this.add(sales_rep);
    }, new Set)

    setAccountOwnersOptions(cleanAccountOwner);
    let csms = tableData.map((item, i) => {
      return {value:item.customer_success_manager, ...item}
    })
    let cleanCsm = csms.filter(function({customer_success_manager}) {
      return customer_success_manager && !this.has(customer_success_manager) && this.add(customer_success_manager);
    }, new Set)

    setCsmsOptions(cleanCsm);
    if(reportTab === "Gold") {
      let expiryYears = tableData.map((item, i) => {
      return {value:item.contract_expiry, ...item}
    })
      let cleanYears = expiryYears.filter(function({contract_expiry}) {
        return contract_expiry && !this.has(contract_expiry) && this.add(contract_expiry);
      }, new Set)
      setExpiryYearsOptions(cleanYears);
      let csms = tableData.map((item, i) => {
        return {value:item.customer_success_manager, ...item}
      })
      let actionSteps = tableData.map((item, i) => {
        return {value:item.action_step, ...item}
      })
      let cleanActionSteps = actionSteps.filter(function({action_step}) {
        return action_step && !this.has(action_step) && this.add(action_step);
      }, new Set)
      setActionStepsOptions(cleanActionSteps);
    }
  };

  useEffect(() => {
    setTokenData(Jwt.getItem("all"));
  }, []);

  useEffect(() => {
    if (tokenData) {
      setCanSeeReports(
        tokenData.claims.roles.find((role) => {
          return role.role === "PDU" || role.role === "Admin";
        })
      );
    }
  }, [tokenData]);

  const applyFilters = () => {
    openFilterModal(false);
    switch(reportTab) {
      case "Gold":
        (owners && owners.length > 0 || accountOwners && accountOwners.length > 0 || csms && csms.length > 0 || expiryYears && expiryYears.length > 0 || actionSteps && actionSteps.length > 0) ? filterData () : fetch_gold_data();
        break;
      case "Silver":
        (owners && owners.length > 0 || accountOwners && accountOwners.length > 0 || csms && csms.length > 0 ) ? filterData(): fetch_silver_data();
        break;
      case "SkilljarGroup":
        (owners && owners.length > 0 || accountOwners && accountOwners.length > 0 || csms && csms.length > 0 ) ? filterData() : fetch_skill_group();
        break;
      case "SkilljarFiltered":
        (owners && owners.length > 0 || accountOwners && accountOwners.length > 0 || csms && csms.length > 0 ) ? filterData() :fetch_skill_filtered();
        break;
    }
  };

  const filterData = () => {
    const filteredData = tableData.filter((data) => {
      return (
        (owners && owners.includes(data.owner)) ||
        (accountOwners && accountOwners.includes(data.sales_rep)) ||
        (expiryYears && expiryYears.includes(data.contract_expiry)) ||
        (csms &&
          csms.includes(
            data.customer_success_manager ||
              (actionSteps && actionSteps.includes(data.action_step))
          ))
      );
    });
    setTableData(filteredData);
  }

  useEffect(() => {
    fetch_last_data_loaded();
    setReportTab("Gold");
    setIsLoadingSkeleton(false);
  }, []);

  return (
    <>
      {isLoading && (
        <div id="SuccessOnDemand-loader">
          <LoadingSpinner />
        </div>
      )}
     { loadingSkeleton ? <SkeletonTable></SkeletonTable> : <div id="PDU-container">
        <div id="PDU-title-container">
          <div style={{ display: "flex" }}>
            <h2 id="SuccessOnDemand-title">PDU Status App</h2>
          </div>
          {lastLoadedDate ? (
            <div style={{ display: "flex", flexDirection:"column", alignItems: "end" }}>
              <div id="pdu-FAQ" onClick={() => setFAQModalOpen(true)}>
                Frequently Asked Questions
                <FontAwesomeIcon id="pdu-FAQIcon" icon={faQuestionCircle} />
              </div>
              <div id="SuccessOnDemand-container-time">
                <label>
                  <b>Last Loaded:</b>
                </label>
                <span>{lastLoadedDate}</span>
              </div>
            </div>
          ) : (
            <div>
              <div id="SuccessOnDemand-container-time"></div>
              <div id="pdu-FAQ" onClick={() => setFAQModalOpen(true)}>
                Frequently Asked Questions
                <FontAwesomeIcon id="pdu-FAQIcon" icon={faQuestionCircle} />
              </div>
            </div>
            
          )}
        </div>
        <div className="">
          <Modal
            show={modalOpen}
            title={
              reportTab === "Gold" || reportTab === "Silver"
                ? `ILT Sessions for ${customerName}`
                : `Skilljar Detailed for ${customerName}`
            }
            handleClose={() => handleModal()}
            modalFitContent
          >
            <div style={{ margin: "20px" }}>
              {modalTableData && modalTableData.length > 0 ? (
                <SortingTable
                  tableName="ItlDetail"
                  columnsArray={[
                    {
                      label: "Date",
                      valueLabel: "due_date",
                      sortable: true,
                      widthPX: 300,
                      type: "Date",
                      innerType: "Date",
                      textAlignDef:'left',
                    },
                    {
                      label: "Name",
                      valueLabel: "name",
                      sortable: true,
                      widthPX: 300,
                      type: "String",
                      innerType: "String",
                      textAlignDef:'left',
                    },
                    {
                      label: "# of attendees",
                      valueLabel: "number_of_attendees",
                      sortable: true,
                      widthPX: 300,
                      type: "Number",
                      innerType: "Number",
                      textAlignDef:'left',
                    },
                  ]}
                  rowsArray={modalTableData}
                  iSortDescending={false}
                  hideCheckbox={true}
                  tableHeight="100%"
                  elementOnClick={(e) => {}}
                  showElementsPerPage={5}
                  dontShowFilters={true}
                />
              ) : tableSkilljarDetailed && tableSkilljarDetailed.length > 0 ? (
                <SortingTable
                  tableName="ItlDetail"
                  columnsArray={[
                    {
                      label: "Name",
                      valueLabel: "name",
                      sortable: true,
                      widthPX: 300,
                      type: "String",
                      innerType: "String",
                    },
                    {
                      label: "Email",
                      valueLabel: "email",
                      sortable: true,
                      widthPX: 300,
                      type: "String",
                      innerType: "String",
                    },
                    {
                      label: "Registered Courses",
                      valueLabel: "registred_courses",
                      sortable: true,
                      widthPX: 300,
                      type: "String",
                      innerType: "String",
                    },
                    {
                      label: "Completed Courses",
                      valueLabel: "completed_courses",
                      sortable: true,
                      widthPX: 300,
                      type: "String",
                      innerType: "String",
                    },
                    {
                      label: "Certificates",
                      valueLabel: "certificates",
                      sortable: true,
                      widthPX: 300,
                      type: "Number",
                      innerType: "Number",
                    },
                  ]}
                  rowsArray={tableSkilljarDetailed}
                  iSortDescending={true}
                  hideCheckbox={true}
                  tableHeight="100%"
                  elementOnClick={(e) => {}}
                  showElementsPerPage={5}
                  dontShowFilters={true}
                />
              ) : (
                <h5>No data to display</h5>
              )}
              <div>
                <button
                  className="OnBoarding-container-right-button button-regular"
                  onClick={() => handleModal()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          <div className="tabs-container">
            <ul className="tab-list">
              {
                <>
                  <li
                    className={
                      reportTab === "Gold"
                        ? "tab-list-item-active"
                        : "tab-list-item"
                    }
                    onClick={() => setReportTab("Gold")}
                  >
                    Gold
                  </li>
                  <li
                    className={
                      reportTab === "Silver"
                        ? "tab-list-item-active"
                        : "tab-list-item"
                    }
                    onClick={() => setReportTab("Silver")}
                  >
                    Silver
                  </li>
                </>
              }
              {
                <li
                  className={
                    reportTab === "SkilljarGroup"
                      ? "tab-list-item-active"
                      : "tab-list-item"
                  }
                  onClick={() => setReportTab("SkilljarGroup")}
                >
                  Skilljar Group
                </li>
              }
              {canSeeReports && (
                <li
                  className={
                    reportTab === "SkilljarFiltered"
                      ? "tab-list-item-active"
                      : "tab-list-item"
                  }
                  onClick={() => setReportTab("SkilljarFiltered")}
                >
                  Skilljar Filtered
                </li>
              )}
            </ul>
          </div>
          {
            <div id="HealthCheck-button-container">
              <div className="filter-panel-statusApp">
                {customersArray.length > 0 && currentTeam && (
                  <>
                    <div>
                      <MultiSelect
                        options={customersArray}
                        onChange={(e) => handleChangeMultiSelect(e)}
                        valueToDisplay={"value"}
                        allowOnlyOne={false}
                        defaultValues={null}
                        placeholder={"Customer Name search..."}
                      />
                    </div>
                  </>
                )}
                {(reportTab === "Gold" || reportTab === "Silver") && (
                  ( (ownerFilter.length > 0 && accountOwnerFilter.length > 0 && accountCSM.length > 0) && <div style={{ marginLeft: "30px" }}>
                    {" "}
                    <button
                      className="pdu-button-export"
                      onClick={() => {
                        setModalFilterOpen(true);
                      }}
                    >
                      Filter
                    </button>
                  </div>)
                )}
              </div>
            </div>
          }
          <div id="HC-Incidents-container">
            <div id="table-container">
              {((tableData && tableData.length > 0 && reportTab === "Gold") ||
                (tableData &&
                  tableData.length > 0 &&
                  reportTab === "Silver")) && (
                <div style={{ height: "calc(100% - 55px)" }}>
                  <div id="PS-Status-container-right-top10-table">
                    <SortingTable
                      tableName="TOP10Table"
                      columnsArray={[
                        {
                          label: "Customer Name",
                          valueLabel: "customer_name_format",
                          sortable: true,
                          widthPX: 290,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Package",
                          valueLabel: "package",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Owner",
                          valueLabel: "owner",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Status",
                          valueLabel: "current_status",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Account Owner",
                          valueLabel: "sales_rep",
                          sortable: true,
                          widthPX: 300,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Account CSM",
                          valueLabel: "customer_success_manager",
                          sortable: true,
                          widthPX: 300,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Contract Expiry",
                          valueLabel: "contract_expiry",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Action Step",
                          valueLabel: "action_step",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Contract Year",
                          valueLabel: "contract_year",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "ILT Purchased",
                          valueLabel: "ilt_purchased",
                          sortable: true,
                          widthPX: 300,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "ILT Remaining",
                          valueLabel: "ilt_remaining",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "ILT Taken",
                          valueLabel: "ilt_taken",
                          sortable: true,
                          widthPX: 300,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "E-Learning Users",
                          valueLabel: "elearning_users",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Subdomain",
                          valueLabel: "subdomain",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                      ]}
                      rowsArray={tableData}
                      iSortDescending={true}
                      hideCheckbox={true}
                      tableHeight="100%"
                      elementOnClick={(e) => {
                        openModal(e);
                      }}
                      showElementsPerPage={
                        reportTab === "Gold" ? tableData.length : 10
                      }
                      dontShowFilters={true}
                      frozenColumn={true}
                    />
                  </div>
                </div>
              )}
              {tableData && reportTab === "SkilljarGroup" && (
                <div style={{ height: "calc(100% - 55px)" }}>
                  <div id="PS-Status-container-right-top10-table">
                    <SortingTable
                      tableName="TOP10Table"
                      columnsArray={[
                        {
                          label: "Group Name",
                          valueLabel: "customer_name_format",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "User Count",
                          valueLabel: "user_count",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Registered Courses",
                          valueLabel: "registered_courses",
                          sortable: true,
                          widthPX: 300,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Completed Courses",
                          valueLabel: "completed_courses",
                          sortable: true,
                          widthPX: 300,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Certificates",
                          valueLabel: "certificates",
                          sortable: true,
                          widthPX: 300,
                          type: "Number",
                          innerType: "Number",
                        },
                      ]}
                      rowsArray={tableData}
                      dontShowFilters={true}
                      iSortDescending={true}
                      hideCheckbox={true}
                      tableHeight="100%"
                      elementOnClick={(e) => {
                        openModal(e);
                      }}
                      showElementsPerPage={10}
                    />
                  </div>
                </div>
              )}
              {tableVilt && reportTab === "ViltMetrics" && (
                <div style={{ height: "calc(100% - 55px)" }}>
                  <div id="PS-Status-container-right-top10-table">
                    <SortingTable
                      tableName="TOP10Table"
                      columnsArray={[
                        {
                          label: "Course Title",
                          valueLabel: "course_title",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Display Name",
                          valueLabel: "display_name",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Instructor Name",
                          valueLabel: "instructor_name",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Lesson Title",
                          valueLabel: "lesson_title",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Session ID",
                          valueLabel: "session_id",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Skilljar ID",
                          valueLabel: "skilljar_id",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Starts At",
                          valueLabel: "starts_at",
                          sortable: true,
                          widthPX: 200,
                          type: "Date",
                          innerType: "Date",
                        },
                        {
                          label: "Trained Users",
                          valueLabel: "trained_users",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                      ]}
                      rowsArray={tableData}
                      dontShowFilters={true}
                      iSortDescending={true}
                      hideCheckbox={true}
                      tableHeight="100%"
                      elementOnClick={(e) => {}}
                      showElementsPerPage={10}
                    />
                  </div>
                </div>
              )}
              {tableData && reportTab === "SkilljarFiltered" && (
                <div style={{ height: "calc(100% - 55px)" }}>
                  <div id="PS-Status-container-right-top10-table">
                    <SortingTable
                      tableName="TOP10Table"
                      columnsArray={[
                        {
                          label: "Name",
                          valueLabel: "customer_name_format",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Original Name",
                          valueLabel: "original_name",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Skilljar ID",
                          valueLabel: "skill_jar_id",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "User Count",
                          valueLabel: "user_count",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Execution Date",
                          valueLabel: "execution_date_fk",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Group ID",
                          valueLabel: "group_id",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Has Valid Name",
                          valueLabel: "has_valid_name",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                        {
                          label: "Premium",
                          valueLabel: "is_premium",
                          sortable: true,
                          widthPX: 200,
                          type: "String",
                          innerType: "String",
                        },
                      ]}
                      rowsArray={tableData}
                      dontShowFilters={true}
                      iSortDescending={true}
                      hideCheckbox={true}
                      tableHeight="100%"
                      elementOnClick={(e) => {}}
                      showElementsPerPage={10}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>}
      <>
        <Modal
          show={FAQModalOpen}
          title="FAQ"
          handleClose={() => setFAQModalOpen(!FAQModalOpen)}
          modalFitContent
        >
          <Questions
            questionsArray={questionsArray}
            color="rgb(255, 230, 100)"
          />
        </Modal>
        <Modal
          type="small"
          show={modalFilterOpen}
          title={`Filters`}
          text={'Please type in to search for options and use arroykeydown to navigate through dropdown options'}
          handleClose={() => openFilterModal(false)}

        >
          <div id="SuccessOnDemand-modal-container">
            {
              <>
                {ownerFilter.length > 0 && <div>
                  <p>Owner</p>
                  <MultiSelect
                    options={ownerFilter}
                    onChange={(e) => onSelectReport(e, "owner")}
                    valueToDisplay={"value"}
                    allowOnlyOne={false}
                    defaultValues={null}
                  />
                </div>}
                <div>
                  <p>Account Owner</p>
                  <MultiSelect
                    options={accountOwnerFilter}
                    onChange={(e) => onSelectReport(e, "sales_rep")}
                    valueToDisplay={"value"}
                    allowOnlyOne={false}
                    defaultValues={null}
                  />
                </div>
                <div>
                  <p>Account CSM</p>
                  <MultiSelect
                    options={accountCSM}
                    onChange={(e) =>
                      onSelectReport(e, "customer_success_manager")
                    }
                    valueToDisplay={"value"}
                    allowOnlyOne={false}
                    defaultValues={null}
                  />
                </div>
                {reportTab === "Gold" && (
                  <>
                    <div>
                      <p>Contract Expiry</p>
                      <MultiSelect
                        options={contract}
                        onChange={(e) => onSelectReport(e, "contract_expiry")}
                        valueToDisplay={"value"}
                        allowOnlyOne={false}
                        defaultValues={null}
                      />
                    </div>
                    <div>
                      <p>Action Step</p>
                      <MultiSelect
                        options={step}
                        onChange={(e) => onSelectReport(e, "action_step")}
                        valueToDisplay={"value"}
                        allowOnlyOne={false}
                        defaultValues={null}
                      />
                    </div>
                  </>
                )}
              </>
            }
          </div>
          <div id="SOD-form-row-buttons">
            <button
              style={{ backgroundColor: "#DEE2E8" }}
              className="button-modal"
              onClick={() => openFilterModal(false)}
            >
              Cancel
            </button>
            <button
              className="button-modal"
              onClick={() => {
                applyFilters();
              }}
            >
              Apply Filters
            </button>
          </div>
        </Modal>
      </>
    </>
  );
}
