import { forwardRef, useRef } from "react";
import './Copyright.css'




const Copyright = forwardRef(() => {
 

  return (
    <div className="container__copyright">
      Copyright © {new Date().getFullYear()} CSG Innovation Services -
      PagerDuty, Inc. All rights reserved.
    </div>
  )
})

export default Copyright;