import React, { useState, useRef, useEffect } from "react";
import SkeletonHCDV from "../../components/Skeletons/HCDV/SkeletonHCDV";
import { useNavigate, createSearchParams } from "react-router-dom";
import AppIcon from "../../components/AppIcon";
import Dropdown from "../../components/Dropdown";
import "./HealthCheckDataViewer.css";
import Paths from "../../paths";
import LoadingSpinner from "../../components/LoadingSpinner";
import Tabs from "../../components/Tabs";
import PDFViewer from "../../components/PDFViewer";
import Modal from "../../components/Modal";
import Template1IMG from "../../assets/Template1.png";
import Template1IMGPP from "../../assets/Template1PP.png";
import Template2IMG from "../../assets/Template2.png";
import Template3IMG from "../../assets/Template3.png";
import {
  TemplatesModalContainer,
  IndividualTemplateContainer,
  IndividualTemplateButton,
  TemplateViwerContainerInner,
  TemplateViwerContainer,
  LeftPanel,
  DashboardTitlesContainer,
  TemplatesModalContainerTemplates,
  TemplatesModalContainerInfo,
  TemplatePagesButton,
  PaginationTemplateConteiner,
  ButtonsTemplateConteiner,
  InputsTemplateConteiner,
  ButtonNormal,
  TopCardsBottomLabel,
  FootersHCDV,
} from "./HealthCheckDataViewerStyled";
import { apiRequest, apiRoutes } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

export default function HealthCheckDataViewer() {
  const navigate = useNavigate();
  const [entitiesArray, setEntitiesArray] = useState("services");
  const [selectedEntitie, setSelectedEntitie] = useState("Services");
  const [domainOptions, setDomainOptions] = useState([]);
  const [teamsOptions, setTeamsOptions] = useState([]);
  const [datesOptions, setDatesOptions] = useState([]);
  const [entitiesInfo, setEntitiesInfo] = useState([]);
  const [dateStart, setDateStart] = useState(null);
  const [currentDomain, setCurrentDomain] = useState("");
  const [selectionModalState, setSelectionModalState] = useState(null);
  const [currentModalIndex, setCurrentModalIndex] = useState(1);
  const [clientIMG, setClientIMG] = useState(null);
  const [PP, setPP] = useState(false);
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [successfullCards, setSuccessfullCards] = useState(2);
  const [failedCards, setFailedCards] = useState(3);
  const [weekDay, setWeekDay] = useState("Monday");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [HTML, setHTML] = useState("");
  const [sendEmailFlag, setSendEmailFlag] = useState(false);
  const [loadingHTML, setLoadingHTML] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [emailBody, setEmailBody] = useState(
    "Thanks for your attention! Below you will find an attachment that we consider relevant to you."
  );
  const reportTemplateRef = useRef(null);
  const pdfViewerRef = useRef(null);

  const handleDownload = (name) => {
    if (pdfViewerRef.current) {
      pdfViewerRef.current.downloadPdf(name);
    }
  };

  useEffect(() => {
    fetch_Domains();
    fetch_EntitiesInfo();
  }, []);

  useEffect(() => {
    domainOptions && setCurrentDomain(domainOptions[0]?.value);
  }, [domainOptions]);

  useEffect(() => {
    currentDomain && fetch_DomainsDates();
  }, [currentDomain]);

  useEffect(() => {
    selectedEntitie === "TEAM" && fetch_Teams();
  }, [selectedEntitie, currentDomain, dateStart]);

  const fetch_Teams = async (client) => {
    const [error, res] = await apiRequest({
      method: "get",
      url: `${apiRoutes.healthCheckDataViewerTeamsList}${currentDomain}/${dateStart}`,
      newDomain: true,
    });
    if (res) {
      const options = res.map((element) => {
        return element.team_name;
      });
      setTeamsOptions(
        options.map((element) => {
          return { value: element };
        })
      );
      setSelectedTeam(options[0]);
    }
    if (error) console.log("err", error);
  };

  const fetch_Domains = async (client) => {
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.healthCheckDataViewerDomains,
      newDomain: true,
    });
    if (res) {
      const options = res.map((element) => {
        return { value: element.domain };
      });
      setDomainOptions(options);
      if (currentDomain && dateStart) {
        navigate({
          pathname: Paths.HealthCheckDataViewer,
          search: `?${createSearchParams({
            domain: currentDomain,
            startDate: dateStart,
          })}`,
        });
      }
    }
    if (error) console.log("err", error);
  };

  useEffect(() => {
    datesOptions && setDateStart(datesOptions[0]?.value);
  }, [datesOptions]);

  const fetch_DomainsDates = async (client) => {
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.healthCheckDataViewerDomainsAvailableDates + currentDomain,
      newDomain: true,
    });
    if (res) {
      const options = res.map((element) => {
        return { value: element.folder };
      });
      setDatesOptions(options);
    }
    if (error) console.log("err", error);
  };

  const fetch_Info = async () => {
    setLoadingHTML(true);
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.healthCheckDataViewerGetPDF,
      newDomain: true,
      data: {
        entity: entitiesArray.filter(
          (entity) => entity.value === selectedEntitie
        )[0].key,
        domain_name: currentDomain,
        template_name: selectionModalState,
        date: dateStart,
        week_day: weekDay,
        number_of_successes: successfullCards,
        number_of_failures: failedCards,
        companyImg: clientIMG,
      },
    });
    if (res) {
      setHTML(res);
      setLoadingHTML(false);
    }
    if (error) console.log("err", error);
  };

  const sendEmail = async () => {
    setSendEmailFlag(false);
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.healthCheckDataViewerSendPDF,
      newDomain: true,
      data: {
        entity: entitiesArray.filter(
          (entity) => entity.value === selectedEntitie
        )[0].key,
        domain_name: currentDomain,
        template_name: selectionModalState,
        date: dateStart,
        week_day: weekDay,
        number_of_successes: successfullCards,
        number_of_failures: failedCards,
        recipient: receiver,
        email_body: emailBody,
        companyImg: clientIMG,
      },
    });
    if (error) console.log("err", error);
  };

  const fetch_Entities = async (client) => {
    const [error, res] = await apiRequest({
      method: "get",
      url:
        apiRoutes.healthCheckDataViewerEntities +
        `${currentDomain}/${dateStart}`,
      newDomain: true,
    });
    if (res) {
      const set = [...new Set(res)];
      setEntitiesArray(
        set.map((element) => {
          return {
            value: element.replace(/[_-]/g, " ").toUpperCase(),
            key: element,
          };
        })
      );
    }
    if (error) console.log("err", error);
  };

  const fetch_PP_template = async (client) => {
    setLoadingHTML(true);
    const [error, res] = await apiRequest({
      method: "get",
      url:
        apiRoutes.healthCheckDataViewerPPTemplate +
        `${selectedTeam}/${currentDomain}/${dateStart}`,
      newDomain: true,
    });
    if (res) {
      setHTML(res.base_64);
      setPP(true);
      setLoadingHTML(false);
    }
    if (error) console.log("err", error);
  };

  const fetch_EntitiesInfo = async (client) => {
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.healthCheckDataViewerEntitiesInfo,
      newDomain: true,
      headers: { "field-filter": 1 },
    });
    if (res) {
      setEntitiesInfo(res);
    }
    if (error) console.log("err", error);
  };

  useEffect(() => {
    currentDomain && dateStart && fetch_Entities();
  }, [currentDomain, dateStart]);

  useEffect(() => {
    entitiesArray && setSelectedEntitie(entitiesArray[0]?.value);
  }, [entitiesArray]);

  useEffect(() => {
    selectionModalState && fetch_Info();
  }, [selectionModalState]);

  const selectTemplate = (template) => {
    setSelectionModalState(template);
  };

  const validateEmail = () => {
    var re = /\S+@\S+\.\S+/;
    return re.test(receiver);
  };

  const setSelectionModalStateFunction = () => {
    setSelectionModalState(null);
    setPP(false);
  };

  const templateIMGLiteral = {
    template1: () => Template1IMG,
    templatePP1: () => Template1IMGPP,
    template2: () => Template2IMG,
    template3: () => Template3IMG,
  };

  return domainOptions.length && datesOptions.length && selectedEntitie ? (
    <div className="healthCheckDataViewer__container" style={{ width: "100%" }}>
      <DashboardTitlesContainer>
        <div style={{ display: "flex" }}>
          <AppIcon type="healthCheckDataViewer" />
          <h2
            id="healthCheckDataViewer-title"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            Health Check Data Viewer
          </h2>
        </div>
      </DashboardTitlesContainer>
      <div
        style={{
          display: "flex",
          justifyContent: 'center',
          padding:"20px 0",
          margin: "20px 0",
          columnGap: "20px",
          height: "100%",
          width: "100%",
        }}
      >
        <LeftPanel>
          <div>
            <TopCardsBottomLabel>Current Domain</TopCardsBottomLabel>
            <Dropdown
              options={domainOptions}
              onChange={(value) => setCurrentDomain(value)}
              value={currentDomain}
            />
          </div>
          <div>
            <TopCardsBottomLabel>Start Date</TopCardsBottomLabel>
            <Dropdown
              options={datesOptions}
              onChange={(value) => setDateStart(value)}
              value={dateStart}
            />
          </div>
          <div>
            <TopCardsBottomLabel>Entitie</TopCardsBottomLabel>
            <Dropdown
              options={entitiesArray}
              onChange={(value) => setSelectedEntitie(value)}
              value={selectedEntitie}
            />
          </div>
        </LeftPanel>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Tabs
            Tabs={
              selectedEntitie === "TEAM"
                ? [
                    { label: "HTML", enable: true },
                    { label: "Power Point", enable: true },
                  ]
                : [{ label: "HTML", enable: true }]
            }
            onTabChange={(tab) => setCurrentTab(tab)}
          >
            <TemplatesModalContainer>
              {currentTab === "HTML" && (
                <>
                  <TemplatesModalContainerInfo>
                    <div style={{ width: "100%" }}>
                      <label>Week Day</label>
                      <div style={{ padding: "10px 10px 0px 10px" }}>
                        <Dropdown
                          options={[
                            { value: "Monday" },
                            { value: "Tuesday" },
                            { value: "Wednesday" },
                            { value: "Thursday" },
                            { value: "Friday" },
                            { value: "Saturday" },
                            { value: "Sunday" },
                          ]}
                          onChange={(value) => setWeekDay(value)}
                          value={weekDay}
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <label>Client Logo URL</label>
                        <InputsTemplateConteiner
                          value={clientIMG}
                          onChange={(e) => setClientIMG(e.target.value)}
                        />
                      </div>
                      <div>
                        <label>Number of Successfull Cards</label>
                        <InputsTemplateConteiner
                          type="number"
                          onChange={(e) => setSuccessfullCards(e.target.value)}
                          value={successfullCards}
                        />
                      </div>
                      <div>
                        <label>Number of Failed Cards</label>
                        <InputsTemplateConteiner
                          type="number"
                          onChange={(e) => setFailedCards(e.target.value)}
                          value={failedCards}
                        />
                      </div>
                    </div>
                  </TemplatesModalContainerInfo>
                  <TemplatesModalContainerTemplates>
                    <TemplatePagesButton
                      disabled={currentModalIndex === 1}
                      onClick={() =>
                        setCurrentModalIndex(currentModalIndex - 1)
                      }
                    >
                      <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    </TemplatePagesButton>
                    <IndividualTemplateContainer>
                      <img
                        src={templateIMGLiteral[
                          "template" + currentModalIndex
                        ]()}
                        style={{
                          height: "400px",
                          boxShadow: "0px 0px 5px 5px #00000045",
                        }}
                      />
                      <IndividualTemplateButton
                        onClick={() =>
                          selectTemplate("template" + currentModalIndex)
                        }
                      >
                        Select Template
                      </IndividualTemplateButton>
                    </IndividualTemplateContainer>
                    <TemplatePagesButton
                      disabled={currentModalIndex === 3}
                      onClick={() =>
                        setCurrentModalIndex(currentModalIndex + 1)
                      }
                    >
                      <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    </TemplatePagesButton>
                  </TemplatesModalContainerTemplates>
                </>
              )}
              {currentTab === "Power Point" && (
                <>
                  <TemplatesModalContainerInfo>
                    <div style={{ width: "100%" }}>
                      <label>Team</label>
                      <div style={{ padding: "10px 10px 0px 10px" }}>
                        <Dropdown
                          options={teamsOptions}
                          onChange={(value) => setSelectedTeam(value)}
                          value={selectedTeam}
                        />
                      </div>
                    </div>
                  </TemplatesModalContainerInfo>
                  <TemplatesModalContainerTemplates>
                    <TemplatePagesButton
                      disabled={currentModalIndex === 1}
                      onClick={() =>
                        setCurrentModalIndex(currentModalIndex - 1)
                      }
                    >
                      <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    </TemplatePagesButton>
                    <IndividualTemplateContainer>
                      <img
                        src={templateIMGLiteral[
                          "templatePP" + currentModalIndex
                        ]()}
                        style={{
                          height: "400px",
                          boxShadow: "0px 0px 5px 5px #00000045",
                        }}
                      />
                      <IndividualTemplateButton
                        onClick={() => fetch_PP_template()}
                        disabled={!selectedTeam}
                      >
                        Select Template
                      </IndividualTemplateButton>
                    </IndividualTemplateContainer>
                    <TemplatePagesButton
                      disabled={currentModalIndex === 1}
                      onClick={() =>
                        setCurrentModalIndex(currentModalIndex + 1)
                      }
                    >
                      <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    </TemplatePagesButton>
                  </TemplatesModalContainerTemplates>
                </>
              )}
            </TemplatesModalContainer>
            <FootersHCDV>
              Copyright © {new Date().getFullYear()} Csg Innovation Services -
              Pagerduty, Inc. All rights reserved.
            </FootersHCDV>
          </Tabs>
        </div>
      </div>
      <Modal
        modalFitContent={true}
        show={selectionModalState || loadingHTML || PP}
        title={""}
        handleClose={() => setSelectionModalStateFunction()}
      >
        <TemplateViwerContainer>
          {!loadingHTML && (
            <TemplateViwerContainerInner>
              <div ref={reportTemplateRef}>
                <PDFViewer
                  ref={pdfViewerRef}
                  onSetNumberOfPages={(numPages) => setNumPages(numPages)}
                  pageNumber={pageNumber}
                  HTML={HTML}
                />
              </div>
            </TemplateViwerContainerInner>
          )}
          {loadingHTML && (
            <div style={{ width: "595px" }}>
              <LoadingSpinner />
            </div>
          )}
          {!loadingHTML && (
            <>
              <PaginationTemplateConteiner>
                <TemplatePagesButton
                  disabled={pageNumber <= 1}
                  onClick={() => setPageNumber(pageNumber - 1)}
                >
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                </TemplatePagesButton>
                <label>
                  Page {pageNumber} of {numPages}
                </label>
                <TemplatePagesButton
                  disabled={pageNumber === numPages}
                  onClick={() => setPageNumber(pageNumber + 1)}
                >
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </TemplatePagesButton>
              </PaginationTemplateConteiner>
              <ButtonsTemplateConteiner>
                <ButtonNormal onClick={() => setSendEmailFlag(!sendEmailFlag)}>
                  Send E-mail
                </ButtonNormal>
                <ButtonNormal
                  onClick={() =>
                    handleDownload(
                      "template" + currentModalIndex + new Date().getTime()
                    )
                  }
                >
                  Download as PDF
                </ButtonNormal>
              </ButtonsTemplateConteiner>
            </>
          )}
        </TemplateViwerContainer>
      </Modal>
      <Modal
        modalFitContent={true}
        show={sendEmailFlag}
        title={""}
        handleClose={() => {
          setSendEmailFlag(null);
          setReceiver("");
          setEmailBody("");
        }}
      >
        <ButtonsTemplateConteiner style={{ alignItems: "center" }}>
          <input
            style={{ borderRadius: "5px" }}
            placeholder="E-mail"
            value={receiver}
            onChange={(e) => setReceiver(e.target.value)}
          />
          <ButtonNormal
            onClick={() => sendEmail()}
            disabled={!receiver || !validateEmail()}
          >
            Send
          </ButtonNormal>
        </ButtonsTemplateConteiner>
        {!validateEmail() && receiver && (
          <label
            style={{
              fontWeight: "normal",
              color: "red",
              marginBottom: "10px",
              textAlign: "center",
              width: "100%",
            }}
          >
            E-mail is not valid *
          </label>
        )}
        <ButtonsTemplateConteiner style={{ alignItems: "center" }}>
          <textarea
            style={{
              borderRadius: "5px",
              width: "1000px",
              height: "500px",
              padding: "20px",
            }}
            placeholder="E-mail Body"
            value={emailBody}
            onChange={(e) => setEmailBody(e.target.value)}
          />
        </ButtonsTemplateConteiner>
      </Modal>
    </div>
  ) : (
    <SkeletonHCDV />
  );
}
