import styled from "styled-components";

export const QuestionStyle = styled.div.attrs(({ color }) => ({
  "data-active": color,
}))`
  background-color: white;
  max-height: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  & svg {
    color: ${({ color }) => (color ? color : "rgb(6, 172, 56)")};
    margin-right: 7px;
  }
`;

export const Question = styled.div`
  font-weight: bold;
  text-align: initial;
`;

export const Answer = styled.div`
  margin-top: 10px;
  font-size: 12px;
  text-align: initial;
`;
export const QuestionContainer = styled.div`
  background-color: white;
  margin-bottom: 15px;
  box-shadow: 0px 0px 2px #9d9d9d;
  padding: 10px;
  width: 100%;
`;
