import React from "react";

const GoodFace = ({ scale }) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={scale ? 198.853 * scale : "198.853px"}
      height={scale ? 198.748 * scale : "198.748px"}
      viewBox="0 0 198.853 198.748"
      enableBackground="new 0 0 198.853 198.748"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.853,99.212c-0.013,55.179-44.334,99.546-99.435,99.535
	C44.458,198.738-0.138,154.035,0,99.093C0.138,44.264,44.884-0.056,100.045,0C154.329,0.055,198.864,44.773,198.853,99.212z
	 M10.45,99.258c0.021,49.151,39.893,89.195,88.882,89.263c48.789,0.067,89.12-39.957,89.133-88.457
	c0.013-49.989-39.481-89.594-89.331-89.581C50.341,10.497,10.43,50.448,10.45,99.258z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.49,151.761c-10.483-0.268-23.527-5.391-33.026-17.75
	c-1.219-1.586-1.664-4.316-1.336-6.333c0.196-1.204,2.673-2.955,3.983-2.841c1.863,0.163,4.139,1.395,5.301,2.896
	c14.634,18.907,46.444,18.072,60.254-0.397c2.078-2.778,4.803-3.896,7.734-1.668c3.208,2.44,2.363,5.432,0.3,8.333
	C130.08,144.714,114.826,151.785,94.49,151.761z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.346,90.481c-6.572,0.137-11.813-4.947-11.867-11.513
	c-0.054-6.277,4.963-11.398,11.3-11.535c6.373-0.138,11.633,4.697,11.903,10.941C147.961,84.8,142.766,90.348,136.346,90.481z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.011,67.46c6.549,0.07,11.729,5.397,11.509,11.835
	c-0.212,6.188-5.577,11.311-11.734,11.206c-6.238-0.106-11.479-5.327-11.492-11.445C51.281,72.54,56.483,67.391,63.011,67.46z"
      />
    </svg>
  );
};

export default GoodFace;
