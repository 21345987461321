import React, { useState, useEffect } from "react";
import { apiRoutes, apiRequest } from "../../services";
import LoadingPagey from "../../components/LoadingPagey";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

import {
  AreaContainer,
  Button,
  CirclesContainer,
  FormContainer,
  LoginContainer,
  RightPanel,
  RightPanelBottom,
  RightPanelContainer,
  WallpaperContainer,
  OrLabel,
} from "./loginStyled";
import InnovationLogo from "../../assets/InnovationLogo.png";
import "./Login.css";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNewIndex, setSelectedNewIndex] = useState(0);
  const newsArray = [
    {
      image: "https://d274pwa32h7vmw.cloudfront.net/loginBackgound1.png",
      area: "CSG Innovation Services",
      title: "August 2024 Release Summary",
      description: "New portal features to adapt the new Pagerduty standard brand.",
      launch: false,
      items:[
        {
          "title": "Health Check Alert App",
          "item":[
            "4 New Health Check Alerts",
            "Improved Alert Cards, showing expected and actual values on all alerts and level of effort on Get Well Plans",
            "Improved Health Check Alerts Dashboard, showing top 3 most critical alerts and monthly trends. New capability to click on rule type or severity and filter to those alerts, submit requests and find related Service Request Accelerators",
            "New Team Health Check Alert Dashboard, including team health scores, monthly trends, 'worst/best team' drill down and ability click on rule type or severity and filter to those alerts, submit requests and find related Service Request Accelerators"
          ]
        },
        {
          "title": "Solutions Gallery",
          "item":[
            "6 New Service Request Accelerators aligned with the Operational Maturity Model and 5 New Customized Solutions"
          ]
        },{
          "title": "Requests",
          "item":[
            "Improved Premium Support Request and Service Request Form UI/UX"
          ]
        }
      ],
    },
    
    {
      image: "https://d274pwa32h7vmw.cloudfront.net/loginBackgound1.png",
      area: "CSG Innovation Services",
      title: "Premium Services Customer Entitlements",
      description: "New portal features to adapt the new Pagerduty standard brand.",
      items:[
        {
          "item": [
            "Service Request Hours ",
            "PagerDuty University Learning & Certifications",
            "Professional Services Optimize & Automation Engagements",
            "Premium Support Cases "
          ]
        }
        // "Service Request Hours ",
        // "PagerDuty University Learning & Certifications",
        // "Professional Services Optimize & Automation Engagements",
        // "Premium Support Cases "
      ],
      launch: false
    },
    {
      image: "https://d274pwa32h7vmw.cloudfront.net/loginBackgound1.png",
      area: "CSG Innovation Services",
      title: "Universal Request",
      description: "New portal features to adapt the new Pagerduty standard brand.",
      items:[
        {
          "item": [
            "Service Request",
            "Premium Support Request",
            "PagerDuty University Request",
            "Feature Request",
          ]
        },
        // "Service Request",
        // "Premium Support Request",
        // "PagerDuty University Request",
        // "Feature Request",
      ],
      launch: false
    },
    {
      image: "https://d274pwa32h7vmw.cloudfront.net/loginBackgound1.png",
      area: "CSG Innovation Services",
      title: "Health Check Alerts",
      description: "New portal features to adapt the new Pagerduty standard brand.",
      items: [
        {
          "item": [
            "Urgent Recommendations", "One-click Service Request submission from each Health Check Alert."
          ]
        },
        // "Urgent Recommendations", "One-click Service Request submission from each Health Check Alert."
      ],
      launch: false
    },
    // {
    //   image: "https://d274pwa32h7vmw.cloudfront.net/loginBackgound1.png",
    //   area: "CSG Innovation Services",
    //   title: "Product Subscriptions, Add-Ons and Deployment Metrics",
    //   description: "New portal features to adapt the new Pagerduty standard brand.",
    //   launch: false
    // },
  ];

  const getLoginWithOkta = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes["login"],
      });

      if (!error) {
        window.location.replace(data.url);
      }
    }, 1500);
  };

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    sessionStorage.removeItem("mostrarModal");
  },[]);
  
  useEffect(() => {
    let intervalId;
      intervalId = setInterval(() => {
        setSelectedNewIndex(() =>
          selectedNewIndex !== newsArray.length - 1 ? selectedNewIndex + 1 : 0
        );
      }, 10000);


    return () => {
      clearInterval(intervalId);
    };
  }, [isHovered, selectedNewIndex]);

  const getLoginWithOauth = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      const [error, data] = await apiRequest({
          method: "get",
          url: apiRoutes.loginOauth,
      });
      if (!error) {
        window.location.replace(data.url);
      }
    }, 1500);
  };

  return (
    <LoginContainer>
          <AreaContainer>
            <CirclesContainer>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </CirclesContainer>
          </AreaContainer>
      {isLoading ? (
        <>
          <FormContainer>
          <LoadingPagey state={0}></LoadingPagey>
          </FormContainer>
        </>
      ) : (
        <>
          <FormContainer>
            <RightPanelContainer>
            <RightPanel>
              <img
                src={InnovationLogo}
                alt="Innovation Logo"
                style={{
                  height: "60px",
                  marginBottom: "15px",
                  alignSelf: "baseline",
                }}
              />
              <span
                style={{
                  alignSelf: "baseline",
                  fontSize: "12px",
                  marginBottom: "15px",
                  color: "grey",
                  textAlign: "left",
                }}
              >
                The CSG Premium Service Portal is more than just a portal; it's your one-stop command center for maximizing the value of your PagerDuty Operations Cloud and Premium Services investment. From a centralized view of your Premium Services entitlements, a streamlined request experience, product deployment insights and proactive health check alerts, the portal empowers you to drive desired outcomes with ease. It's your centralized hub for action, success, and maximizing your PagerDuty investment. 
              </span>
              <Button onClick={() => getLoginWithOauth()}>
                Premium Customer Login (PagerDuty Login/Password)
              </Button>
              <OrLabel>or</OrLabel>
              <Button onClick={() => getLoginWithOkta()}>
                Dutonian Login (Okta)
              </Button>
              <span style={{ marginTop: "20px" }}>
                Problems?, <a href="mailto:CSG-Innovation@pagerduty.com">CSG-Innovation@pagerduty.com</a>
              </span>
            </RightPanel>
                <RightPanelBottom>
                Copyright © {new Date().getFullYear()} CSG Innovation Services -
          Pagerduty, Inc. All rights reserved.
                </RightPanelBottom>
            </RightPanelContainer>
            <WallpaperContainer
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              style={{
                backgroundImage: `url(${newsArray[selectedNewIndex].image}`,
              }}
            >
              {/* <div>
                <h2>Highlights</h2>
                <ul role="list" className="login-features">
                  <li class="relative">
                    <div class="login-feature__item">
                      <FeatureContainer>
                        <p>Premium Services Customer Entitlements</p>
                      </FeatureContainer>
                    </div>
                  </li>
                  <li class="relative">
                    <div class="login-feature__item">
                      <FeatureContainer>
                        <p>Product Subscriptions, Add-Ons and Deployment Metrics</p>
                      </FeatureContainer>
                    </div>
                  </li>
                  <li class="relative">
                    <div class="login-feature__item">
                      <FeatureContainer>
                        <p>Universal Request </p>
                      </FeatureContainer>
                    </div>
                  </li>
                  <li class="relative">
                    <div class="login-feature__item">
                      <FeatureContainer>
                        <p>Health Check Alerts</p>
                      </FeatureContainer>
                    </div>
                  </li>
                </ul>
              </div> */}
              <div
                style={{
                  alignSelf: "baseline",
                  textAlign: "left",
                  fontSize: "30px",
                }}
              >
                {newsArray[selectedNewIndex].title}
              </div>
              {newsArray[selectedNewIndex].items?.length > 0 &&<div style={{display: "flex"}}>
                <ul className="item-list">
                  {newsArray[selectedNewIndex].items.map((item) => (
                    <div>
                        <div className="title__sliderLogin">{item?.title}</div>
                        {item?.item?.map(description => (
                           <li className="item-feature">{description}</li>
                        ))}
                    </div>
                  ))}
                </ul>
              </div>}
              {/* { newsArray[selectedNewIndex].launch && <Fireworks autorun={{ speed: 3 }} />

              } */}
              {/* <WallpaperContainerBottom>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {newsArray[selectedNewIndex].area}
                  <div style={{ display: "flex", columnGap: "5px" }}>
                    <ArrowButton
                      onClick={() =>
                        setSelectedNewIndex(
                          selectedNewIndex !== 0
                            ? selectedNewIndex - 1
                            : newsArray.length - 1
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </ArrowButton>
                    <ArrowButton
                      onClick={() =>
                        setSelectedNewIndex(
                          selectedNewIndex !== newsArray.length - 1
                            ? selectedNewIndex + 1
                            : 0
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </ArrowButton>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    textAlign: "left",
                    marginTop: "10px",
                    fontSize: "12px",
                  }}
                >
                  {newsArray[selectedNewIndex].description}
                </div>
              </WallpaperContainerBottom> */}
            </WallpaperContainer>
          </FormContainer>
        </>
      )}
    </LoginContainer>
  );
}
