import  './Spinner.css'

const  Spinner = () => { 
  return(
    <div className="spinner">
      <span></span>
      <span></span>
      <span></span>
  </div>
  )
}

export default Spinner