import React, { useState, useEffect } from "react";
import { Redirect, useNavigate } from "react-router-dom";
import "./Profile.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import Paths from "../../paths";
import { apiRoutes, apiRequest } from "./../../services";


export default function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState(null);

  const getProfile = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.userProfile,
    });
    if (!error) {
      setUserData(data)
    }
    else {
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProfile();
  }, []);


  return (
    <>
    {isLoading && (
        <div id="SuccessOnDemand-loader">
          <LoadingSpinner />
        </div>
      )}
  {userData && <div className="profile-container">
    <div className="profile-maininfo">
      <img src={userData.userInfo.userProfilePhotoUrl}></img>
      <h3>{userData.userInfo.userName} </h3>
      <p className="title"> {userData.userInfo.userJobTitle}</p>
      <p className="subtitle">{userData.userEmail}</p>
      <div className="profile-general-info_container">
        <label><strong>Manager</strong></label>
        <p className="subtitle"> {userData.userInfo.userManager}</p>
        <label><strong>Role</strong></label>
        <p className="subtitle">{userData.userInfo.userRole}</p>
      </div>
    </div>
    <div>
      <div>
      </div>
      <div>

      </div>
    </div>
  </div>}
  </>
    );
}
