import React, { useState, useEffect } from 'react';
import './SupportModal.css';

const SupportModal = ({ subdomain, email, onFormDataChange, initialFormData }) => {
  const [formData, setFormData] = useState(initialFormData || {
    subdomain: subdomain,
    email: email,
    subject: '',
    details: `1. What is the issue you are facing? 
2. What is the expected behavior? 
3. What is the actual behavior? 
4. Steps to reproduce the behavior? 
5. Any additional information?`
  });

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    validateForm();
    onFormDataChange(formData);
  }, [formData, onFormDataChange]);

  const validateForm = () => {
    const valid = 
      typeof formData.subdomain === 'string' && formData.subdomain.trim() !== '' &&
      typeof formData.email === 'string' && formData.email.trim() !== '' &&
      typeof formData.subject === 'string' && formData.subject.trim() !== '' &&
      typeof formData.details === 'string' && formData.details.trim() !== '';
    setIsValid(valid);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  return (
    <section className='premium-support-request__modal-main'>
      <section className='premium-support-request__modal-form'>
        <section className="premium-support-request__modal-col2">
          <div className="premium-support-request__modal-group">
            <label htmlFor="subdomain" className="premium-support-request__modal-label">Subdomain</label>
            <input
              id="subdomain"
              name="subdomain"
              className="premium-support-request__modal-input"
              type="text"
              value={subdomain || ''}
              disabled
            />
          </div>
          <div className="premium-support-request__modal-group">
            <label htmlFor="email" className="premium-support-request__modal-label">Email</label>
            <input
              id="email"
              name="email"
              className="premium-support-request__modal-input"
              type="email"
              value={email || ''}
              disabled
            />
          </div>
        </section>
        <section className="premium-support-request__modal-col1">
          <div className="premium-support-request__modal-group">
            <label htmlFor="subject" className="premium-support-request__modal-label">Request Subject</label>
            <input
              id="subject"
              name="subject"
              className="premium-support-request__modal-input"
              type="text"
              value={formData.subject || ''}
              onChange={handleInputChange}
            />
          </div>
        </section>
        <section className="premium-support-request__modal-col1">
          <div className="premium-support-request__modal-group">
            <label htmlFor="details" className="premium-support-request__modal-label">Request Details</label>
            <textarea
              id="details"
              name="details"
              className="premium-support-request__modal-textarea"
              rows={10}
              value={formData.details || ''}
              onChange={handleInputChange}
            />
          </div>
        </section> 
      </section>
    </section>
  );
};

export default SupportModal;