import React from "react";

import ButtonStyled from "./Button.styles";

const Button = ({
  text,
  style,
  disabled,
  id,
  onClick,
  icon,
  large,
  loading,
}) => {
  return (
    <ButtonStyled
      id={id}
      onClick={onClick}
      buttonStyle={style}
      disabled={disabled}
      large={large}
      loading={loading}
    >
      {icon && React.createElement(icon)}
      {text}
    </ButtonStyled>
  );
};

export default Button