import styled from "styled-components";

export const HealthySmallIcon = styled.img`
  height: 40px;
`;

export const TemplatesModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: center;
  margin: 20px;
  background-color: white;
  column-gap: 40px;
  border-radius: 4px;
`;

export const LeftPanel = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  padding: 20px;
  padding-bottom: 40px;
  align-items: flex-start;
  row-gap: 10px;
  flex-shrink: 1;
  border-radius: 4px;
  border: 1px solid #dededf;
  min-width: 280px;
`;

export const TemplatesModalContainerTemplates = styled.div`
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const TemplatesModalContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 470px;
`;

export const TemplatesModalContainerInfoInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const IndividualTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

export const TemplateViwerContainerInner = styled.div`
  height: 562px;
  overflow-y: auto;
`;

export const TemplateViwerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  margin: 20px;
`;

export const DashboardGraphicContainer = styled.div`
  width: 200px;
  border: 1px solid #0000003b;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 5px #0000003d;
  padding: 20px 13px;
  background-color: #f7f7f7;
`;
export const DashboardGraphicCard = styled.div`
  width: fit-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 3px #00000085;
  background-color: white;
`;

export const DashboardGraphicCardsContainer = styled.div`
  padding: 20px;
  margin: 20px 0px;
  box-shadow: 0px 0px 5px 1px #000000 inset;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  -webkit-column-gap: 20px;
  overflow-x: auto;
  background-color: #5d5d5d57;
`;

export const DashboardTableContainer = styled.div`
  width: calc(100vw - 146px);
  height: 300px;
  margin-bottom: 70px;
`;

export const DashboardTitlesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 20px;
`;

export const DashboardButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  justify-content: end;
  margin-top: 10px;
`;

export const BigDropdownContainer = styled.div`
  & input {
    width: 467px !important;
  }
`;

export const ButtonNormal = styled.button`
  font: inherit;
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  height: 40px;
  border-radius: 8px;
  line-height: 1;
  border: 2px solid var(--c-border-primary);
  color: var(--c-text-action);
  font-size: 1rem;
  transition: 0.15s ease;
`;

export const IndividualTemplateButton = styled.button`
  border: 1px solid #0000004a;
  margin-top: 30px;
  padding: 6px 20px;
  border-radius: 5px;
  background-color: #08ac38;
  color: white;
  &:hover {
    background-color: #e9ecf4;
    color: black;
  }
  &:disabled {
    background-color: #e9ecf4;
    color: white;
    border-color: white;
    cursor: not-allowed;
  }
`;

export const TemplatePagesButton = styled.button`
  font-size: 45px;
  border: none;
  outline: none;
  background: transparent;
  color: #08ac38;
  &:disabled {
    color: #e9ecf4;
  }
`;

export const PaginationTemplateConteiner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const ButtonsTemplateConteiner = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const InputsTemplateConteiner = styled.input`
  border: 1px solid #0000004d;
  border-radius: 5px;
  width: calc(100% - 20px);
  padding: 17px 24px;
  margin: 10px;
  border: 1px #8a95a8 solid;
  border-radius: 8px;
  font-size: 14px;
`;

export const TopCards = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 15px 20px;
  justify-items: center;
  border-radius: 5px;
  align-items: end;
  min-width: 200px;
  font-size: 20px;
`;

export const TopCardLeft = styled.div`
  display: flex;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 5px 20px;
  justify-items: center;
  border-radius: 5px;
  align-items: center;
  min-width: 200px;
  font-size: 20px;
  column-gap: 10px;
`;

export const TopCardsBottomLabel = styled.label`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const FootersHCDV = styled.label`
  height: fit-content;
  background-color: white;
  padding: 20px;
  border-top: 1px solid #dededf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
`;
