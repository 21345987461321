import React from 'react';
import styles from './PainPointCard.module.css';

function PainPointCard({ title, description }) {
  return (
    <div className={styles.card}>
      <h3 className={styles.cardTitle}>{title}</h3>
      <p className={styles.cardDescription}>{description}</p>
    </div>
  );
}

export default PainPointCard;