
import {
  faTimes,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MultiCheckbox = ({options, isEmptyValue, tooltip, label,disabled, dropdownRef, filterState, isOpenDropdown, searchState, setSearchState, filteredOptions, handleDropdownClick, toggleCheckbox, mapFunction, nameLabel, disabledItem }) => {
  return (
      <div className="dropdown__clients" ref={dropdownRef}>
        <div className="container__tooltipMultiCheckbox">
          <div className="label__inputFilter">{label}</div>
          {tooltip  && <div className="tooltip-containerMultiCheck" >
            <span className="tooltip__multiCheck">Select Team Name(s) to see all available Health Check Alerts.</span>
            <div>
              <FontAwesomeIcon icon={faInfoCircle} className="icon__multiCheck" />
            </div>
          </div>}
        </div>
        <div style={{opacity: disabled  ? '0.5'  : '' }} className="dropdown-toggleButtonSolutionCategories" onClick={handleDropdownClick}>
          <div className="container__buttonFilter">
            {filterState.map(filter => (
              filteredOptions.map(item => (
                filter === mapFunction(item) && 
                <div key={mapFunction(item)}>
                  <p className="item__filter">{nameLabel(item)} <FontAwesomeIcon onClick={() => !disabled && toggleCheckbox(mapFunction(item))} icon={faTimes} /></p>
                </div>
              ))
            ))}
            {filterState.length === 0 && !isEmptyValue && (
                <div className="placeholder__select">Select {label}</div>
            )}
            {isEmptyValue && (
              <div className="placeholder__select">No {label} with Health Check Alerts</div>
            )}
          </div>
        </div>
        {isOpenDropdown && options.length > 0 &&(
            <div key={label} className="dropdown-menuMyAlerts">
              <ul key={label}>
                <input
                  className="search__subdomainHomeFilter input__filter"
                  type="text"
                  placeholder="Search..."
                  value={searchState}
                  onChange={(e) => setSearchState(e.target.value)}
                />
                {filteredOptions?.map(item => (
                  <li  key={item.id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={filterState.includes(mapFunction(item))}
                        onChange={() => toggleCheckbox(mapFunction(item))}
                        disabled={disabled || disabledItem}
                      />
                      <span className="customer_name">
                        {nameLabel(item)}
                        {label === 'Team Name' && ` - (${item.count})`}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )}
    </div>
      );
}

export default MultiCheckbox