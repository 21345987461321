import React from "react";

const AmazingFace = ({ scale }) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={scale ? 198.853 * scale : "198.853px"}
      height={scale ? 198.748 * scale : "198.748px"}
      viewBox="0 0 198.853 198.748"
      enableBackground="new 0 0 198.853 198.748"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.898,99.927c-0.086,54.706-44.961,98.997-100.141,98.838
	c-53.9-0.154-99.25-45.631-98.8-98.844C0.429,44.24,44.89-0.038,99.375-0.018C154.697,0.004,198.986,44.495,198.898,99.927z
	 M100.119,10.385c-49.662,0.094-87.778,37.97-89.712,84.518c-2.144,51.623,38.112,93.126,88.589,93.539
	c48.797,0.396,89.303-39.586,89.682-88.52C189.057,51.024,149.367,10.896,100.119,10.385z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.9,121.716c11.979,0,23.957-0.02,35.936,0.008
	c7.203,0.016,8.807,2.078,7.313,9.256c-4.375,21.023-22.01,35.59-42.877,35.42c-21.787-0.178-40.714-15.928-44.646-37.154
	c-0.944-5.098,0.938-7.451,6.345-7.486c12.644-0.078,25.288-0.025,37.931-0.025C99.9,121.728,99.9,121.722,99.9,121.716z
	 M68.001,132.269c3.441,14.523,19.539,24.918,35.679,23.527c13.699-1.182,26.746-12.217,27.512-23.527
	C110.248,132.269,89.324,132.269,68.001,132.269z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.002,95.442c5.217-9.161,0.166-16.22-7.828-22.832
	c-3.986-3.296-2.213-7.853,3.016-9.087c2.58-0.608,5.248-1.272,7.861-1.216c5.186,0.112,7.77-2.604,9.365-7.112
	c0.826-2.333,2.07-4.54,3.307-6.7c2.574-4.499,7.16-4.553,9.703-0.054c1.795,3.179,2.996,6.725,5.023,9.729
	c1.104,1.636,3.221,3.025,5.152,3.568c3.492,0.982,7.238,1.022,10.781,1.864c4.873,1.157,6.605,6.021,2.729,8.906
	c-8.613,6.409-11.361,13.864-8.217,24.34c1.598,5.324-3.293,8.714-7.875,5.451c-8.592-6.121-16.205-5.919-24.777-0.047
	C122.629,105.412,118.6,102.384,119.002,95.442z M131.275,87.904c5.658-3.476,10.904-3.486,16.566,0.149
	c-2.424-6.771,0.223-11.415,5.068-15.819c-7.293,0.503-10.816-3.594-13.58-10.837c-1.82,8.1-6.473,10.634-14.33,10.835
	C131.449,76.765,133.461,81.306,131.275,87.904z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.924,95.671c5.006-9.308,0.265-16.578-8.07-23.305
	c-3.849-3.105-1.998-7.619,2.976-8.793c3.068-0.725,6.229-1.473,9.348-1.47c4.02,0.005,5.961-1.926,7.319-5.377
	c1.092-2.775,2.604-5.399,4.064-8.014c2.595-4.643,7.777-5.102,9.502-0.325c3.61,10,9.659,15.154,20.671,15.247
	c5.433,0.046,7.265,5.979,2.906,9.218c-8.392,6.232-11.382,13.467-7.859,23.526c0.573,1.638-0.615,4.974-2.052,5.931
	c-1.471,0.982-4.88,0.848-6.348-0.254c-8.154-6.121-15.688-5.691-23.973,0.039C42.688,105.359,38.563,102.519,38.924,95.671z
	 M67.98,88.019c-3.66-7.057,0.766-11.173,5.478-16.147c-7.696,0.383-11.709-2.785-13.985-9.043
	c-2.407,6.243-6.346,9.702-13.587,9.64c6.109,3.957,7.067,9.262,5.414,15.765C56.95,84.239,62.055,84.49,67.98,88.019z"
      />
    </svg>
  );
};

export default AmazingFace;
