import "./SkeletonHome.css"

const SkeletonSupportSummary = () => {
  return (
    <div className="container__supportSummary">
      <div className="supportSummary__title skeleton__animation"></div>
      <div className="supportSummary__grid">
        <div className="grid__supportSummary skeleton__animation" ></div>
        <div className="grid__supportSummary skeleton__animation"></div>
        <div className="grid__supportSummary skeleton__animation"></div>
      </div>
    </div>
  );
};

export default SkeletonSupportSummary;