import React from "react";

const TerribleFace = ({ scale }) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={scale ? 198.853 * scale : "198.853px"}
      height={scale ? 198.748 * scale : "198.748px"}
      viewBox="0 0 198.853 198.748"
      enableBackground="new 0 0 198.853 198.748"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.279,0.013c54.955-0.07,99.611,44.536,99.592,99.483
	c-0.02,54.538-44.791,99.245-99.385,99.239c-54.621-0.004-99.471-44.416-99.506-98.538C-0.059,44.521,43.984,0.085,99.279,0.013z
	 M188.5,100.173c0.693-48.652-39.223-89.328-88.266-89.949c-48.793-0.617-89.342,39.18-89.924,88.259
	c-0.574,48.336,38.814,89.12,86.719,89.786C147.313,188.967,187.791,149.983,188.5,100.173z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.666,152.633c-6.809,0-13.623,0.174-20.424-0.055
	c-5.418-0.184-7.037-2.283-6.635-7.615c0.982-13.029,12.748-23.54,26.432-23.616c14.164-0.076,25.963,10.179,27.008,23.474
	c0.449,5.715-1.17,7.592-6.955,7.676c-6.475,0.096-12.951,0.021-19.426,0.021C99.666,152.557,99.666,152.594,99.666,152.633z
	 M114.207,141.854c-2.873-7.42-9.277-10.852-17.463-9.855c-6.246,0.76-11.129,4.896-11.68,10.25c8.641,0,17.246,0.01,25.85-0.016
	C111.863,142.231,112.811,142.022,114.207,141.854z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.041,86.042c0,14.443,0,27.918,0,41.394c0,8.832-0.041,17.666,0.027,26.498
	c0.027,3.576-0.789,6.5-5.021,6.49c-4.137-0.01-5.205-2.854-5.203-6.445c0.014-20.498-0.129-40.997,0.107-61.492
	c0.057-4.802-0.875-7.381-6.229-6.422c-1.125,0.201-2.709,0.444-3.418-0.139c-1.846-1.516-4.334-3.344-4.6-5.313
	c-0.186-1.366,2.828-4.485,4.521-4.567c9.809-0.475,19.65-0.385,29.479-0.25c3.193,0.044,5.91,1.994,5.027,5.328
	c-0.518,1.953-3.086,4.102-5.16,4.719C151.848,86.653,148.699,86.042,145.041,86.042z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.307,86.011c0,3.389,0,5.854,0,8.32c0,18.489,0.002,36.976-0.004,55.464
	c0,1.664-0.014,3.332-0.09,4.994c-0.156,3.486-1.717,5.867-5.477,5.654c-3.445-0.197-4.801-2.512-4.793-5.814
	c0.02-8.328-0.008-16.656-0.01-24.984c-0.002-12.823-0.117-25.651,0.078-38.472c0.059-3.836-0.938-5.669-5.033-5.107
	c-1.631,0.223-3.709,0.596-4.902-0.168c-1.977-1.266-4.402-3.238-4.713-5.215c-0.213-1.352,2.793-4.564,4.457-4.634
	c10.137-0.421,20.313-0.399,30.447,0.071c1.582,0.072,4.236,3.228,4.191,4.898c-0.045,1.701-2.547,4.209-4.449,4.781
	C71.283,86.622,68.123,86.011,64.307,86.011z"
      />
    </svg>
  );
};

export default TerribleFace;
