import { forwardRef, useImperativeHandle, useState, useLayoutEffect, useEffect } from 'react';
import './RangeSlider.css';
import Slider from '@mui/material/Slider';


const RangeSlider = forwardRef(({ min, max, label, onValueChange, values}, Ref) => {
  const [value, setValue] = useState([min, max]);
  useImperativeHandle(Ref, () => ({}));

  const handleChange = (event, newValue) => {
    onValueChange && onValueChange(newValue);
    setValue(newValue);
  };

  function valuetext(value) {
    return `${value}°C`;
  }


  return (
    <div id='sliderContainer'>
      {label && <label>{label}</label>}
      <div id='divRange'>
        <label className='filterRangeLabels'>{value[0]}</label>
        <label className='filterRangeLabels'>{value[1]}</label>
      </div>
      <Slider getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        defaultValue={values || [min,max]}
        min={min}
        step={1}
        max={max}
        getAriaValueText={valuetext}
        color="secondary"
      />
    </div>
  );
}
);

export default RangeSlider;