import { forwardRef, useImperativeHandle } from 'react';
import './Modal.css';


const Modal = forwardRef(({ title, type, handleClose, show, modalFitContent, dontShowCloseButton, children, text }, Ref) => {

    const showHideClassName = show ? "modal display-block" : "modal display-none";

    useImperativeHandle(Ref, () => ({}));

    return (
        <div className={showHideClassName}>
            <span 
              data-type="inside" 
              tabIndex="0" 
              aria-hidden="true" 
              data-floating-ui-focus-guard="" 
              data-floating-ui-inert="true" 
              style={{
                border: '0px',
                clip: 'rect(0px, 0px, 0px, 0px)',
                height: '1px',
                margin: '-1px',
                overflow: 'hidden',
                padding: '0px',
                position: 'fixed',
                whiteSpace: 'nowrap',
                width: '1px',
                top: '0px',
                left: '0px'
              }}
            ></span>
            <section className="modal-main" style={{height: modalFitContent, maxWidth: type ? type === 'small' ? '42rem': '90vw': '70%'}}>
                <div id='modal-button-close-div'>
                    <h3 id="modal-title">{title}</h3>
                   {!dontShowCloseButton && <button
                      onClick={handleClose}
                      aria-label="Close"
                      className='modal-button-close'
                      type="button"
                    >
                      <svg 
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        style={{
                          height: '1em',
                          width: '1em'
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path 
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                    }
                </div>
                    {text && <div style={{margin:'10px 20px'}}><p style={{fontSize:'11px'}}>{text}</p></div>}
                <div id="modal-container" style={{height: modalFitContent || 'calc(100% - 48px)'}}>
                    {children}
                </div>
                {false && <div id="modal-action-buttons">
                <button 
                  type="button"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0.125rem',
                    textAlign: 'center',
                    fontWeight: '500',
                    position: 'relative',
                    zIndex: 10,
                    border: 'none',
                    backgroundColor: '#2C7A7B',
                    color: '#FFFFFF',
                    borderRadius: '0.375rem',
                    ':hover': {
                      backgroundColor: '#2B6B6D'
                    },
                    ':focus': {
                      boxShadow: '0 0 0 2px #319795',
                      outline: 'none'
                    },
                    ':hover:dark': {
                      backgroundColor: '#285E61'
                    },
                    ':focus:dark': {
                      boxShadow: '0 0 0 2px #2B6B6D'
                    }
                  }}
                >
                  <span 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      transition: 'all 0.2s',
                      borderRadius: '0.375rem',
                      fontSize: 'small',
                      padding: '0.5rem 1rem'
                    }}
                  >
                    I accept
                  </span>
                </button>

                <button 
                  type="button"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0.125rem',
                    textAlign: 'center',
                    fontWeight: '500',
                    position: 'relative',
                    zIndex: 10,
                    color: '#1F2937',
                    backgroundColor: '#FFFFFF',
                    borderColor: '#E5E7EB',
                    borderRadius: '0.375rem',
                    ':hover': {
                      backgroundColor: '#F9FAFB',
                      color: '#2C7A7B'
                    },
                    ':focus': {
                      color: '#2C7A7B',
                      boxShadow: '0 0 0 2px #319795',
                      outline: 'none'
                    },
                    ':hover:dark': {
                      color: '#FFFFFF',
                      backgroundColor: '#3B3F46'
                    },
                    ':focus:dark': {
                      boxShadow: '0 0 0 2px #2B6B6D'
                    }
                  }}
                >
                  <span 
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      transition: 'all 0.2s',
                      borderRadius: '0.375rem',
                      fontSize: 'small',
                      padding: '0.5rem 1rem'
                    }}
                  >
                    <p>Decline</p>
                  </span>
                </button>
                </div>}
            </section>
        </div>
    );
}
);

export default Modal;