import "./SkeletonHome.css"

const SkeletonPremiumService = () => {
  return (
    <div className="container__premiumService">
      <div className="premiumService__title skeleton__animation"></div>
      <div className="premiumService__grid">
        <div className="grid__premiumService skeleton__animation" ></div>
        <div className="grid__premiumService skeleton__animation"></div>
        <div className="grid__premiumService skeleton__animation"></div>
      </div>
    </div>
  );
};

export default SkeletonPremiumService;