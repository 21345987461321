export const cleanAndSortCustomers = (data,name) => {
  const customerClean = data.filter((element) => {
    return (name ? element.name !== '' && element.name !== null :element.customer_name !== '' && element.customer_name !== null);
  });

  let result = name ? customerClean.filter(function({name}) {
    return !this.has(name) && this.add(name);
  }, new Set) : customerClean.filter(function({customer_name}) {
    return !this.has(customer_name) && this.add(customer_name);
  }, new Set)

  const cleanData = result.map(element => { 
    const trim = name ? element.name.trim(): element.customer_name.trim();
    return { value: trim, ...element } });
  
  const sort = name ? cleanData.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.customer_name) ? -1 : 0)) :cleanData.sort((a,b) => (a.customer_name > b.customer_name) ? 1 : ((b.customer_name > a.customer_name) ? -1 : 0))
  return sort
}