export const formatDay = (date) => {
  if (date) {
      date = new Date(date);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      return month < 10 ? `0${month}/${day}/${year}` : `${month}/${day}/${year}`;
  }
  return '';
};

