export const getDescendingColors = () => [
  '#FF0000',
  '#FF1919',
  '#FF3232',
  '#FF4C4C',
  '#FF6666',
  '#FF7F7F',
  '#FF9999',
  '#FFB2B2',
  '#FFCCCC',
  '#fddbdb'
];

export const getAscendingColors = () => [
  '#025A17',
  '#038723',
  '#05B42F',
  '#06E13A',
  '#1DF851',
  '#4AF974',
  '#77FB97',
  '#A4FCB9',
  '#D1FDDC',
  '#daf6e1'
];