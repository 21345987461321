import jwt_decode from "jwt-decode";

class Jwt {
  /*
    return value token save in localStorage
  */
  static getToken() {
    return localStorage.getItem("token");
  }

  /*
    save value token
    if exists and is the same don't save it.
  */
  static setToken(token) {
    if (token !== null && this.getToken() !== token) {
      localStorage.setItem("token", token);
    }
  }

  /*
    return value of key.
    if key is all return all values.
    if header is true return values headers.
    @Param key: {all, sessionId, login, role, companyCode}
    @Param headers: {true, false}
  */
  static getItem(key, header = false) {
    if (this.getToken()) {
      const data = jwt_decode(this.getToken(), { header: header });
      return key === "all" ? data : data[key];
    }
    return null;
  }

  /*
    Remove item token in localStorage
  */
  static removeToken() {
    localStorage.clear();
  }

 }

export default Jwt;
