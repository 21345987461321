import React from "react";

const OkayFace = ({ scale }) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={scale ? 198.853 * scale : "198.853px"}
      height={scale ? 198.748 * scale : "198.748px"}
      viewBox="0 0 198.853 198.748"
      enableBackground="new 0 0 198.853 198.748"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.662,0.156c54.86,0.108,99.265,44.485,99.251,99.224
	c-0.014,56.607-46.881,99.349-99.79,99.212C45.381,198.452-0.684,154.167-0.055,98.38C0.559,44.108,44.854,0.048,99.662,0.156z
	 M99.016,10.445c-49.414,0.017-88.64,40.017-88.658,90.294c-0.016,41.788,34.615,90.597,95.219,87.659
	c43.04-2.086,82.571-38.563,82.853-88.916C188.704,50.205,148.566,10.429,99.016,10.445z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.668,128.549c10.144-0.006,20.287,0.039,30.43-0.029
	c3.668-0.025,6.988,0.586,7.111,5.002c0.124,4.455-3.233,5.33-6.861,5.334c-20.785,0.027-41.57,0.027-62.355,0.021
	c-3.646-0.002-6.907-0.852-6.82-5.346c0.089-4.564,3.461-5.004,7.068-4.99C78.717,128.583,89.192,128.557,99.668,128.549z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.793,67.413c6.434-0.089,11.509,4.708,11.698,11.06
	c0.195,6.562-4.857,11.886-11.368,11.979c-6.457,0.094-11.716-5.134-11.716-11.648C124.406,72.537,129.439,67.501,135.793,67.413z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.396,78.829c0.049,6.494-5.221,11.716-11.729,11.624
	c-6.262-0.089-11.324-5.194-11.396-11.494c-0.073-6.313,4.881-11.383,11.256-11.52C69.208,67.296,74.346,72.227,74.396,78.829z"
      />
    </svg>
  );
};

export default OkayFace;
