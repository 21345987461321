import styled, { keyframes } from "styled-components";

const animate = keyframes`
0%{
  transform: translateY(0) rotate(0deg);
  opacity: 1;
  border-radius: 0;
}

100%{
  transform: translateY(-1000px) rotate(720deg);
  opacity: 0;
  border-radius: 50%;
}

`;

export const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  float: left;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.div`
  position: absolute;
  display: flex;
  column-gap: 20px;
`;

export const ButtonContainer = styled.div`
  padding: 30px;
  border-top: 1px solid #80808036;
  box-shadow: 0px 1px 3px 0px #88888840;
  display: flex;
  column-gap: 10px;
  justify-content: center;
`;

export const WallpaperContainer = styled.div`
  height: 610px;
  width: 825px;
  background-size: cover;
  border-radius: 10px;
  box-shadow: rgb(136 136 136 / 35%) 0px 0px 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 20px;
  color: white;
`;

export const WallpaperContainerBottom = styled.div`
  background-color: #00000073;
  padding: 20px;
  border-radius: 10px;
`;

export const RightPanel = styled.div`
  height: 500px;
  width: 625px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgb(136 136 136 / 35%) 0px 0px 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
`;

export const RightPanelBottom = styled.div`
  height: 100px;
  width: 625px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgb(136 136 136 / 35%) 0px 0px 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
`;

export const FeatureContainer = styled.div`

  display: block;
  vertical-align: middle;
  max-width: 100%;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  background-color: #117170;
  padding: 10px;
`;

export const RightPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const ArrowButton = styled.button`
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 10px 15px;
  border-radius: 50%;
  transform: rotate(-45deg);
  &:hover {
    background-color: rgb(6, 172, 56);
  }
`;

export const Button = styled.button`
  background-color: #06ac38;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  width: 100%;
  margin: 20px 0px;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #005a24;
  }
`;

export const Hyperlink = styled.span`
  color: blue;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const OrLabel = styled.span`
  width: 100%;
  &:before {
    content: "";
    display: inline-block;
    width: 140px;
    height: 1px;
    background-color: grey;
    margin-bottom: 3px;
    margin-right: 8px;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 140px;
    height: 1px;
    background-color: grey;
    margin-bottom: 3px;
    margin-left: 8px;
  }
`;

export const AreaContainer = styled.div`
  background: #33cf54;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
`;

export const CirclesContainer = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.5);
    animation: ${animate} 25s linear infinite;
    bottom: -150px;
  }

  & li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }

  & li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  & li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  & li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  & li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  & li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  & li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  & li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  & li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  & li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
`;

export const LoginTitle = styled.h1`
  position: 'absolute',
  top: '50%',
  left: '50%',
  margin: '-50px 0px 0px -50px',
`;

export const LoginCTitle = styled.h2`
  margin-bottom: 0px;
  color: #06ac38;
  margin: 20px;
`;

export const LoginSubTitle = styled.h1`
  font-size: 16px;
  font-weight: 200;
  margin-top: 0px;
`;
