import { useState, useEffect, forwardRef, useImperativeHandle, useRef, useCallback } from 'react';
import './SortingTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import {
    faAngleDown,
    faAngleUp,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleRight,
    faAngleLeft,
    faDotCircle,
    faListNumeric
} from '@fortawesome/free-solid-svg-icons';
import Tabs from '../Tabs';
import DragAndDropList from '../DragAndDropList';
import RangeSlider from '../RangeSlider';
import DatePickerComponent from "../../components/DatePicker";
import MultiSelect from "../../components/MultiSelect";

const SortingTable = forwardRef(
    (
        {
            rowsArray,
            columnsArray,
            hideCheckbox = false,
            iSortDescending = true,
            dontShowHover = false,
            tableHeight = '525px',
            showElementsPerPage = 10,
            elementOnClick,
            elementOnClickColumn,
            multiselect = true,
            onSelectElement,
            dontShowPagination = false,
            dontShowFilters = false,
            multiselectFilter = false,
            frozenColumn = false,
            scrollTable = false
        },
        Ref
    ) => {
        const [selectedColumnFilterIndex, setSelectedColumnFilterIndex] = useState(0);
        const [sortDescending, setSortDescending] = useState(true);
        const [originalData, setOriginalData] = useState([]);
        const [columnsArrayOriginal, setColumnsArrayOriginal] = useState([...columnsArray]);
        const [columnsArrayCopy, setColumnsArrayCopy] = useState([...columnsArray]);
        const [forceUpdate, setForceUpdate] = useState(true);
        const [openFilters, setOpenFilters] = useState(false);
        const [justLoad, setJustLoad] = useState(true);
        const [activeRow, setActiveRow] = useState(false);
        const [pagination, setPagination] = useState(showElementsPerPage);
        const [pages, setPages] = useState(showElementsPerPage);
        const [actualPage, setActualPage] = useState(1);
        const [firstElement, setFirstElement] = useState(0);
        const [lastElement, setlastElement] = useState(showElementsPerPage);
        const titlesRef = useRef({});
        const tableContainerRef = useRef(null);


        // const [tableRefHeight, setTableRefHeight] = useState(null);
        const [tableRefWidth, setTableRefWidth] = useState(null);
        const tableRef = useCallback(node => {
            if (node !== null) {
                // setTableRefHeight(node.getBoundingClientRect().height);
                setTableRefWidth(node.getBoundingClientRect().width);
            }
        }, []);

        useImperativeHandle(Ref, () => ({
            setForceUpdateFunction,
            sortArrayFunction,
            GetTagData,
        }));

        useEffect(() => {
            setColumnsArrayCopy([...columnsArrayOriginal.filter((column) => {
                return !column.hiddenRow
            })]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [columnsArrayOriginal]);

        useEffect(() => {
            setSortDescending(iSortDescending);
            setOriginalData(rowsArray.map(a => { return { ...a } }).sort((a, b) => sortingFunction(a, b)));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            setOriginalData(rowsArray.filter(row => {
                let isValid = true;
                columnsArrayCopy.every(element => {
                    if (element.type === 'Number' && row[element.valueLabel] && element.value) {
                        if (element.value[0] > row[element.valueLabel] || row[element.valueLabel] > element.value[1]) { isValid = false; return false }
                    };
                    if (element.type === 'Array' && row[element.valueLabel].length && element.value) {
                        if (element.value[0] > row[element.valueLabel].length || row[element.valueLabel].length > element.value[1]) { isValid = false; return false }
                    };
                    if (element.type === 'Color' && element.innerType === 'Number' && row[element.valueLabel].value && element.value) {
                        if (element.value[0] > row[element.valueLabel].value || row[element.valueLabel].value > element.value[1]) { isValid = false; return false }
                    };
                    if (element.type === 'String' && row[element.valueLabel] && element.value) {
                        if (!row[element.valueLabel].toLowerCase().includes(element.value.toLowerCase())) { isValid = false; return false }
                    };
                    if (element.type === 'Color' && element.innerType === 'String' && row[element.valueLabel].value && element.value) {
                        if (!row[element.valueLabel].value.toLowerCase().includes(element.value.toLowerCase())) { isValid = false; return false }
                    };
                    if (element.type === 'Date' && row[element.valueLabel] && element.dateStart && element.dateEnd) {
                        if (!(new Date(row[element.valueLabel]) > element.dateStart && element.dateEnd > new Date(row[element.valueLabel]))) { isValid = false; return false }
                    };
                    if ((element.type === 'Color' && element.innerType === 'Date') && row[element.valueLabel].value && element.dateStart && element.dateEnd) {
                        if (!(new Date(row[element.valueLabel].value) > element.dateStart && element.dateEnd > new Date(row[element.valueLabel].value))) { isValid = false; return false }
                    };
                    return true
                });
                return isValid
            }));

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [columnsArrayCopy]);

        useEffect(() => {
            setOriginalData(rowsArray.map(a => { return { ...a } }).sort((a, b) => sortingFunction(a, b)));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [rowsArray]);

        useEffect(() => {
            setColumnsArrayOriginal([...columnsArray]);
            setColumnsArrayCopy([...columnsArray]);
        }, [columnsArray]);

        useEffect(() => {
            sortArrayFunction();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [originalData]);

        useEffect(() => {
            setPages(Math.floor((originalData.length - 1) / pagination));
            setActualPage(0);
            setFirstElement(pagination > 0 ? actualPage * pagination + 1 : 0);
            setlastElement(
                originalData.length >= (actualPage + 1) * pagination ? (actualPage + 1) * pagination : originalData.length
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [originalData]);

        const setSelectedRowIndexFuntion = (event, index) => {
            event.stopPropagation();
            if (originalData[index]['isSelectedOnTable']) {
                originalData[index]['isSelectedOnTable'] = false;
                onSelectElement && onSelectElement(null);
            }
            else {
                if (multiselect) {
                    originalData[index]['isSelectedOnTable'] = !originalData[index]['isSelectedOnTable'];
                    onSelectElement && onSelectElement(originalData.filter(element => element.isSelectedOnTable).map(a => { return { ...a } }).map(element => { delete element.isSelectedOnTable; return element }));
                } else {
                    for (const elementIndex in originalData) {
                        originalData[elementIndex]['isSelectedOnTable'] = parseInt(elementIndex) === index;
                    }
                    const elementToSent = { ...originalData[index] };
                    delete elementToSent.isSelectedOnTable
                    onSelectElement && onSelectElement(elementToSent);
                }
            }
            setForceUpdateFunction();
        };

        const setForceUpdateFunction = () => {
            setForceUpdate(!forceUpdate);
        };

        useEffect(() => {
            sortDescending ? sortArrayFunction() : setSortDescending(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedColumnFilterIndex]);

        useEffect(() => {
            sortArrayFunction();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [sortDescending, selectedColumnFilterIndex]);

        const sortingFunction = (a, b) => {
            let AValueFirstRow =
                columnsArrayCopy[0].type === 'Color' ?
                    typeof a[columnsArrayCopy[0].valueLabel] === 'object' ?
                        columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Number' ?
                            parseInt(a[columnsArrayCopy[0].valueLabel].value)
                            : columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Array' ?
                                a[columnsArrayCopy[0].valueLabel] && a[columnsArrayCopy[0].valueLabel].value.length
                                : a[columnsArrayCopy[0].valueLabel].value ? a[columnsArrayCopy[0].valueLabel].value : ''
                        : columnsArrayCopy[0].innerType === 'Number' ?
                            parseInt(a[columnsArrayCopy[0].valueLabel])
                            : columnsArrayCopy[0].innerType === 'Array' ?
                                a[columnsArrayCopy[0].valueLabel].length
                                : a[columnsArrayCopy[0].valueLabel]
                    :
                    columnsArrayCopy[0].type === 'Number'
                        ? parseInt(a[columnsArrayCopy[0].valueLabel])
                        : columnsArrayCopy[0].type === 'Array' ? a[columnsArrayCopy[0].valueLabel].length : a[columnsArrayCopy[0].valueLabel];
            let BValueFirstRow =
                columnsArrayCopy[0].type === 'Color' ?
                    typeof b[columnsArrayCopy[0].valueLabel] === 'object' ?
                        columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Number' ?
                            parseInt(b[columnsArrayCopy[0].valueLabel].value)
                            : columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Array' ?
                                b[columnsArrayCopy[0].valueLabel].value.length
                                : b[columnsArrayCopy[0].valueLabel].value
                        : columnsArrayCopy[0].innerType === 'Number' ?
                            parseInt(b[columnsArrayCopy[0].valueLabel])
                            : columnsArrayCopy[0].innerType === 'Array' ?
                                b[columnsArrayCopy[0].valueLabel].length
                                : b[columnsArrayCopy[0].valueLabel]
                    :
                    columnsArrayCopy[0].type === 'Number'
                        ? parseInt(b[columnsArrayCopy[0].valueLabel])
                        : columnsArrayCopy[0].type === 'Array' ? b[columnsArrayCopy[0].valueLabel].length : b[columnsArrayCopy[0].valueLabel];
            if (selectedColumnFilterIndex !== 0) {
                let AValueSelectedRow =
                    columnsArrayCopy[selectedColumnFilterIndex].type === 'Color' ?
                        typeof a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel] === 'object' ?
                            columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Number' ?
                                parseInt(a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel].value)
                                : columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Array' ?
                                    a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel].value && a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel].value.length
                                    : a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel] && a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel].value ? a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel].value : ''
                            : columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Number' ?
                                parseInt(a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel])
                                : columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Array' ?
                                    a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel].length
                                    : a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel]
                        :
                        columnsArrayCopy[selectedColumnFilterIndex].type === 'Number'
                            ? parseInt(a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel])
                            : a[columnsArrayCopy[selectedColumnFilterIndex].valueLabel];
                let BValueSelectedRow =
                    columnsArrayCopy[selectedColumnFilterIndex].type === 'Color' ?
                        typeof b[columnsArrayCopy[selectedColumnFilterIndex].valueLabel] === 'object' ?
                            columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Number' ?
                                parseInt(b[columnsArrayCopy[selectedColumnFilterIndex].valueLabel].value)
                                : columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Array' ?
                                    b[columnsArrayCopy[selectedColumnFilterIndex].valueLabel].value.length
                                    : b[columnsArrayCopy[selectedColumnFilterIndex].valueLabel].value
                            : columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Number' ?
                                parseInt(b[columnsArrayCopy[selectedColumnFilterIndex].valueLabel])
                                : columnsArrayCopy[selectedColumnFilterIndex].innerType === 'Array' ?
                                    b[columnsArrayCopy[selectedColumnFilterIndex].valueLabel].length
                                    : b[columnsArrayCopy[selectedColumnFilterIndex].valueLabel]
                        :
                        columnsArrayCopy[selectedColumnFilterIndex].type === 'Number'
                            ? parseInt(b[columnsArrayCopy[selectedColumnFilterIndex].valueLabel])
                            : b[columnsArrayCopy[selectedColumnFilterIndex].valueLabel];
                if (sortDescending) {
                    return AValueSelectedRow === BValueSelectedRow
                        ? AValueFirstRow === BValueFirstRow
                            ? 0
                            : AValueFirstRow > BValueFirstRow
                                ? 1
                                : -1
                        : AValueSelectedRow > BValueSelectedRow
                            ? 1
                            : -1;
                } else {
                    return AValueSelectedRow === BValueSelectedRow
                        ? AValueFirstRow === BValueFirstRow
                            ? 0
                            : AValueFirstRow > BValueFirstRow
                                ? 1
                                : -1
                        : AValueSelectedRow > BValueSelectedRow
                            ? -1
                            : 1;
                }
            } else {
                if (sortDescending) {
                    return AValueFirstRow === BValueFirstRow ? 0 : AValueFirstRow > BValueFirstRow ? 1 : -1;
                } else {
                    return AValueFirstRow === BValueFirstRow ? 0 : AValueFirstRow > BValueFirstRow ? -1 : 1;
                }
            }
        }

        const sortArrayFunction = () => {
            if (originalData && originalData.length > 0 && columnsArrayCopy) {
                setOriginalData(originalData.sort((a, b) => sortingFunction(a, b)));
                setForceUpdateFunction();
            }
        };

        const GetTagData = () => {
            return originalData.map(a => { return { ...a } });
        };

        const formatDay = (date, param) => {
            if (date) {
                date = new Date(date);
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();

                if (param === 'year') {
                    return `${year}/${month < 10 ? `0${month}` : `${month}`}/${day < 10 ? `0${day}` : `${day}`}`
                } else {
                    return `${month < 10 ? `0${month}` : `${month}`}/${day}/${year}`;
                }
            }
            return '';
        };

        useEffect(() => {
            setPages(Math.floor((originalData.length - 1) / pagination));
            setActualPage(0);
            setFirstElement(pagination > 0 ? actualPage * pagination + 1 : 0);
            setlastElement(
                originalData.length >= (actualPage + 1) * pagination ? (actualPage + 1) * pagination : originalData.length
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [pagination]);

        useEffect(() => {
            setFirstElement(pagination > 0 ? actualPage * pagination + 1 : 0);
            setlastElement(
                originalData.length >= (actualPage + 1) * pagination ? (actualPage + 1) * pagination : originalData.length
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [actualPage]);

        const setOpacity = (hex, alpha) => {
            var r = parseInt(hex.slice(1, 3), 16),
                g = parseInt(hex.slice(3, 5), 16),
                b = parseInt(hex.slice(5, 7), 16);

            if (alpha) {
                return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
            } else {
                return "rgb(" + r + ", " + g + ", " + b + ")";
            }
        }

        const hideRow = (label) => {
            columnsArrayOriginal.forEach(element => {
                if (element.label === label) element.hiddenRow = !element.hiddenRow;
            });
            setColumnsArrayCopy(columnsArrayOriginal.filter((column) => {
                return !column.hiddenRow
            }));
        };

        const changeColumnValue = (label, value) => {
            if (Array.isArray(value) && value[0].value) {
                columnsArrayOriginal.forEach(element => {

                    if (element.label === label) element.value = value[0].value;
                });
                setColumnsArrayCopy([...columnsArrayOriginal.filter((column) => {
                    return !column.hiddenRow
                })]);
            } else {
                columnsArrayOriginal.forEach(element => {
                    if (element.label === label) element.value = value;
                });
                setColumnsArrayCopy([...columnsArrayOriginal.filter((column) => {
                    return !column.hiddenRow
                })]);
            }
        };

        const changeColumnDateValueStart = (label, value) => {
            columnsArrayOriginal.forEach(element => {
                if (element.label === label) element.dateStart = value;
            });
            setColumnsArrayCopy([...columnsArrayOriginal.filter((column) => {
                return !column.hiddenRow
            })]);
        };

        const changeColumnDateValueEnd = (label, value) => {
            columnsArrayOriginal.forEach(element => {
                if (element.label === label) element.dateEnd = value;
            });
            setColumnsArrayCopy([...columnsArrayOriginal.filter((column) => {
                return !column.hiddenRow
            })]);
            setForceUpdate(!forceUpdate);
        };

        const setNewWidth = (label, width) => {
            for (const index in columnsArrayCopy) {
                if (columnsArrayCopy[index].label === label) {
                    columnsArrayCopy[index].widthPX = width;
                }
            }
            setForceUpdate(!forceUpdate);
        };

        const getOptions = (column) => {
            let options = rowsArray.map(row => {
                return { value: row[[column.valueLabel]], ...row }
            });
            var result = options.reduce((unique, o) => {
                if (!unique.some(obj => obj.value === o.value)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            return result
        };

        const openFiltersFunction = () => {
            setOpenFilters(!openFilters);
            setJustLoad(false);
        };

        return (
            <div className={tableRefWidth > 1000 ? "tableDiv" : "tableDivMobile"} style={{ height: tableHeight }} ref={tableRef}>
                <div id="tableAndFilterContainer">
                    <div id='FilterMenu'>
                        <div id='FilterMenuContainer'>
                            <Tabs Tabs={[{ label: 'Hiding Columns', enable: true }, { label: 'Columns Ordering', enable: true }, { label: 'Filters', enable: true }]} onTabChange={(tab) => { setActiveRow(tab) }}>
                                {activeRow === 'Hiding Columns' && !dontShowFilters && <div id='filtersContainer'>
                                    {columnsArrayOriginal.map((column, i) => (
                                        <div key={uuidv4(column.label + i + 'divt')} className='hiddenRowDiv'>
                                            <label className="containerTable">
                                                <input
                                                    type="checkbox"
                                                    readOnly
                                                    onClick={() => hideRow(column.label)}
                                                    defaultChecked={column.hiddenRow}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className='hiddenRowText'>{column.label}</label>
                                        </div>
                                    ))}
                                </div>}
                                {activeRow === 'Columns Ordering' && !dontShowFilters && <div id='filtersContainer'>
                                    <DragAndDropList arrayOfOptions={columnsArrayOriginal} onListOrdenChange={(list) => setColumnsArrayOriginal([...list])} />
                                </div>}
                                {activeRow === 'Filters' && <div id='filtersContainer'>
                                    {columnsArrayCopy.map((column, i) => (
                                        <div key={column.label + 'divr'}>
                                            {column.type === 'Number' && <RangeSlider key={column.label + 'range'} label={column.label} min={Math.min(...rowsArray.map(element => element[column.valueLabel]))} max={Math.max(...rowsArray.map(element => element[column.valueLabel]))} onValueChange={(values) => changeColumnValue(column.label, values)} values={column.value} />}
                                            {column.type === 'Array' && <RangeSlider key={column.label + 'range'} label={column.label} min={Math.min(...rowsArray.map(element => element[column.valueLabel].length))} max={Math.max(...rowsArray.map(element => element[column.valueLabel].length))} onValueChange={(values) => changeColumnValue(column.label, values)} values={column.value} />}
                                            {column.type === 'Color' && column.innerType === 'Number' && <RangeSlider key={column.label + 'range'} label={column.label} min={Math.min(...rowsArray.map(element => element[column.valueLabel].value))} max={Math.max(...rowsArray.map(element => element[column.valueLabel].value))} onValueChange={(values) => changeColumnValue(column.label, values)} values={column.value} />}
                                            {column.type === 'String' && <div key={column.label + 'div'}>
                                                {column.label && <label>{column.label}</label>}
                                                {multiselectFilter ? <MultiSelect
                                                    options={getOptions(column)}
                                                    onChange={(e) => changeColumnValue(column.label, e)}
                                                    valueToDisplay={'value'}
                                                    allowOnlyOne={false}
                                                    defaultValues={column.value}
                                                /> : <input value={column.value} onChange={value => changeColumnValue(column.label, value.target.value)} />}
                                            </div>}
                                            {column.type === 'Color' && column.innerType === 'String' && <div key={column.label + 'div'}>
                                                {column.label && <label>{column.label}</label>}
                                                <input value={column.value} onChange={value => changeColumnValue(column.label, value.target.value)} />
                                            </div>}
                                            {(column.type === 'Date' || (column.type === 'Color' && column.innerType === 'Date')) && <div key={column.label + 'div'}>
                                                <div className='dateFilterDiv'>
                                                    {column.label} from:
                                                    <DatePickerComponent
                                                        pickerWidth={"100%"}
                                                        value={column.dateStart}
                                                        label={""}
                                                        onValue={(value, isValid) =>
                                                            changeColumnDateValueStart(column.label, isValid ? new Date(value) : null)
                                                        }
                                                    />
                                                    To:
                                                    <DatePickerComponent
                                                        pickerWidth={"100%"}
                                                        value={column.dateEnd}
                                                        label={""}
                                                        onValue={(value, isValid) =>
                                                            changeColumnDateValueEnd(column.label, isValid ? new Date(value) : null)
                                                        }
                                                    />
                                                </div>
                                            </div>}
                                        </div>
                                    ))}
                                </div>}
                            </Tabs>
                        </div>
                    </div>
                    <div className={openFilters ? "TableContainerOpen" : !justLoad ? "TableContainer" : "TableContainerNoAnimation"}>
                        <div className="Titles">
                            <div className={!hideCheckbox ? 'FirstColumn' : 'FirstColumnHide'}></div>
                            {columnsArrayCopy.length > 0 &&
                                columnsArrayCopy.map((column, i) => (
                                    <div
                                        key={uuidv4(column.label + 'div')}
                                        className={frozenColumn && i === 0 ? "TitleColumnsFrozen" : frozenColumn && i === 1 ? "TitleColumnsFrozenScrollableFirst" : frozenColumn && i > 1 ? "TitleColumnsFrozenScrollable" : "TitleColumns"}
                                        ref={(element) => titlesRef.current[i] = element}
                                        // onMouseUp={(e) => { if (e.target.className === 'TitleColumns') { setNewWidth(column.label, titlesRef.current[i].offsetWidth) } }}
                                        // onMouseLeave={() => setNewWidth(column.label, titlesRef.current[i].offsetWidth)}
                                        style={{ width: 100 >= column.widthPX ? '100px' : column.widthPX + 'px', textAlign: column.textAlignDef ? column.textAlignDef : 'center' }}
                                    >
                                        <span className="TextLabelRowTitle">{column.label}{column.sortable && (
                                            <div className="divIconsTitle">
                                                {i !== selectedColumnFilterIndex ? (
                                                    <button className="buttonTable" onClick={() => setSelectedColumnFilterIndex(i)}>
                                                        &#x25CF;
                                                    </button>
                                                ) : sortDescending ? (
                                                    <button className="buttonTable" onClick={() => setSortDescending(false)}>
                                                        <FontAwesomeIcon icon={faAngleDown} />
                                                    </button>
                                                ) : (
                                                    <button className="buttonTable" onClick={() => setSortDescending(true)}>
                                                        <FontAwesomeIcon icon={faAngleUp} />
                                                    </button>
                                                )}
                                            </div>
                                        )}</span>

                                    </div>
                                ))}
                        </div>
                        <div className="TableRowContainer">
                            {originalData.length > 0 &&
                                originalData.map((row, e) =>
                                    e >= firstElement - 1 && lastElement - 1 >= e ? (
                                        <div key={uuidv4(e + 'row')} className={dontShowHover ? "GenericRow" : "GenericRowHover"}
                                            onClick={() => elementOnClick && elementOnClick(row)}>
                                            <div className={!hideCheckbox ? 'FirstColumn' : 'FirstColumnHide'} onClick={(e) => e.stopPropagation()}>
                                                {!hideCheckbox && (
                                                    <label className="containerTable">
                                                        <input
                                                            type="checkbox"
                                                            readOnly
                                                            onClick={(event) => setSelectedRowIndexFuntion(event, e)}
                                                            checked={row.isSelectedOnTable ?? false}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                )}
                                            </div>
                                            {columnsArrayCopy.map((column, i) => (
                                                <div key={uuidv4(column.label + i + 'div')}
                                                    className={frozenColumn && i === 0 ? `GenericColumnFrozen` : frozenColumn && i === 1 ? `GenericColumnFrozenScollableFirst` : frozenColumn && i > 1 ? `GenericColumnFrozenScollable` : `GenericColumn`}
                                                    onClick={(e) => {
                                                        if (elementOnClickColumn) {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            elementOnClickColumn({ row, column });
                                                        }
                                                    }}
                                                    style={{ width: 100 >= column.widthPX ? '100px' : column.widthPX + 'px', textAlign: column.textAlignDef ? column.textAlignDef : 'center' }}
                                                >
                                                    {column.type === 'Color' && typeof row[column.valueLabel] === 'object' && <span className='tooltip' style={{ color: row[column.valueLabel].color || '#707070', marginRight: '10px' }}><FontAwesomeIcon icon={faDotCircle} />
                                                        {row[column.valueLabel].message && <span className="tooltiptext" style={{ border: `3px solid ${row[column.valueLabel].color}`, color: 'white', backgroundColor: `${setOpacity(row[column.valueLabel].color, 0.8)}` }}>{row[column.valueLabel].message}</span>}
                                                    </span>}
                                                    {column.type === 'Color' && column.innerType !== 'Color' && typeof row[column.valueLabel] !== 'object' && <span className='tooltip' style={{ color: '#707070', marginRight: '10px' }}><FontAwesomeIcon icon={faDotCircle} /></span>}
                                                    {column.innerType !== 'Color' ? <label className="TextLabelRow">
                                                        {column.type === 'Link' ? <a href={row[column.valueLabel]} target="_blank"> Opportunity Link </a> :
                                                            column.type !== 'Color'
                                                                ? column.type !== 'Array'
                                                                    ? column.type !== 'Date' && column.type === 'DateYear' ?
                                                                        formatDay(row[column.valueLabel], 'year') :
                                                                        column.type !== 'Date' ?
                                                                            row[column.valueLabel] :
                                                                            formatDay(row[column.valueLabel], '')
                                                                    : row[column.valueLabel] ? row[column.valueLabel].length : 0
                                                                : typeof row[column.valueLabel] === 'object' ? row[column.valueLabel].type === 'Array' ? row[column.valueLabel].value.length : row[column.valueLabel].value :
                                                                    column.innerType !== 'Array'
                                                                        ? column.innerType !== 'Date'
                                                                            ? row[column.valueLabel]
                                                                            : column.type === 'DateYear' ?
                                                                                formatDay(row[column.valueLabel], 'year')
                                                                                : formatDay(row[column.valueLabel], '')
                                                                        : row[column.valueLabel] ? row[column.valueLabel].length : 0}
                                                    </label> : <FontAwesomeIcon style={{ width: '100%', color: row[column.valueLabel] }} icon={faDotCircle} />
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    ) : null
                                )}
                        </div>
                    </div>
                </div>
                <div id='bottomContainer'>
                    {!dontShowPagination && <div className="PaginationDiv">
                        <label>
                            Showing{' '}
                            <input
                                className="PaginationNumber"
                                onChange={(e) =>
                                    (parseInt(e.target.value) < 100 || !e.target.value) && setPagination(parseInt(e.target.value))
                                }
                                type="number"
                                value={pagination}
                            />{' '}
                            Elements
                        </label>
                        <div style={{ marginLeft: '10px', display: 'flex' }}>
                            <button className="buttonTable" onClick={() => actualPage >= 1 && setActualPage(0)}>
                                <span className={actualPage >= 1 ? 'PaginationIconActive' : 'PaginationIconInActive'}><FontAwesomeIcon icon={faAngleDoubleLeft} /></span>
                            </button>
                            <button className="buttonTable" onClick={() => actualPage >= 1 && setActualPage(actualPage - 1)}>
                                <span className={actualPage >= 1 ? 'PaginationIconActive' : 'PaginationIconInActive'}><FontAwesomeIcon icon={faAngleLeft} /></span>
                            </button>
                            <label id='pagination-label'>
                                {firstElement}-{lastElement}/{originalData.length}
                            </label>
                            <button
                                className="buttonTable"
                                onClick={() => pages > actualPage && pages !== 0 && setActualPage(actualPage + 1)}
                            >
                                <span className={pages > actualPage && pages !== 0 ? 'PaginationIconActive' : 'PaginationIconInActive'}><FontAwesomeIcon icon={faAngleRight} /></span>
                            </button>
                            <button className="buttonTable" onClick={() => pages > actualPage && pages !== 0 && setActualPage(pages)}>
                                <span className={pages > actualPage && pages !== 0 ? 'PaginationIconActive' : 'PaginationIconInActive'}><FontAwesomeIcon icon={faAngleDoubleRight} /></span>
                            </button>
                        </div>
                    </div>}
                    {false && <div><button id='filterButton' onClick={() => { openFiltersFunction() }}>Open Filters</button></div>}

                </div>
            </div>
        );
    }
);

export default SortingTable;