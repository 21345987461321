export const haveClientDeliveryPermissions = (role) => {
  return clientDeliveryAccessRoles.some(accessRole => accessRole.type_key === role);
};

export const clientDeliveryAccessRoles = [
  {
      description: "Delivery Manager",
      type_key: "DELIVERY_MANAGER"
  },
  {
      description: "Program Manager",
      type_key: "PROGRAM_MANAGER"
  },
  {
      description: "Portfolio Success Manager",
      type_key: "PORTFOLIO_SUCCESS_MANAGER"
  },
  {
      description: "Strategy Lead",
      type_key: "STRATEGY_LEAD"
  },
  {
      description: "Account Manager",
      type_key: "ACCOUNT_MANAGER"
  },
  {
      description: "Super User",
      type_key: "SUPER_USER"
  },
  {
    description: "Delivery Manager Team Manager",
    type_key: "DELIVERY_MANAGER_TEAM_MANAGER"
}
];

export const deliveryManagers = [
  "Aldo Camacho",
  "Alistair Ross",
  "Alphonso Calanoc",
  "Andy Graham",
  "Andy Skelton",
  "Audra Tadlock",
  "Bharath Prasanna",
  "Brittney Rowan",
  "Carolyn C. Jocson",
  "Chad Haddock",
  "Conall Lynch",
  "Daniela Velásquez",
  "David Pérez",
  "Dee Raipuria",
  "Donavon Roberson",
  "Elsa Aguirre",
  "Emm Arbouch",
  "Fabian Leon",
  "Felipe Riquelme Martinez",
  "Giselle Vazquez",
  "Haroldo Cedeño",
  "Jane Domeck",
  "Jen Schulien",
  "Jessica Dill",
  "Joe Thornock",
  "Juan Franco Pinochet",
  "Kelly Higgins",
  "Ken Thayer",
  "Kristie Carroll",
  "Lee Massengill",
  "Lindsey Nelson",
  "Liviu Tanasoaica",
  "Loren McDaniel",
  "Lucas González Roa",
  "Macarena Machado",
  "Madeleyne Acosta",
  "Michael Isaacs",
  "Monica Berlin",
  "Nick Karpathios",
  "Patricia Onofre",
  "Peadar Loughrey",
  "Pedro Gutiérrez Gálvez",
  "Peter Park",
  "Pia Ureta",
  "Preethi Tummala",
  "Stefanía González",
  "Tet Hirose",
  "Vinod Kasturi",
  "Vitor Arnaut",
  "Yoko Itagaki",
  "Yukihiro Nishida"
]

export const accountManagers = [
  "AJ Dullard",
  "Alan Mitchell",
  "Alex Conrad",
  "Alex Trujillo",
  "Ali Akbary",
  "Andi French",
  "Andy Mao",
  "Andy Paff",
  "Angel Cartagena",
  "Ani Rajagopalan",
  "Anthony Pullia",
  "Arathi K Ramesh",
  "Arndt Brozowski Schrader",
  "Aurelie Coignard",
  "Aya Kuroda",
  "Ben Rodman",
  "Berek Martichuski",
  "Brianna DeChant",
  "Brittanny Smith",
  "Carla Tunstall",
  "Chloe Taylor",
  "Chris Mlincsek",
  "Christiane Kapsalis",
  "Christina Andersen",
  "Christopher Toro",
  "Clayton Hanson",
  "Colin Pentz",
  "Dale Robertson",
  "David Bell",
  "David Callaghan",
  "David Fitzgerald",
  "Dean Paterson",
  "Derek DuBois",
  "Eduardo Castelan",
  "Gaku Kitahara",
  "George Duffen",
  "George Osman",
  "Jack Funnell",
  "Jack Sloan",
  "Jacquie Nonneman",
  "Jake Holt",
  "James Wilcox",
  "Janina Roth",
  "Jason Fischer",
  "Jay Beckman",
  "Jeff Nemesi",
  "Jessica Bonneau",
  "John Malamud",
  "John Resch",
  "Jon Bisley",
  "Jonathan Peck",
  "Justin Crosby",
  "Karen Bekker",
  "Karl Mikan",
  "Katie Kelley",
  "Kazunori Okura",
  "Kerri Pruitt",
  "Kyle Burke",
  "Lan Bui-Soudah",
  "Leah Metz",
  "Leonardo L'ecaros",
  "Lisa DeBenedictis",
  "Lorenzo Augugliaro",
  "Margie Verdon",
  "Mark Nelson",
  "Mark Sarman",
  "Martin Zhang",
  "Matt Bujalski",
  "Matt Goodwin",
  "Matthew Tomlinson",
  "Max Carlson",
  "Max Chamberlain",
  "Michael Gargaro",
  "Mike Campbell",
  "Mike Carr",
  "Mike Sheahan",
  "Moraya Sousou",
  "Nektarios Singh",
  "Oliver Hopkins",
  "Percy Darkwah",
  "Randy Moley",
  "Ravi Yadav",
  "Ricky Lalli",
  "Rob Niedzwiecki",
  "Rowan Johnson",
  "Russell Howlett",
  "Sally Wright",
  "Sanjay Chawla",
  "Sara Shine",
  "Sayaka Tanemoto",
  "Scott Patterson",
  "Shaneh Fielding",
  "Shinpei Horiuchi",
  "Shubham Baid",
  "Stephen Collins",
  "Steve Tranter",
  "Stewart McGlinchey",
  "Takashi Handa",
  "Thomas Kim",
  "Tim Ray",
  "Timothy Festa",
  "Tom Harris",
  "Tom Ward",
  "Turner Spears",
  "Tyler Wells",
  "Yoshinari Yokoyama （横山 慶成 - よこやま よしなり）",
  "Zach Ferwerda"
]

export const programManagers = ['Ana Farias', 'Benjamín Pérez', 'Enrique Hernández', 'Fernanda Mella', 'Jackeline Argüello', 'Luis Anaya', 'Mitz Pono', 'Norelis Monsalve', 'Pilar Castillo', 'Richard Howell', 'Rossmell Alejandro Galindo España', 'Sebastián Lizama', 'Shilpa Bidnoor', 'Valentina Palma', 'Álvaro Gaete']

export const premiumType = [
  "Gold",
  "Silver",
  "Premium Support",
  "None"
]


export const customerType = [
  "AMER Enterprise East North 1",
  "AMER Enterprise East North 2",
  "AMER Enterprise East South",
  "AMER Enterprise West 1",
  "AMER Enterprise West 2",	
  "AMER Enterprise West Central",	
  "AMER Strategic",	
  "AMER Strategic East",
  "AMER Strategic West",	
  "APJ Enterprise 1",
  "APJ Enterprise 2",
  "Commercial Business AMER Growth",	
  "Commercial Business APJ",
  "Commercial Business Emerging",	
  "EMEA Enterprise 1",	
  "EMEA Enterprise 2",	
  "EMEA Strategic",	
  "Japan",	
  "US Public Sector",
]