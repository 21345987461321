import "./SkeletonHome.css"

const SkeletonProducts = () => {
  return (
    <div className="container__product">
      <div className="product__title skeleton__animation"></div>
      <div className="product__grid">
        <div className="product__leftArrow skeleton__animation" ></div>
        <div className="product__banner skeleton__animation"></div>
        <div className="product__banner skeleton__animation"></div>
        <div className="product__rightArrow skeleton__animation"></div>
      </div>
    </div>
  );
};

export default SkeletonProducts;