import { useMemo, useState } from "react";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/** @param {Object} props
 * @param {import("./index").TFerReducerState} props.tferState
 * @param {import("./index").RequestTerraformation} props.requestTerraformation
 * @param {() => void} props.requestTerraformation
 */
export const TerraformationPlan = ({
  tferState,
  customerSubdomain,
  requestTerraformation,
}) => {
  const terraformationMode = tferState.terraformationMode;
  const [requestTerraformationPending, setRequestTerraformationPending] =
    useState(false);

  const sizeOfPlan = (state) => {
    return (
      state.terraformationPlan.services.length +
      state.terraformationPlan.users.length
    );
  };

  const isPlanEmpty = useMemo(() => {
    return sizeOfPlan(tferState) === 0;
  }, [tferState]);

  const renderTerraformEverything = () => {
    return (
      <div>
        <h5>
          Terraform configuration code will be generated for the subdomain{" "}
          <code class="tfer-keyword">{customerSubdomain}</code> extracting all
          PagerDuty resources of the following types:
        </h5>
        <div className="tfer-app__show-plan-list-container">
          <ul>
            <li>Services</li>
            <li>Users</li>
          </ul>
        </div>
      </div>
    );
  };

  const renderTerraformPlan = () => {
    return (
      <div>
        <h5>
          Terraform configuration code will be generated for the subdomain{" "}
          <code class="tfer-keyword">{customerSubdomain}</code> extracting the
          following PagerDuty resources:
        </h5>
        <div className="tfer-app__show-plan-list-container">
          <ul className="tfer-app__show-plan-list">
            {tferState.terraformationPlan.services.length > 0 && (
              <>
                <li>Services</li>
                {tferState.terraformationPlan.services.map((resource) => {
                  return (
                    <li
                      key={resource.id}
                      className="tf-app__show-plan-sub-item"
                    >
                      <a target="_blank" href={resource.html_url}>
                        {resource.summary}
                      </a>
                    </li>
                  );
                })}
              </>
            )}
            {tferState.terraformationPlan.users.length > 0 && (
              <>
                <li>Users</li>
                {tferState.terraformationPlan.users.map((resource) => {
                  return (
                    <li
                      key={resource.id}
                      className="tf-app__show-plan-sub-item"
                    >
                      <a target="_blank" href={resource.html_url}>
                        {resource.summary}
                      </a>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
        </div>
      </div>
    );
  };

  const startTerraformation = async () => {
    const terraformEverything = terraformationMode === "everything";
    const terraformationPlan = [
      ...tferState.terraformationPlan.services,
      ...tferState.terraformationPlan.users,
    ];
    setRequestTerraformationPending(true);
    await requestTerraformation(terraformEverything, terraformationPlan);
    setRequestTerraformationPending(false);
  };

  return (
    <div className="tfer-app__show-plan-container">
      <div className="tfer-app__plan-btn-container">
        <button
          className="tfer-app__btn tfer-app__btn--generate-tf"
          onClick={() => {
            startTerraformation();
          }}
          disabled={
            (isPlanEmpty && terraformationMode !== "everything") ||
            requestTerraformationPending
          }
        >
          <FontAwesomeIcon icon={faGears} style={{ marginRight: 10 }} />
          Generate Terraform Code
        </button>
      </div>
      {terraformationMode === "plan" &&
        (isPlanEmpty ? (
          <p className="tfer-app--warning-text">
            A terraformation plan must be chosen, either by selecting the
            PagerDuty resources to be managed by Terraform, or by selecting
            terraformate everything.
          </p>
        ) : (
          renderTerraformPlan()
        ))}
      {terraformationMode === "everything" && renderTerraformEverything()}
    </div>
  );
};
