import { useRive } from "@rive-app/react-canvas";
import thankyou from "./thankyou.riv";

const ThankYou = (riveProps) => {
  const { rive, RiveComponent } = useRive({
    src: thankyou,
    stateMachines: "State Machine 1",
    autoplay: true,
  });

  return <RiveComponent style={{  height: "400px",width:"400px" }} />;
};

export default ThankYou;
