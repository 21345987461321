import React, { useState, forwardRef, useImperativeHandle } from 'react';
import './StatusMessages.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning, faCheck } from '@fortawesome/free-solid-svg-icons';


const StatusMessages = forwardRef((props, ref) => {
    const [messages, setMessanges] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);

    useImperativeHandle(ref, () => ({
        appendNewMessage,
    }));

    const appendNewMessage = message => {
        const timeStamp = new Date().getTime();
        setMessanges([...messages, { ...message, timeStamp }]);
        setTimeout(() => {
            setForceUpdate(!forceUpdate);
        }, 5000);
    }

    const getFinishTime = (t) => {
        let date = new Date();
        date = new Date().setSeconds(date.getSeconds());
        return t && date - t < 5000;
    }

    const primaryColorLiteral = {
        'ERROR': () => '#ac0606',
        'WARNING': () => '#ffe664',
        'SUCCESS': () => '#08de49',
    }

    const secundaryColorLiteral = {
        'ERROR': () => '#FCE8E7',
        'WARNING': () => '#FFD300',
        'SUCCESS': () => '#06ac38',
    }

    const iconLiteral = {
        'ERROR': () => <FontAwesomeIcon icon={faWarning} style={{ color: '#f82431', marginRight: '15px' }} />,
        'WARNING': () => <FontAwesomeIcon icon={faWarning} style={{ color: '#ffe664', marginRight: '15px' }} />,
        'SUCCESS': () => <FontAwesomeIcon icon={faCheck} style={{ color: '#08de49', marginRight: '15px' }} />,
    }

    return (
        <div className='messagesList' style={{zIndex:10000}}>
            {messages.map(message => getFinishTime(message.timeStamp) &&
                <div key={message.timeStamp} className='messageCard' style={{color: primaryColorLiteral[message.type](), backgroundColor: secundaryColorLiteral[message.type]() }}>
                    {iconLiteral[message.type]()} {message.text}
                </div>)}
        </div>
    );
});

export default StatusMessages;