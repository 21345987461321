import React, { createContext, useState } from 'react';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addNotification = (notif) => {
    setNotifications((prevNotifs) => [...prevNotifs, notif]);
  };

  const updateNotification = (id, progress) => {
    setNotifications((prevNotifs) =>
      prevNotifs.map((notif) => notif.id === id  ? { ...notif, progress: progress } : notif)
    );
  };

  const updateFinishNotification = (id, message) => {
    setNotifications((prevNotifs) =>
    prevNotifs.map((notif) => notif.id === id  ? { ...notif, message: message, progress: undefined} : notif)
    );
    setTimeout(() => {
      removeNotification(id);
    }, 6000);
  };

  const removeNotification = (id) => {
   setNotifications((prevNotifs) => prevNotifs.filter((notif) => notif.id !== id));
  };

  const addNotificationAndCheckModal = (notif) => {
    setNotifications((prevNotifs) => [...prevNotifs, notif]);
    
    // Example condition to open the modal, you can customize this
    if (notif.type === 'sessionExpired') {
      setIsModalOpen(true);
    }
  }


  return (
    <NotificationContext.Provider value={{ notifications, addNotification, updateNotification, removeNotification,updateFinishNotification, isModalOpen, setIsModalOpen }}>
      {children}
    </NotificationContext.Provider>
  );
};
