import Error404 from "../404/404";
import Error502 from "../502/502";
import Unauthorized from "../Unauthorized/Unauthorized";

const getErrorComponent = (statusCode) => {
  switch (statusCode) {
    case 404:
      return Error404;
    case 502:
      return Error502;
    case 401:
    return Unauthorized;
    default:
      return '';
  }
};

const ErrorHandler = ({ statusCode }) => {
  if (!statusCode) return null;

  const ErrorComponent = getErrorComponent(statusCode);
  return <ErrorComponent />;
};

export default ErrorHandler;