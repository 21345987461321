import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = forwardRef(({onSetNumberOfPages, pageNumber, HTML}, ref) => {

  useImperativeHandle(ref, () => ({
    downloadPdf: (name) => {
        // Create a temporary anchor element to trigger the download
        const a = document.createElement("a");
        a.href = blobURL;
        a.download = name + ".pdf";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
  }));
  
  const [blobURL, setBlobURL] = useState(null);

  useEffect(() => {
    const binaryData = Uint8Array.from(atob(HTML), c => c.charCodeAt(0));
    const blob = new Blob([binaryData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    setBlobURL(url);

    return () => {

      URL.revokeObjectURL(url);
    };
  }, [HTML]);

  function onDocumentLoadSuccess({ numPages }) {
    onSetNumberOfPages(numPages)
  }

  return (
    <div>
      <Document file={blobURL} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}  />
      </Document>
    </div>
  );
});

export default PDFViewer;
