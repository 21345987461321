import React, { useState, useEffect, useRef } from "react";
import "./SuccessOnDemand.css";
import SortingTable from "../../components/SortingTable";
import Modal from "../../components/Modal";
import SkeletonTable from "../../components/Skeletons/TableSkeleton/TableSkeleton";
import AppIcon from "../../components/AppIcon";
import { apiRequest, apiRoutes } from "./../../services";
import BarChart from "../../components/BarChart2";
import StackedBarChart from "../../components/StackedBarChart";
import LoadingSpinner from "../../components/LoadingSpinner";
import Dropdown from "../../components/Dropdown";
import { ReactComponent as DocIcon } from "./../../assets/document.svg";
import MultiSelect from "../../components/MultiSelect";
import Jwt from "../../utils/jwt";
import { notificationSubject } from "../../services/subjects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Questions from "../../components/Questions";

export default function SuccessOnDemand() {
  const [originalTableData, setOriginalData] = useState(null);
  const [customersArray, setCustomersArray] = useState([]);
  const [customersArrayAll, setCustomersArrayAll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSkeleton, setIsLoadingSkeleton] = useState(false);
  const acceptedRowsFilter = ["Period 1", "Period 2", "Period 3", "Period 4"];
  const [modalOpen, setModalOpen] = useState(false);
  const [rowArrayByDays, setRowArrayByDays] = useState([]);
  const [rowArray, setRowArray] = useState([]);
  const [clientName, setClientName] = useState("");
  const [accountManager, setAccountManager] = useState("");
  const [asanaId, setAsanaId] = useState("");
  const [CSM, setCSM] = useState(false);
  const [isSales, setIsSales] = useState("");
  const [arrayRes, setArrayRes] = useState([]);
  const [nameSection, setNameSection] = useState("");
  const [activeTab, setActiveTab] = useState("Filter By Days");
  const [reportsActiveTab, setReportsActiveTab] = useState(null);
  const [lastLoadedDate, setLastLoadedDate] = useState("");
  const [currentEntitie, setCurrentEntitie] = useState("Services");
  const [fiscalYears, setFiscalYears] = useState([]);
  const [fiscalEndYears, setFiscalEndYears] = useState([]);
  const [startFiscalYear, setFiscalStart] = useState(null);
  const [endFiscalYear, setFiscalEnd] = useState("");
  const [endValueFiscalYear, setFiscalEndValue] = useState("");
  const [milestonecsv, setMilestoneCsv] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [allCsv, setAllCsv] = useState(null);
  const [detailCsv, setDetailCsv] = useState(null);
  const [editableItem, setEditableItem] = useState(false);
  const [canSeeReports, setCanSeeReports] = useState(null);
  const [canOpenModal, setCanOpenModal] = useState(null);
  const [isCSM, setIsCSM] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [tableKickData, setKickSku] = useState(null);
  const [tableBacklogData, setBacklogSku] = useState(null);
  const [tableAgingData, setAgingSku] = useState(null);
  const [reportSelectedOption, setReportSelectedOption] = useState(null);
  const [SLAReportsView, setSLAReportsView] = useState(null);
  const [SKUReportsView, setSKUReportsView] = useState(null);
  const [SKUReportsViewAll, setSKUReportsViewAll] = useState(null);
  const [avgSku, setAvgSku] = useState(null);
  const [avgNotStarted, setAvgNotStarted] = useState(null);
  const [notStarted, setNotStarted] = useState(null);
  const [form, setForm] = useState(null);
  const [FAQModalOpen, setFAQModalOpen] = useState(false);

  const questionsArray = [
    {
      question: `I’m unable to find my customer.`,
      answer: `If you’re unable to see your customer on the first page of the application, switch to the “All” tab and search for your customer name. If you are still unable to find your customer, please let us know in the #csg-sod-app Slack channel.`,
    },
    {
      question: `The available hours remaining for my customer look incorrect`,
      answer: `Please let us know in the #csg-sod-app Slack channel and we’ll take a look.`,
    },
    {
      question: `The used hours for my customer look incorrect`,
      answer: `Please let us know in the #csg-sod-app Slack channel and we’ll take a look.`,
    },
  ];

  // const steps = [{
  //   selector: ".SOD-first-step",
  //   content: "Select a range of fiscal year quarters to generate the report", },
  //   {
  //     selector: ".SOD-second-step",
  //     content: "This chart represent the number of projects by status and grouped by sku the total number of projects can be viewed at the top", },
  //   {
  //     selector: ".first-step",
  //     content: "This is my first step", },
  // ];
  // const [isTourOpen, setIsTourOpen] = useState(true);
  const AllTable = useRef();

  const [editModalTitleOpen, setEditModalTitleOpen] = useState(false);
  const [editModalTitle, setEditModalTitle] = useState("Edit");

  const columnsArrayByDays = [
    {
      label: "ID",
      valueLabel: "AsanaId",
      sortable: true,
      widthPX: 170,
      type: "Number",
      hiddenRow: true,
    },
    {
      label: "ID section",
      valueLabel: "Asana_Project_Section",
      sortable: true,
      widthPX: 170,
      type: "Number",
      hiddenRow: true,
    },
    {
      label: "Client",
      valueLabel: "Asana_Account_Name",
      sortable: true,
      widthPX: 170,
      type: "String",
    },
    {
      label: "Days Remaining",
      valueLabel: "Asana_Days_Remaining",
      sortable: true,
      widthPX: 250,
      type: "Color",
      innerType: "Number",
    },
    {
      label: "Hours Used/Available",
      valueLabel: "hours",
      sortable: true,
      widthPX: 200,
      type: "String",
    },
    {
      label: "Account Manager",
      valueLabel: "Asana_Account_Manager",
      sortable: true,
      widthPX: 250,
      type: "String",
    },
    {
      label: "CSM",
      valueLabel: "Asana_CSM",
      sortable: true,
      widthPX: 200,
      type: "String",
    },
    {
      label: "Period",
      valueLabel: "Asana_Section",
      sortable: true,
      widthPX: 300,
      type: "String",
    },
    {
      label: "SKU",
      valueLabel: "Asana_Sku",
      sortable: true,
      widthPX: 200,
      type: "String",
    },
    {
      label: "Hours Used",
      valueLabel: "EverHour_Expend_Hours",
      sortable: true,
      widthPX: 200,
      type: "Number",
    },
    {
      label: "Hours Available",
      valueLabel: "EverHour_Expend_Remaining",
      sortable: true,
      widthPX: 250,
      type: "Number",
    },
  ];

  const columnsArray = [
    {
      label: "ID",
      valueLabel: "AsanaId",
      sortable: true,
      widthPX: 170,
      type: "Number",
      hiddenRow: true,
    },
    {
      label: "ID section",
      valueLabel: "Asana_Project_Section",
      sortable: true,
      widthPX: 170,
      type: "Number",
      hiddenRow: true,
    },
    {
      label: "Start Date",
      valueLabel: "Asana_Start_Date",
      sortable: true,
      widthPX: 170,
      type: "Date",
      hiddenRow: true,
    },
    {
      label: "Client",
      valueLabel: "Asana_Account_Name",
      sortable: true,
      widthPX: 170,
      type: "String",
    },
    {
      label: "Days Remaining",
      valueLabel: "Asana_Days_Remaining",
      sortable: true,
      widthPX: 250,
      type: "Color",
      innerType: "Number",
    },
    {
      label: "Hours Used",
      valueLabel: "EverHour_Expend_Hours",
      sortable: true,
      widthPX: 200,
      type: "Number",
    },
    {
      label: "Hours Available",
      valueLabel: "EverHour_Expend_Remaining",
      sortable: true,
      widthPX: 250,
      type: "Number",
    },
    {
      label: "Period 1",
      valueLabel: "Q1",
      sortable: true,
      widthPX: 300,
      type: "Color",
      innerType: "String",
    },
    {
      label: "Period 2",
      valueLabel: "Q2",
      sortable: true,
      widthPX: 300,
      type: "Color",
      innerType: "String",
    },
    {
      label: "Period 3",
      valueLabel: "Q3",
      sortable: true,
      widthPX: 300,
      type: "Color",
      innerType: "String",
    },
    {
      label: "Period 4",
      valueLabel: "Q4",
      sortable: true,
      widthPX: 300,
      type: "Color",
      innerType: "String",
    },
    {
      label: "Account Manager",
      valueLabel: "Asana_Account_Manager",
      sortable: true,
      widthPX: 250,
      type: "String",
    },
    {
      label: "CSM",
      valueLabel: "Asana_CSM",
      sortable: true,
      widthPX: 200,
      type: "String",
    },
    {
      label: "SKU",
      valueLabel: "Asana_Sku",
      sortable: true,
      widthPX: 200,
      type: "String",
    },
    {
      label: "Project End",
      valueLabel: "Project_end",
      sortable: true,
      widthPX: 300,
      type: "String",
      innerType: "String",
      hiddenRow: true,
    },
  ];

  const columnsArray_modal = [
    {
      label: "Task",
      valueLabel: "task_name",
      sortable: true,
      widthPX: 250,
      type: "String",
    },
    {
      label: "Date",
      valueLabel: "task_date",
      sortable: true,
      widthPX: 250,
      type: "Date",
    },
    {
      label: "Hours",
      valueLabel: "task_hours",
      sortable: true,
      widthPX: 250,
      type: "String",
    },
    {
      label: "Collaborator",
      valueLabel: "task_collaborator",
      sortable: true,
      widthPX: 250,
      type: "String",
    },
    {
      label: "Due Date",
      valueLabel: "task_due_date",
      sortable: true,
      widthPX: 250,
      type: "Date",
    },
    {
      label: "Assigned",
      valueLabel: "task_assigned",
      sortable: true,
      widthPX: 250,
      type: "String",
    },
    {
      label: "Status",
      valueLabel: "task_status",
      sortable: true,
      widthPX: 250,
      type: "Color",
      innerType: "String",
    },
  ];

  const SLA_columnsArray = [
    {
      label: "",
      valueLabel: "Asana_message",
      sortable: true,
      widthPX: 200,
      type: "Color",
      innerType: "Number",
    },
    {
      label: "SKU",
      valueLabel: "Asana_SKU",
      sortable: true,
      widthPX: 250,
      type: "String",
    },
    {
      label: "Project",
      valueLabel: "Asana_Project_Name",
      sortable: true,
      widthPX: 250,
      type: "String",
    },
    {
      label: "Client",
      valueLabel: "Asana_AccountName",
      sortable: true,
      widthPX: 250,
      type: "String",
    },
    {
      label: "CSM",
      valueLabel: "Asana_CSM",
      sortable: true,
      widthPX: 250,
      type: "String",
    },
    {
      label: "Order Date",
      valueLabel: "Asana_OrderDate",
      sortable: true,
      widthPX: 250,
      type: "Date",
    },
    {
      label: "Hand Off Date",
      valueLabel: "Asana_HandOff",
      sortable: true,
      widthPX: 250,
      type: "Date",
    },
    {
      label: "Elapsed Days from Order Date",
      valueLabel: "Asana_HandOff_Diff",
      sortable: true,
      widthPX: 250,
      type: "Number",
    },
    {
      label: "Kick Off Date",
      valueLabel: "Asana_KickOff",
      sortable: true,
      widthPX: 250,
      type: "Date",
    },
    {
      label: "Elapsed Days from Order Date",
      valueLabel: "Asana_KickOff_Diff",
      sortable: true,
      widthPX: 250,
      type: "Number",
    },
    {
      label: "Target Date",
      valueLabel: "Asana_Target",
      sortable: true,
      widthPX: 250,
      type: "Date",
    },
    {
      label: "Elapsed Days from Order Date",
      valueLabel: "Asana_Target_Diff",
      sortable: true,
      widthPX: 250,
      type: "Number",
    },
    {
      label: "Intro Date",
      valueLabel: "Asana_CustomerIntro",
      sortable: true,
      widthPX: 250,
      type: "Date",
    },
    {
      label: "Elapsed Days from Order Date",
      valueLabel: "Asana_CustomerIntro_Diff",
      sortable: true,
      widthPX: 250,
      type: "Number",
    },
  ];

  const fetch_last_data_loaded = async (param) => {
    let source = `1,1`;
    const [error, res] = await apiRequest({
      method: "get",
      url: `${apiRoutes.successOnDemandlastDataLoaded}${source}`,
    });
    if (res) {
      setLastLoadedDate(res);
      setIsLoading(false);
    }
    if (error) console.log("err", error);
  };

  const fetch_data = async (days_consulting) => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.techDetails}${days_consulting}`,
    });
    if (data) {
      const formatedData = data.map((item) => {
        return {
          hours: `${item.EverHour_Expend_Hours}/${item.EverHour_Expend_Remaining}`,
          ...item,
        };
      });
      setRowArrayByDays(formatedData);
      setOriginalData(formatedData);
      setCustomersArray(cleanAndSortCustomers(formatedData));
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  // const fetch_user = async () => {
  //   setIsLoading(true);
  //   const [error, data] = await apiRequest({
  //     method: "get",
  //     url: `${apiRoutes.userProfile}`,
  //   });
  //   if(data) {
  //     console.log(data)
  //     setUserName(data);
  //     setIsLoading(false);
  //   } else {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  const fetch_fiscal_years = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.fiscalYears}`,
    });
    if (data) {
      const fiscalOptions = data.map((year) => {
        return {
          value: year.fiscal_year_name,
          name: year.fiscal_year_start,
        };
      });
      const fiscalEndOptions = data.map((year) => {
        return {
          value: year.fiscal_year_name,
          name: year.fiscal_year_end,
        };
      });
      setFiscalYears(fiscalOptions);
      setFiscalEndYears(fiscalEndOptions);
      setFiscalEndValue(fiscalOptions[fiscalOptions.length - 1].value);
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTokenData(Jwt.getItem("all"));
    // fetch_user();
    fetch_data("10");
    fetch_data_no_filter("10");
  }, []);

  const fetch_data_SLA = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.slaReport,
    });
    if (data) {
      setIsLoading(false);
      setSLAReportsView(data);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetch_data_SKU = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.graphSku,
    });
    if (data) {
      setSKUReportsView(data);
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetch_data_SKU_All = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.graphSku2,
      data: { start_date: startFiscalYear, end_date: endFiscalYear },
    });
    if (data) {
      setSKUReportsViewAll(data);
      setTableData(null);
      setKickSku(null);
      setBacklogSku(null);
      setAgingSku(null);
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetch_data_no_filter = async (days_consulting) => {
    setCustomersArray([])
    setIsLoadingSkeleton(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.listProjects,
    });
    if (data) {
      const formatData = data.map((element) => {
        const newArray = {
          ...element,
          Q1: element.Q1.length > 0 ? element.Q1[0] : {},
          Q2: element.Q2.length > 0 ? element.Q2[0] : {},
          Q3: element.Q3.length > 0 ? element.Q3[0] : {},
          Q4: element.Q4.length > 0 ? element.Q4[0] : {},
        };
        if (newArray.Q1.current_now) {
          newArray.Q1.message = "Active work quarter";
          newArray.EverHour_Expend_Hours = newArray.Q1.EverHour_Expend_Hours;
          newArray.EverHour_Expend_Remaining =
            newArray.Q1.EverHour_Expend_Remaining;
        }
        if (newArray.Q2.current_now) {
          newArray.Q2.message = "Active work quarter";
          newArray.EverHour_Expend_Hours = newArray.Q2.EverHour_Expend_Hours;
          newArray.EverHour_Expend_Remaining =
            newArray.Q2.EverHour_Expend_Remaining;
        }
        if (newArray.Q3.current_now) {
          newArray.Q3.message = "Active work quarter";
          newArray.EverHour_Expend_Hours = newArray.Q3.EverHour_Expend_Hours;
          newArray.EverHour_Expend_Remaining =
            newArray.Q3.EverHour_Expend_Remaining;
        }
        if (newArray.Q4.current_now) {
          newArray.Q4.message = "Active work quarter";
          newArray.EverHour_Expend_Hours = newArray.Q4.EverHour_Expend_Hours;
          newArray.EverHour_Expend_Remaining =
            newArray.Q4.EverHour_Expend_Remaining;
        }
        return newArray;
      });
      setRowArray(formatData);
      setOriginalData(formatData);
      setCustomersArrayAll(cleanAndSortCustomers(formatData));
      setIsLoading(false);
    }
    setIsLoadingSkeleton(false);
  };

  useEffect(() => {
    setTokenData(Jwt.getItem("all"));
    fetch_last_data_loaded("page");
  }, []);

  useEffect(() => {
    if (tokenData) {
      setCanSeeReports(
        tokenData.claims.roles.some((role) => {
          return (
            role.role === "Admin" ||
            role.role === "Engagement Manager" ||
            role.role === "Customer Success Manager" ||
            role.role === "Professional Services" ||
            role.role === "Customer Success Engineer" ||
            role.role === "TAM" 
          );
        })
      );
      setIsSales(tokenData.claims.roles.some((role) => {
        return role.role === "Sales";
      }))

      setCSM(tokenData.claims.roles.some((role) => {
        return role.role === "Customer Success Manager";
      }))

      setCanOpenModal(
        tokenData.claims.roles.some((role) => {
          return (
            role.role === "Admin" ||
            role.role === "Engagement Manager" ||
            (role.role === "Customer Success Manager" && getPermission(role.apps))
          );
        })
      );

      setIsCSM(
        tokenData.claims.roles.find((role) => {
          return (
            role.role === "Admin" ||
            role.role === "Engagement Manager" ||
            role.role === "Customer Success Manager" && getPermission(role.apps)
          );
        })
      );
    }
  }, [tokenData]);

  useEffect(() => {
    if (activeTab === "All") {
      setIsLoading(true);
      AllTable.current.sortArrayFunction();
      AllTable.current.setForceUpdateFunction();
      setIsLoading(false);
    }
  }, [activeTab]);

  const getPermission = (apps) => {
    return apps.find(
      (app) =>
        app.app === "SuccessOnDemandApp" && app.permissions.includes("WRITE")
    );
  };

  const cleanAndSortCustomers = (data) => {
    const customerClean = data.filter((element) => {
      return (
        element.Asana_Project_Name !== "" && element.Asana_Project_Name !== null
      );
    });
    let result = customerClean.filter(function ({ Asana_Project_Name }) {
      return !this.has(Asana_Project_Name) && this.add(Asana_Project_Name);
    }, new Set());
    const cleanData = result.map((element) => {
      return { value: element.Asana_Project_Name, ...element };
    });
    const sort = cleanData.sort((a, b) =>
      a.Asana_Project_Name > b.Asana_Project_Name
        ? 1
        : b.Asana_Project_Name > a.Asana_Project_Name
        ? -1
        : 0
    );

    return sort;
  };

  const filtro_days = (element) => {
    fetch_data(element);
  };

  const openEditModal = () => {
    setForm(editableItem);
    setEditModalTitleOpen(true);
  };

  const closeModal = () => {
    setEditModalTitleOpen(false);
    setEditableItem(null);
    setForm(null);
  };

  const submitEditModal = () => {
    const payload = {
      asana_id: form.AsanaId,
      asana_account_manager: form.Asana_Account_Manager,
      asana_account_name: form.Asana_Account_Name,
      asana_csm: form.Asana_CSM,
      asana_slack_channel: form.Asana_slack_channel,
      asana_handover_date: form.Asana_handOff_date,
      asana_kickoff_date: form.Asana_kickOff_date,
      asana_target_date: form.Asana_target_date,
      asana_intro_date: form.Asana_intro_date,
    };
    postEdit(payload);
  };

  const handleChangeInput = (e) => {
    let updateForm = { ...form };
    updateForm[e.target.id] = e.target.value;
    setForm(updateForm);
  };

  const fetch_data_row = async (AsanaId, Asana_Project_Section) => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.lastInfo}${AsanaId}/${Asana_Project_Section}`,
    });
    if (data) {
      setIsLoading(false);
      setArrayRes(data);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetch_data_avg_sku = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "post",
      url: `${apiRoutes.avgSku}`,
      data: { start_date: startFiscalYear, end_date: endFiscalYear },
    });
    if (data) {
      setAvgSku(data);
      setTableData(null);
      setKickSku(null);
      setBacklogSku(null);
      setAgingSku(null);
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetch_data_avg_not_started = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "post",
      url: `${apiRoutes.avgNotStarted}`,
      data: { start_date: startFiscalYear, end_date: endFiscalYear },
    });
    if (data) {
      setAvgNotStarted(data);
      setTableData(null);
      setKickSku(null);
      setBacklogSku(null);
      setAgingSku(null);
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetch_data_not_started = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "post",
      url: `${apiRoutes.notStarted}`,
      data: { start_date: startFiscalYear, end_date: endFiscalYear },
    });
    if (data) {
      setNotStarted(data, setIsLoading(false));
      setTableData(null);
      setKickSku(null);
      setBacklogSku(null);
      setAgingSku(null);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const openModalByDays = (selectedItem) => {
    fetch_data_row(selectedItem.AsanaId, selectedItem.Asana_Project_Section);
    setModalOpen(true);
    setClientName(selectedItem.Asana_Account_Name);
    setNameSection(selectedItem.Asana_Section);
  };

  const postEdit = async (payload) => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "post",
      url: `${apiRoutes.editModalSod}`,
      data: payload,
    });
    if (data) {
      setEditModalTitleOpen(false);
      setEditableItem(null);
      setForm(null);
      fetch_data_no_filter("10");
      setIsLoading(false);
      notificationSubject.next({
        message: "Successfully edited",
        type: "SUCCESS",
      });
    } else {
      console.log(error);
      setIsLoading(false);
      notificationSubject.next({
        message: "There was a problem editing",
        type: "ERROR",
      });
    }
  };

  const periodLiteral = {
    "Period 1": () => "Q1",
    "Period 2": () => "Q2",
    "Period 3": () => "Q3",
    "Period 4": () => "Q4",
  };

  const reportsOption = [
    { value: "Milestone Report", option: "Milestone Report" },
    { value: "Engament status by SKU", option: "PS Status All" },
    {
      value: "Average days to kickoff by SKU",
      option: "Time to Value (days) by SKU",
    },
    { value: "Project backlog by SKU", option: "Avg Projects Not Kicked Off" },
    { value: "Aging by SKU", option: "Aging by Sku" },
  ];

  const openModal = (selectedItem) => {
    setAsanaId(selectedItem.row.AsanaId);
    if (acceptedRowsFilter.includes(selectedItem.column.label)) {
      fetch_data_row(
        selectedItem.row.AsanaId,
        selectedItem.row[periodLiteral[selectedItem.column.label]()]
          .Asana_idSection
      );
      setModalOpen(true);
      setClientName(selectedItem.row.Asana_Account_Name);
      setNameSection(selectedItem.row.Asana_Project_Name);
      fetch_last_data_loaded(selectedItem.column.id);
    }
  };

  const postAccountManager = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.postAccount}${asanaId}/${accountManager}`,
    });
    if (data) {
      fetch_data_no_filter("10");
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const postCSM = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.postCsm}${asanaId}/${asanaId}/`,
    });
    if (data) {
      fetch_data_no_filter("10");
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const downloadMilestonCsv = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.milestoneCsv}`,
    });
    if (data) {
      const str = window.atob(data);
      let download = document.createElement("a");
      download.textContent = "download";
      download.download = "Milestone.csv";
      download.href = "data:text/csv;charset=utf-8," + escape(str);
      download.click();
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const downloadAllCsv = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.allCsv}`,
    });
    if (data) {
      const str = window.atob(data);
      let download = document.createElement("a");
      download.textContent = "download";
      download.download = "All.csv";
      download.href = "data:text/csv;charset=utf-8," + escape(str);
      download.click();
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const downloadDetailedCsv = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.detailCsv}`,
    });
    if (data) {
      const str = window.atob(data);
      let download = document.createElement("a");
      download.textContent = "download";
      download.download = "Detail.csv";
      download.href = "data:text/csv;charset=utf-8," + escape(str);
      download.click();
      setIsLoading(false);
    } else {
      console.log(error);
      setIsLoading(false);
    }
  };

  const clickBar = (e) => {
    if (reportsActiveTab === "PS Status All") {
      const data = SKUReportsViewAll[e[0].index].details
        .filter((element) => {
          return (
            element.details.asana_sku ===
            e[0].element.$datalabels[0].$context.dataset.label
          );
        })
        .map((element) => {
          return {
            asana_csm: element.details.asana_csm
              ? element.details.asana_csm
              : "",
            asana_name: element.details.asana_name
              ? element.details.asana_name
              : "",
            asana_order_date: element.details.asana_order_date
              ? element.details.asana_order_date
              : "",
            asana_project_name: element.details.asana_project_name
              ? element.details.asana_project_name
              : "",
            asana_status: element.details.asana_status
              ? element.details.asana_status
              : "",
            asana_sku: element.details.asana_sku
              ? element.details.asana_sku
              : "",
            link: element.details.link ? element.details.link : "",
          };
        });
      setTableData(data);
    } else if (reportsActiveTab === "Time to Value (days) by SKU") {
      const data = avgSku[e[0].index].details.map((element) => {
        return {
          asana_csm: element.details.asana_csm,
          asana_name: element.details.asana_name,
          asana_order_date: element.details.asana_order_date,
          asana_project_name: element.details.asana_project_name,
          asana_status: element.details.asana_status,
          link: element.details.link,
        };
      });
      setKickSku(data);
    } else if (reportsActiveTab === "Avg Projects Not Kicked Off") {
      const data = avgNotStarted[e[0].index].details.map((element) => {
        return {
          asana_csm: element.details.asana_csm,
          asana_name: element.details.asana_name,
          asana_order_date: element.details.asana_order_date,
          asana_kickoff_date: element.details.asana_kickoff_date,
          asana_project_name: element.details.asana_project_name,
          asana_status: element.details.asana_status,
          link: element.details.link,
        };
      });
      setBacklogSku(data);
    } else if (reportsActiveTab === "Aging by Sku") {
      const data = notStarted[e[0].index].details.map((element) => {
        return {
          asana_csm: element.details.asana_csm,
          asana_name: element.details.asana_name,
          asana_order_date: element.details.asana_order_date,
          asana_project_name: element.details.asana_project_name,
          asana_status: element.details.asana_status,
          link: element.link,
        };
      });
      setAgingSku(data);
    }
  };

  const reportActiveTabLiteral = {
    "Milestone Report": () => fetch_data_SLA(),
    "PS Status All": () => fetch_data_SKU_All(),
    "Time to Value (days) by SKU": () => fetch_data_avg_sku(),
    "Avg Projects Not Kicked Off": () => fetch_data_avg_not_started(),
    "Aging by Sku": () => fetch_data_not_started(),
  };

  const editModalTitleLiteral = {
    "Account Manager": () => "Edit Account Manager",
    CSM: () => "Edit CSM",
  };

  const postEditModalLiteral = {
    "Edit Account Manager": () => postAccountManager(),
    "Edit CSM": () => postCSM(),
  };

  const fetchReports = {
    "Edit Account Manager": () => postAccountManager(),
    "Edit CSM": () => postCSM(),
  };

  const fiscalYearChange = (value, type) => {
    if (type === "start") {
      const date = fiscalYears.find((d) => value === d.value);
      const format = formatDay(date.name);
      setFiscalStart(format);
    } else {
      const date = fiscalEndYears.find((d) => value === d.value);
      const format = formatDay(date.name);
      setFiscalEnd(format);
    }
  };

  const onSelectReport = (value) => {
    switch (value) {
      case "Milestone Report":
        setReportsActiveTab("Milestone Report");
        break;
      case "Engament status by SKU":
        setReportsActiveTab("PS Status All");
        break;
      case "Average days to kickoff by SKU":
        setReportsActiveTab("Time to Value (days) by SKU");
        break;
      case "Project backlog by SKU":
        setReportsActiveTab("Avg Projects Not Kicked Off");
        break;
      case "Aging by SKU":
        setReportsActiveTab("Aging by Sku");
        break;
    }
  };

  const handleChangeMultiSelect = async (values) => {
    if (values.length > 0) {
      let options = values.map((a) => a.value);
      const copyData = originalTableData;
      const filtered = copyData.filter((obj) => {
        return options.includes(obj.Asana_Project_Name);
      });
      activeTab === "All" ? setRowArray(filtered) : setRowArrayByDays(filtered);
    } else {
      activeTab === "All"
        ? setRowArray(originalTableData)
        : setRowArrayByDays(originalTableData);
    }
  };

  const getDataset = () => {
    const convertDataSet = SKUReportsViewAll.map(
      (element) => element.sku_distribution_per_status
    );
    let datasetLabels = [];
    let labels = SKUReportsViewAll.map((element) => element.name);
    for (var i = 0; i < convertDataSet.length; i++) {
      for (var j = 0; j < convertDataSet[i].length; j++) {
        datasetLabels.push(convertDataSet[i][j].distributions);
      }
    }

    const groupBySku = datasetLabels.reduce(function (r, a) {
      r[a.asana_sku] = r[a.asana_sku] || [];
      r[a.asana_sku].push(a);
      return r;
    }, Object.create(null));

    const getData = (key) => {
      const filterArray = labels.map((label) => {
        return groupBySku[key].find((item) =>
          item.status === label ? item.total : 0
        );
      });
      const data = filterArray.map((item) => (item ? item.total : 0));
      return data;
    };

    const getColor = (key) => {
      const filterArray = labels.map((label) => {
        return groupBySku[key].find((item) => item.color);
      });

      return filterArray[0].color;
    };

    const dataset = Object.keys(groupBySku).map(function (key, i) {
      return {
        label: key,
        data: getData(key),
        backgroundColor: getColor(key),
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value, context) => {
            const dataSetArray = [];
            context.chart.data.datasets.forEach((dataset) => {
              if (dataset.data[context.dataIndex] !== undefined) {
                dataSetArray.push(dataset.data[context.dataIndex]);
              }
            });
            const totalSum = (total, datapoint) => {
              return total + datapoint;
            };
            let sum = dataSetArray.reduce(totalSum, 0);
            if (context.datasetIndex === dataSetArray.length - 1) {
              return sum;
            } else {
              return "";
            }
          },
        },
      };
    });
    return dataset;
  };

  useEffect(() => {
    reportsActiveTab && reportActiveTabLiteral[reportsActiveTab]();
  }, [startFiscalYear, endFiscalYear]);

  const formatDay = (date) => {
    const monthsShort = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    if (date) {
      date = date.slice(5, 17);
      let day = date.slice(0, 2);
      let month = monthsShort[date.slice(3, 6)];
      let year = date.slice(7, 11);

      return `${year}-${month}-${day}`;
    }
    return "";
  };

  useEffect(() => {
    if (activeTab === "Reports" && reportsActiveTab !== null) {
      if (reportsActiveTab === "Milestone Report") {
        reportActiveTabLiteral[reportsActiveTab]();
      } else {
        fetch_fiscal_years();
        reportActiveTabLiteral[reportsActiveTab]();
      }
    }
  }, [reportsActiveTab, activeTab]);

  return (
    <>
      {isLoading && (
        <div id="SuccessOnDemand-loader">
          <LoadingSpinner />
        </div>
      )}


     {loadingSkeleton ? <SkeletonTable></SkeletonTable> : <div id="SuccessOnDemand-container">
        {/*
          Section Header
       */}
        <div id="SuccessOnDemand-title-container">
        <div id="PS-Status-title-container">
          <div style={{ display: "flex" }}>
            <h2 id="SuccessOnDemand-title">Success On Demand</h2>
          </div>
          {lastLoadedDate ? (
            <div>
               <div id="SuccessOnDemand-FAQ" onClick={() => setFAQModalOpen(true)}>
                Frequently Asked Questions
                <FontAwesomeIcon id="SuccessOnDemand-FAQIcon" icon={faQuestionCircle} />
              </div>
              <div id="SuccessOnDemand-container-time">
                <label>
                  <b>Last Loaded:</b>
                </label>
                <span>{lastLoadedDate}</span>
              </div>
            </div>
          ) : (
            <div>
              <div id="SuccessOnDemand-FAQ" onClick={() => setFAQModalOpen(true)}>
                Frequently Asked Questions
                <FontAwesomeIcon id="SuccessOnDemand-FAQIcon" icon={faQuestionCircle} />
              </div>
              <div id="SuccessOnDemand-container-time"></div>
            </div>
          )}
        </div>
        </div>

        {/*
       TAB MENU
       */}
        <div className="tabs-container">
          <ul className="tab-list">
            {
              <>
                <li
                  className={
                    activeTab === "Filter By Days"
                      ? "tab-list-item-active"
                      : "tab-list-item"
                  }
                  onClick={() => setActiveTab("Filter By Days")}
                >
                  Filter By Days
                </li>
                <li
                  className={
                    activeTab === "All"
                      ? "tab-list-item-active"
                      : "tab-list-item"
                  }
                  onClick={() => setActiveTab("All")}
                >
                  All
                </li>
              </>
            }
            {
              !isSales && canSeeReports  && <li
                className={
                  activeTab === "Reports"
                    ? "tab-list-item-active"
                    : "tab-list-item"
                }
                onClick={() => setActiveTab("Reports")}
              >
                Reports
              </li>
            }
          </ul>
        </div>
        {/*
       SUB TAB MENU
       */}
        <div className="SuccessOnDemand-tabs-div">
          <div className="sod-filter-panel-statusApp">
            {activeTab === "Reports" && (
              <div
                style={{ margin: "0 10px", minWidth: "350px" }}
                className="input-container-report"
              >
                <label>Report: </label>
                <Dropdown
                  options={reportsOption}
                  onChange={(e) => onSelectReport(e)}
                  value={reportSelectedOption}
                />
              </div>
            )}
            {activeTab !== "All" &&
              activeTab !== "Reports" &&
              customersArray.length > 0 && (
                <>
                  <div className="search-container">
                    <MultiSelect
                      options={customersArray}
                      onChange={(e) => handleChangeMultiSelect(e)}
                      valueToDisplay={"value"}
                      allowOnlyOne={false}
                      defaultValues={null}
                      placeholder={"Customer Name search..."}
                    />
                  </div>
                </>
              )}
            {activeTab === "All" &&
              activeTab !== "Reports" &&
              customersArrayAll.length > 0 && (
                <>
                  <div className="search-container">
                    <MultiSelect
                      options={customersArrayAll}
                      onChange={(e) => handleChangeMultiSelect(e)}
                      valueToDisplay={"value"}
                      allowOnlyOne={false}
                      defaultValues={null}
                      placeholder={"Customer Name search..."}
                    />
                  </div>
                </>
              )}
            {activeTab === "Filter By Days" && (
              <div className="input-container">
                <label>Days Remaining: </label>
                <input
                  className="input-search"
                  onChange={(element) => filtro_days(element.target.value)}
                />
              </div>
            )}
            {activeTab === "All" && (
              <div style={{ marginLeft: "30px" }}>
                <button
                  className="sod-button-export"
                  onClick={() => downloadAllCsv()}
                >
                  {" "}
                  All
                </button>
              </div>
            )}
            {activeTab === "All" && (
              <div style={{ marginLeft: "30px", alignItems: "center" }}>
                {" "}
                <button
                  className="sod-button-export"
                  onClick={() => downloadDetailedCsv()}
                >
                  Detail
                </button>
              </div>
            )}
            {activeTab === "Reports" &&
              reportsActiveTab === "Milestone Report" && (
                <>
                  <a
                    style={{ height: "48px" }}
                    className="sod-button-export"
                    onClick={() => downloadMilestonCsv()}
                  >
                    {" "}
                    <DocIcon
                      style={{
                        width: "27px",
                        cursor: "pointer",
                        top: '-2px',
                        fill: "#fff",
                        left: "5px",
                        position: "absolute",
                      }}
                      onClick={() => {
                        downloadDetailedCsv();
                      }}
                    ></DocIcon>
                  </a>
                </>
              )}
            {activeTab === "All" && editableItem && (
              <div>
                <button
                  className="sod-button-export"
                  onClick={(e) => {
                    openEditModal(e);
                  }}
                >
                  Edit
                </button>
              </div>
            )}
            {activeTab === "Reports" &&
              reportsActiveTab !== "Milestone Report" &&
              reportsActiveTab !== null && (
                <>
                  <div style={{ margin: "0 10px" }} className="input-container">
                    <label>Fiscal Start </label>
                    <Dropdown
                      options={fiscalYears}
                      onChange={(value) => fiscalYearChange(value, "start")}
                      value={endValueFiscalYear}
                    />
                  </div>
                  <div style={{ margin: "0 10px" }} className="input-container">
                    <label>Fiscal End </label>
                    <Dropdown
                      options={fiscalYears}
                      onChange={(value) => fiscalYearChange(value, "end")}
                      value={endValueFiscalYear}
                    />
                  </div>
                </>
              )}
          </div>

          {/*
        Filter By Days Tab Section
       */}
          {activeTab === "Filter By Days" && (
            <SortingTable
              showElementsPerPage={25}
              elementOnClick={(e) => openModalByDays(e)}
              hideCheckbox={true}
              tableHeight="calc(100% - 60px)"
              columnsArray={columnsArrayByDays}
              rowsArray={rowArrayByDays}
              dontShowFilters
            />
          )}

          {/*
        All Tab section
       */}
          {activeTab === "All" && (
            <SortingTable
              onSelectElement={(e) => setEditableItem(e)}
              ref={AllTable}
              showElementsPerPage={25}
              elementOnClickColumn={(e) => openModal(e)}
              hideCheckbox={!canOpenModal}
              multiselect={false}
              tableHeight="calc(100% - 60px)"
              columnsArray={columnsArray}
              rowsArray={rowArray}
            />
          )}
          {/*
          Report tab section
        */}
          {activeTab === "Reports" && (
            <div id="HC-Incidents-container">
              {reportsActiveTab ? (
                <div
                  style={{ width: "100%" }}
                  className={!startFiscalYear ? "sod-container-right" : ""}
                >
                  {/*
            Milestone Report
          */}
                  {reportsActiveTab === "Milestone Report" &&
                    SLAReportsView && (
                      <div style={{ height: "100%", width:'100%' }}>
                        <div id="SOD-Incidents-container-right-SLA-table">
                          <SortingTable
                            columnsArray={SLA_columnsArray}
                            rowsArray={SLAReportsView}
                            iSortDescending={false}
                            hideCheckbox={true}
                            tableHeight="100%"
                            showElementsPerPage={20}
                            elementOnClick={(e) => {}}
                          />
                        </div>
                      </div>
                    )}
                  {/* <Tour
                steps={steps}
                isOpen={isTourOpen}
                onClickHighlighted={(e) => {
                  e.stopPropagation()
                  console.log('No interaction at all')
                }}
                onRequestClose={() => setIsTourOpen(false)}
              /> */}

                  {/*
            Engagement Status by SKU
          */}
                  {reportsActiveTab === "PS Status All" &&
                    (startFiscalYear && SKUReportsViewAll ? (
                      <div>
                        <div
                          id="SOD-Incidents-container-right-SLA-StacklineBars"
                          className="SOD-second-step"
                        >
                          <p style={{ textAlign: "left", fontSize: "10px" }}>
                            This chart will show the project status by SKU
                          </p>
                          <StackedBarChart
                            className={"SOD-second-step"}
                            title="Project Status by SKU"
                            subTitle="Total Projects"
                            data={SKUReportsViewAll}
                            onClickElement={(e) => clickBar(e)}
                            labels={SKUReportsViewAll.map(
                              (element) => element.name
                            )}
                            datasets={getDataset()}
                          />
                        </div>
                        {tableData && (
                          <div id="table-container">
                            <p
                              style={{
                                color: "gray",
                                textAlign: "left",
                                fontSize: "10px",
                                marginTop: "15px",
                              }}
                            >
                              Table data drilldown of projects and data related
                              to the feature selected
                            </p>
                          <div style={{ height: "calc(100% - 55px)" }}>
                          <div id="PS-Status-container-right-top10-table">
                            <SortingTable
                              tableName="TOP10Table"
                              columnsArray={[
                                {
                                  label: "Customer Name",
                                  valueLabel: "asana_name",
                                  sortable: true,
                                  widthPX: 300,
                                  type: "Color",
                                  innerType: "String",
                                },
                                {
                                  label: "Project Name",
                                  valueLabel: "asana_project_name",
                                  sortable: true,
                                  widthPX: 300,
                                  type: "String",
                                  innerType: "String",
                                },
                                {
                                  label: "PS Status",
                                  valueLabel: "asana_status",
                                  sortable: true,
                                  widthPX: 300,
                                  type: "String",
                                  innerType: "String",
                                },
                                {
                                  label: "PS SKU",
                                  valueLabel: "asana_sku",
                                  sortable: true,
                                  widthPX: 300,
                                  type: "String",
                                  innerType: "String",
                                },
                                {
                                  label: "Order Date",
                                  valueLabel: "asana_order_date",
                                  sortable: true,
                                  widthPX: 300,
                                  type: "Date",
                                  innerType: "Date",
                                },
                                {
                                  label: "CSM",
                                  valueLabel: "asana_csm",
                                  sortable: true,
                                  widthPX: 500,
                                  type: "String",
                                  innerType: "String",
                                },
                                {
                                  label: "Asana link",
                                  valueLabel: "link",
                                  sortable: true,
                                  widthPX: 500,
                                  type: "Link",
                                  innerType: "Link",
                                },
                              ]}
                              rowsArray={tableData}
                              iSortDescending={false}
                              hideCheckbox={true}
                              tableHeight="100%"
                              showElementsPerPage={10}
                              elementOnClick={(e) => {}}
                            />
                            </div>
                          </div>
                          </div>
                        )}
                      </div>
                    ) : startFiscalYear &&
                      SKUReportsViewAll !== null &&
                      SKUReportsViewAll.length > 0 ? (
                      <div className="soc-empty-query">
                        <h3>No data available for that Fiscal Year</h3>
                      </div>
                    ) : (
                      <div className="soc-empty-state">
                        <h3>
                          Select a start Fiscal year to generate the report
                        </h3>
                      </div>
                    ))}

                  {/*
                Average Days to Kickoff by SKU
              */}
                  {reportsActiveTab === "Time to Value (days) by SKU" &&
                    (startFiscalYear && avgSku ? (
                      <div>
                        <p style={{ textAlign: "left" }}>
                          This chart will show the average days of projects to
                          kicked off
                        </p>
                        <div id="SOD-Incidents-container-right-SLA-lineBars">
                          <BarChart
                            title="Average Days to Project Kick Off"
                            subTitle="Total Days"
                            data={avgSku}
                            onClickElement={(e) => clickBar(e)}
                            labels={avgSku.map((element) => element.name)}
                            backgroundColors={avgSku.map(
                              (element) => element.color
                            )}
                            values={avgSku.map((element) => element.value)}
                            messages={""}
                            legends={true}
                          />
                        </div>
                        {tableKickData && (
                          <div id="HC-Incidents-container-right-top10-table">
                            <p
                              style={{
                                color: "gray",
                                textAlign: "left",
                                fontSize: "10px",
                              }}
                            >
                              Table data drilldown of projects and data related
                              to the feature selected
                            </p>
                            <SortingTable
                              tableName="TOP10Table"
                              columnsArray={[
                                {
                                  label: "Customer Name",
                                  valueLabel: "asana_name",
                                  sortable: true,
                                  widthPX: 300,
                                  type: "Color",
                                  innerType: "String",
                                },
                                {
                                  label: "Project Name",
                                  valueLabel: "asana_project_name",
                                  sortable: true,
                                  widthPX: 500,
                                  type: "String",
                                  innerType: "String",
                                },
                                {
                                  label: "PS Status",
                                  valueLabel: "asana_status",
                                  sortable: true,
                                  widthPX: 300,
                                  type: "String",
                                  innerType: "String",
                                },
                                {
                                  label: "Order Date",
                                  valueLabel: "asana_order_date",
                                  sortable: true,
                                  widthPX: 200,
                                  type: "Date",
                                  innerType: "Date",
                                },
                                {
                                  label: "Kick Off Date",
                                  valueLabel: "asana_kickoff_date",
                                  sortable: true,
                                  widthPX: 200,
                                  type: "Date",
                                  innerType: "Date",
                                },
                                {
                                  label: "CSM",
                                  valueLabel: "asana_csm",
                                  sortable: true,
                                  widthPX: 500,
                                  type: "String",
                                  innerType: "String",
                                },
                                {
                                  label: "Asana link",
                                  valueLabel: "link",
                                  sortable: true,
                                  widthPX: 500,
                                  type: "Link",
                                  innerType: "Link",
                                },
                              ]}
                              rowsArray={tableKickData}
                              iSortDescending={false}
                              hideCheckbox={true}
                              tableHeight="100%"
                              showElementsPerPage={10}
                              elementOnClick={(e) => {}}
                            />
                          </div>
                        )}
                      </div>
                    ) : startFiscalYear &&
                      avgSku !== null &&
                      avgSku.length > 0 ? (
                      <div className="soc-empty-query">
                        <h3>No data available for that Fiscal Year</h3>
                      </div>
                    ) : (
                      <div className="soc-empty-state">
                        <h3>
                          Select a start Fiscal year to generate the report
                        </h3>
                      </div>
                    ))}

                  {/*
                Project Backlog by SKU
              */}
                  {reportsActiveTab === "Avg Projects Not Kicked Off" &&
                    (startFiscalYear && avgNotStarted ? (
                      <div>
                        <p style={{ textAlign: "left" }}>
                          This chart will show project backlog by SKU
                        </p>
                        <div id="SOD-Incidents-container-right-SLA-lineBars">
                          <BarChart
                            title="Project Backlog by SKU"
                            subTitle="Total Projects"
                            data={avgNotStarted}
                            onClickElement={(e) => clickBar(e)}
                            labels={avgNotStarted.map(
                              (element) => element.name
                            )}
                            backgroundColors={avgNotStarted.map(
                              (element) => element.color
                            )}
                            values={avgNotStarted.map(
                              (element) => element.value
                            )}
                            messages={""}
                            legends={true}
                          />
                        </div>
                        {tableBacklogData && (
                          <div id="HC-Incidents-container-right-top10-table">
                            <p
                              style={{
                                color: "gray",
                                textAlign: "left",
                                fontSize: "10px",
                              }}
                            >
                              Table data drilldown of projects and data related
                              to the feature selected
                            </p>
                            <SortingTable
                              tableName="TOP10Table"
                              columnsArray={[
                                {
                                  label: "Customer Name",
                                  valueLabel: "asana_name",
                                  sortable: true,
                                  widthPX: 300,
                                  type: "Color",
                                  innerType: "String",
                                },
                                {
                                  label: "Project Name",
                                  valueLabel: "asana_project_name",
                                  sortable: true,
                                  widthPX: 500,
                                  type: "String",
                                  innerType: "String",
                                },
                                {
                                  label: "PS Status",
                                  valueLabel: "asana_status",
                                  sortable: true,
                                  widthPX: 300,
                                  type: "String",
                                  innerType: "String",
                                },
                                {
                                  label: "Order Date",
                                  valueLabel: "asana_order_date",
                                  sortable: true,
                                  widthPX: 200,
                                  type: "Date",
                                  innerType: "Date",
                                },
                                {
                                  label: "CSM",
                                  valueLabel: "asana_csm",
                                  sortable: true,
                                  widthPX: 500,
                                  type: "String",
                                  innerType: "String",
                                },
                                {
                                  label: "Asana link",
                                  valueLabel: "link",
                                  sortable: true,
                                  widthPX: 500,
                                  type: "Link",
                                  innerType: "Link",
                                },
                              ]}
                              rowsArray={tableBacklogData}
                              iSortDescending={false}
                              hideCheckbox={true}
                              tableHeight="100%"
                              showElementsPerPage={10}
                              elementOnClick={(e) => {}}
                            />
                          </div>
                        )}
                      </div>
                    ) : startFiscalYear &&
                      avgNotStarted !== null &&
                      avgNotStarted.length > 0 ? (
                      <div className="soc-empty-query">
                        <h3>No data available for that Fiscal Year</h3>
                      </div>
                    ) : (
                      <div className="soc-empty-state">
                        <h3>
                          Select a start Fiscal year to generate the report
                        </h3>
                      </div>
                    ))}
                  {/*
                Aging by Sku
              */}

                  {reportsActiveTab === "Aging by Sku" && notStarted && (
                    <div>
                      <p style={{ textAlign: "left" }}>
                        This chart will show the average days of projects not
                        kicked off by SKU
                      </p>
                      <div id="SOD-Incidents-container-right-SLA-lineBars">
                        <BarChart
                          labels={notStarted.map((element) => element.name)}
                          data={notStarted}
                          backgroundColors={notStarted.map(
                            (element) => element.color
                          )}
                          values={notStarted.map((element) => element.value)}
                          messages={""}
                          title="Aging by SKU"
                          subTitle="Total Projects"
                          onClickElement={(e) => clickBar(e)}
                          legends={true}
                        />
                      </div>
                      {tableAgingData && (
                        <div id="HC-Incidents-container-right-top10-table">
                          <p
                            style={{
                              color: "gray",
                              textAlign: "left",
                              fontSize: "10px",
                            }}
                          >
                            Table data drilldown of projects and data related to
                            the feature selected
                          </p>
                          <SortingTable
                            tableName="TOP10Table"
                            columnsArray={[
                              {
                                label: "Customer Name",
                                valueLabel: "asana_name",
                                sortable: true,
                                widthPX: 200,
                                type: "Color",
                                innerType: "String",
                              },
                              {
                                label: "Project Name",
                                valueLabel: "asana_project_name",
                                sortable: true,
                                widthPX: 500,
                                type: "String",
                                innerType: "String",
                              },
                              {
                                label: "PS Status",
                                valueLabel: "asana_status",
                                sortable: true,
                                widthPX: 300,
                                type: "String",
                                innerType: "String",
                              },
                              {
                                label: "Order Date",
                                valueLabel: "asana_order_date",
                                sortable: true,
                                widthPX: 200,
                                type: "Date",
                                innerType: "Date",
                              },
                              {
                                label: "CSM",
                                valueLabel: "asana_csm",
                                sortable: true,
                                widthPX: 500,
                                type: "String",
                                innerType: "String",
                              },
                              {
                                label: "Asana link",
                                valueLabel: "link",
                                sortable: true,
                                widthPX: 500,
                                type: "Link",
                                innerType: "Link",
                              },
                            ]}
                            rowsArray={tableAgingData}
                            iSortDescending={false}
                            hideCheckbox={true}
                            tableHeight="100%"
                            showElementsPerPage={10}
                            elementOnClick={(e) => {}}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="soc-empty-state">
                  <h3>Select an option from Report dropdown</h3>
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          show={FAQModalOpen}
          title="FAQ"
          handleClose={() => setFAQModalOpen(!FAQModalOpen)}
          modalFitContent
        >
          <Questions questionsArray={questionsArray} color='rgb(248, 36, 49)' />
        </Modal>
      </div>}

      <>
        <Modal
          show={modalOpen}
          title={`${clientName} - ${nameSection}`}
          handleClose={() => setModalOpen(false)}
        >
          <div id="SuccessOnDemand-modal-container">
            {arrayRes.length > 0 ? (
              <SortingTable
                showElementsPerPage={15}
                tableHeight="calc(100% - 40px)"
                hideCheckbox={true}
                columnsArray={columnsArray_modal}
                rowsArray={arrayRes}
                dontShowPagination={arrayRes.length > 15 ? true : false}
                dontShowFilters={true}
              />
            ) : (
              <>
                <h4>No data available</h4>
              </>
            )}
          </div>
        </Modal>
        {isLoading ? (
          <div id="SuccessOnDemand-loader">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <Modal
              modalFitContent={true}
              show={editModalTitleOpen}
              title={`${editModalTitle} ${lastLoadedDate}`}
              handleClose={() => setEditModalTitleOpen(false)}
            >
              <div className="SOD-form-row">
                <div className="SOD-form-row-inline">
                  <div>
                    <label>Account Id:</label>
                    <input
                      value={form?.AsanaId}
                      className="SOD-form-input"
                      readonly
                      disabled
                    />
                  </div>
                  <div>
                    <label>Project Name:</label>
                    <input
                      value={form?.Asana_Project_Name}
                      className="SOD-form-input"
                      readonly
                      disabled
                    />
                  </div>
                  <div>
                    <label>Account Name:</label>
                    <input
                      id="Asana_Account_Name"
                      onChange={(e) => handleChangeInput(e)}
                      value={form?.Asana_Account_Name}
                      className="SOD-form-input"
                      readOnly={!isCSM}
                      disabled={!isCSM}
                    />
                  </div>
                </div>
                <div className="SOD-form-row-inline">
                  <div>
                    <label>Account Manager:</label>
                    <input
                      id="Asana_Account_Manager"
                      value={form?.Asana_Account_Manager}
                      onChange={(e) => handleChangeInput(e)}
                      className="SOD-form-input"
                    />
                  </div>
                  <div>
                    <label>Slack Channel:</label>
                    <input
                      id="Asana_slack_channel"
                      value={form?.Asana_slack_channel}
                      onChange={(e) => handleChangeInput(e)}
                      className="SOD-form-input"
                      readOnly={!isCSM}
                      disabled={!isCSM}
                    />
                  </div>
                  <div>
                    <label>CSM:</label>
                    <input
                      id="Asana_CSM"
                      value={form?.Asana_CSM}
                      onChange={(e) => handleChangeInput(e)}
                      className="SOD-form-input"
                    />
                  </div>
                </div>
                <div className="SOD-form-row-inline">
                  <div>
                    <label>Customer Intro Date:</label>
                    <input
                      input
                      type="date"
                      id="Asana_intro_date"
                      value={form?.Asana_intro_date}
                      onChange={(e) => handleChangeInput(e)}
                      className="SOD-form-input"
                      readOnly={!isCSM}
                      disabled={!isCSM}
                    />
                  </div>
                  <div>
                    <label>Internal Hand-Off Date:</label>
                    <input
                      input
                      type="date"
                      id="Asana_handOff_date"
                      value={form?.Asana_handOff_date}
                      onChange={(e) => handleChangeInput(e)}
                      className="SOD-form-input"
                      readOnly={!isCSM}
                      disabled={!isCSM}
                    />
                  </div>
                  <div>
                    <label>Target Completion Date:</label>
                    <input
                      input
                      type="date"
                      id="Asana_target_date"
                      value={form?.Asana_target_date}
                      onChange={(e) => handleChangeInput(e)}
                      className="SOD-form-input"
                      readOnly={!isCSM}
                      disabled={!isCSM}
                    />
                  </div>
                  <div>
                    <label>Kickoff Date:</label>
                    <input
                      className="SOD-form-input"
                      type="date"
                      id="Asana_kickOff_date"
                      value={form?.Asana_kickOff_date}
                      onChange={(e) => handleChangeInput(e)}
                      readOnly={!isCSM}
                      disabled={!isCSM}
                    />
                  </div>
                </div>

                <div id="SOD-form-row-buttons">
                  <button
                    className="button-regular"
                    onClick={() => {
                      submitEditModal();
                    }}
                  >
                    Submit
                  </button>
                  <button
                    style={{ backgroundColor: "black" }}
                    className="button-regular"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>{" "}
          </>
        )}
      </>
    </>
  );
}
