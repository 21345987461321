import React, { useState, useEffect, useRef } from "react";
import "./PsStatus.css";
import Modal from "../../components/Modal"
import SortingTable from "../../components/SortingTable"
import { apiRequest, apiRoutes } from "./../../services";
import LoadingSpinner from "../../components/LoadingSpinner";
import MultiSelect from "../../components/MultiSelect";
import { cleanAndSortCustomers } from "../../utils/customers";
import { formatDay } from "../../utils/formatDate";

export default function GeneralReport(props) {

  const [originalTableData, setOriginalData] = useState(null);
  const [customersArrayMyProjects, setCustomersArrayMyProjects] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [tab, setActiveTab] = useState('');
  const [reportTab, setReportTab] = useState('');
  const [reportTypeTab, setReportTypeTab] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [currentTeam, setCurrentTeam] = useState([]);
  const [customersArray, setCustomersArray] = useState([]);
  const [asanaLink, setAsanaLink] = useState(null);
  const [skus, setSkus] = useState([]);
  const [skusApi, setSkusApi] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [regions, setRegions] = useState([]);
  const [status, setStatus] = useState([]);
  const [csms, setCsm] = useState([]);
  const [accountManagers, setAccountM] = useState([]);
  const [skusFilter, setSkusFilter] = useState(null);
  const [skusApiFilter, setSkusApiFilter] = useState(null);
  const [csmFilter, setCsmFilter] = useState(null);
  const [accountMFilter, setAccountMFilter] = useState(null);
  const [regionsFilter, setRegionsFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [skusPillFilter, setSkusPillFilter] = useState(null);
  const [skusApiPillFilter, setSkusApiPillFilter] = useState(null);
  const [csmPillFilter, setCsmPillFilter] = useState(null);
  const [accountMPillFilter, setAccountMPillFilter] = useState(null);
  const [regionsPillFilter, setRegionsPillFilter] = useState(null);
  const [modalFilterOpen, setModalFilterOpen] = useState(false);
  const [statusPillFilter, setStatusPillFilter] = useState(null);
  const [updateModal, setModalUpdateOpen] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [exportGeneralReportUrl, setGeneralReportUrl] = useState(null);
  const [exportRevenueReportUrl, setRevenueReportUrl] = useState(null);
  const [reportPsStatusTypeTab, setReportPsStatusType] = useState('');
  
  const fetch_general_report_data_up_to_date = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.psProjects}`,
    });
    if (data) {
      filterAndClean(data)
      setCustomersArray(cleanAndSortCustomers(data));
      setGeneralReportUrl(`https://backend-test.innovation.csg.pagerduty.com${apiRoutes.psStatusExportGeneralHistorical}`);
      setIsLoading(false);
    } else {
      console.log(error);
      setTableData([])
      setIsLoading(false);
    }
  };

  const fetch_general_report_data_historical = async () => {
    setCustomersArray([]);
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.psProjectsHistoric}`,
    });
    if (data) {
      const groupData = data.map((month) => {
        return month.projects
      })
      const fixData = groupData.flat();
      filterAndClean(fixData);
      setCustomersArray(cleanAndSortCustomers(fixData));
      setGeneralReportUrl(`https://backend-test.innovation.csg.pagerduty.com${apiRoutes.psStatusExportGeneralHistorical}`);
      setIsLoading(false);
    } else {
      console.log(error);
      setTableData([])
      setIsLoading(false);
    }
  };

  const fetch_data_filtered_by_role = async () => {
    setCustomersArray([]);
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.psStatusProjectsByRole}`,
    });
    if (data) {
      filterAndClean(data)
      setCustomersArray(cleanAndSortCustomers(data));
      setIsLoading(false);
    } else {
      console.log(error);
      setTableData([])
      setIsLoading(false);
    }
  };

  const fetch_data_ps_status_updates = async () => {
    setCustomersArray([]);
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.psProjectsStatusUpdates}`,
    });
    if (data) {
      filterAndClean(data)
      setCustomersArray(cleanAndSortCustomers(data));
      setIsLoading(false);
    } else {
      console.log(error);
      setTableData([])
      setIsLoading(false);
    }
  };

  const generateExportURLQuery = () => {
    const skus = `${skusFilter ? 'skus=['+ "'" + skusFilter.join("','") + "'" + ']': ''}`
    return (
            skus ? `?${skus}`: '');
  }

  const handleModal = () => {
    setCustomerName(null);
    setAsanaLink(null);
    setModalUpdateOpen(false);
  }

  const openModal = (selectedItem) => {
    setModalUpdateOpen(true);
    setStatuses(selectedItem.statuses);
    setCustomerName(selectedItem.customer_name)
    setAsanaLink(selectedItem.project_link)
  };

  const openFilterModal = (value) => {
    setModalFilterOpen(value)
  };

  const getFormattedDescription = (string) => {
    string = string.replaceAll('\\n', '\r\n');
    string.split(" ").join("<br/>")
    return string
  }

  const filterAndClean = (data) => {
    const addPortfolio = data.map(obj => {
      const status = getColor(obj.ps_status);
      const customerName = obj.customer_name.trim();
      const portfolioName = portfolios.filter(portfolio => { return portfolio.id === obj.portfolio_fk} )[0];
      
      return { statusColor: status, customer_name_format: customerName, portfolio_name_match: portfolioName && portfolioName.description ? portfolioName.description : '', ...obj}
    });
    const sort = addPortfolio.sort((a,b) => (a.customer_name_format > b.customer_name_format) ? 1 : ((b.customer_name_format > a.customer_name_format) ? -1 : 0))
    const clean = sort.filter((element) => {
      return ( element.customer_name !== '' && element.customer_name !== null);
    });
    setStatusData(clean);
    setOriginalData(clean)
    setTableData(clean);
    setCustomersArrayMyProjects(cleanAndSortCustomers(clean));
    const filterRegion = clean.map((item, i) => {
      return {value:item.region, ...item}
    })
    let cleanRegion = filterRegion.filter(function({region}) {
      return region && !this.has(region) && this.add(region);
    }, new Set)
    setRegions(cleanRegion);
    const filterStatus = clean.map((item) => {
      return item.ps_status !== null && {value:item.ps_status, ...item}
    });
    let cleanStatus = filterStatus.filter(function({ps_status}) {
      return ps_status && !this.has(ps_status) && this.add(ps_status);
    }, new Set)
    setStatus(cleanStatus);
    const filterCsm = clean.map((item) => {
      return item.customer_success_manager !== null && {value:item.customer_success_manager, ...item}
    });
    let cleanCsm = filterCsm.filter(function({customer_success_manager}) {
      return customer_success_manager && !this.has(customer_success_manager) && this.add(customer_success_manager);
    }, new Set)
    setCsm(cleanCsm);
    const filterAM = clean.map((item) => {
      return item.account_manager !== null && {value:item.account_manager, ...item}
    });
    let cleanAM = filterAM.filter(function({account_manager}) {
      return account_manager && !this.has(account_manager) && this.add(account_manager);
    }, new Set)
    setAccountM(cleanAM);
    const filterSku = clean.map((item) => {
      return item.sku !== null && {value:item.sku, ...item}
    });
    let cleanSku = filterSku.filter(function({sku}) {
      return sku && !this.has(sku) && this.add(sku);
    }, new Set)
    setSkus(cleanSku);

  }

  useEffect(() => {
    customersArray.length > 0 && setCurrentTeam(customersArray[0]);
      
  }, [customersArray]);

  useEffect(() => {
    fetch_general_report_data_up_to_date();
    setReportTypeTab('UpToDate');
    if(props.reportTab === 'PSGeneralReport') {
      setReportPsStatusType('All')
    }
      
  }, [props]);

  const getColor = (status) => {
    if (status){
      if(status.includes('On Track')) {
        return '#5CA183';
      } else if(status.includes('At Risk') || status.includes('Churn')) {
        return '#F1BD6C';
      } else if(status.includes('Off Track')) {
        return '#D1395A';
      } else if(status.includes('On hold')) {
        return '#4573D1';
      }
      else {
        return '#5CA183';
      }
    }
  }


  useEffect(() => {
    reportTabLiteral.hasOwnProperty(reportTab) && reportTabLiteral[reportTab]();
  }, [reportTab]);

  useEffect(() => {
    TabLiteral.hasOwnProperty(tab) && TabLiteral[tab]();
  }, [tab]);

  useEffect(() => {
    reportTypeTabLiteral.hasOwnProperty(reportTypeTab) && reportTypeTabLiteral[reportTypeTab]();
  }, [reportTypeTab]);


const filterData = () => {
  const filters = [
    { filterFn: (item) => !skusFilter || skusFilter.includes(item.sku) },
    { filterFn: (item) => !skusApiFilter || skusApiFilter.includes(item.portfolio_name) },
    { filterFn: (item) => !regionsFilter || regionsFilter.includes(item.region) },
    { filterFn: (item) => !statusFilter || statusFilter.includes(item.ps_status) },
    { filterFn: (item) => !csmFilter || csmFilter.includes(item.customer_success_manager) },
    { filterFn: (item) => !accountMFilter || accountMFilter.includes(item.account_manager) }
  ];
  const filteredData = originalTableData.filter(item => {
    return filters.every(filter => !filter.filterFn || filter.filterFn(item));
  });
  
  filteredData.length > 0 ? setTableData(filteredData) : setTableData(originalTableData);
}

  const handleChangeMultiSelect = async (values) => {
    if(values.length > 0) {
      let options = values.map(a => a.value);
      const copyData = statusData;
      const filtered = copyData.filter(obj => {
        return options.includes(obj.customer_name_format)
      });
      setTableData(filtered);
    } else {
      setTableData(statusData)
    }
  };

  useEffect(() => {
    reportPsStatusType.hasOwnProperty(reportPsStatusTypeTab) && reportPsStatusType[reportPsStatusTypeTab]();
  }, [reportPsStatusTypeTab]);

  const getReportTable = () => {
    if(props.reportTab === 'PSGeneralReport') {
      return [
        { label: 'Customer Name', valueLabel: 'customer_name_format', sortable: true, widthPX: 300, textAlignDef:'left',type: 'String', innerType: 'String' },
        { label: 'Service Package', valueLabel: 'service_package', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Project Name', valueLabel: 'project_name', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Status', valueLabel: 'statusColor', sortable: true, widthPX: 300, type: 'Color',innerType: 'Color' },
        { label: 'PS Status', valueLabel: 'ps_status', sortable: true, widthPX: 300, type: 'String',innerType: 'String' },
        { label: 'Progress Percentage', valueLabel: 'progress_percentage', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Order Date', valueLabel: 'order_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear'},
        { label: 'Welcome Email Date', valueLabel: 'welcome_email_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear' },
        { label: 'Optimization Kickoff Date', valueLabel: 'optimization_kickoff_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear'},
        { label: 'Target Completion Date', valueLabel: 'target_completion_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear' },
        { label: 'Optimization End Date', valueLabel: 'optimization_end_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear'},
        { label: 'Account Manager', valueLabel: 'account_manager', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'CSM', valueLabel: 'customer_success_manager', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Engagement Manager', valueLabel: 'engagement_manager', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Project Owner', valueLabel: 'project_owner', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Region', valueLabel: 'region', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Salesforce Opportunity Link', valueLabel: 'salesforce_link', sortable: true, widthPX: 300, type: 'Link', innerType: 'Link',textAlignDef:'left' },
        { label: 'Load Date', valueLabel: 'creation_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear' },
      ]
    } else if (props.reportTab === 'GeneralReport') {
      return [
        { label: 'Customer Name', valueLabel: 'customer_name_format', sortable: true, widthPX: 300, textAlignDef:'left',type: 'String', innerType: 'String' },
        { label: 'Service Package', valueLabel: 'service_package', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Project Name', valueLabel: 'project_name', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Status', valueLabel: 'statusColor', sortable: true, widthPX: 300, type: 'Color',innerType: 'Color' },
        { label: 'PS Status', valueLabel: 'ps_status', sortable: true, widthPX: 300, type: 'String',innerType: 'String' },
        { label: 'Progress Percentage', valueLabel: 'progress_percentage', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Welcome Email Date', valueLabel: 'welcome_email_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear' },
        { label: 'Optimization Kickoff Date', valueLabel: 'optimization_kickoff_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear'},
        { label: 'Target Completion Date', valueLabel: 'target_completion_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear' },
        { label: 'Optimization End Date', valueLabel: 'optimization_end_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear'},
        { label: 'Account Manager', valueLabel: 'account_manager', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        {label: 'CSM', valueLabel: 'customer_success_manager', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Engagement Manager', valueLabel: 'engagement_manager', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Region', valueLabel: 'region', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Salesforce Opportunity Link', valueLabel: 'salesforce_link', sortable: true, widthPX: 300, type: 'Link', innerType: 'Link',textAlignDef:'left' },
        { label: 'Load Date', valueLabel: 'creation_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear' },
      ]
    } else {
      return [
        { label: 'Account Name', valueLabel: 'customer_name_format', sortable: true, widthPX: 300, textAlignDef:'left',type: 'String', innerType: 'String' },
        { label: 'Project Name', valueLabel: 'project_name', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
        { label: 'Progress Percentage', valueLabel: 'progress_percentage', sortable: true, widthPX: 200, type: 'String', innerType: 'String' },
        { label: 'Order Date', valueLabel: 'order_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear'},
        { label: 'Load Date', valueLabel: 'creation_date', sortable: true, widthPX: 200, type: 'DateYear', innerType: 'DateYear' },
      ]
    }
  }

  const handleChangeMultiSelectSkus = async (values) => {
    setSkusPillFilter(values);
    let options = values.map(a => a.value);
    setSkusFilter(values && values.length > 0 ? options: null);
  };

  const handleChangeMultiSelectSkusApi = async (values) => {
    setSkusApiPillFilter(values);
    let options = values.map(a => a.value);
    setSkusApiFilter(values && values.length > 0 ? options: null);
  };

  const handleChangeMultiSelectRegions = async (values) => {
    setRegionsPillFilter(values);
    let options = values.map(a => a.value);
    setRegionsFilter(values && values.length > 0? options: null)
  };

  const handleChangeMultiSelectStatus = async (values) => {
    setStatusPillFilter(values);
    let options = values.map(a => a.value);
    setStatusFilter(values && values.length > 0  ? options: null)
  };

  const handleChangeMultiSelectAccountManeger = async (values) => {
    setAccountMPillFilter(values);
    let options = values.map(a => a.value);
    setAccountMFilter(values && values.length > 0 ? options: null)
  };

  const handleChangeMultiSelectCsm = async (values) => {
    setCsmPillFilter(values);
    let options = values.map(a => a.value);
    setCsmFilter(values && values.length > 0 ? options: null)
  };

  const applyFilters = () => {
    openFilterModal(false);
    if(props.reportTab === 'GeneralReport') {
      switch(reportTypeTab) {
        case 'Historical':
        ((skusFilter && skusFilter.length > 0)|| (skusApiFilter && skusApiFilter.length > 0)|| (regionsFilter && regionsFilter.length > 0) || (statusFilter && statusFilter.length > 0) || csmFilter && csmFilter.length > 0 || accountMFilter && accountMFilter.length > 0) ? filterData() : fetch_general_report_data_historical();
         break;
        case 'UpToDate':
          ((skusFilter && skusFilter.length > 0) || (skusApiFilter && skusApiFilter.length > 0) || (regionsFilter && regionsFilter.length > 0) || (statusFilter && statusFilter.length > 0) || csmFilter && csmFilter.length > 0 || accountMFilter && accountMFilter.length > 0) ? filterData() :  fetch_general_report_data_up_to_date('10');
        break;
      }
    } else {
      switch(reportPsStatusTypeTab) {
        case 'All':
        ((skusFilter && skusFilter.length > 0)|| (skusApiFilter && skusApiFilter.length > 0)|| (regionsFilter && regionsFilter.length > 0) || (statusFilter && statusFilter.length > 0) || csmFilter && csmFilter.length > 0 || accountMFilter && accountMFilter.length > 0) ? filterData() : fetch_data_ps_status_updates();
         break;
        case 'My Projects':
          ((skusFilter && skusFilter.length > 0) || (skusApiFilter && skusApiFilter.length > 0) || (regionsFilter && regionsFilter.length > 0) || (statusFilter && statusFilter.length > 0) || csmFilter && csmFilter.length > 0 || accountMFilter && accountMFilter.length > 0) ? filterData() :  fetch_data_filtered_by_role();
        break;
      }
    }
  };

  const reportTypeTabLiteral = {
    'UpToDate': () =>  fetch_general_report_data_up_to_date(),
    'Historical': () =>  fetch_general_report_data_historical(),
  }

  const reportTabLiteral = {
    'GeneralReport': () => fetch_general_report_data_up_to_date(),
  }
  
  const TabLiteral = {
    'Data': () => reportTypeTab === 'UpToDate' ?  fetch_general_report_data_up_to_date() : fetch_general_report_data_historical(),
  }
  
  const reportPsStatusType = {
    'All': () => fetch_data_ps_status_updates(),
    'My Projects': () => fetch_data_filtered_by_role(),
  }

  const fetch_skus = async () => {
    const [error, res] = await apiRequest({
        method: "get",
        url: apiRoutes.psStatusSkus,
    });
    if (res) {
        setSkusApi(res.map(element => { return { value: element, element } }));
    }
    if (error) console.log('err', error)
  };

  useEffect(() => {
    fetch_skus();
  }, []);


  return (
    <>
      {isLoading && <div id="SuccessOnDemand-loader"><LoadingSpinner /></div>}
      <div className="filter-panel-statusApp">
        {customersArray.length > 0 && currentTeam && reportTab !== 'Revenue' && <><div>
          <MultiSelect
            options={customersArray}
            onChange={(e) => handleChangeMultiSelect(e)}
            valueToDisplay={'value'}
            allowOnlyOne={false}
            defaultValues={null}
            type={'hide'}
            placeholder={'Customer Name typing search...'}
          /></div></>}
            <div className="pills-container">
            {props.reportTab === 'PSGeneralReport' && <ul className="pills-list">
              <li className={reportPsStatusTypeTab === 'My Projects' ? 'pill-list-item-active' : 'pill-list-item'}  onClick={() => { setReportPsStatusType('My Projects') }}>My Projects</li>
              <li className={reportPsStatusTypeTab === 'All'  ? 'pill-list-item-active' : 'pill-list-item'}  onClick={() => { setReportPsStatusType('All') }}>All</li>
            </ul>}
          </div>
          <div className="pills-container">
            {props.reportTab !== 'PSGeneralReport' && <ul className="pills-list">
              <li className={reportTypeTab === 'UpToDate' ? 'pill-list-item-active' : 'pill-list-item'}  onClick={() => { setReportTypeTab('UpToDate') }}>Up to date</li>
              <li className={reportTypeTab === 'Historical'  ? 'pill-list-item-active' : 'pill-list-item'}  onClick={() => { setReportTypeTab('Historical') }}>Historical</li>
            </ul>}
          </div>
          {tableData && tableData.length > 0 && <div style={{marginLeft:'30px', display: 'flex', alignItems: 'baseline'}}>
              {(skusPillFilter || regionsPillFilter || statusFilter || csmPillFilter || accountMPillFilter) && <div className="filter-pills">
              {skusPillFilter && skusPillFilter.map((sku) => {
                return <span>{sku.value}</span>
              })}
              {regionsPillFilter && regionsPillFilter.length > 0 && regionsPillFilter.map((region) => {
                return <span>{region.value}</span>
              })}
              {statusFilter && statusFilter.length > 0 && statusFilter.map((status) => {
                return <span>{status}</span>
              })}
              {csmFilter && csmFilter.length > 0 && csmFilter.map((status) => {
                return <span>{status}</span>
              })}
              {accountMFilter && accountMFilter.length > 0 && accountMFilter.map((status) => {
                return <span>{status}</span>
              })}
              </div>}
              <a className="button-export" onClick={() => { setModalFilterOpen(true) }}>Filter</a></div>}
            {tableData && tableData.length > 0 && props.reportTab === 'GeneralReport' && props.canSeeReports && <div style={{marginLeft:'30px'}}> <a className="button-export" href={exportGeneralReportUrl} download>Export</a></div>}
            {tableData && tableData.length > 0 && props.reportTab === 'Revenue' && props.canSeeReports && <div style={{marginLeft:'30px'}}> <a className="button-export" href={exportRevenueReportUrl} download>Export</a></div>}
        </div>
        {(tableData && tableData.length > 0 ) ? <div style={{ height: '100%' }}>
        <div id="PS-Status-container-right-top10-table">
          <p style={{ textAlign: 'left', fontSize:'11px', margin: '0' }}>*To review details, please click in the row for the respective client</p>
        <SortingTable tableName='TOP10Table' columnsArray={getReportTable()} rowsArray={tableData} iSortDescending={true} hideCheckbox={true} tableHeight='100%' elementOnClick={(e) => { openModal(e)}} showElementsPerPage={10}  dontShowFilters={true} frozenColumn={true}/>
        </div> 
        </div> : tableData !== null ? <div className="soc-empty-query">
              <h3>No data available </h3>
            </div> :<></>}
      <>
        <Modal style={{width: '600px'}}show={updateModal} title={customerName} handleClose={() => handleModal()}>
        {statuses && statuses.length > 0 && <div  style={{color: '#FFF', textAlign:'right', marginBottom:'5px'}}><a style={{color: '#FFF', textDecoration:'none', fontWeight: 'bold', cursor: 'pointer',fontSize: '13px',backgroundColor: '#E86767', padding: '5px',borderRadius: '14px'}} target="_blank" href={asanaLink}> Asana</a></div>}
        {statuses && statuses.length > 0  ? 
          statuses.map(status => 
            {
            return ( <>
            <details className="accordion">
              <summary className="accordion__title">
                <div>
                  <p style={status.status_type === 'complete' ? {color:"#06AC38"} : status.status_type === 'on_track' ? {color:"#00607f"}: status.status_type === 'on_hold' ? {color:"#fa640a"} : status.status_type === 'at_risk'? {color:"#f82431"} : {color:"black"}  }>
                    {status.status_type} 
                  </p>
                </div>
                <div style={{display:'flex'}}>
                  <p style={{fontWeight:600, fontSize:'10px', marginRight:'10px'}}>
                    {`${status.title}`}
                  </p>
                <p style={{fontWeight:300, fontSize:'10px'}}>
                 { `${formatDay(status.modified_at)}` }
                </p>
                </div>
                
              </summary>
            <div className="accordion__content">
                <p >
                {getFormattedDescription(status.description)}
              </p> 
            </div>
            </details>
          </>)
            }) : <strong><p>No updates</p></strong>
          }
          <div id="SOD-form-row-buttons">
              <button style={{ backgroundColor: '#DEE2E8' }} className="button-modal" onClick={() => handleModal()}>Cancel</button>
            </div>
        </Modal>
      </>
      <>
        <Modal show={modalFilterOpen} title={`Filters`} handleClose={() => openFilterModal(false)} text={'Please type in to search for options and use arroykeydown to navigate through dropdown options'}>
          <div id="SuccessOnDemand-modal-container">
             {props.reportTab === 'Revenue' && skusApi.length > 0 && currentTeam && <div>
             <p>SKU</p>
              <MultiSelect
              options={skusApi}
              onChange={(e) => handleChangeMultiSelectSkusApi(e)}
              valueToDisplay={'value'}
              allowOnlyOne={false}
              defaultValues={null}
            /></div>}
             {props.reportTab !== 'Revenue' && <>{skus.length > 0 && currentTeam && <div>
             <p>SKU</p>
              <MultiSelect
              options={skus}
              onChange={(e) => handleChangeMultiSelectSkus(e)}
              valueToDisplay={'value'}
              allowOnlyOne={false}
              defaultValues={null}
            /></div>}
            {regions.length > 0 && currentTeam && <div>
            <p>Regions</p>
              <MultiSelect
              options={regions}
              onChange={(e) => handleChangeMultiSelectRegions(e)}
              valueToDisplay={'value'}
              allowOnlyOne={false}
              defaultValues={null}
            /></div>}
            {status.length > 0 && currentTeam && <div>
            <p>Ps Status</p>
              <MultiSelect
              options={status}
              onChange={(e) => handleChangeMultiSelectStatus(e)}
              valueToDisplay={'value'}
              allowOnlyOne={false}
              defaultValues={null}
            /></div>}
            {status.length > 0 && currentTeam && <div>
            <p>Csm</p>
              <MultiSelect
              options={csms}
              onChange={(e) => handleChangeMultiSelectCsm(e)}
              valueToDisplay={'value'}
              allowOnlyOne={false}
              defaultValues={null}
            /></div>}
            {status.length > 0 && currentTeam && <div>
            <p>Account Manager</p>
              <MultiSelect
              options={accountManagers}
              onChange={(e) => handleChangeMultiSelectAccountManeger(e)}
              valueToDisplay={'value'}
              allowOnlyOne={false}
              defaultValues={null}
            /></div>}</>}
          </div>
          <div id="SOD-form-row-buttons">
              <button style={{ backgroundColor: '#DEE2E8' }} className="button-modal" onClick={() => openFilterModal(false)}>Cancel</button>
              <button className="button-modal" onClick={() => {applyFilters() }}>Apply Filters</button>
            </div>
        </Modal>
      </>
    </>
    
  )
}