import React from "react";
import styles from "./SolutionCard.module.css";

function SolutionCard({ iconSrc, title, description }) {
  return (
    <div className={styles.solutionCard}>
      <div className={styles.iconWrapper}>
        <img src={iconSrc} alt="" className={styles.solutionIcon} />
      </div>
      <h3 className={styles.solutionTitle}>{title}</h3>
      <p className={styles.solutionDescription}>{description}</p>
    </div>
  );
}

export default SolutionCard;
