import { forwardRef, useImperativeHandle } from 'react';
import './ModalHome.css';


const ModalHome = forwardRef(({ title, handleClose, show, modalFitContent, dontShowCloseButton, children }, Ref) => {

    const showHideClassName = show ? "modal-home display-block" : "modal-home display-none";

    useImperativeHandle(Ref, () => ({}));

    return (
        <div className={showHideClassName}>
            <span 
              data-type="inside" 
              tabIndex="0" 
              aria-hidden="true" 
              data-floating-ui-focus-guard="" 
              data-floating-ui-inert="true" 
              style={{
                border: '0px',
                clip: 'rect(0px, 0px, 0px, 0px)',
                height: '1px',
                margin: '-1px',
                overflow: 'hidden',
                padding: '0px',
                position: 'fixed',
                whiteSpace: 'nowrap',
                width: '1px',
                top: '0px',
                left: '0px'
              }}
            ></span>
            <section className="modal-main-home" style={{maxWidth: modalFitContent ? 'fit-content': '97%', padding: title ? '32px' : '0px'}}>
                <div className={title ? 'modal-button-close-div-home-title': 'modal-button-close-div-home'}>
                    <h3 className="modal-title">{title}</h3>
                   {!dontShowCloseButton && <button
                      onClick={handleClose}
                      aria-label="Close"
                      className='modal-button-close-home'
                      style={{color: title ? 'black': 'white',}}
                      type="button"
                    >
                      <svg 
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        style={{
                          height: '1em',
                          width: '1em'
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path 
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                    }
                </div>
                <div id="modal-container-home" style={{height: modalFitContent || 'calc(100% - 48px)'}}>
                    {children}
                </div>
              
            </section>
        </div>
    );
}
);

export default ModalHome;