import React, { useEffect, useState } from "react";
import pageyUser from "../../assets/pageyUser.png";
import { useNavigate } from "react-router-dom";
import Paths from "../../paths";
import  './AuthorizationModal.css';
import { sessionModal } from "../../services/subjects";
import { apiRequest, apiRoutes } from "../../services";

 const  AuthorizationModal = ({}) => { 
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    const timerId = setTimeout(() => {
      logoutService();
    }, 6000);
    return () => clearTimeout(timerId);
  }, [history]);

  const logoutService = async () => {
    setIsLoading(true);
    localStorage.removeItem("token");
    localStorage.removeItem("cart");
    localStorage.removeItem("click_refresh");
    sessionStorage.removeItem("subdomain");
    const [error, data] = await apiRequest({
      method: "put",
      url: `${apiRoutes.login}`,
    })
    if (data) {
      setIsLoading(false);
      sessionModal.next(false);
      history(Paths.Login);
    } else {
      setIsLoading(false);
      sessionModal.next(false);
      history(Paths.Login);
    }
  };

  return (
    <div className="container__authorizationModal">
      <div className="container__info">   
        <div className="container__imgPageyUseAuthorization">
          
				</div>
        <div className="imgPageyUseAuthorization">
            <img
              className="img__authorization"
              src={pageyUser}
            />
          </div>
          {isLoading ? 
            <div className="loading__submit">
              <span></span>
              <span></span>
              <span></span>
            </div> : 
            <div className="container__messageText">
              <div>Sorry, your session has expired. Please log in again to continue using our services.</div>
              <div className="container__btns">
                <button onClick={() => logoutService()} className="btn__logIn">Login</button>
              </div>
          </div>}
      </div>
    </div>
  )
}

export default AuthorizationModal;