import { useRef, useState } from 'react';
import './MultiSelect.css';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';

const MultiSelect = ({ options, onChange, valueToDisplay, defaultValues, allowOnlyOne, placeholder, type }) => {
  const inputRef = useRef(null);
  const [focus, setFocus] = useState(false);
  const [openDropDawn, setOpenDropDawn] = useState(type === 'hide' ? false : true);
  const [textValue, setTextValue] = useState('');
  const [currentSelected, setCurrentSelected] = useState(0);
  const [displaying, setDisplaying] = useState(100);
  const [reset, setReset] = useState(false);
  const [currentOptions, setCurrentOptions] = useState([...options]);
  const [currentValues, setCurrentValues] = useState(
    defaultValues ? (Array.isArray(defaultValues) ? defaultValues : [defaultValues]) : []
  );

  const handleKeyPress = (text) => {
    setTextValue(text);
    const allOptionsC = options.filter(
      (object) => !currentValues.filter((value) => JSON.stringify(value) === JSON.stringify(object)).length
    );
    setCurrentOptions(
      text
        ? allOptionsC.filter((option) =>
          option[valueToDisplay].toString().toLowerCase().startsWith(text.toLowerCase())
        )
        : allOptionsC
    );
    setOpenDropDawn(true);
    setDisplaying(100);
    setCurrentSelected(0);
  };

  const handleBackSpace = (e) => {
    if (e.key === 'Backspace' && textValue === '') {
      currentValues.length === 1 &&  setReset(true)
      setCurrentValues(currentValues.length > 0 ? [...currentValues.slice(0, currentValues.length - 1)] : []);
      setTextValue('');
      setCurrentSelected(0);
     
      inputRef.current?.focus();
    }
    if(e.key === 'ArrowUp') {
      openDropDawn && setCurrentSelected( currentSelected - 1 > 0 ? currentSelected - 1 : 0);
      openDropDawn && currentSelected <= displaying - 4 && setDisplaying(displaying - 100 <= 0 ? 100 : displaying - 100);
    }
    if(e.key === 'ArrowDown') {
      openDropDawn && setCurrentSelected( currentSelected + 1 < currentOptions.length ? currentSelected + 1 : currentOptions.length - 1);
      openDropDawn && currentSelected >= displaying && setDisplaying(displaying + 100);
    }
    if(e.key === 'Enter') {
      if(currentOptions.length > 0) {
        if (currentValues.filter((value) => JSON.stringify(value) === JSON.stringify(currentOptions
          [currentSelected])).length === 0) {
          setCurrentValues(currentValues.length > 0 && !allowOnlyOne ? [...currentValues, currentOptions[currentSelected]] : [currentOptions[currentSelected]]);
          setTextValue('');
          inputRef.current?.focus();
          setTimeout(() => {
            inputRef.current?.scrollIntoView({block: "start", inline: "nearest"});
          }, 0);
          setDisplaying(100);
          setCurrentSelected(0);
        }
      }
    }
  };

  const addClick = (object) => {
    if (currentValues.filter((value) => JSON.stringify(value) === JSON.stringify(object)).length === 0) {
      setCurrentValues(currentValues.length > 0 && !allowOnlyOne ? [...currentValues, object] : [object]);
      setTextValue('');
      inputRef.current?.focus();
      // setTimeout(() => {
      //   inputRef.current?.scrollIntoView({block: "start", inline: "nearest"});
      // }, 0);
      setDisplaying(100);
      setOpenDropDawn(false);
      setCurrentSelected(0);
    }
  };

  useEffect(() => {
    if (defaultValues) {
      setCurrentOptions(
        currentOptions.filter((value) => {
          return JSON.stringify(value) !== JSON.stringify(defaultValues);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(currentValues.length > 0 || reset === true) {
      onChange(
        currentValues,
        options.filter((option) => currentValues.includes(option[valueToDisplay].toString()))
      );
      setCurrentOptions(
        options.filter(
          (object) => !currentValues.filter((value) => JSON.stringify(value) === JSON.stringify(object)).length
        )
      );
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues]);

  const removeClick = (object) => {
    if(currentValues.length === 1) {
      setReset(true);
      setCurrentValues([]);
    } else {
      setCurrentValues(currentValues.filter((value) => JSON.stringify(value) !== JSON.stringify(object)));
    }
    setOpenDropDawn(false);
  };

  const handlerFocus = () => {
    setFocus(true);
    handlerBlurF = () => { };
  };

  const handlerBlur = () => {
    handlerBlurF = () => {
      setTextValue('');
      setFocus(false);
      setOpenDropDawn(type === 'hide' ? false : true);
    };
    setTimeout(() => {
      handlerBlurF();
    }, 200);
  };

  const openFullDropDown = () => {
    inputRef.current?.focus();
  };

  let handlerBlurF = () => {
    setTextValue('');
    setFocus(false);
    setOpenDropDawn(type === 'hide' ? false : true);
  };

  const clickClear = () => {
    inputRef.current?.focus();
    setCurrentValues([]);
    setReset(true);
  };

  const dropdownRef = useRef(null);
  const dropdownContainerRef = useRef(null);

  useEffect(() => {
    const adjustDropdownHeight = () => {
      if (dropdownContainerRef.current) {
        const rect = dropdownContainerRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        const maxHeight = Math.min(spaceBelow - 20, 300); // 10px for margin
        dropdownRef.current.style.maxHeight = `${maxHeight}px`;
        dropdownRef.current.style.overflowY = 'auto';
      }
    };
  
    if (dropdownContainerRef.current) {
      // Perform calculations
      if (focus && openDropDawn) {
        adjustDropdownHeight();
      }
    
      // Add the resize event listener
      const handleResize = () => {
        if (focus && openDropDawn) {
          adjustDropdownHeight();
        }
      };
      // Adjust the height when focus or openDropDawn changes
    
      window.addEventListener('resize', handleResize);
    
      // Remove the event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [focus, openDropDawn]); // Depend on focus and openDropDawn
   

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div
        tabIndex={0}
        onFocus={() => handlerFocus()}
        onBlur={() => handlerBlur()}
        className="InputContainer"
      >
        <div className="InputContainerInner">
          {currentValues.map((value) => (
            <div className="Flex" key={value[valueToDisplay]}>
              <p className="Tag">{value[valueToDisplay]}</p>
              <div onClick={() => removeClick(value)} className="TagIcon">
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          ))}
          <input
            type="text"
            value={textValue}
            onChange={(e) => handleKeyPress(e.target.value)}
            onKeyDown={(e) => handleBackSpace(e)}
            className="InputInput"
            ref={inputRef}
            placeholder={placeholder ? placeholder : ''}
          />
        </div>
        <div className="Flex">
          <div className="InputIcon1" onClick={() => clickClear()}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="InputIcon2" onClick={() => openFullDropDown()}>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
      </div>
      {focus && openDropDawn ? (
        currentOptions.length ? (
          <div className="MultiSelectBody"  tabIndex={0} onFocus={() => handlerFocus()} onBlur={() => handlerBlur()}>
            <ul>
              {currentOptions.map((option, i) => (
                i <= displaying && i >= displaying - 120  && <li key={option[valueToDisplay]} onClick={() => addClick(option)}  style={currentSelected === i ? { backgroundColor: '#e6e3e3' }: {}}>
                  {option[valueToDisplay]}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="MultiSelectNoOptions">No options</div>
        )
      ) : null}
    </div>
  );
};

export default MultiSelect;
