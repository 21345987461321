import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import pageyUser from "../../assets/pageyUser.png";
import ImgSlider from "../../components/ImgSlider"
import wallpaper from "../../assets/wallpaper1.png";
import AppIcon from "../../components/AppIcon";

export default function HomeUserNotCustomer({userOptions, role, name}) {
  const currentDate = new Date();


  return (
    <div className="home-container">
      <section className="home-title">
      <div style={{ display: "flex" }}>
        <AppIcon type="Portal" />
        <h1 className="home-title-text">
          Dashboard <span className="home-title-span">Customer Success Group View</span>
        </h1>
      </div>
      </section>
      <div className="menu-container-top not__customer">
        <div className="card-dashboard-container-side-left">
              <div className="card-dashboard">
                <div style={{ display: "flex" }}>
                  <FontAwesomeIcon icon={faHome} style={{ color: "#117170" }} />
                  <div
                    style={{
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >{`Welcome to the CSG Innovation Portal `}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: '10px',
                  }}
                >
                  <div className="container__homeWelcome">
                    <img
                      className="img__homeWelcome"
                      src={pageyUser}
                    />
                  </div>
                  <div className="home-title-span-user">{name}</div>
                  <div className="container__role">
                    <div className="role__type">{role}</div>
                  </div>
                </div>
              </div>
        </div>
        <div className="slider__app" style={{ backgroundImage: `url(${wallpaper}`,  backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="app__title">Allowed Apps</div>
          <div className="app__slider"><ImgSlider menuItems={userOptions}/> </div>
          <div className="app__total">Total Apps: {userOptions?.length - 1}</div> 
        </div>
      </div>
      <div className="card-dashboard-fit">
        Copyright © {new Date().getFullYear()} Csg Innovation Services -
        Pagerduty, Inc. All rights reserved.
      </div>
    </div>
  )
}