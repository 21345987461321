import React, { Component } from "react";
import './alert.css';

class Alert extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { message } = this.props;
    return (
      <section className="alertWrapper" role="alert">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0a75d81291ca8ca2f91bb233d60f5bdd546b2bfde8c35934c036c7beaf626a3f?apiKey=d88c2efea5264650bc291cc83d80bdbf&"
          className="alertIcon"
          alt="Alert icon"
        />
        <div className="contentContainer">
          <div className="messageWrapper">
            <p className="alertMessage">
              {message}
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default Alert;