import React from "react";

const BadFace = ({ scale }) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={scale ? 198.853 * scale : "198.853px"}
      height={scale ? 198.748 * scale : "198.748px"}
      viewBox="0 0 198.853 198.748"
      enableBackground="new 0 0 198.853 198.748"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.848,99.263c0.177,54.25-44.537,99.324-98.673,99.47
	c-55.326,0.15-100.035-43.947-100.171-98.8C-0.133,44.448,44.11,0.022,99.509,0.015C154.177,0.007,198.67,44.46,198.848,99.263z
	 M99.362,188.28c48.823,0.115,88.817-39.625,89.029-88.466c0.215-49.415-39.995-89.627-89.596-89.601
	C50.725,10.238,10.431,50.389,10.314,98.38C10.193,148.36,49.618,188.163,99.362,188.28z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.076,142.288c-1.437,1.539-2.647,3.965-4.229,4.236
	c-1.856,0.32-4.738-0.631-5.961-2.064c-7.916-9.291-17.884-12.293-29.586-10.398c-6.825,1.104-12.564,4.293-16.885,9.789
	c-2.18,2.771-4.724,4.643-8.017,2.061c-3.46-2.713-2.222-5.785,0.011-8.73c14.258-18.81,47.677-18.753,61.883,0.15
	C131.261,138.618,131.895,140.159,133.076,142.288z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.319,78.579c0.114,6.646-4.864,11.701-11.531,11.711
	c-6.466,0.01-11.459-4.82-11.564-11.188c-0.108-6.55,5.012-11.777,11.549-11.791C69.082,67.299,74.213,72.306,74.319,78.579z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.408,78.931c-0.054,6.616-5.183,11.527-11.865,11.362
	c-6.349-0.157-11.278-5.237-11.197-11.538c0.083-6.5,5.375-11.592,11.895-11.446C142.548,67.449,147.46,72.562,147.408,78.931z"
      />
    </svg>
  );
};

export default BadFace;
