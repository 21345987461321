import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import './Chevron.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';


const Chevron = forwardRef(({ title, chevronColor, onChevronStateChange, nameClass, startOpen, color, children }, Ref) => {
    const [chevronOpen, setChevronOpen] = useState(startOpen);

    useImperativeHandle(Ref, () => ({ setChevronState }));

    const setChevronState = (state) => {
        setChevronOpen(state);
    }

    useEffect(() => {
        onChevronStateChange && onChevronStateChange(chevronOpen);
    }, [chevronOpen]);

    return (
        <div id="Chevron-container" className={nameClass || 'chevron-spacing'}>
            <div id='Chevron-container-title' style={{ backgroundColor: color || '#035F7F' }}>
                <div id='Chevron-title'>{title}</div>
                <div id='Chevron-chevron' style={{ color: chevronColor || '#000' }} onClick={() => { setChevronOpen(!chevronOpen) }}><FontAwesomeIcon icon={chevronOpen ? faChevronCircleUp : faChevronCircleDown} /></div>
            </div>
            <div style={{ display: chevronOpen ? 'unset' : 'none' }}>{children}</div>
        </div>
    );
}
);

export default Chevron;