import './SkeletonDeckGenerator.css';

const SkeletonDeckGenerator = () => {
  return (  
  <div className="contain-skeleton">
      <div id="Deck-generator-title-container">
        <div style={{ display: "flex", alignItems:"baseline" }}>
          <p id="Deck-generator-title">Deck Generator</p>
          <span className="beta-tag">Beta v1.1</span>
      </div>
    </div>
    <div className="form">
      <p className="info-message">...</p>
      <form action="">
        <div className="form-section">
          <div style={{display:'flex'}}>
            <p id="deck-form">
              <label className="skeleton-text"></label>
              <input className="skeleton-input"/>
            </p>
            <p id="deck-form">
              <label className="skeleton-text"></label>
                <input className="skeleton-input"/>
            </p>
          </div>
          <div style={{display:'flex', marginTop:'20px'}}>
            <p id="deck-form">
              <label className="skeleton-text"></label>
              <input className="skeleton-input"/>
            </p>
            <p id="deck-form">
              <label className="skeleton-text"></label>
                <input className="skeleton-input"/>
            </p>
          </div>
          <div style={{display:'flex', marginTop:'20px'}}>
            <p id="deck-form">
              <label className="skeleton-text"></label>
              <input className="skeleton-input"/>
            </p>
          </div>
        </div>
      </form>
    </div>
    <div className="card-dashboard-fit">
     <label className="skeleton-text"></label>
    </div>
  </div>
  )
  };

  export default SkeletonDeckGenerator;