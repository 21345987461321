import { useState, forwardRef, useEffect, useImperativeHandle, } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2";


ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = forwardRef(({ labels, backgroundColors, values }, Ref) => {

    const [chartData, setChartData] = useState({
        datasets: [],
    });

    useEffect(() => {
        setChartData({
            labels: labels,
            datasets: [
                {
                    label: labels,
                    data: values,
                    borderColor: backgroundColors,
                    backgroundColor: backgroundColors,
                    borderWidth: 2,

                },
            ],
        });
    }, []);
    useImperativeHandle(Ref, () => ({}));

    return (
        <div style={{ width: '100%' }}>
            <Doughnut data={chartData} />
        </div>
    );
}
);

export default DoughnutChart;