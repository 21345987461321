import "./SkeletonHome.css"

const SkeletonBanner = () => {
  return (
   <>
     <h2 className="new-title__home">Latest Alerts</h2>
      <div className="news-item__home skeleton__animation" style={{height: '250px', marginBottom: '10px'}} />
      <div className="news-item__home skeleton__animation" style={{height: '250px', marginBottom: '10px'}} />
      <div className="news-item__home skeleton__animation" style={{height: '250px', marginBottom: '10px'}} />
      <div className="news-item__home skeleton__animation" style={{height: '250px', marginBottom: '10px'}} />
      <div className="news-item__home skeleton__animation" style={{height: '250px', marginBottom: '10px'}} />
    </>
  )
};

export default SkeletonBanner;