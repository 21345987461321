import "./SkeletonHome.css"

const SkeletonProfile = () => {
  return (
    <div className="container__infoUser">
      <div className="container__welcome ">
        <div className="welcome skeleton__animation"></div>
      </div>
    </div>
  );
};

export default SkeletonProfile;