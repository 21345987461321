import { forwardRef, useImperativeHandle } from "react";
import { QuestionStyle, Question, Answer, QuestionContainer } from "./QuestionslStyled.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const Questions = forwardRef(({ questionsArray, color }, Ref) => {
  useImperativeHandle(Ref, () => ({}));

  return (
    <QuestionStyle color={color}>
      {questionsArray.map((question) => (
        <QuestionContainer>
          <Question>{question.question}</Question>
          {Array.isArray(question.answer) ? (
            <ul style={{textAlign:'left',listStyleType:'square', paddingLeft:'10px', fontSize:'12px'}}>
              {question.answer.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          ) : (
            <Answer>{question.answer}</Answer>
          )}
        </QuestionContainer>
      ))}
    </QuestionStyle>
  );
});

export default Questions;
