import styled from "styled-components";

export const Contenedor = styled.div`
  display: inline-grid;
  width: 100%;

  & .MuiInputBase-input {
    padding: 10px;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    /* margin: 10px 0px; */
    outline: none;
  }

  & .MuiFormControl-root {
    margin: 10px 0px;
    outline: none;
  }

  & .MuiSvgIcon-root {
    color: hsl(0, 0%, 80%);
  }

  & .MuiSvgIcon-root:hover {
    color: #06ac38;
  }
  & .MuiPickersDay-root {
    background-color: #005a24 !important;
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: hsl(0, 0%, 80%);
  & svg {
    cursor: pointer;
  }
  & svg:hover {
    color: red;
  }
`;
