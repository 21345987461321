import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Paths from "../../paths";
import Table from './../../components/Table/Table'
import { apiRequest, apiRoutes } from "./../../services";
import "./Users.css";
import SortingTable from "../../components/SortingTable"
import AppIcon from '../../components/AppIcon';
import LoadingSpinner from "../../components/LoadingSpinner";

import "./Users.css"
const Users = () => {
  const [error, setError] = useState(null);
  const [usersList, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [form, setForm] = useState({});
  const history = useNavigate();
  const [isEdit, setisEdit] = useState(false);
  const [inputErrors, setErrorInputs] = useState(null);

  const columnsArray = [
    { label: 'Username', valueLabel: 'userName', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
    { label: 'Email', valueLabel: 'userEmail', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
    { label: 'Job Title', valueLabel: 'userJobTitle', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
    { label: 'Role', valueLabel: 'userRole', sortable: true, widthPX: 300, type: 'String', innerType: 'String' },
  ];

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const [error, users] = await apiRequest({
      method: "get",
      url: apiRoutes.users,
    });
    if (users) {
      setUsers(users);
    }
    if (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const headings = [ "Username",
    "Email", "Job Title", "Role"
  ];

  const rows =
    usersList.length &&
    usersList.map((user) => {
      return {
        userName: user.userName,
        userEmail: user.userEmail,
        userJobTitle: user.userJobTitle || '',
        userRole: user.userRole,
      }
    });

  const onSelectedUser = (user) => {
    history(Paths.EditUser.replace(":id", user.userName), { state: { email: user.userEmail, user: user.userName } })
  }

  return (
    <>
      {/* {!isLoading && usersList.length > 0 && */}
      <div id="Administration-container">
        <div id="Administration-title-container">
          <AppIcon type='Administration' />
          <h2 id="Administration-title">Administration</h2>
        </div>
        <div id="Administration-table-container">
          <div id="Administration-button-container">
            <button className="button-regular" onClick={() => history(Paths.AddUser)}>+ Add User</button>
          </div>
          {rows ? <div style={{ height: 'calc(100% - 55px)' }}>
            <SortingTable columnsArray={columnsArray} rowsArray={rows} hideCheckbox={true} tableHeight='calc(100% -2px)' showElementsPerPage={25} elementOnClick={(e) => onSelectedUser(e)} />
          </div> : <LoadingSpinner />}
        </div>
      </div>
      {/* <Modal show={modalOpen} title={'Service Information'} handleClose={() => setModalOpen(false)}>

            </Modal> */}
      {/* } */}
    </>
  )
}

export default Users;