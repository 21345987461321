import {
  TemplatesModalContainer,
  IndividualTemplateContainer,
  LeftPanel,
  TemplatesModalContainerTemplates,
  FootersHCDV,
  TemplatePagesButton,
} from "../../../pages/HealthCheckDataViewer/HealthCheckDataViewerStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import "./SkeletonHCDV.css";
import Tabs from "../../Tabs/index";

const SkeletonHCDV = () => {
  return (
    <div className="home-container" style={{ width: "100%" }}>
      <div style={{ display: "flex"}}>
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%"
          }}
          className="skeleton-IMG-HCDV"
        />
        <h2
          className="skeleton-text-HCDV"
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          Health Check Data Viewer
        </h2>
      </div>
      <div className="containerForm" style={{marginTop: '92px'}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
            columnGap: "20px",
          }}
        >
          <LeftPanel
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p>
            <label className="skeleton-text-HCDV-title">Current Domain</label>
              <input className="skeleton-input-HCDV-title"/>
            </p>
            <p>
            <label className="skeleton-text-HCDV-title">Start Date</label>
              <input className="skeleton-input-HCDV-title"/>
            </p>
            <p>
            <label className="skeleton-text-HCDV-title">Entitie</label>
              <input className="skeleton-input-HCDV-title"/>
            </p>
          </LeftPanel>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginRight: "12px",
            }}
          >
            <Tabs Tabs={[{ label: 'HTML', enable: true }]} isLoading onTabChange={() => {}}>
            <TemplatesModalContainer>
              <div style={{display: 'flex', flexDirection: 'column'}}>
              <p>
            <label className="skeleton-text-HCDV">Week Day</label>
              <input className="skeleton-input-HCDV"/>
            </p>
              <p>
            <label className="skeleton-text-HCDV">Client Logo URL</label>
              <input className="skeleton-input-HCDV"/>
            </p>
            <p>
            <label className="skeleton-text-HCDV">Number of Successfull Cards</label>
              <input className="skeleton-input-HCDV"/>
            </p>
            <p>
            <label className="skeleton-text-HCDV">Number of Failed Cards</label>
              <input className="skeleton-input-HCDV"/>
            </p>
              </div>
              <div>
              <TemplatesModalContainerTemplates>
                <TemplatePagesButton disabled={true}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} className="skeleton-text-HCDV" />
                </TemplatePagesButton>
                <IndividualTemplateContainer>
                  <div
                    className="skeleton-IMG-HCDV"
                    style={{
                      height: "400px",
                      width: "250px",
                      marginBottom: "20px",
                    }}
                  ></div>
                  <div className="skeleton-input-HCDV"></div>
                </IndividualTemplateContainer>
                <TemplatePagesButton disabled={true} className="skeleton-text-HCDV">
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </TemplatePagesButton>
              </TemplatesModalContainerTemplates>

              </div>
            </TemplatesModalContainer>
            <FootersHCDV className="skeleton-text-HCDV">
              Copyright © {new Date().getFullYear()} Csg Innovation Services -
              Pagerduty, Inc. All rights reserved.
            </FootersHCDV>
            </Tabs>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonHCDV;
