import {useState, useEffect, useRef} from "react";
import pageyUser from "../../assets/pageyUser.png";
import {apiRoutes, apiRequest} from "../../services";
import Copyright from "../../components/Copyright"
import wallpaper from "../../assets/wallpaper1.png";
import Jwt from "../../utils/jwt";
import {useNavigate} from "react-router-dom";
import Paths from "../../paths";
import "./SelectClient.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";


export default function SelectClient() {
    const navigate = useNavigate();
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [isOpenDropdownSubdomain, setIsOpenDropdownSubdomain] = useState(false);
    const [clients, setClients] = useState([]);
    const [subdomain, setSubdomains] = useState([]);
    const [filterClients, setFilterClients] = useState({});
    const [salesForceId, setSalesForceId] = useState('');
    const [filterSubdomain, setFilterSubdomain] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermSubdomain, setSearchTermSubdomain] = useState('');
    const [showPage, setShowPage] = useState(false);
    const [loadingSubdomains, setLoadingSubdomains] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const dropdownRefClient = useRef(null);
    const dropdownRefSubdomain = useRef(null);
    const [errorHandlerSubdomain, setErrorHandlerSubdomain] = useState(false);
    const [errorHandlerEmail, setErrorHandlerEmil] = useState(false);
    const [errorSubdomainSubmit, setErrorSubdomainSubmit] = useState(false);
    const [loadingClients, setLoadingClients] = useState(true);
    const history = useNavigate();

    useEffect(() => {
        getNewsClients()
        const closeDropdown = (event) => {

            if (dropdownRefClient.current && !dropdownRefClient.current.contains(event.target)) {
                setIsOpenDropdown(false);
            }

            if (dropdownRefSubdomain.current && !dropdownRefSubdomain.current.contains(event.target)) {
                setIsOpenDropdownSubdomain(false);
            }
        };

        document.addEventListener('click', closeDropdown);

        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, [])

    useEffect(() => {
        const token = Jwt.getItem("all")
        token?.claims?.roles.map((role) => {
            if (role.role === 'Admin' || role.role === 'Premium Csg Member') {
                setShowPage(true)
            } else {
                history(Paths.Home)
            }
        })
    }, [])

    const handleDropdownClick = () => {
        setErrorSubdomainSubmit(false);
        setIsOpenDropdown(!isOpenDropdown);
    };

    const handleDropdownClickSubdomain = () => {
        setIsOpenDropdownSubdomain(!isOpenDropdownSubdomain);
    };


    const getNewsClients = async () => {
        setLoadingClients(true)
        const [error, data] = await apiRequest({
            method: "get",
            url: apiRoutes.getClients,
        });

        if (data?.data) {
            setClients(data?.data?.profiles)
            setLoadingClients(false)
        } else {
            setLoadingClients(false)
        }
    };

    const handleSearchChangeClient = (e) => {
        setSearchTerm(e.target.value);
    };

    const toggleCheckbox = async (client) => {
        setSubdomains([])
        setErrorHandlerEmil(false)
        setErrorHandlerSubdomain(false)
        setLoadingSubdomains(true)
        setFilterClients(client)
        setSalesForceId(client.salesforce_id)
        handleDropdownClick()
        const [error, data] = await apiRequest({
            method: "get",
            url: `${apiRoutes.getSubdomain}?customer_company_name=${client.customer_company_name}&salesforce_id=${client.salesforce_id}`,
        });
        localStorage.setItem("region", client.region);
        if (data?.subdomains?.length) {
            setLoadingSubdomains(false)
            setSubdomains(data?.subdomains)
        } else {
            setLoadingSubdomains(false)
            setSubdomains([])
            setErrorHandlerSubdomain(true)
        }
    }


    const handleSearchChangeSubdomains = (e) => {
        setSearchTermSubdomain(e.target.value);
    };


    const toggleCheckboxSubdomain = async (subdomain) => {
        setFilterSubdomain(subdomain)
        handleDropdownClickSubdomain()
    }


    const onsubmitClient = async () => {
        setLoadingSubmit(true)

        const [error, data] = await apiRequest({
            method: "post",
            url: apiRoutes.setCustomer,
            data: {
                "customer_company_name": filterClients.customer_company_name,
                "customer_subdomain": filterSubdomain.subdomain_name,
                "salesforce_id": salesForceId,
				"asana_sod_id": filterSubdomain.asana_sod_id
            }
        });

        if (data?.status_code === 200) {
            history(Paths.Home);
            sessionStorage.setItem("subdomain", JSON.stringify(filterSubdomain));
            setFilterClients({});
            setFilterSubdomain({});
        } else {
            setFilterClients({});
            setFilterSubdomain({});
            setErrorSubdomainSubmit(true);
            setLoadingSubmit(false);
        }
    }

    const filteredOptions = clients.filter(option =>
        option.customer_company_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredOptionsSubdomains = subdomain.filter(option =>
        option.subdomain_name.toLowerCase().includes(searchTermSubdomain.toLowerCase())
    );


    const disabledBtnSave = Object.keys(filterClients).length > 0 && Object.keys(filterSubdomain).length > 0 ? '' : 'disabled__dtnSaveFilter'

    return (
        <div className="page__containerSelectClient">
            {showPage &&
                <div className="content__selectClient">
                    <div className="card__selectClient" style={{backgroundImage: `url(${wallpaper}`}}>
                        <div style={{display: "flex"}}>
                            <div className="text__welcome">{`Please select the customer you wish to review`}</div>
                        </div>
                        <div
                            className="container__imgPageyUse"
                        >
                            <div className="container__selectClientPageyUser">
                                <img
                                    className="img__selectClientPageyUser"
                                    src={pageyUser}
                                />
                            </div>
                        </div>
                        {errorSubdomainSubmit &&
                            <div className="container__errorHandlerSubdomain">We regret to inform you that, after a
                                thorough search of our records, we have been unable to find any information regarding
                                the requested customer. This may be due to a variety of reasons, such as the customer
                                not being in our database or a possible discrepancy in the information provided. Please
                                try again with another customer name or subdomain.</div>}
                        {errorHandlerSubdomain &&
                            <div className="container__errorHandlerSubdomain">We're sorry, we were unable to find a
                                valid subdomain for this client. We've notified our Innovation Engineering team to
                                investigate this.</div>
                        }
                        {errorHandlerEmail &&
                            <div className="container__errorHandlerSubdomain">Ups, it looks like we couldn't find your
                                email information.</div>
                        }
                        {!loadingClients &&
                            <div ref={dropdownRefClient}>
                                <button className="myAlertsAppReturnButton" onClick={() => navigate('/')}><FontAwesomeIcon className="icon__hide" icon={faArrowLeft} />Return to home</button>
                                <br/><br/>
                                <button className="dropdown-toggleButton" onClick={() => handleDropdownClick()}>
                                    {Object.keys(filterClients).length === 0 ? 'Filter by clients' : filterClients.customer_company_name}
                                </button>
                                {isOpenDropdown && (
                                    <div className="dropdown-menuClient">
                                        <input
                                            className="search__clientsFilter"
                                            type="text"
                                            placeholder="Search client..."
                                            value={searchTerm}
                                            onChange={handleSearchChangeClient}
                                        />
                                        <ul>
                                            {filteredOptions?.sort((a, b) => a.customer_company_name - b.customer_company_name).map(option => (
                                                <li key={option.project_id}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            checked={filterClients.customer_company_name === option.customer_company_name}
                                                            onChange={() => toggleCheckbox(option)}
                                                        />
                                                        <span
                                                            className="customer_name">{option.customer_company_name}</span>
                                                    </label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>}
                        {subdomain.length > 0 &&
                            <div className="container__subdomain" ref={dropdownRefSubdomain}>
                                <button className="dropdown-toggleButton"
                                        onClick={() => handleDropdownClickSubdomain()}>
                                    {Object.keys(filterSubdomain).length === 0 ? 'Filter by subdomain' : filterSubdomain.subdomain_name}
                                </button>
                                {isOpenDropdownSubdomain && (
                                    <div className="dropdown-menuClient">
                                        <input
                                            className="search__clientsFilter"
                                            type="text"
                                            placeholder="Search subdomain..."
                                            value={searchTermSubdomain}
                                            onChange={handleSearchChangeSubdomains}
                                        />
                                        <ul>
                                            {filteredOptionsSubdomains?.sort((a, b) => a.subdomain_name - b.subdomain_name).map(option => (
                                                <li key={option.project_id}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            checked={filterSubdomain.subdomain_name === option.subdomain_name}
                                                            onChange={() => toggleCheckboxSubdomain(option)}
                                                        />
                                                        <span className="subdomain_name">{option.subdomain_name}</span>
                                                    </label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>}
                        {!loadingClients &&
                            <div className="container__btnSubmit">
                                <button
                                    onClick={() => onsubmitClient()}
                                    className={`submit__buttonFilter ${disabledBtnSave}`}
                                    disabled={Object.keys(filterSubdomain).length === 0}
                                >
                                    Submit
                                </button>
                            </div>}
                        {(loadingSubmit || loadingSubdomains || loadingClients) &&
                            (<div className="loading__submit">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>)}
                    </div>
                </div>}
            <div className="footer__selectClient">
                <Copyright/>
            </div>
        </div>
    )
}
