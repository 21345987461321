import { useRef } from "react";
import { faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TOOLTIP_DEFAULT_CONTENT =
  "Select Team Name(s) to see all available Health Check Alerts.";

/** @param {import("./index").MultiCheckboxProps} props
 */
const MultiCheckbox = ({
  dropdownRef,
  filterState,
  filteredOptions,
  handleDropdownClick,
  isOpenDropdown,
  label,
  disabled,
  mapFunction,
  nameLabel,
  searchState,
  setSearchState,
  toggleCheckbox,
  tooltip,
  tooltipText,
}) => {
  const stateRef = useRef(null);
  const handleToggleCheckbox = (value) => (ev) => {
    if (disabled) {
      return;
    }
    ev.stopPropagation();
    toggleCheckbox(value);
    setTimeout(() => {
      if (stateRef != null && stateRef.current != null) {
        stateRef.current.scrollLeft = stateRef.current.scrollWidth;
      }
    }, 10);
  };

  return (
    <div className="dropdown__clients" ref={dropdownRef}>
      <div className="container__tooltipMultiCheckbox">
        <div className="label__inputFilter">{label}</div>
        {tooltip && (
          <div className="tooltip-containerMultiCheck">
            <span className="tooltip__multiCheck">
              {tooltipText === undefined
                ? TOOLTIP_DEFAULT_CONTENT
                : tooltipText}
            </span>
            <div>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="icon__multiCheck"
              />
            </div>
          </div>
        )}
      </div>

      <div
        style={{ opacity: disabled ? "0.5" : "" }}
        className="dropdown-toggleButtonSolutionCategories"
        onClick={handleDropdownClick}
      >
        <div className="container__buttonFilter" ref={stateRef}>
          {filterState.length > 0 &&
            filterState.map((filter) =>
              filteredOptions.map(
                (item) =>
                  filter === mapFunction(item) && (
                    <div key={mapFunction(item)}>
                      <p className="item__filter">
                        {nameLabel(item)}{" "}
                        <FontAwesomeIcon
                          onClick={handleToggleCheckbox(mapFunction(item))}
                          icon={faTimes}
                        />
                      </p>
                    </div>
                  ),
              ),
            )}
        </div>
      </div>

      {isOpenDropdown && !disabled && (
        <div className="dropdown-menuMyAlerts">
          <ul>
            <input
              className="search__subdomainHomeFilter"
              type="text"
              placeholder="Search..."
              value={searchState}
              onChange={(e) => setSearchState(e.target.value)}
            />
            {filteredOptions.length > 0 &&
              filteredOptions?.map((item) => (
                <li key={item.id}>
                  <label>
                    <input
                      type="checkbox"
                      checked={filterState.includes(mapFunction(item))}
                      onChange={handleToggleCheckbox(mapFunction(item))}
                      disabled={disabled}
                    />
                    <span className="customer_name">{nameLabel(item)}</span>
                  </label>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiCheckbox;
