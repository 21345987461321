import "./SkeletonHome.css"

const SkeletonPdUniversity = () => {
  return (
    <div className="container__pdUniversity">
      <div className="pdUniversity__title skeleton__animation"></div>
      <div className="pdUniversity__grid">
        <div className="grid skeleton__animation" ></div>
        <div className="grid skeleton__animation"></div>
        <div className="grid skeleton__animation"></div>
        <div className="grid skeleton__animation"></div>
      </div>
    </div>
  );
};

export default SkeletonPdUniversity;