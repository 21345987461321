import styled from "styled-components";
import designVariables from "./../utils";
import pxToRem from "./../pxToTem"

export const SectionHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: solid 1px ${designVariables.COLOR_NEUTRAL_60};
  padding-top: ${pxToRem(designVariables.SIZE_SPACING_REGULAR)};
  padding-bottom: ${pxToRem(designVariables.SIZE_SPACING_REGULAR)};
`;

export const SectionHeaderTitleContainer = styled.div`
  position: relative;
  flex: 1 1 0;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    padding-right: ${pxToRem(designVariables.SIZE_SPACING_REGULAR)};
  }
  svg {
    stroke: ${designVariables.COLOR_BRAND_PRIMARY_BASE};
    margin-right: ${pxToRem(designVariables.SIZE_SPACING_TIGHT)};
  }
`;

export const SectionHeaderActions = styled.div`
  display: flex;
  flex: none;
  width: auto;
  & > * {
    &:not(:last-child) {
      margin-right: ${pxToRem(designVariables.SIZE_SPACING_TIGHT)};
    }
  }
`;

export const SectionBody = styled.div`
  margin-left: -${pxToRem(designVariables.SIZE_SPACING_BASE_TIGHT)};
  margin-right: -${pxToRem(designVariables.SIZE_SPACING_BASE_TIGHT)};
  margin-top: ${pxToRem(designVariables.SIZE_SPACING_BASE_LOOSE)};
  padding-top: ${pxToRem(designVariables.SIZE_SPACING_BASE_TIGHT)};
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  font-size: ${pxToRem(designVariables.SIZE_FONT_DISPLAY_SMALL)};
  font-weight: ${designVariables.SIZE_FONT_WEIGHT_DISPLAY_SMALL};
`;
export default styled.div`
  position: relative;
  padding-left: ${pxToRem(designVariables.SIZE_SPACING_BASE_TIGHT)};
  padding-right: ${pxToRem(designVariables.SIZE_SPACING_BASE_TIGHT)};
`;

