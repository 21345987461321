import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import './DropdownO.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';


const DropdownO = forwardRef(({ options, onChange, isTypeahead, value, disabled }, Ref) => {
    const [dropdownState, setDropdownState] = useState(false);
    const [currentValue, setCurrentValue] = useState(value || '');
    const [typeaheadValue, setTypeaheadValue] = useState(value || '');

    useImperativeHandle(Ref, () => ({}));

    useEffect(() => {
        setCurrentValue(value);
        setTypeaheadValue(value);
    }, [value]);

    const setCurrentValueFunction = (value, option) => {
        if (!disabled) {
            setCurrentValue(value);
            setTypeaheadValue(value);
            setDropdownState(false);
            onChange && onChange(value, option);
        }
    }

    return (
        <div id='Dropdown'>
            <span id='Dropdown-container' onClick={() => !disabled && setDropdownState(!dropdownState)}>
                <input style={disabled ? { backgroundColor: 'darkgray', width: '100%' } : {width: '100%'}} value={isTypeahead ? typeaheadValue : currentValue} disabled={!isTypeahead || disabled} onChange={(e) => setTypeaheadValue(e.target.value)} />
                <FontAwesomeIcon icon={dropdownState ? faChevronUp : faChevronDown} />
            </span>
            {dropdownState && <div id='Dropdown-options'>
                {options.map((option, index) => (
                    (!isTypeahead || (isTypeahead && option.value.toLowerCase().includes(typeaheadValue.toLowerCase()))) && <div key={uuidv4(option + 'option' + index)} className={option.value === currentValue ? 'Dropdown-option-active' : 'Dropdown-option'} onClick={() => setCurrentValueFunction(option.value, option)}>{option.value}</div>
                ))}
            </div>}
        </div>
    );
}
);

export default DropdownO;