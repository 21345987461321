import React, { useState, useEffect, useRef } from "react";
import "./PsStatus.css";
import SkeletonTable from "../../components/Skeletons/TableSkeleton/TableSkeleton";
import Modal from "../../components/Modal"
import AppIcon from '../../components/AppIcon';
import { apiRequest, apiRoutes } from "./../../services";
import LoadingSpinner from "../../components/LoadingSpinner";
import GeneralReport from "./GeneralReport";
import PsStatusUpdatesReport from "./PsStatusUpdatesReport";
import Revenue from "./Revenue";
import Jwt from "../../utils/jwt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Questions from "../../components/Questions";


export default function PsStatusApp() {
  const [tokenData, setTokenData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSkeleton, setIsLoadingSkeleton] = useState(false);
  const [portfolios, setPortfolios] = useState([]);
  const [isCSM, setIsCSM] = useState(false);
  const [lastLoadedDate, setLastLoadedDate] = useState('');
  const [tab, setActiveTab] = useState('');
  const [reportTab, setReportTab] = useState('');
  const [reportTypeTab, setReportTypeTab] = useState('');
  const [reportPsStatusTypeTab, setReportPsStatusType] = useState('');
  const [isSales, setIsSales] = useState(null);
  const [date, setDate] = useState(null);
  const [canSeeReports, setCanSeeReports] = useState(null)
  const [generalView, setGeneralView] = useState(null);
  const [FAQModalOpen, setFAQModalOpen] = useState(false);

  const questionsArray = [
    {
      question: `I’m unable to find my customer’s project.`,
      answer: `If you’re unable to see your customer after logging in on the PS Status Updates tab, try searching via the customer search box. If you are still unable to find your customer, please let us know in the #csg-ps-status-app Slack channel.`,
    },
  ];

  // const generateURLQuery = () => {
  //   const skus = `${skusFilter && skusFilter.length > 0 ? 'skus=['+ "'" + skusFilter.join("','") + "'" + ']': ''}`
  //   return (
  //           skus ? `?${skus}`: '');
  // }




useEffect(() => {
  reportTabLiteral.hasOwnProperty(reportTab) && reportTabLiteral[reportTab]();
}, [reportTab]);

useEffect(() => {
  TabLiteral.hasOwnProperty(tab) && TabLiteral[tab]();
}, [tab]);

useEffect(() => {
  reportTypeTabLiteral.hasOwnProperty(reportTypeTab) && reportTypeTabLiteral[reportTypeTab]();
}, [reportTypeTab]);


const reportTabLiteral = {
  'GeneralReport': () => (console.log()),
  'Revenue': () => (setReportTab('Revenue')),
  'PSGeneralReport': () => (console.log()),
}

const reportTypeTabLiteral = {
}

const TabLiteral = {
  'Data': () => (console.log()),
  'Graph': () => (console.log()),
}

const fetch_last_data_loaded = async (tab) => {
  const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.psLoadTime,
  });
  setIsLoadingSkeleton(false);
  if (res) {
      setLastLoadedDate(res.execution_date);
  }
  if (error) console.log('err', error);
};

const fetch_portfolios = async () => {
  const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.psStatusPortfolio,
  });
  if (res) {
      setPortfolios( res.map(element => { return { value: element.description, ...element } }));
  }
  if (error) console.log('err', error)
};

useEffect(() => {
  setTokenData(Jwt.getItem('all'));
}, []);

useEffect(() => {
  if(canSeeReports || portfolios.length > 0) {
    setReportPsStatusType('My Projects')
    setReportTab('PSGeneralReport');
  } else  if(generalView  || portfolios.length > 0){
    setReportTab('GeneralReport');
  }
 
}, [generalView, portfolios]);

useEffect(() => {
  if (tokenData) {
    setCanSeeReports(tokenData.claims.roles.some(role => {
      return role.role === 'Admin' || role.role === 'PS Revenue' || role.role === 'Engagement Manager' || role.role === 'Customer Success Manager' || role.role === 'TAM' || role.role === 'Professional Services' || role.role === 'CSG Innovation Team' || role.role === 'Premium Csg Member'
    }));

    setIsSales(tokenData.claims.roles.some(role => {
      return role.role === 'Sales' || role.role === 'Sales CSG -' || role.role === 'Sales Org Extended' || role.role === 'Premium Csg Member'
    }));

    setIsCSM(tokenData.claims.roles.some(role => {
      return role.role === 'Customer Success Manager' || role.role === 'Premium Csg Member'
    }));

    setGeneralView(tokenData.claims.roles.some(role => {
      return role.role === 'Admin' || role.role === 'PS Revenue' || role.role === 'CSG Innovation Team' || role.role === 'Premium Csg Member'
    }));
  }
}, [tokenData]);


  const handleChangeInput = (e) => {
    setIsLoading(true);
    setDate(e.target.value);
  }

  useEffect(() => {
    setIsLoadingSkeleton(true);
    fetch_portfolios();
    fetch_last_data_loaded();
    setActiveTab('Graph');
  }, []);

  return (
    <>
      {/* {isLoading && <div id="SuccessOnDemand-loader"><LoadingSpinner /></div>} */}
      {loadingSkeleton ? <SkeletonTable></SkeletonTable>  :<div id="PS-Status-container">
        <div id="PS-Status-title-container">
          <div style={{ display: 'flex' }}>
            <h2 id="SuccessOnDemand-title">PS Status</h2>
          </div>
          {lastLoadedDate ? <div>
            <div id="PSStatus-FAQ" onClick={() => setFAQModalOpen(true)}>
              Frequently Asked Questions
              <FontAwesomeIcon id="PSStatus-FAQIcon" icon={faQuestionCircle} />
            </div>
            <div id="SuccessOnDemand-container-time">
              <label><b>This data was loaded at:</b></label>
              <span>{lastLoadedDate}</span>
            </div> 
          </div>
          : <>
            <div id="SuccessOnDemand-container-time"></div>
            <div id="PSStatus-FAQ" onClick={() => setFAQModalOpen(true)}>
            Frequently Asked Questions
            <FontAwesomeIcon id="PSStatus-FAQIcon" icon={faQuestionCircle} />
          </div></>}
        </div>
        <div className="tabs-container">
          <ul className="tab-list">
          <>
            <li className={reportTab === 'GeneralReport' ? 'tab-list-item-active' : 'tab-list-item'} onClick={() =>  setReportTab('GeneralReport')}>General Report</li>
            <li className={reportTab === 'Revenue' ? 'tab-list-item-active' : 'tab-list-item'} onClick={() =>  setReportTab('Revenue')}>Revenue %</li>
          </>
          <li className={reportTab === 'PSGeneralReport' ? 'tab-list-item-active' : 'tab-list-item'} onClick={() =>  setReportTab('PSGeneralReport')}>PS Status Updates</li>
          </ul>
        </div>
        <div className="sub-tabs-container">
          {reportTab === 'Revenue' && <ul className="sub-tab-list">
           <li className={tab === 'Data' ? 'sub-tab-list-item-active' : 'sub-tab-list-item'}  onClick={() => { setActiveTab('Data') }}>Report</li>
            <li className={tab === 'Graph' ? 'sub-tab-list-item-active' : 'sub-tab-list-item'}  onClick={() => { setActiveTab('Graph') }}>Graph</li>
          </ul>}
        </div>
        <div id="HealthCheck-button-container">
        </div>
        <div className="">
          <div id="HC-Incidents-container">
            <div id="table-container">  
              {reportTab === 'GeneralReport' && <GeneralReport canSeeReports={generalView} reportTab={reportTab}/>}
              {reportTab === 'Revenue' && <Revenue reportTab={reportTab} tab={tab} canSeeReports={generalView} />}
              {reportTab === 'PSGeneralReport' && <PsStatusUpdatesReport isCSM={isCSM} reportTab={reportTab} tab={tab} canSeeReports={generalView} />}
            </div>
          </div> 
        </div>
      </div>}
        <Modal
          show={FAQModalOpen}
          title="FAQ"
          handleClose={() => setFAQModalOpen(!FAQModalOpen)}
          modalFitContent
        >
          <Questions questionsArray={questionsArray} color='rgb(148, 178, 165)' />
        </Modal>
    </>
  )
}