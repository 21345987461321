import { useState, createContext, useMemo, useContext } from "react";


const UserContext = createContext({
  menu: null,
  profile: null,
  profileSet() {},
  menuSet() {},
});


export const UserProvider = ({ children }) => {
  const [menu, setMenu] = useState(null);
  const [profile, setProfile] = useState(null);
  const [login, setLogin] = useState(false);
  const [cartAlert, setCartAlert] = useState([]);



  const sessionContext = useMemo(
    () => ({
      profileSet: (data) => {
        setProfile(data)
      },
      menuSet: (data) => {
        setMenu(data)
      },
      addAlertToCart: (item) => {
        setCartAlert(prevState => {
          const newState = [...prevState];
          const itemIndex = newState.indexOf(item);
          if (itemIndex === -1) {
            newState.push(item);
          } 
          
          return newState.flat();
        });
      },
      deleteAlertsInCart:  (alert) => {
          const alerts = cartAlert?.filter(item => item?.id !== alert)
          setCartAlert(alerts);
      },
      deleteAllAlertsTeamInCart:  (alert) => {
        const alerts = cartAlert?.filter(cartItem => 
          !alert.some(alertItem => cartItem.id === alertItem.id)
        );
        setCartAlert(alerts);
    },
      clearAllAlerts :  () => {
        setCartAlert([])
      },
      menu,
      profile,
      cartAlert
    })
  )


  return (
    <UserContext.Provider value={sessionContext}>
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => useContext(UserContext)