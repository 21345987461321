import React, { useState, useRef, useEffect } from 'react';
import './MultiSelectPill.css';

const MultiSelectPill = ({ options, selected, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    const newSelected = selected.includes(option)
      ? selected.filter(item => item !== option)
      : [...selected, option];
    onChange(newSelected);
  };

  const removeOption = (option) => {
    const newSelected = selected.filter(item => item !== option);
    onChange(newSelected);
  };

  return (
    <div className="multi-select-container" ref={dropdownRef}>
      <div className="select-wrapper">
        <div className="select-box" onClick={toggleDropdown}>
          <span>{selected.length > 0 ? `${selected.length} selected` : 'Select Type'}</span>
          <span className="arrow"></span>
        </div>
        {isOpen && (
          <ul className="options-list">
            {options.map(option => (
              <li key={option} onClick={() => handleOptionClick(option)}>
                <input
                  type="checkbox"
                  checked={selected.includes(option)}
                  readOnly
                />
                <span>{option}</span>
              </li>
            ))}
          </ul>
        )}
      </div>

      {selected.length > 0 && (
        <div className="selected-pills">
          {selected.map(option => (
            <span key={option} className="pill">
              {option}
              <button onClick={() => removeOption(option)}>×</button>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectPill;