import React, { useState, useEffect } from "react";
import { Redirect, useNavigate } from "react-router-dom";
import { apiRequest, apiRoutes } from "./../../services";
import Dropdown from "../../components/Dropdown";
import Paths from "./../../paths";
import useCSV from "../../hooks/useCSV";
import AppIcon from "../../components/AppIcon";

export default function HealthCheckClient() {
  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();
  const [clients, setClients] = useState([]);
  const { exportCSV } = useCSV();
  const [stacks, setStacks] = useState([]);
  const [currentClient, setCurrentClient] = useState("");
  const [currentStack, setCurrentStack] = useState("");

  const onSelectClient = (value) => {
    setCurrentClient(value);
    // history(Paths.HealthCheck,{ state: { stack: value } })
    fetch_stacks(value);
  };

  const onSelectStack = (value) => {
    setCurrentStack(value);
  };

  useEffect(() => {
    fetch_clients();
  }, []);

  const fetch_Json = async () => {
    const json = stacks.find(
      (element) => element.client_stack_detail_tag === currentStack
    );
    const [error, res] = await apiRequest({
      method: "get",
      url: json.curl_url,
      headers: {
        "instance-id": json.client_stack_detail_id,
        "instance-composite-url": json.instance_composite_url,
      },
    });
    if (res) {
      let download = document.createElement("a");
      download.textContent = "download";
      download.download = "Report.csv";
      download.href = "data:text/csv;charset=utf-8," + escape(res);
      download.click();
    }
    if (error) console.log("err", error);
  };

  const fetch_clients = async () => {
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.healthCheckClients,
    });
    if (res) {
      setClients(
        res.result.map((element) => {
          return { value: element.client_stack_name };
        })
      );
    }
    if (error) console.log("err", error);
  };

  const fetch_stacks = async (client) => {
    const [error, res] = await apiRequest({
      method: "post",
      url: apiRoutes.healthCheckStacks,
      data: { client_stack_name: client },
    });
    if (res) {
      setStacks(
        res.result.map((element) => {
          return { value: element.client_stack_detail_tag, ...element };
        })
      );
    }
    if (error) console.log("err", error);
  };

  // const getJson =  () => {
  //   const fileName = "report.json";
  //   const data = new Blob([JSON.stringify(json.client_stack_detail_url)], { type: "text/json" });
  //   const jsonURL = window.URL.createObjectURL(data);
  //   const link = document.createElement("a");
  //   document.body.appendChild(link);
  //   link.href = jsonURL;
  //   link.setAttribute("download", fileName);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return (
    <div className="home-container" style={{ width: "100%" }}>
      <div style={{ display: "flex" }}>
        <AppIcon type="HealthCheck" />
        <h2 id="HealthCheck-title">Health Check</h2>
      </div>
      {!isLoading ? (
        <></>
      ) : (
        <>
          {" "}
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                minHeight: "90vh",
                gap: '10px',
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  margin: "0px",
                  marginRight: "10px",
                }}
              >
                Client
              </p>
              <Dropdown
                options={clients}
                onChange={(value) => onSelectClient(value)}
                value={currentClient}
              />
              {stacks.length > 0 && (
                <>
                  {" "}
                  <p
                    style={{
                      fontWeight: "bold",
                      margin: "0px",
                      marginRight: "10px",
                    }}
                  >
                    Stack
                  </p>
                  <Dropdown
                    options={stacks}
                    onChange={(value) => onSelectStack(value)}
                    value={currentStack}
                  />
                </>
              )}
              {currentStack !== "" && (
                <div style={{ marginTop: "15px" }}>
                  <button
                    className="button-regular"
                    onClick={() => fetch_Json()}
                  >
                    Download Report
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
