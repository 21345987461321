import { faBoxOpen, faStar, faGraduationCap, faCode, faSliders } from '@fortawesome/free-solid-svg-icons';

export const SERVICES = [
  {
    icon: faStar,
    title: 'Premium Support',
    type: 'support',
    description: 'Top-tier assistance from our Support team, for emergencies or unknown issues.'
  },
  {
    icon: faBoxOpen,
    title: 'Service Request',
    type: 'service',
    description: 'Any request within your contracted hourly capacity.'
  },
  {
    icon: faGraduationCap,
    title: 'Training Request',
    type: 'training',
    description: 'Any need related to payerduty university, courses, training.'
  },
  {
    icon: faCode,
    title: 'Feature',
    type: 'feature',
    description: 'Any new application, function, or fixes.'
  },
  {
    icon: faSliders,
    title: 'Other',
    type: 'other',
    description: 'Top-tier assistance from our Support team 24×7.'
  }
];