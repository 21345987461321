import { forwardRef, useImperativeHandle, useState } from "react";
import {
  BarChartContainer,
  CarouselButton,
  CarouselIcon,
  Contenedor,
  ContenedorApps,
  ContenedorCarousel,
  ContenedorItem,
  ContenedorItems,
} from "./ChartSliderStyled.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import BarChart from "../../components/Charts/BarChart";
import FillingCircle from "../FillingCircle/index.js";
import './ChartSlider.css'
interface Props {
  menuItems: any;
}

const ChartSlider = forwardRef(({ menuItems }: Props, ref) => {
  const [startIndex, setStartIndex] = useState(0);
  useImperativeHandle(ref, () => ({}));

  return (
    <Contenedor>
        <ContenedorApps >
          <ContenedorCarousel>
            <CarouselButton
              onClick={() =>
                setStartIndex(startIndex - 2 > 0 ? 0 : startIndex - 2)
              }
              disabled={startIndex === 0}
            >
              <FontAwesomeIcon icon={faChevronCircleLeft} />
            </CarouselButton>
            <ContenedorItems>
              {menuItems?.slice(startIndex, startIndex + 2).map((item: any) => (
                 <div className="product-card">
                    <h2 className="product-name">{item?.customer_product_name}</h2>
                    <div className="licenses">
                        <div className="licenses-purchased">
                            <h3>Purchased</h3>
                            <span className="percentage scrollSpan">{item?.customer_purchased_licenses}</span>
                        </div>
                        <div className="licenses-deployed">
                            <h3>Deployed</h3>
                            <span  className="percentage scrollSpan">{item?.customer_deployed_licenses}</span >
                        </div>
                        <div className="licenses-deployed">
                            <h3>Available</h3>
                            <span  className="percentage scrollSpan">{item?.customer_available_licenses}</span >
                        </div>
                    </div>
                    <div className="product-type-value">{item?.licence_type}</div>
                </div>              
                ))}
            </ContenedorItems>
            <CarouselButton
              onClick={() => setStartIndex(startIndex + 2)}
              disabled={startIndex + 2 >= menuItems.length}
            >
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </CarouselButton>
          </ContenedorCarousel>
        </ContenedorApps>
    </Contenedor>
  );
});

export default ChartSlider;
